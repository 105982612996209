import React from "react";
// MUI
import {
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { CampaignState } from "../../contexts/Campaign";
import { WarningOutlined } from "@material-ui/icons";
import { COPY } from "../../consts/Copy";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";

function CampaignEditReviewSection({ reviewItems, inline, id, fieldErrors }) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <ListItemText
      id={id}
      primary={reviewItems?.map((item, index) => {
        const error = fieldErrors?.includes(item?.field);
        if (item) {
          return (
            <Box
              minHeight={48}
              display="flex"
              width="100%"
              key={`campaignEditReviewSection-${item.label}-${index}`}
            >
              <Grid container alignItems="center" spacing={4}>
                <Grid item xs={4} md={3}>
                  <Typography color={error ? "error" : "textSecondary"}>
                    {item.label}:
                  </Typography>
                </Grid>
                <Grid item xs={5} md={7}>
                  {inline ? (
                    <Grid container>
                      <Grid item xs={error ? 10 : 12}>
                        <Typography
                          color={
                            error
                              ? "error"
                              : item.value
                              ? "textPrimary"
                              : "textSecondary"
                          }
                          id={`${id}-ItemInlineValue-${index}`}
                        >
                          {item.value ? item.value : "n/a"}
                        </Typography>
                      </Grid>
                      {error ? (
                        <Grid item xs={2}>
                          <WarningOutlined
                            fontSize="small"
                            color="error"
                            id={`${id}-ErrorIcon-${index}`}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid item xs={3} md={2}>
                  <CampaignState>
                    {({ userCanEdit, channelAllowed, channel }) => {
                      return (
                        <Box
                          width="100%"
                          boxSizing="border-box"
                          p={0}
                          pr={xsScreen ? 0 : 1}
                        >
                          {index === 0 ? (
                            <Tooltip
                              disableHoverListener={userCanEdit}
                              title={
                                <Typography variant="caption">
                                  {!userCanEdit
                                    ? channelAllowed
                                      ? COPY.CAMPAIGN_EDIT.REVIEW
                                          .OWNERS_EDIT_MSG
                                      : NOTIFICATION_CHANNELS[channel]?.label +
                                        COPY.CAMPAIGN_EDIT.REVIEW
                                          .DISABLED_CHANNEL_MSG
                                    : ""}
                                </Typography>
                              }
                            >
                              <Typography
                                align="right"
                                color={
                                  userCanEdit ? "textPrimary" : "textSecondary"
                                }
                                id={`${id}-EditButton-${index}`}
                              >
                                <u>Edit</u>
                              </Typography>
                            </Tooltip>
                          ) : null}
                        </Box>
                      );
                    }}
                  </CampaignState>
                </Grid>
                {!inline ? (
                  <Grid item xs={12}>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      id={`${id}-ItemValue-${index}`}
                    >
                      {item.value ? item.value : "n/a"}
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          );
        } else {
          return null;
        }
      })}
    />
  );
}

export default CampaignEditReviewSection;
