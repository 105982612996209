import React, { useEffect, useState } from "react";

// MUI
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";

function SelectInputField({
  value,
  handleChange,
  field,
  componentKey,
  placeholder,
  id,
  inputProps = {},
}) {
  const [selectedOption, setSelectedOption] = useState(" ");
  useEffect(() => {
    if (value) {
      setSelectedOption(value);
    } else if (field.defaultValue) {
      setSelectedOption(field.defaultValue);
      handleChange(field.defaultValue);
    } else {
      setSelectedOption(" ");
    }
  }, [value]);

  return (
    <Box my={1}>
      <FormControl
        variant="outlined"
        fullWidth
        margin="none"
        key={componentKey}
        required={field.required}
        {...inputProps}
      >
        <InputLabel shrink id={`${id}-SelectInputField-Label`}>
          {field.label}
        </InputLabel>
        <Select
          input={
            <OutlinedInput
              notched
              label={field.label}
              name={`${id}-SelectInputField-Input`}
            />
          }
          labelId={`${id}-SelectInputField-Label`}
          value={selectedOption}
          onChange={(e) => {
            if (!placeholder || e.target.value !== " ") {
              handleChange(e.target.value);
            } else {
              handleChange("");
            }
          }}
          label={field.label}
          InputLabelProps={{
            shrink: true,
          }}
        >
          {placeholder ? (
            <MenuItem
              value=" "
              disabled={placeholder.disabled || field.required}
              id={`${id}-SelectInputField-MenuItem-Placeholder`}
            >
              <Typography color="textSecondary">{placeholder.label}</Typography>
            </MenuItem>
          ) : null}
          {field.options?.map((option) => (
            <MenuItem
              value={option.value}
              id={`${id}-SelectInputField-MenuItem-${option.label}`}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SelectInputField;
