import React, { useState } from "react";
import FileUpload from "../common/FileUpload";
import QA from "../../consts/QA";
import { CAMPAIGN } from "../../consts/DBFields";
import { uploadCampaignAttachments } from "../../utils/campaign";
import { CampaignState } from "../../contexts/Campaign";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditAttachments`;

function CampaignEditAttachments({
  value = [],
  handleChange,
  accept,
  label,
  sizeLimit,
  maxFiles,
}) {
  const [uploadError, toggleUploadError] = useState(false); // SAS/upload error

  return (
    <CampaignState>
      {({ selectedCampaign, calculateCampaignAttachmentSize }) => {
        return (
          <FileUpload
            accept={accept}
            id={QA_ID_PREFIX}
            dense
            label={label}
            multiple
            removable
            files={value}
            getTotalFileSize={calculateCampaignAttachmentSize}
            sizeLimit={sizeLimit}
            maxFiles={maxFiles}
            handleUpload={async (files, existingFiles) => {
              const uploaded = [];
              await uploadCampaignAttachments(
                files,
                selectedCampaign?.[CAMPAIGN.TEAM_ID],
                selectedCampaign?.[CAMPAIGN.UID],
                (newFile) => uploaded.push(newFile),
                () => {
                  toggleUploadError(true);
                }
              );
              handleChange(
                existingFiles ? existingFiles.concat(uploaded) : uploaded,
                uploadError
              );
              return uploaded;
            }}
            removeFileAtIndex={async (index) => {
              let updatedValue = [...value];
              updatedValue.splice(index, 1);
              handleChange(updatedValue, false);
            }}
            error={uploadError}
            clearError={() => toggleUploadError(false)}
            dropInstructions={
              <>
                Drag or <u>browse</u> to upload files.
              </>
            }
            fileInstructions={`You can upload up to ${maxFiles} ${accept
              .split(",")
              .map((type, index) => {
                return index < accept.split(",").length - 1
                  ? ` ${type.trim()}`
                  : ` or ${type.trim()}`;
              })} files up to ${sizeLimit / 1000000} MB.`}
          />
        );
      }}
    </CampaignState>
  );
}

export default CampaignEditAttachments;
