import React from "react";

// MUI
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid,
  makeStyles,
  Toolbar,
  useTheme,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { HEX_COLORS } from "../../consts/Colors";

const useStyles = makeStyles(() => ({
  whiteButton: {
    color: "white",
  },
}));

function Overlay({
  isOpen,
  closeOverlay,
  closeText = "Back",
  children,
  id,
  dialogProps = {},
  backgroundColor = "grey"
}) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={() => {
          closeOverlay();
        }}
        id={`${id}-Overlay`}
        {...dialogProps}
      >
        <Grid container spacing={0} style={{ height: "100%" }}>
          <Grid item xs={12}>
            <AppBar color="primary">
              <Toolbar>
                <Button
                  onClick={() => {
                    closeOverlay();
                  }}
                  className={classes.whiteButton}
                  id={`${id}-Overlay-BackButton`}
                >
                  <ArrowBack />{" "}
                  <Box ml={1} id={`${id}-Overlay-BackButton-Text`}>
                    {closeText}
                  </Box>
                </Button>
              </Toolbar>
            </AppBar>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              background:
                theme.palette.type === "dark"
                  ? HEX_COLORS.muiGray
                  : (backgroundColor === "grey" ? HEX_COLORS.lightestGray: HEX_COLORS.white),
              height: "100%",
            }}
          >
            <Box
              width="100%"
              boxSizing="border-box"
              id={`${id}-Overlay-Children`}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

export default Overlay;
