import { Box, makeStyles, Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import QA from '../../../../consts/QA';
import { INPUT_TYPE } from '../grapesjs/consts/Settings';

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-DimensionSlider`;

const useStyle = makeStyles({
  flex1: {
    display: "flex",
    gap: "20px",
  }
});

function DimensionSlider({
    element,
    setting,
    componentSettings,
    label,
    maxValue,
    onChange,
    inputType,
}) {
    const classes = useStyle();

    const [value, setValue] = useState(0);

    useEffect(() => {
      if(inputType === INPUT_TYPE.BORDER){
        const br = componentSettings?.[setting?.ID]?.width
        setValue(br || 0)
      }
      else{
        const br = componentSettings?.[inputType];
        setValue(br || 0);
      }
    }, [componentSettings]);
    return (
        <>
          <Box id={QA_ID_PREFIX} px={1} mt={1}>
            <Box className={classes.flex1}>
                {label && <div style={{width: "40px"}}>{`${label} `}</div>}
                <Slider
                    value={value}
                    onChange={onChange}
                    min={0}
                    max={maxValue}
                    id={`${QA_ID_PREFIX}-${label}-Slider`}
                />
                {`${Math.round(value)}px`}
            </Box>
          </Box>
        </>
    );
}

export default DimensionSlider;
