import React from "react";
import QA from "../../consts/QA";
import CampaignEditCsvUpload from "./CampaignEditCsvUpload";

// MUI
import { Box, Grid } from "@material-ui/core";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditDataConnection`;

function CampaignEditDataConnection() {
  return (
    <>
      <Box
        boxSizing="border-box"
        width="100%"
        overflow="hidden"
        height="100%"
        display="flex"
        alignItems="center"
        id={QA_ID_PREFIX}
      >
        <Grid container alignItems="stretch" justify="center">
          <Grid
            item
            xs={11}
            sm={9}
            md={7}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <CampaignEditCsvUpload />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CampaignEditDataConnection;
