import General from "./General";
import TwoColumn from "./TwoColumn";
import ThreeColumn from "./ThreeColumn";
import CorporateUpdate from "./Comms/CorporateUpdate";
import FiveAndTen from "./Comms/FiveAndTen";
import Letter from "./Comms/Letter";
import LetterLight from "./Comms/LetterLight";
import Scoop from "./Comms/Scoop";
import GTCalendar from "./GlobalTech/Calendar";
import GTEvent from "./GlobalTech/Event";
import GTPromotion from "./GlobalTech/Promotion";
import GTUpdate from "./GlobalTech/Update";
import SamsCalendar from "./Sams/Calendar";
import SamsLetter from "./Sams/Letter";
import SamsLetterLight from "./Sams/LetterLight";
import SamsPromotion from "./Sams/Promotion";
import { THEMES } from "../../consts/Teams";
import Blank from "./Blank";

export default {
  [THEMES.WALMART.value]: [
    Blank,
    General,
    TwoColumn,
    ThreeColumn,
    CorporateUpdate,
    FiveAndTen,
    Letter,
    LetterLight,
    Scoop
  ],
  [THEMES.GLOBAL_TECH.value]: [Blank, GTUpdate, GTCalendar, GTEvent, GTPromotion],
  [THEMES.SAMS.value]: [
    Blank,
    SamsCalendar,
    SamsLetter,
    SamsLetterLight,
    SamsPromotion
  ],
  [THEMES.INTL.value]: [Blank],
};
