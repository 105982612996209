import { FEATURE } from "../../../consts/Common";
import { GALLERY_SCHEMA } from "../../../consts/Template";
import Configs from "../../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const FiveAndTen = {
  [GALLERY_SCHEMA.ID]: "comms_five_and_ten",
  [GALLERY_SCHEMA.NAME]: "5 and 10",
  [GALLERY_SCHEMA.CATEGORY]: FEATURE.COMMS_TEMPLATES,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="i60cc" background-color="#ffffff"><mj-column width="30%" id="i8i5k" vertical-align="middle"><mj-image src="${blob_url}/walmartthemeimages-image/kvh2123a-Walmart-Logo.png" padding-top="20px" padding-bottom="20px" padding-left="20px" padding-right="20px" id="ia2pm"></mj-image></mj-column><mj-column width="70%" id="ih1uf" vertical-align="middle"><mj-text padding-top="5px" padding-bottom="5px" padding-left="15px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ianxq"><p style="text-align: center;"><span style="font-size:10.5pt"><span style="color:#274359"><strong>Link 1&nbsp; &nbsp; Link 2&nbsp; &nbsp; Link 3&nbsp; &nbsp; Link 4&nbsp; &nbsp; Link 5</strong></span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="i2yx3" background-color="#0071dc"><mj-column><mj-text padding-top="16px" padding-bottom="16px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="iinhz"><p style="text-align:center"><strong><span style="font-size:20pt"><span style="color:#ffffff">Walmart 5&amp;10</span></span></strong></p>

<p style="text-align:center"><span style="font-size:11pt"><strong><span style="color:#ffffff">Walmart Week {{week}} | {{date}}</span></strong></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="15px" padding-bottom="15px" padding-left="18px" padding-right="18px" id="is54j" background-color="#ffffff"><mj-column vertical-align="middle" id="iofli" width="40%"><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Type your text here</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#041f41">{ Subtitle here; }</span></span></p>
</mj-text><mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, Arial, sans-serif" font-size="16.0px" background-color="#cccccc" border-radius="10px" width="120px" height="40px" inner-padding="5px 5px" font-weight="normal" color="#041f41" id="iclm9" font-style="normal">Button</mj-button></mj-column><mj-column vertical-align="middle" id="ibe56" width="60%"><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="i5cet"><p><span style="font-size:10.5pt"><span style="color:#041f41"><u><strong>Lorem ipsum dolor sit amet</strong></u>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="20px" padding-left="18px" padding-right="18px" background-color="#ffffff" id="ir62h" padding-bottom="3px"><mj-column><mj-text padding-top="5px" padding-bottom="0px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ilnwk"><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Section Headline Goes Here //*</span></span></p>
</mj-text><mj-divider border-width="2px" padding-top="3px" padding-bottom="0px" padding-left="20px" padding-right="20px" border-color="#02f17a" id="i6nvz"></mj-divider></mj-column></mj-section><mj-section padding-top="18px" padding-bottom="3px" padding-left="18px" padding-right="18px" id="ihjvj" background-color="#ffffff"><mj-column width="55%" id="izj3s"><mj-text padding-top="0px" padding-bottom="5px" padding-left="5px" padding-right="16px" font-family="Bogle, sans-serif" line-height="1.5" id="i5yuf"><p><span style="color:#041f41"><span style="font-size:10.5pt"><u><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong></u></span></span></p>

<p><span style="color:#605e63"><span style="font-size:10.5pt">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</span></span></p>
</mj-text></mj-column><mj-column width="45%" id="i9s63"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ite0s" container-background-color="#041f41"></mj-image></mj-column></mj-section><mj-section padding-top="3px" padding-left="18px" padding-right="18px" id="im57g" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Consectetur adipiscing elit ut aliquam purus sit amet.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Adipiscing elit duis tristique sollicitudin nibh.&nbsp;</span></strong></u></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="14px" padding-bottom="20px" padding-left="18px" padding-right="18px" id="i78oa" background-color="#ffffff"><mj-column><mj-text padding-top="5px" padding-bottom="0px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="i6r8d"><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Section Headline Goes Here **/</span></span></p>
</mj-text><mj-divider border-width="2px" padding-top="3px" padding-bottom="0px" padding-left="20px" padding-right="20px" border-color="#02f17a" id="ir4c6"></mj-divider><mj-spacer height="18px" container-background-color="transparent" id="i8llp"></mj-spacer><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Consectetur adipiscing elit ut aliquam purus sit amet.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Adipiscing elit duis tristique sollicitudin nibh.&nbsp;</span></strong></u></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="14px" padding-bottom="0px" padding-left="18px" padding-right="18px" background-color="#ffffff" id="iz02f"><mj-column><mj-text padding-top="5px" padding-bottom="0px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="ib88r"><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Add to Calendar _;</span></span></p>
</mj-text><mj-divider border-width="2px" padding-top="3px" padding-bottom="0px" padding-left="20px" padding-right="20px" border-color="#02f17a" id="izndk"></mj-divider><mj-text padding-top="18px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="i039w"><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt"><em>Type your text here.</em></span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="18px" padding-left="18px" padding-right="18px" id="iihy5" background-color="#ffffff"><mj-column><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="ilix3"><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 1:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Dolor sit amet, consectetur</u></span></span></p>
</mj-text><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="isn63"><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 2:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p>
</mj-text><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="iqs4t"><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 3:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum dolor</u></span></span></p>

<p>&nbsp;</p>

<p><span style="color:#605e63"><span style="font-size:14px"><u>Lorem ipsum</u></span></span></p>
</mj-text></mj-column><mj-column><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="i6i1r"><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 4:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum dolor sit amet</u></span></span></p>

<p>&nbsp;</p>

<p><span style="color:#605e63"><span style="font-size:14px"><u>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</u></span></span></p>
</mj-text><mj-text padding-left="10px" padding-right="10px" font-family="Bogle, sans-serif" line-height="1.5" id="iimhl"><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 5:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="0px" padding-bottom="8px" padding-left="0px" padding-right="0px" id="iikqm" background-color="#ffffff"><mj-column><mj-divider border-width="2px" padding-top="0px" padding-bottom="8px" padding-left="0px" padding-right="0px" border-color="#cccccc" id="i0yud"></mj-divider></mj-column></mj-section><mj-section padding-top="12px" padding-bottom="12px" padding-left="3px" padding-right="3px" id="i0pm8" background-color="#ffffff"><mj-column><mj-social font-size="12px" border-radius="12px" mode="horizontal" padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px"><mj-social-element border-radius="50%" icon-size="32px" name="workplace-noshare" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" background-color="#9a9a9a" id="iqz4f"></mj-social-element><mj-social-element border-radius="50%" icon-size="32px" name="linkedin-noshare" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" background-color="#9a9a9a" id="imw4s"></mj-social-element></mj-social><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt">Type your text </span></span><span style="color:#041f41"><span style="font-size:10.5pt"><u>here</u></span></span><span style="color:#605e63"><span style="font-size:10.5pt">.</span></span></p>
</mj-text></mj-column><mj-column><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="isiqf" width="80px" border="solid 1px #cccccc"></mj-image><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt">Type your text </span></span><span style="color:#041f41"><span style="font-size:10.5pt"><u>here</u></span></span><span style="color:#605e63"><span style="font-size:10.5pt">.</span></span></p>
</mj-text></mj-column><mj-column><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="iw53i" width="80px" border="solid 1px #cccccc"></mj-image><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align: center;"><span style="font-size:10.5pt"><span style="color:#605e63">Type your text </span><span style="color:#041f41"><u>here</u></span><span style="color:#605e63">.</span></span></p>
</mj-text></mj-column></mj-section><mj-section padding-top="20px" padding-bottom="20px" padding-left="20px" padding-right="20px" id="ihd5n" background-color="#ffffff"><mj-column><mj-image src="${blob_url}/walmartthemeimages-image/kvh2123a-Walmart-Logo.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="iuzgv" width="120px"></mj-image></mj-column></mj-section></mj-body><mj-head><mj-style>
    p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }
  </mj-style><mj-style>
  ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }
  </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <title>
          
        </title>
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--<![endif]-->
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style type="text/css">
          #outlook a { padding:0; }
          body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
          table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
          img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
          p { display:block;margin:13px 0; }
        </style>
        <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
        <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
        
        
    <style type="text/css">
      @media only screen and (min-width:480px) {
        .mj-column-per-30 { width:30% !important; max-width: 30%; }
.mj-column-per-70 { width:70% !important; max-width: 70%; }
.mj-column-per-100 { width:100% !important; max-width: 100%; }
.mj-column-per-40 { width:40% !important; max-width: 40%; }
.mj-column-per-60 { width:60% !important; max-width: 60%; }
.mj-column-per-55 { width:55% !important; max-width: 55%; }
.mj-column-per-45 { width:45% !important; max-width: 45%; }
.mj-column-per-50 { width:50% !important; max-width: 50%; }
.mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
      }
    </style>
    <style media="screen and (min-width:480px)">
      .moz-text-html .mj-column-per-30 { width:30% !important; max-width: 30%; }
.moz-text-html .mj-column-per-70 { width:70% !important; max-width: 70%; }
.moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
.moz-text-html .mj-column-per-40 { width:40% !important; max-width: 40%; }
.moz-text-html .mj-column-per-60 { width:60% !important; max-width: 60%; }
.moz-text-html .mj-column-per-55 { width:55% !important; max-width: 55%; }
.moz-text-html .mj-column-per-45 { width:45% !important; max-width: 45%; }
.moz-text-html .mj-column-per-50 { width:50% !important; max-width: 50%; }
.moz-text-html .mj-column-per-33-333333333333336 { width:33.333333333333336% !important; max-width: 33.333333333333336%; }
    </style>
    
  
        <style type="text/css">
        
        

    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile { width: 100% !important; }
      td.mj-full-width-mobile { width: auto !important; }
    }
  
        </style>
        <style type="text/css">p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }</style>
        
      </head>
      <body style="word-spacing:normal;background-color:#f0f0f0;">
        
        
      <div
         style="background-color:#f0f0f0;"
      >
        
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:middle;width:178.2px;" ><![endif]-->
            
      <div
         class="mj-column-per-30 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:20px;padding-right:20px;padding-bottom:20px;padding-left:20px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:138px;">
              
      <img
         height="auto" src="${blob_url}/walmartthemeimages-image/kvh2123a-Walmart-Logo.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="138"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:middle;width:415.8px;" ><![endif]-->
            
      <div
         class="mj-column-per-70 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:15px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="font-size:10.5pt"><span style="color:#274359"><strong>Link 1&nbsp; &nbsp; Link 2&nbsp; &nbsp; Link 3&nbsp; &nbsp; Link 4&nbsp; &nbsp; Link 5</strong></span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#041f41" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#0071dc;background-color:#0071dc;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#0071dc;background-color:#0071dc;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:16px;padding-right:5px;padding-bottom:16px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><strong><span style="font-size:20pt"><span style="color:#ffffff">Walmart 5&amp;10</span></span></strong></p>

<p style="text-align:center"><span style="font-size:11pt"><strong><span style="color:#ffffff">Walmart Week {{week}} | {{date}}</span></strong></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:15px;padding-left:18px;padding-right:18px;padding-top:15px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:middle;width:225.6px;" ><![endif]-->
            
      <div
         class="mj-column-per-40 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Type your text here</span></span></p>

<p style="text-align:center"><span style="font-size:12pt"><span style="color:#041f41">{ Subtitle here; }</span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:120px;line-height:100%;"
      >
        <tbody>
          <tr>
            <td
               align="center" bgcolor="#cccccc" role="presentation" style="border:none;border-radius:10px;cursor:auto;font-style:normal;height:40px;mso-padding-alt:5px 5px;background:#cccccc;" valign="middle"
            >
              <p
                 style="display:inline-block;width:110px;background:#cccccc;color:#041f41;font-family:Bogle, Arial, sans-serif;font-size:16.0px;font-style:normal;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:10px;"
              >
                Button
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:middle;width:338.4px;" ><![endif]-->
            
      <div
         class="mj-column-per-60 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:middle;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:middle;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><u><strong>Lorem ipsum dolor sit amet</strong></u>, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:18px;padding-right:18px;padding-top:20px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:564px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:0px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Section Headline Goes Here //*</span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:3px;padding-right:20px;padding-bottom:0px;padding-left:20px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:524px;" role="presentation" width="524px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:18px;padding-right:18px;padding-top:18px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:310.2px;" ><![endif]-->
            
      <div
         class="mj-column-per-55 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:16px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="color:#041f41"><span style="font-size:10.5pt"><u><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit</strong></u></span></span></p>

<p><span style="color:#605e63"><span style="font-size:10.5pt">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu feugiat pretium nibh ipsum consequat nisl vel pretium. Dignissim enim sit amet venenatis urna cursus. Risus pretium quam vulputate dignissim suspendisse in. Velit dignissim sodales ut eu. Gravida quis blandit turpis cursus in hac habitasse. Odio facilisis mauris sit amet. Magna fermentum iaculis eu non. Varius vel pharetra vel turpis nunc eget. Vitae tempus quam pellentesque nec. Mi sit amet mauris commodo quis.</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:253.8px;" ><![endif]-->
            
      <div
         class="mj-column-per-45 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="background:#041f41;font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:253px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="253"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-left:18px;padding-right:18px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:564px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Consectetur adipiscing elit ut aliquam purus sit amet.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Adipiscing elit duis tristique sollicitudin nibh.&nbsp;</span></strong></u></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-left:18px;padding-right:18px;padding-top:14px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:564px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:0px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Section Headline Goes Here **/</span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:3px;padding-right:20px;padding-bottom:0px;padding-left:20px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:524px;" role="presentation" width="524px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   style="background:transparent;font-size:0px;word-break:break-word;"
                >
                  
      <div
         style="height:18px;line-height:18px;"
      >&#8202;</div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Consectetur adipiscing elit ut aliquam purus sit amet.</span></strong></u></span></p>

<p>&nbsp;</p>

<p><span style="color:#041f41"><u><strong><span style="font-size:10.5pt">Adipiscing elit duis tristique sollicitudin nibh.&nbsp;</span></strong></u></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:0px;padding-left:18px;padding-right:18px;padding-top:14px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:564px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:0px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align:center"><span style="font-size:18pt"><span style="color:#041f41">Add to Calendar _;</span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:3px;padding-right:20px;padding-bottom:0px;padding-left:20px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #02f17a;font-size:1px;margin:0px auto;width:524px;" role="presentation" width="524px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:18px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt"><em>Type your text here.</em></span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:18px;padding-left:18px;padding-right:18px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:282px;" ><![endif]-->
            
      <div
         class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 1:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p>

<p>&nbsp;</p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Dolor sit amet, consectetur</u></span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 2:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 3:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum dolor</u></span></span></p>

<p>&nbsp;</p>

<p><span style="color:#605e63"><span style="font-size:14px"><u>Lorem ipsum</u></span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:282px;" ><![endif]-->
            
      <div
         class="mj-column-per-50 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 4:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum dolor sit amet</u></span></span></p>

<p>&nbsp;</p>

<p><span style="color:#605e63"><span style="font-size:14px"><u>Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</u></span></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#041f41"><strong>Date 5:</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#605e63"><u>Lorem Ipsum</u></span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:8px;padding-left:0px;padding-right:0px;padding-top:0px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:8px;padding-left:0px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 2px #cccccc;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 2px #cccccc;font-size:1px;margin:0px auto;width:600px;" role="presentation" width="600px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:12px;padding-left:3px;padding-right:3px;padding-top:12px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:198px;" ><![endif]-->
            
      <div
         class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      
     <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td><![endif]-->
              <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
              >
                <tbody>
                  
      <tr
        
      >
        <td  style="padding:4px;vertical-align:middle;">
          <table
             border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#9a9a9a;border-radius:50%;width:32px;"
          >
            <tbody>
              <tr>
                <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                  
                    <img
                       height="32" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" style="border-radius:50%;display:block;" width="32"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        
      </tr>
    
                </tbody>
              </table>
            <!--[if mso | IE]></td><td><![endif]-->
              <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
              >
                <tbody>
                  
      <tr
        
      >
        <td  style="padding:4px;vertical-align:middle;">
          <table
             border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#9a9a9a;border-radius:50%;width:32px;"
          >
            <tbody>
              <tr>
                <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                  
                    <img
                       height="32" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" style="border-radius:50%;display:block;" width="32"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        
      </tr>
    
                </tbody>
              </table>
            <!--[if mso | IE]></td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt">Type your text </span></span><span style="color:#041f41"><span style="font-size:10.5pt"><u>here</u></span></span><span style="color:#605e63"><span style="font-size:10.5pt">.</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:198px;" ><![endif]-->
            
      <div
         class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:80px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="80"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="color:#605e63"><span style="font-size:10.5pt">Type your text </span></span><span style="color:#041f41"><span style="font-size:10.5pt"><u>here</u></span></span><span style="color:#605e63"><span style="font-size:10.5pt">.</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:198px;" ><![endif]-->
            
      <div
         class="mj-column-per-33-333333333333336 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:80px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="80"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="font-size:10.5pt"><span style="color:#605e63">Type your text </span><span style="color:#041f41"><u>here</u></span><span style="color:#605e63">.</span></span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:20px;padding-left:20px;padding-right:20px;padding-top:20px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:560px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:120px;">
              
      <img
         height="auto" src="${blob_url}/walmartthemeimages-image/kvh2123a-Walmart-Logo.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="120"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><![endif]-->
    
    
      </div>
    
      </body>
    </html>`,
};
export default FiveAndTen;
