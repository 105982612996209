import CampaignEditChannel from "../components/campaign/CampaignEditChannel";
import CampaignEditDataConnection from "../components/campaign/CampaignEditDataConnection";
import CampaignEditDetails from "../components/campaign/CampaignEditDetails";
import CampaignEditGeneralDataConnection from "../components/campaign/CampaignEditGeneralDataConnection";
import CampaignEditReview from "../components/campaign/CampaignEditReview";
import CampaignEditTemplate from "../components/campaign/CampaignEditTemplate";
import CampaignEditType from "../components/campaign/CampaignEditType";
import {
  getCampaignHeadings,
  getCampaignType,
  getDataSourceType,
  getNotification,
} from "../utils/campaign";
import {
  isValidMailNotification,
  getTemplateContent,
  isValidZoomTeamNotification,
} from "../utils/notification";
import { CAMPAIGN_TYPES, NOTIFICATION_CHANNELS, UI_DATA } from "./Campaign";
import {
  CAMPAIGN,
  CONFIGURATIONS,
  DATA_SOURCE,
  DATA_SOURCE_TYPES,
  NOTIFICATION,
  RECIPIENTS,
} from "./DBFields";

export const BUTTONS = {
  SAVE_DRAFT: "Save as Draft",
  SAVE_CHANGES: "Save Changes",
  TEST: "Test Message",
  SEND: {
    label: "Send Options",
    options: ["Send now", "Schedule for later"],
  },
};

const STEPS = {
  CHANNEL: {
    label: "Channel",
    title: "Where do you want to reach your recipients?",
    required: true,
    isComplete: (campaign, needToSaveTemplate = false, notificationIndex = 0) => {
      const notification =
        campaign?.[CAMPAIGN.NOTIFICATIONS]?.[notificationIndex];
      return notification?.[NOTIFICATION.TYPE] ? true : false;
    },
    component: (props) => <CampaignEditChannel {...props} />,
    review: true,
  },
  CAMPAIGN_TYPE: {
    label: "Connection",
    title: "What kind of campaign are you sending?",
    required: true,
    isComplete: (campaign) => {
      return campaign?.[CAMPAIGN.DATA_SOURCE]?.[DATA_SOURCE.TYPE]
        ? true
        : false;
    },
    component: (props) => <CampaignEditType {...props} />,
    review: true,
  },
  DATA_CONNECT: {
    label: "Data",
    title: "What data source would you like to use?",
    required: false,
    isComplete: (campaign) => {
      switch (getDataSourceType(campaign)) {
        case DATA_SOURCE_TYPES.CSV:
          if (
            campaign?.[CAMPAIGN.METADATA]?.[CAMPAIGN.METADATA_HEADINGS]?.length
          ) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
    component: (props) => <CampaignEditDataConnection {...props} />,
  },
  GENERAL_DATA_CONNECT: {
    label: "Data",
    title: "What data source would you like to use?",
    required: false,
    isComplete: (campaign) => {
      if (campaign.general_data_source_type) return true;
      if (campaign?.[CAMPAIGN.METADATA]?.[CAMPAIGN.METADATA_HEADINGS]?.length) {
        return true;
      }
      return false;
    },
    component: (props) => <CampaignEditGeneralDataConnection {...props} />,
    fields: [
      RECIPIENTS.TO,
      RECIPIENTS.CC,
      RECIPIENTS.BCC,
      CONFIGURATIONS.SUBJECT,
      UI_DATA.SNIPPET_TEXT,
      CONFIGURATIONS.FROM,
    ],
  },
  EMAIL_CONFIG: {
    label: "Details",
    title: "Tell us the details of your message",
    isComplete: (campaign, needToSaveTemplate = false, notificationIndex = 0, hasExternalUsersToggle=false) => {
      const notification = getNotification(campaign, notificationIndex);
      return isValidMailNotification(
        notification,
        getCampaignHeadings(campaign),
        hasExternalUsersToggle,
        getCampaignType(campaign)
      );
    },
    component: (props) => <CampaignEditDetails {...props} />,
    review: true,
    fields: [
      RECIPIENTS.TO,
      RECIPIENTS.CC,
      RECIPIENTS.BCC,
      CONFIGURATIONS.SUBJECT,
      UI_DATA.SNIPPET_TEXT,
      CONFIGURATIONS.FROM,
    ],
  },
  ZOOM_CONFIG: {
    label: "Details",
    title: "Tell us the details of your message",
    isComplete: (campaign, notificationIndex = 0) => {
      const notification = getNotification(campaign, notificationIndex);
      return isValidZoomTeamNotification(
        notification,
        getCampaignHeadings(campaign)
      );
    },
    component: (props) => <CampaignEditDetails {...props} />,
    review: true,
    fields: [RECIPIENTS.TO],
  },
  EMAIL_TEMPLATE: {
    label: "Design",
    title: "Design your content",
    titlePostfix: " - Template needs to be saved.",
    isComplete: (campaign, needToSaveTemplate = false, notificationIndex = 0) => {
      const notification = getNotification(campaign, notificationIndex);
      if (getTemplateContent(notification)) {
        return true && !needToSaveTemplate;
      }
      return false;
    },
    component: (props) => <CampaignEditTemplate {...props} />,
    review: true,
  },
  ZOOM_TEMPLATE: {
    label: "Content",
    title: "Build your notification",
    isComplete: (campaign, notificationIndex = 0) => {
      const notification = getNotification(campaign, notificationIndex);
      if (getTemplateContent(notification)) {
        return true;
      }
      return false;
    },
    component: (props) => <CampaignEditTemplate {...props} />,
    review: true,
  },
  REVIEW: {
    label: "Review",
    isComplete: () => false,
    component: () => <CampaignEditReview />,
  },
};

export const STEPPER = {
  [NOTIFICATION_CHANNELS.mail.value]: {
    [CAMPAIGN_TYPES.general.value]: [
      STEPS.CHANNEL,
      STEPS.CAMPAIGN_TYPE,
      STEPS.GENERAL_DATA_CONNECT,
      STEPS.EMAIL_CONFIG,
      STEPS.EMAIL_TEMPLATE,
      STEPS.REVIEW,
    ],
    [CAMPAIGN_TYPES.personalized.value]: [
      STEPS.CHANNEL,
      STEPS.CAMPAIGN_TYPE,
      STEPS.DATA_CONNECT,
      STEPS.EMAIL_CONFIG,
      STEPS.EMAIL_TEMPLATE,
      STEPS.REVIEW,
    ],
  },
  [NOTIFICATION_CHANNELS.teams.value]: {
    [CAMPAIGN_TYPES.personalized.value]: [
      STEPS.CHANNEL,
      STEPS.DATA_CONNECT,
      STEPS.ZOOM_CONFIG,
      STEPS.ZOOM_TEMPLATE,
      STEPS.REVIEW,
    ],
  },
};

export const DEFAULT_FLOW =
  STEPPER[NOTIFICATION_CHANNELS.mail.value][CAMPAIGN_TYPES.general.value];
