import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditorState } from "../../../../contexts/Editor";
import { setComponentBgColor } from "../grapesjs/settingsUtil";
import ColorInput from "./ColorInput";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-BackgroundColor`;

function BackgroundColor({ element, setting, componentSettings }) {
  const [color, setColor] = useState(null);

  useEffect(() => {
    const bgColor = componentSettings?.[setting?.ID];
    setColor(bgColor);
  }, [componentSettings]);

  return (
    <EditorState>
      {({ addUsedColor, colorPresets }) => {
        return (
          <>
            <Box my={1}>
              <ColorInput
                color={color}
                setColor={(newColor) => {
                  setComponentBgColor(element, setting?.ATTRIBUTE, newColor);
                }}
                presets={colorPresets}
                addPreset={addUsedColor}
                clearColor={() => {
                  setComponentBgColor(element, setting?.ATTRIBUTE, "");
                }}
                id={`${QA_ID_PREFIX}-${setting.ID}`}
              />
            </Box>
          </>
        );
      }}
    </EditorState>
  );
}

export default BackgroundColor;
