import { Box, TextField, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { setComponentHref, setImageAltText } from "../grapesjs/settingsUtil";
import QA from "../../../../consts/QA";
import { HelpOutline } from "@material-ui/icons";
import { CampaignState } from "../../../../contexts/Campaign";
import { CAMPAIGN_TYPES } from "../../../../consts/Campaign";
import VariableToggle from "./VariableToggle";
import VariableList from "./VariableList";
import { COPY } from "../grapesjs/consts/Settings";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-ImageSettings`;

function ImageSettings({
  element,
  setting,
  componentSettings,
  campaignType,
  dataSourceHeadings,
}) {
  const { HREF, ALT } = setting?.INPUTS;
  const [href, setHref] = useState("");
  const [alt, setAlt] = useState("");
  const [variableHref, toggleVariableHref] = useState(false);

  useEffect(() => {
    const image = componentSettings?.[setting.ID] || {};
    setHref(image.href);
    toggleVariableHref(image.href?.variable ? true : false);
    setAlt(image.alt);
  }, [componentSettings]);

  return (
    <>
      <Box mb={1} id={QA_ID_PREFIX}>
        <Typography
          variant="body2"
          color="textSecondary"
          id={`${QA_ID_PREFIX}-Href-Label`}
        >
          <strong>{HREF.label}</strong>
        </Typography>
        {!variableHref || campaignType !== CAMPAIGN_TYPES.personalized.value ? (
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            helperText="Example: https://one.walmart.com"
            value={href?.value}
            onChange={(e) => {
              let newHref = e.target.value || "";
              setHref({ value: newHref });
            }}
            onBlur={() => setComponentHref(element, href?.value)}
            id={`${QA_ID_PREFIX}-TextField-Href`}
          />
        ) : (
          <Box my={1}>
            <VariableList
              value={href?.variable || " "}
              variables={dataSourceHeadings}
              handleChange={(variable) => {
                setComponentHref(
                  element,
                  variable?.value,
                  variable?.variable,
                  variable?.cip
                );
              }}
              id={`${QA_ID_PREFIX}-Href-VariableList`}
            />
          </Box>
        )}
        {campaignType === CAMPAIGN_TYPES.personalized.value ? (
          <VariableToggle
            id={`${QA_ID_PREFIX}-VariableToggle`}
            checked={variableHref}
            handleChange={() => {
              const isVariable = !variableHref;
              toggleVariableHref(isVariable);
              if (!isVariable) {
                setComponentHref(element, href?.value);
              }
            }}
            tooltipProps={{
              title: COPY.DYNAMIC_IMAGE_HREF_TOOLTIP,
            }}
          />
        ) : null}
      </Box>
      <Box mb={1}>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color="textSecondary"
            id={`${QA_ID_PREFIX}-Alt-Label`}
          >
            <strong>{ALT.label}</strong>{" "}
          </Typography>
          <Box ml={1} display="flex" alignItems="center">
            <Tooltip title={ALT.tooltip} id={`${QA_ID_PREFIX}-Alt-Tooltip`}>
              <HelpOutline fontSize="small" color="disabled" />
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <TextField
            fullWidth
            value={alt}
            onChange={(e) => {
              const newText = e.target.value || "";
              setImageAltText(element, newText);
            }}
            variant="outlined"
            margin="dense"
            id={`${QA_ID_PREFIX}-TextField-Alt`}
          />
        </Box>
      </Box>
    </>
  );
}

export default ImageSettings;
