import { Apps, Code, Save, SaveAlt } from "@material-ui/icons";
import { REQUEST_PARAMS } from "./DBFields";
import { COMMANDS } from "../components/template/EmailEditor/grapesjs/consts/Commands";
import { EVENTS, EVENT_TYPES } from "./Common";
import { trackEvent } from "../utils/appInsight";

export const GALLERY_TABS = {
  THEME: "Theme",
  SAVED: "Saved",
  USED: "Previously Used",
};
export const IMAGE_GALLERY_TABS = {
  GALLERY: "Gallery",
  MY_IMAGES: "My Images",
};

export const CODES = {
  TEMPLATE_NAME_TAKEN: "SAVED_TEMPLATE_OBJ_ERR006",
};

export const TEMPLATES_PER_PAGE = 20;
export const imagesPerPageOptions = [24, 48, 72, 120];

export const GALLERY_SCHEMA = {
  NAME: "name",
  ID: "id",
  PREVIEW: "preview",
  CATEGORY: "category",
};

export const IMAGE_SCHEMA = {
  SRC: "src",
  NAME: "name",
  CREATED_ON: "createdOn",
  LAST_MODIFIED: "lastModified",
  DELETED_ON: "deletedon",
  BLOB_NAME: "blobName",
  OWNER: "owner",
  CAN_EDIT: "canEdit",
  FOLDER_ID: "folderId",
};

export const BUTTONS = {
  UNDO: "Undo",
  REDO: "Redo",
  SAVE: "Save Changes",
  SAVE_AND_EXIT: "Save & Exit",
  MORE: {
    label: "More Options",
    options: [
      {
        icon: <Apps fontSize="small" />,
        label: "Change Template",
        onClick: (editor) => editor?.runCommand(COMMANDS.CHANGE_TEMPLATE),
      },
      {
        icon: <Save fontSize="small" />,
        label: "Save as Template",
        onClick: (editor) => editor?.runCommand(COMMANDS.SAVE_AS_TEMPLATE),
        campaignOnly: true,
      },
      {
        icon: <Code fontSize="small" />,
        label: "Export Template",
        onClick: (editor) => {
          editor?.runCommand(COMMANDS.EXPORT_TEMPLATE)
          trackEvent(EVENTS.USER_INTERACTION, null, EVENT_TYPES.USER_INTERACTION.EXPORT_TEMPLATE)
        },
      },
      {
        icon: <SaveAlt fontSize="small" />,
        label: "Import Template",
        onClick: (editor) => {
          editor?.runCommand(COMMANDS.IMPORT_TEMPLATE)
          trackEvent(EVENTS.USER_INTERACTION, null, EVENT_TYPES.USER_INTERACTION.IMPORT_TEMPLATE)
        },
      },
    ],
  },
};

export const IGNORE_VARIABLES = [".", "this"];

export const TEMPLATE_VARIABLES = {
  SRC: "srcname",
  VARIABLE: "variable",
  CIP: "cip",
  HREFSRC: "hrefsrcname",
  HREFCIP: "hrefcip",
};

export const SORTING_OPTIONS = {
  NEWEST: {
    label: "Newest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: IMAGE_SCHEMA.CREATED_ON,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
  OLDEST: {
    label: "Oldest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: IMAGE_SCHEMA.CREATED_ON,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  AZ: {
    label: "A to Z",
    params: {
      [REQUEST_PARAMS.SORT_BY]: IMAGE_SCHEMA.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  ZA: {
    label: "Z to A",
    params: {
      [REQUEST_PARAMS.SORT_BY]: IMAGE_SCHEMA.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
};

export const NAME_MAX_CHAR_COUNT = 50;
