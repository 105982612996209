import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, Grid } from "@material-ui/core";
import QA from "../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.HELP}-Loading`;

const HelpLoading = () => {
  return (
    <Box
      width="100%"
      id={QA_ID_PREFIX}
      boxSizing="border-box"
      p={3}
      pb={4}
      height="100%"
    >
      <Box mb={3}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Skeleton height={60} variant="text" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton height={60} variant="text" />
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <Grid container spacing={4}>
          {new Array(8).fill(0).map(() => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Skeleton height={180} variant="rect" />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={3}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Skeleton height={60} variant="text" />
          </Grid>
          <Grid item xs={4}>
            <Skeleton height={60} variant="text" />
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <Grid container>
          {new Array(8).fill(0).map(() => (
            <Grid item xs={11}>
              <Skeleton height={60} variant="rect" />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box mb={3}>
        <Grid container justify="space-between">
          <Grid item xs={3}>
            <Skeleton height={60} variant="text" />
          </Grid>
        </Grid>
      </Box>
      <Box mb={5}>
        <Grid container justifyContent={"space-around"} alignItems={"center"}>
          <Grid item xs={6} md={4} lg={3}>
            <Skeleton height={300} variant="rect" />
          </Grid>
          <Grid item xs={6} md={4} lg={3}>
            <Skeleton height={300} variant="rect" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HelpLoading;
