//This file holds the name of the fields/keys which are used in sso
export const JWT = {
  FULLNAME: "fullname",
  TIMESTAMP: "timestamp",
  //This upn field will have full upn value
  UPN: "upn",
  EMAIL: "email",
  ROLE: "role",
};

export const LANDING_URL = "landingUrl";
