import { Box, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import QA from "../../../../consts/QA";
import { setComponentAlignment } from "../grapesjs/settingsUtil";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Alignment`;

function Alignment({ element, setting, componentSettings }) {
  const { values, defaultValue, attribute } = setting.INPUTS.ALIGN;
  const [align, setAlign] = useState(defaultValue);

  useEffect(() => {
    setAlign(componentSettings?.[setting?.ID] || defaultValue);
  }, [componentSettings]);

  return (
    <Box mt={1} id={QA_ID_PREFIX}>
      <Select
        value={align}
        onChange={(e) => {
          setComponentAlignment(element, attribute, e.target.value);
        }}
        fullWidth
        variant="outlined"
        margin="dense"
        id={`${QA_ID_PREFIX}-Select`}
      >
        {values.map((value) => {
          return (
            <MenuItem
              value={value.value}
              key={value.value}
              id={`${QA_ID_PREFIX}-MenuItem-${value.label}`}
            >
              {value.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
}

export default Alignment;
