import mjml2html from "mjml-browser";
import { DEFAULT_LINE_HEIGHT } from "./plugins/plugin";
import {
  dynamicToolbar,
  dynamicToolbarEnabled,
  noClone,
  noDelete,
  noMove,
  sectionStyle,
  textStyle,
  TYPES,
} from "./consts/DomComponents";
import { IMAGE_SCHEMA, TEMPLATE_VARIABLES } from "../../../../consts/Template";
import { COMMANDS } from "./consts/Commands";
import exclamationCricleIcon from "../../../../assets/svg/exclamation-circle.svg";

export const loadTemplateInCanvas = (
  editor,
  templateContent,
  removeListMargin
) => {
  const content = createHtmlElement(templateContent);
  try {
    // convert personalized variables to a user-friendly "h-card" format
    // Hi {{firstname}} => Hi <span contenteditable="false">{{ first name }}</span>
    handleMustacheVariables(content, "span.h-card", (variable) => {
      const srcName = variable.getAttribute(TEMPLATE_VARIABLES.SRC);
      if (srcName) {
        variable.innerHTML = `<span contenteditable="false">{{${srcName}}}</span>`;
      }
    });
    // switch mj-mustache-section elements to mj-section to be able to view them in the editor
    handleMustacheVariables(content, TYPES.MUSTACHE_SECTION, (dynamic) => {
      const section = document.createElement(TYPES.SECTION);
      if (dynamic.hasAttributes()) {
        let attrs = dynamic.attributes;
        for (var i = 0; i < attrs?.length; i++) {
          section.setAttribute(attrs[i].name, attrs[i].value);
        }
      }
      section.innerHTML = dynamic.innerHTML;
      dynamic.parentNode?.replaceChild(section, dynamic);
    });
    // display the srcname (actual csv heading name or CIP label) for dynamic buttons
    handleMustacheVariables(content, "mj-button[variable]", (button) => {
      let srcname = button.getAttribute(TEMPLATE_VARIABLES.SRC);
      if (srcname) {
        button.innerHTML = `{{${srcname}}}`;
      }
    });
  } catch (err) {}
  if (editor) {
    editor.setComponents(content.innerHTML);
    insertDefaultStyle(editor, removeListMargin);
  }
  return;
};

export const blurEditor = (editor) => {
  if (editor) {
    editor.select();
  }
};

// converts a given mjml string to html
export const convertMjml = (mjml) => {
  try {
    let options = {
      useMjmlConfigOptions: false,
      mjmlConfigPath: null,
      filePath: null,
    };
    return mjml2html(mjml, options);
  } catch (err) {
    throw new Error(err);
  }
};

// exports the current template in the editor as html
export const exportHtml = (editor) => {
  if (editor) {
    const mjml = editor.getHtml();
    const code = convertMjml(mjml);
    try {
      return {
        content: code.html?.trim(),
        error: false,
      };
    } catch (err) {
      return {
        content: "",
        error: true,
      };
    }
  } else {
    return {
      content: "",
      error: true,
    };
  }
};

// exports the current template in the editor as mjml
export const exportMjml = (editor) => {
  if (editor) {
    try {
      const html = createHtmlElement(editor.getHtml());
      // swap out the user-readable personalized variable for the unqique mustache-friendly variable name
      // ex: Hi <span contenteditable="false">{{ first name }}</span> => Hi {{firstname}}
      handleMustacheVariables(html, "span.h-card", (variable) => {
        const templateFieldName = variable.getAttribute("variable");
        if (templateFieldName) {
          variable.innerHTML = `{{${templateFieldName}}}`;
        }
      });
      // turn all mj-section elements with variables into mj-mustache-sections that will be
      // converted into the correct mustache syntax on convert
      handleMustacheVariables(html, "mj-section[variable]", (section) => {
        const dynamic = document.createElement(TYPES.MUSTACHE_SECTION);
        if (section.hasAttributes()) {
          const attrs = section.attributes;
          for (var i = 0; i < attrs?.length; i++) {
            dynamic.setAttribute(attrs[i].name, attrs[i].value);
          }
        }
        dynamic.innerHTML = section.innerHTML;
        section.parentNode?.replaceChild(dynamic, section);
      });
      // ex: <mj-button>{{ first name }}</mj-button> => <mj-button>{{firstname}}</mj-button>
      handleMustacheVariables(html, "mj-button[variable]", (el) => {
        el.innerHTML = `{{${el.getAttribute("variable")}}}`;
      });
      return {
        content: html.innerHTML,
        error: false,
      };
    } catch (err) {
      return {
        content: editor.getHtml(),
        error: true,
      };
    }
  }
};

// sets the current template in the editor with mjml code
export const importMjml = (editor, mjml, removeListMargin) => {
  mjml = mjml?.trim();
  if (mjml) {
    convertMjml(mjml); // check if convertMjml throws an error
    loadTemplateInCanvas(editor, mjml, removeListMargin);
  } else {
    editor.setComponents(
      `<mjml><mj-body><mj-section ${sectionStyle}><mj-column><mj-text ${textStyle}>Add some content here.</mj-text></mj-column></mj-section></mj-body></mjml>`
    );
  }
  editor.runCommand("mjml-import:change");
  insertDefaultStyle(editor, removeListMargin);
};

export const insertDefaultStyle = (editor, removeListMargin) => {
  if (!editor) {
    return;
  }
  const lineHeightStyle = `
    p, li {
      line-height: ${DEFAULT_LINE_HEIGHT};
      margin-top: 0;
      margin-bottom: 0;
    }
  `;
  const listStyle = `
  ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }
  `;
  const wrapper = editor.getComponents()?.models?.[0];
  if (wrapper) {
    const head = wrapper.findType(TYPES.HEAD)?.[0];
    if (head) {
      const styleTags = head.findType(TYPES.STYLE) || [];
      let matchingStyle = styleTags.find((style) => {
        let content = style.get("content");
        if (!content) {
          content = style.components()?.models?.[0]?.get("content");
        }
        return content?.trim().includes(lineHeightStyle.trim()); // if the content contains the line height style tag, return as a match
      });
      if (!matchingStyle) {
        // otherwise add the line height style
        head.append(`<mj-style>${lineHeightStyle}</mj-style>`);
      }
      if (removeListMargin) {
        matchingStyle = null;
        matchingStyle = styleTags.find((style) => {
          let content = style.get("content");
          if (!content) {
            content = style.components()?.models?.[0]?.get("content");
          }
          return content?.trim().includes(listStyle.trim()); // if the content contains the line height style tag, return as a match
        });
        if (!matchingStyle) {
          // otherwise add the line height style
          head.append(`<mj-style>${listStyle}</mj-style>`);
        }
      }
    } else {
      wrapper.append(
        `<mj-head><mj-style>${lineHeightStyle}</mj-style>${
          removeListMargin ? <mj-style>${listStyle}</mj-style> : ""
        }</mj-head>`
      );
    }
  }
};

export const deleteSelectedComponent = (editor) => {
  if (editor) {
    editor.Commands.run("tlb-delete");
  }
};

export const isComponentType = (type) => (el) =>
  el.tagName === type.toUpperCase();

export const getElementLabel = (model) => {
  return model?.get("name");
};

export const getElementType = (model) => {
  return model?.get("type");
};

export const getElementId = (model) => {
  return model?.get("id");
};

export const getElementDOMEl = (model) => {
  return model?.getEl();
};

export const buildToolbar = (model, allowPersonalization) => {
  const tb = [];
  if (!noMove.includes(model.id)) {
    tb.push({
      attributes: {
        class: "fa fa-arrows gjs-no-touch-actions tlb-move",
        draggable: true,
        "tlb-label": "Move",
      },
      command: "tlb-move",
    });
  }
  if (dynamicToolbarEnabled.includes(model.id) && allowPersonalization) {
    tb.push({
      attributes: {
        class: "fa fa-circle tlb-dynamic-enabled",
      },
      command: COMMANDS.TB_DYNAMIC,
    });
  }
  if (dynamicToolbar.includes(model.id) && allowPersonalization) {
    tb.push({
      attributes: {
        class: "fa fa-eye tlb-dynamic",
      },
      command: COMMANDS.TB_DYNAMIC,
    });
  }
  if (!noClone.includes(model.id)) {
    tb.push({
      attributes: {
        class: "fa fa-clone tlb-clone",
      },
      command: "tlb-clone",
    });
  }
  if (!noDelete.includes(model.id)) {
    tb.push({
      attributes: {
        class: "fa fa-trash-o tlb-delete",
      },
      command: COMMANDS.TB_DELETE_CONFIRM,
    });
  }
  return tb;
};

export const insertImage = (editor, src) => {
  try {
    const selected = editor.getSelected();
    if (!getElementType(selected) == TYPES.IMAGE) {
      throw new Error("Selected component is not an image.");
    }
    selected.set("src", src);
    // redo selection to position the component toolbar correctly
    editor.select();
    editor.select(selected);
    return;
  } catch (err) {
    return err;
  }
};

export const getImageNameFromSrc = (src, teamImages) => {
  let name;
  if (teamImages) {
    const match = teamImages.find((image) => image[IMAGE_SCHEMA.SRC] === src);
    if (match) {
      name = match[IMAGE_SCHEMA.NAME];
    }
  }
  if (!name) {
    name = src;
    try {
      src = src?.split("/");
      if (src.length) {
        name = src[src.length - 1];
      }
    } catch (err) {}
  }
  return name;
};

export const isMustacheVariable = (str) => {
  return /^{{[A-Za-z\s]*}}$/.test(str);
};

export const makeSectionDynamic = (
  editor,
  variable,
  srcName,
  cip,
  type = "default"
) => {
  let component = editor?.getSelected();
  if (component && variable) {
    const attrs = {
      ...component.getAttributes(),
      [TEMPLATE_VARIABLES.SRC]: srcName,
      [TEMPLATE_VARIABLES.VARIABLE]: variable,
      type: type,
    };
    if (cip) {
      attrs[TEMPLATE_VARIABLES.CIP] = "true";
    }
    component.setAttributes(attrs);
    const tb = buildToolbar(
      editor.DomComponents.getType(TYPES.MUSTACHE_SECTION),
      true
    );
    component.set("toolbar", tb);
  }
};

export const makeSectionStatic = (editor) => {
  let component = editor?.getSelected();
  if (component) {
    let attrs = component.getAttributes();
    delete attrs[TEMPLATE_VARIABLES.VARIABLE];
    delete attrs.type;
    delete attrs[TEMPLATE_VARIABLES.SRC];
    delete attrs[TEMPLATE_VARIABLES.CIP];
    component.setAttributes(attrs);
    const tb = buildToolbar(editor.DomComponents.getType(TYPES.SECTION), false);
    component.set("toolbar", tb);
  }
};

/**
 *
 * @param {html object} templateHtml
 * @param {string} querySelector // selects an array of elements
 * @param {function} callback // to be called on each element in the array returned by the query selector
 * @returns
 */
export const handleMustacheVariables = (
  templateHtml,
  querySelector,
  callback
) => {
  const elements = templateHtml?.querySelectorAll(querySelector);
  if (callback) {
    elements?.forEach((el) => {
      return callback(el);
    });
  }
};

/**
 *
 * @param {string} template
 * @returns an html object that can be easily parsed
 */
export const createHtmlElement = (template) => {
  let html = document.createElement("div");
  html.innerHTML = template;
  return html;
};

/**
 * Remove any template variables that no longer exist in the CSV
 * Personalized variables in text, buttons or href are simply removed
 * Dynamic sections are converted into static sections
 * @param {string} templateContent -> a template string
 * @param {array} deprecated -> an array of template variable names to remove
 * @returns the modified template string
 */
export const deleteUnusedTemplateVariables = (
  templateContent,
  deprecated = []
) => {
  try {
    const html = createHtmlElement(templateContent);
    handleMustacheVariables(html, "span.h-card", (element) => {
      if (
        deprecated.includes(element.getAttribute(TEMPLATE_VARIABLES.VARIABLE))
      ) {
        element.remove();
      }
    });
    handleMustacheVariables(html, "mj-section[variable]", (element) => {
      if (
        deprecated.includes(element.getAttribute(TEMPLATE_VARIABLES.VARIABLE))
      ) {
        element.removeAttribute(TEMPLATE_VARIABLES.VARIABLE);
        element.removeAttribute(TEMPLATE_VARIABLES.SRC);
      }
    });
    handleMustacheVariables(html, TYPES.MUSTACHE_SECTION, (element) => {
      if (
        deprecated.includes(element.getAttribute(TEMPLATE_VARIABLES.VARIABLE))
      ) {
        element.removeAttribute(TEMPLATE_VARIABLES.VARIABLE);
        element.removeAttribute(TEMPLATE_VARIABLES.SRC);
        const section = document.createElement(TYPES.SECTION);
        if (element.hasAttributes()) {
          let attrs = element.attributes;
          for (var i = 0; i < attrs?.length; i++) {
            section.setAttribute(attrs[i].name, attrs[i].value);
          }
        }
        section.innerHTML = element.innerHTML;
        element.parentNode?.replaceChild(section, element);
      }
    });
    handleMustacheVariables(html, "mj-button[variable]", (element) => {
      if (
        deprecated.includes(element.getAttribute(TEMPLATE_VARIABLES.VARIABLE))
      ) {
        element.removeAttribute(TEMPLATE_VARIABLES.VARIABLE);
        element.removeAttribute(TEMPLATE_VARIABLES.SRC);
        element.innerHTML = "";
      }
    });
    handleMustacheVariables(
      html,
      "mj-button[hrefsrcname], mj-image[hrefsrcname]",
      (element) => {
        let href = element
          .getAttribute("href")
          ?.replace("{{", "")
          .replace("}}", "")
          .trim();
        if (deprecated.includes(href)) {
          element.removeAttribute("href");
          element.removeAttribute(TEMPLATE_VARIABLES.HREFSRC);
          element.removeAttribute(TEMPLATE_VARIABLES.HREFCIP);
        }
      }
    );
    return html.innerHTML;
  } catch (err) {
    return templateContent;
  }
};

/**
 * 
 * @param {string} templateContent 
 * @returns an object of the variables used in the template
 * ex: {
  FirstName: {
    srcName: "First Name",
    cip: true
  },
  surveylink: {
    srcname: "surveylink",
    cip: false
  }
}
 */
export const getTemplateMustacheVariables = (
  templateContent,
  includeCipVariables = true
) => {
  const variables = {};
  try {
    const html = createHtmlElement(templateContent);
    handleMustacheVariables(html, "span.h-card", (variable) => {
      const cip = variable.getAttribute(TEMPLATE_VARIABLES.CIP) === "true";
      if (includeCipVariables || !cip) {
        variables[variable.getAttribute(TEMPLATE_VARIABLES.VARIABLE)] = {
          srcname: variable.getAttribute(TEMPLATE_VARIABLES.SRC),
          cip: cip,
        };
      }
    });
    handleMustacheVariables(
      html,
      "mj-section[variable], mj-mustache-section",
      (variable) => {
        const cip = variable.getAttribute(TEMPLATE_VARIABLES.CIP) === "true";
        if (includeCipVariables || !cip) {
          variables[variable.getAttribute(TEMPLATE_VARIABLES.VARIABLE)] = {
            srcname: variable.getAttribute(TEMPLATE_VARIABLES.SRC),
            cip: cip,
          };
        }
      }
    );
    handleMustacheVariables(
      html,
      "mj-button[variable], mj-button[hrefsrcname], mj-image[hrefsrcname]",
      (element) => {
        const cip = element.getAttribute(TEMPLATE_VARIABLES.CIP) === "true";
        if (includeCipVariables || !cip) {
          let varName = element.getAttribute(TEMPLATE_VARIABLES.VARIABLE);
          let srcName = element.getAttribute(TEMPLATE_VARIABLES.SRC);
          if (varName) {
            variables[varName] = {
              srcname: srcName,
              cip: cip,
            };
          }
        }
        const hrefCip =
          element.getAttribute(TEMPLATE_VARIABLES.HREFCIP) === "true";
        if (includeCipVariables || !hrefCip) {
          let hrefSrc = element.getAttribute(TEMPLATE_VARIABLES.HREFSRC);
          if (hrefSrc) {
            variables[
              element.getAttribute("href").replace("{{", "").replace("}}", "")
            ] = {
              srcname: hrefSrc,
              cip: hrefCip,
            };
          }
        }
      }
    );
  } catch (err) {}
  return variables;
};

//data-gjs-type="mj-image"
//data-gjs-type="mj-button"
export const getAllLinksFromTemplate = (html) => {
  const links = [];
  global.gHtml = html;
  const buttonsList = html.querySelectorAll(`tr[data-gjs-type="mj-button"]`);
  const socialIconList = html.querySelectorAll(`table[data-gjs-type="mj-social-element"]`);
  const imageList = html.querySelectorAll(`tr[data-gjs-type="mj-image"`);
  const linkElements = [...buttonsList, ...socialIconList, ...imageList];
  linkElements.forEach(ele => {
    links.push({
      id: ele.getAttribute("id"),
      link: ele.getAttribute("href"),
      type: ele.getAttribute("data-gjs-type"),
    });
  });
  return links;
}

export const verifyLinks = (links) => {
  const invalidLinks = [];
  links.forEach(linkObj => {
    if(linkObj.type === TYPES.IMAGE && !linkObj.link) {//Don't validate url if href is absent in image
      return;
    }
    if(!isValidUrl(linkObj.link)) {
      invalidLinks.push(linkObj);
    }
  });
  return invalidLinks;
}

export const isValidUrl = (url) => {
  if(!url) return false;
  try {
    if(/^{{.+}}$/.test(url)) {
      //dynamic variable
      return true;
    }
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

export const updateErrorLinksInEditor = (iframeDocument, links) => {
  const invalidLinks = verifyLinks(links);
  invalidLinks.forEach((invalidLink) => {
    let ele = iframeDocument?.querySelector(`#${invalidLink?.id}`);
    if(ele) {// Add tooltip title to element wrapper
      ele.title = "Error in your link";
    }
    if([TYPES.BUTTON, TYPES.IMAGE].includes(invalidLink?.type)) {// Select the presentation element for Buttons and Images
      ele = ele?.querySelector(`table[role="presentation"]`);
    }
    if(ele) {// Add the error icon the selected element
      ele.style.position = "relative";
      const errorIcon = document.createElement("img");
      errorIcon.classList.add("gjs-editor-link-error-image");
      errorIcon.src = exclamationCricleIcon;
      errorIcon.style.position = "absolute";
      errorIcon.style.top = 0;
      errorIcon.style.left = 0;
      errorIcon.style.width = "18px";
      errorIcon.style.background="white";
      errorIcon.style.borderRadius="50px";
      ele?.append(errorIcon);
    }
  })
}
