import * as EmailValidator from "email-validator";

import languageEncoding from "detect-file-encoding-and-language";

import {
  CAMPAIGN_STATUSES,
  CAMPAIGN_TYPES,
  NOTIFICATION_CHANNELS,
  UI_DATA,
} from "../consts/Campaign";
import {
  ATTACHMENT,
  CAMPAIGN,
  CATEGORY,
  CONFIGURATIONS,
  DATA_SOURCE,
  DATA_SOURCE_TYPES,
  EXTERNAL_USER_OPTIONS_CONFIGS,
  FOLDER,
  MAPPING,
  NOTIFICATION,
  RECIPIENTS,
  TEMPLATE,
  TRACKING_CONFIGS,
  TRIGGER,
} from "../consts/DBFields";
import { uploadFile } from "./blobStorage";
import { isUserAdmin, isUserCampaignOwner, isUserFolderOwner, isUserFolderOwnerOrCoOwner } from "./user";
import {
  getChannel,
  getFromField,
  getFromDisplayNameField,
  getPriority,
  getSnippet,
  getSubject,
  getTrackingConfig,
  isValidMailNotification,
  isValidZoomTeamNotification,
  updateOrCreateConfigurations,
  isNotificationTypeAllowed,
  getAttachments,
  getHasExternalUsers,
} from "./notification";
import { formatDate, getNameFromUpn } from "./common";
import moment from "moment-timezone";
import { TIME_ZONE } from "../consts/Common";
import { CIP_FIELDS } from "../consts/CIP";
import { setToken, setUpSAS } from "./auth/SAS";
import Configs from "../Configurations";
import { IMAGE_SCHEMA } from "../consts/Template";

// only the category id is stored in the campaign - get and return the category name
export const getCampaignCategoryName = (campaign) => {
  let name = "";
  if (campaign && campaign[CAMPAIGN.CATEGORY_ID]) {
    name = CATEGORY[campaign[CAMPAIGN.CATEGORY_ID]]?.label;
  }
  return name;
};

export const getCampaignOwnerName = (campaign, selectedTeam, teamUsers) => {
  // TODO get the display name from the owner's upn and return it instead
  return getNameFromUpn(
    campaign?.[CAMPAIGN.OWNER],
    selectedTeam,
    teamUsers,
    true
  );
};

// determine if a campaign is 'general' or 'personalized' based on the data source type
export const getCampaignType = (campaign, notificationIndex) => {
  const dataSource = campaign?.[CAMPAIGN.DATA_SOURCE];
  if (dataSource) {
    const type = dataSource[DATA_SOURCE.TYPE];
    if (type === DATA_SOURCE_TYPES.MANUAL) {
      const notificationChannel = getNotification(
        campaign,
        notificationIndex
      )?.[NOTIFICATION.TYPE];
      if (
        !notificationChannel ||
        CAMPAIGN_TYPES.general.channels.includes(notificationChannel)
      ) {
        return CAMPAIGN_TYPES.general.value;
      }
    } else if (type) {
      return CAMPAIGN_TYPES.personalized.value;
    }
  }
  return undefined;
};

export const getDataSourceType = (campaign) => {
  return campaign?.[CAMPAIGN.DATA_SOURCE]?.[DATA_SOURCE.TYPE];
};

export const uploadCampaignCsv = (campaign, csv, callback, handleError) => {
  uploadFile(
    campaign[CAMPAIGN.TEAM_ID],
    campaign[CAMPAIGN.UID],
    null,
    "data",
    csv
  ).then(async (res) => {
    if (res?.retry) {
      setToken(campaign?.[CAMPAIGN.TEAM_ID], "data", null); // clear the token
      uploadCampaignCsv(campaign, csv, callback, handleError); // and retry
    } else if (!res?.isError) {
      const files = await callback();
      if (!files) {
        handleError({ message: "CSV_HEADER_ERROR" });
      }
    } else if (handleError) {
      handleError({ message: "CSV_UPLOAD_ERROR" });
    }
  });
};

export const getCampaignAttachmentsSize = async (teamId, campaignId) => {
  let size = 0;
  if (teamId) {
    try {
      const token = await setUpSAS(campaignId, "attachment");
      for await (const blob of token.CONTAINER_CLIENT.listBlobsFlat()) {
        size = size + blob.properties?.contentLength; //blob size in bytes
      }
    } catch {}
  }
  return size;
};

export const cleanUpAttachments = async (campaign, attachments) => {
  if (campaign) {
    try {
      const token = await setUpSAS(campaign[CAMPAIGN.UID], "attachment");
      const fileNames = attachments.map((file) => file[ATTACHMENT.NAME]);
      for await (const blob of token.CONTAINER_CLIENT.listBlobsFlat({
        includeMetadata: true,
      })) {
        if (!fileNames.includes(blob.metadata?.[IMAGE_SCHEMA.NAME])) {
          const blockBlobClient = token.CONTAINER_CLIENT.getBlockBlobClient(
            blob.name
          );
          await blockBlobClient.delete();
        }
      }
    } catch {}
  }
};

export const uploadCampaignAttachments = async (
  files,
  teamId,
  campaignId,
  callback,
  handleError
) => {
  if (teamId) {
    const uploads = [];
    await files?.forEach((file) => {
      uploads.push(
        uploadFile(campaignId, null, null, "attachment", file, true)
      );
    });
    let retries = [];
    let uploaded = [];
    let error = false;
    await Promise.all(uploads)
      .then((res) => {
        res.forEach((response, index) => {
          if (response?.retry) {
            retries.push(files[index]);
          } else if (!response?.isError) {
            const filePath = `${Configs?.REACT_APP_IMAGE_STORAGE_URL}/${response?.sas_container}/${response?.fileName}`;
            uploaded.push({
              [ATTACHMENT.NAME]: files[index].name,
              [ATTACHMENT.PATH]: filePath,
            });
            callback({
              [ATTACHMENT.NAME]: files[index].name,
              [ATTACHMENT.PATH]: filePath,
            });
          } else {
            error = true;
          }
        });
      })
      .catch({});
    if (retries?.length) {
      setToken(teamId, "image", null); // clear the token
      const response = await uploadCampaignAttachments(
        retries,
        teamId,
        campaignId,
        callback,
        handleError
      ); // retry
      if (response?.length) {
        const result = uploaded.concat(response);
      }
    } else if (error) {
      handleError();
    }
  } else {
    handleError();
    return [];
  }
};

export const getCampaignHeadings = (campaign) => {
  if (getDataSourceType(campaign) === DATA_SOURCE_TYPES.CSV) {
    let headings =
      campaign?.[CAMPAIGN.METADATA]?.[CAMPAIGN.METADATA_HEADINGS]?.map(
        (heading) => heading.trim()
      ) || [];
    return headings;
  }
  return;
};

// get all of the statuses that apply to the campaign - sent, scheduled or draft
export const getCampaignStatuses = (campaign) => {
  let statuses = [];
  if (campaign?.[CAMPAIGN.DATE_LAST_EXECUTED]) {
    statuses.push(CAMPAIGN_STATUSES.SENT.value);
  } else if (campaign[CAMPAIGN.IS_DRAFT]) {
    statuses.push(CAMPAIGN_STATUSES.DRAFT.value);
  }
  const trigger = campaign?.[CAMPAIGN.TRIGGER];
  if (
    trigger?.[TRIGGER.TYPE] === TRIGGER.CRON &&
    trigger?.[TRIGGER.STATE] === TRIGGER.ACTIVE
  ) {
    if (trigger[TRIGGER.NEXT_EXECUTION_DATE]) {
      statuses.push(CAMPAIGN_STATUSES.SCHEDULED.value);
    }
  }
  return statuses?.length ? statuses : [CAMPAIGN_STATUSES.DRAFT.value];
};

// get all of the channels of all notifications for a campaign
export const getCampaignChannels = (campaign) => {
  let channels = [];
  if (campaign && campaign[CAMPAIGN.NOTIFICATIONS]) {
    campaign[CAMPAIGN.NOTIFICATIONS].forEach((notification) => {
      let channel = NOTIFICATION_CHANNELS[notification[NOTIFICATION.TYPE]];
      if (channel && !channels.includes(channel)) {
        channels.push(channel);
      }
    });
  }
  return channels;
};

// for manual data sources, return the array of manual data in the configurations
export const getCampaignManualData = (campaign) => {
  return campaign[CAMPAIGN.DATA_SOURCE][DATA_SOURCE.CONFIGURATIONS]?.[0]?.[
    CONFIGURATIONS.VALUE
  ];
};

// get an array of recipient email addresses for "general" campaigns
export const getRecipientsArray = (
  campaign,
  notificationIndex,
  recipientType
) => {
  let recipients = [];
  if (recipientType === RECIPIENTS.TO) {
    const data = getCampaignManualData(campaign);
    if (data && data.length) {
      data.forEach((row) => {
        if (row[UI_DATA.MANUAL_RECIPIENTS[RECIPIENTS.TO]]) {
          recipients.push(row[UI_DATA.MANUAL_RECIPIENTS[RECIPIENTS.TO]]);
        }
      });
    }
  } else {
    const notification = campaign[CAMPAIGN.NOTIFICATIONS]?.[notificationIndex];
    if (notification) {
      const recipientConfig = notification[NOTIFICATION.RECIPIENTS]?.find(
        (config) => config[RECIPIENTS.TYPE] === recipientType
      );
      if (recipientConfig) {
        const recipientMapping = notification[NOTIFICATION.MAPPING]?.find(
          (mapping) =>
            mapping[MAPPING.TEMPLATE_NAME] === recipientConfig[RECIPIENTS.FIELD]
        );
        recipients = recipientMapping?.[MAPPING.RULE]?.[MAPPING.CONSTANT_VALUE];
      }
    }
  }

  return recipients
    ? typeof recipients === "object"
      ? recipients
      : [recipients]
    : [];
};

// gets the recipipient data to be displayed in the UI for the campaign
export const getRecipientsFieldValues = (campaign, notificationIndex) => {
  const campaignType = getCampaignType(campaign, notificationIndex);
  if (
    campaignType === CAMPAIGN_TYPES.general.value &&
    campaign.general_data_source_type === "manual"
  ) {
    // if general, return an array of values
    return {
      [RECIPIENTS.TO]: getRecipientsArray(
        campaign,
        notificationIndex,
        RECIPIENTS.TO
      ),
      [RECIPIENTS.CC]: getRecipientsArray(
        campaign,
        notificationIndex,
        RECIPIENTS.CC
      ),
      [RECIPIENTS.BCC]: getRecipientsArray(
        campaign,
        notificationIndex,
        RECIPIENTS.BCC
      ),
    };
  } else {
    // if personalized, return the recipient field name
    // TODO: get campaign recipients for personalized campaigns
    const recipients =
      getNotification(campaign, notificationIndex)?.[NOTIFICATION.RECIPIENTS] ||
      [];
    let returnVal = {};
    recipients.forEach((recipient) => {
      returnVal[recipient.type] = recipient[RECIPIENTS.FIELD];
    });
    return returnVal;
  }
};

// get the values from notification.configurations
export const getConfigurationFieldValues = (campaign, notificationIndex) => {
  const notification = getNotification(campaign, notificationIndex) || {};
  return {
    [CONFIGURATIONS.FROM]: getFromField(notification),
    [CONFIGURATIONS.FROM_DISPLAY_NAME]: getFromDisplayNameField(notification),
    [CONFIGURATIONS.SUBJECT]: getSubject(notification),
    [CONFIGURATIONS.PRIORITY]: getPriority(notification),
    [UI_DATA.SNIPPET_TEXT]: getSnippet(notification),
    [CONFIGURATIONS.ATTACHMENTS]: getAttachments(notification),
    [TRACKING_CONFIGS.VIEW]: getTrackingConfig(
      notification,
      TRACKING_CONFIGS.VIEW
    ),
    [TRACKING_CONFIGS.CLICK]: getTrackingConfig(
      notification,
      TRACKING_CONFIGS.CLICK
    ),
    [TRACKING_CONFIGS.ADVANCED_METRICS]: getTrackingConfig(
      notification,
      TRACKING_CONFIGS.ADVANCED_METRICS
    ),
    [TRACKING_CONFIGS.TRACK_LOCATION]: getTrackingConfig(
      notification,
      TRACKING_CONFIGS.TRACK_LOCATION
    ),
    [EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK]: getHasExternalUsers(notification)
  };
};

// returns the notification at the provided index of the campaigns notifications array
export const getNotification = (campaign, notificationIndex) => {
  return campaign?.[CAMPAIGN.NOTIFICATIONS]?.[notificationIndex];
};

export const findNotificationOfType = (campaign, type) => {
  const match = campaign?.[CAMPAIGN.NOTIFICATIONS]?.find(
    (notification) => notification[NOTIFICATION.TYPE] === type
  );
  return match;
};

export const getSentDate = (campaign) => {
  if (campaign?.[CAMPAIGN.DATE_LAST_EXECUTED]) {
    return `Sent on ${formatDate(
      campaign?.[CAMPAIGN.DATE_LAST_EXECUTED],
      true,
      true
    )}`;
  } else return null;
};

export const getNextScheduled = (campaign) => {
  if (campaign?.[CAMPAIGN.TRIGGER]?.[TRIGGER.NEXT_EXECUTION_DATE]) {
    return `Scheduled for ${formatDate(
      campaign[CAMPAIGN.TRIGGER][TRIGGER.NEXT_EXECUTION_DATE],
      true,
      true
    )}`;
  } else return null;
};

export const getLastEditTime = (campaign) => {
  const lastUpdated = campaign?.[CAMPAIGN.DATE_UPDATED];
  if (lastUpdated) {
    let ms = new Date() - new Date(lastUpdated);
    const minutes = Math.floor(ms / 1000 / 60);
    if (!minutes) {
      return "Edited less than 1 minute ago";
    } else {
      const hours = Math.floor(minutes / 60);
      if (hours >= 24) {
        return `Edited on ${formatDate(lastUpdated, true, true)}`;
      } else if (hours) {
        return `Edited ${hours} hours ago`;
      } else {
        return `Edited ${minutes} minutes ago`;
      }
    }
  } else return null;
};

export const getDateCreatedTime = (campaign) => {
  const dateCreated = campaign?.[CAMPAIGN.DATE_CREATED];
  return getCreatedTimeUtil(dateCreated);
};

export const getCreatedTimeUtil = (date) => {
  if (date) {
    let ms = new Date() - new Date(date);
    const minutes = Math.floor(ms / 1000 / 60);
    if (!minutes) {
      return "Created less than 1 minute ago";
    } else {
      const hours = Math.floor(minutes / 60);
      if (hours >= 24) {
        return `Created on ${formatDate(date, true, true)}`;
      } else if (hours) {
        return `Created ${hours} hours ago`;
      } else {
        return `Created ${minutes} minutes ago`;
      }
    }
  } else return null;
}

export const getFolderDateCreated = (resource) => {
  const createdAt = resource?.[FOLDER.CREATED_AT]
  if (createdAt) {
    return `${formatDate(createdAt, false, true)}`;
  } else return null;
};

// determine if a given campaign meets the channel and category filters
export const doesCampaignMatch = (campaign, channelFilter, categoryFilter) => {
  let match = true;
  if (channelFilter?.length) {
    match = false;
    const channels = campaign[UI_DATA.CHANNELS] || [];
    let index = 0;
    while (!match && index < channels.length) {
      if (channelFilter.includes(channels[index].value)) {
        match = true;
      }
      index++;
    }
  }
  if (match && categoryFilter.length) {
    if (!categoryFilter.includes(String(campaign[CAMPAIGN.CATEGORY_ID]))) {
      match = false;
    }
  }
  return match;
};

// determines if a campaign and its notifications are in an executable state
// TODO: implement isValidZoomNotification and isValidTeamsNotification
export const checkIfExecutable = (campaign, needToSaveTemplate, hasExternalUsersToggle, campaignType) => {
  const metaDataHeadings = getCampaignHeadings(campaign);
  let executable = true;
  let notificationIndex = 0;
  let notifications = campaign[CAMPAIGN.NOTIFICATIONS];
  if (!notifications || !notifications.length || needToSaveTemplate) {
    return false;
  }
  while (
    executable &&
    notifications &&
    notificationIndex < notifications.length
  ) {
    const notification = notifications[notificationIndex];
    if (!notification[NOTIFICATION.TEMPLATE]) {
      executable = false;
      return executable;
    }
    const channel = getChannel(notification);
    if (channel === NOTIFICATION_CHANNELS.mail.value) {
      executable = isValidMailNotification(notification, metaDataHeadings, hasExternalUsersToggle, campaignType);
    } else if (channel) {
      executable = isValidZoomTeamNotification(notification, metaDataHeadings);
    }
    notificationIndex = notificationIndex + 1;
  }
  return executable;
};

// if the campaign is part of a team with their own zoom bot, configure the settings before sending
export const addAConfiguredZoomBot = (campaign, notificationIndex) => {
  return campaign;
};

export const validateEmail = (email) => {
  let isValid = true;
  if (email) {
    if (typeof email === "string") {
      isValid = EmailValidator.validate(email);
    } else if (Array.isArray(email)) {
      isValid = validateEmailArray(email);
    } else {
      isValid = false;
    }
  }
  return isValid;
};

export const checkValidCsv = async (files) => {
  const headers = await parseCsv(files);
  const validateCsvHeadersResult = validateCSVDataSourceHeadings(headers);
  return validateCsvHeadersResult;
};

export const parseCsv = async (files) => {
  const reader = new FileReader();
  reader.readAsText(files);
  return new Promise((resolve, reject) => {
    reader.onload = async () => {
      const binaryStr = reader.result;
      const headings = binaryStr?.split("\n")?.[0]?.trim()?.split(",");
      resolve(headings);
    };
  });
};

export const validateCSVDataSourceHeadings = (headings) => {
  const regex = /[@\.\"]/;
  const result = { isValid: false, invalidHeaders: [] };
  if (headings.length === 0) {
    return result;
  }
  for (const heading of headings) {
    if (regex.test(heading)) {
      result.isValid = false;
      result.invalidHeaders.push(heading);
    }
  }
  if (result.invalidHeaders.length === 0) {
    result.isValid = true;
  }
  return result;
};

export const validateEmailArray = (emailArray) => {
  let isValid = true;
  if (emailArray) {
    let index = 0;
    while (isValid && index < emailArray.length) {
      const email = emailArray[index];
      if (email) {
        if (typeof email === "string") {
          isValid = validateEmail(email);
        } else {
          isValid = false;
        }
      }
      index = index + 1;
    }
  }
  return isValid;
};

export const validateStringLength = (value, maxCharCount) => {
  if (!maxCharCount) {
    return true;
  }
  return !value || value.length <= maxCharCount;
};

// if dataSourceHeadings === undefined, field is valid
// if dataSourceHeadings is an array and contains the field, then field is valid
export const validateField = (field, dataSourceHeadings) => {
  if (
    dataSourceHeadings === undefined ||
    (typeof dataSourceHeadings === "object" &&
      dataSourceHeadings.includes(field))
  ) {
    return field;
  }
  return;
};

// zoom templates are JSON objects, however to display them in the UI they need to be strings
export const convertTemplateContentToString = (templateObj) => {
  let templateContent = templateObj?.[TEMPLATE.CONTENT];
  if (templateContent && typeof templateContent === "object") {
    templateContent = JSON.stringify(templateContent, null, 2);
    templateObj[TEMPLATE.CONTENT] = templateContent;
  }
  return templateObj;
};

export const generateOneTimeCron = (sendDate) => {
  let cron;
  sendDate = moment.utc(sendDate);
  try {
    cron = `${moment(sendDate).get("minutes")} ${moment(sendDate).get(
      "hours"
    )} ${moment(sendDate).get("date")} ${moment(sendDate).get("month")} *`;
  } catch (err) {
    cron = "";
  }
  return cron;
};

// return a date object of the scheduled one-time send
export const getDateFromCron = (campaign) => {
  const trigger = campaign?.[CAMPAIGN.TRIGGER];
  let cronExp = trigger?.[TRIGGER.CONFIG]?.[TRIGGER.CRON_EXP];
  if (cronExp && trigger?.[TRIGGER.IS_ONE_TIME]) {
    let date = moment.utc(moment.now()); // current time in utc
    cronExp = cronExp.split(" "); // utc cron
    if (cronExp?.length && cronExp?.length > 4) {
      date = moment(date)
        .set("minute", cronExp[0])
        .set("hour", cronExp[1])
        .set("date", cronExp[2])
        .set("month", cronExp[3]);
      return moment(date).tz(TIME_ZONE); // convert to local time
    }
  }
  return;
};

export const sortCampaignsByExecutionDate = (campaignArray) => {
  return campaignArray.sort(
    (a, b) =>
      new Date(b[CAMPAIGN.DATE_LAST_EXECUTED]) -
      new Date(a[CAMPAIGN.DATE_LAST_EXECUTED])
  );
};

// returns true is trigger.next_execution_date is the current time or has already passed
export const hasQueuedExecution = (campaign) => {
  const trigger = campaign?.[CAMPAIGN.TRIGGER];
  if (trigger?.[TRIGGER.STATE] === TRIGGER.ACTIVE) {
    const next = trigger[TRIGGER.NEXT_EXECUTION_DATE]; //utc time
    if (next) {
      const now = moment.now(); //local time
      const diff = moment
        .duration(moment(next).diff(moment(now).utc()))
        .asHours();
      return diff <= 0;
    }
  }
  return;
};

export const userHasOwnerPrivileges = (user, campaign, isFolderOwner = false) => {
  return isUserAdmin(user) || isUserCampaignOwner(user, campaign) || isFolderOwner;
};

export const userHasFolderOwnerPrivileges = (user, folder) => {
  return isUserFolderOwnerOrCoOwner(user, folder);
}

/**
 * create a list of drop down options categorized by CSV and CIP data
 * @param {array} dataSourceHeadings  // the headings from an uploaded csv
 * @param {boolean} includeCIP  // adds CIP data as a separate category is true
 * @returns an array of variables in the following format:
 * {
 *  value: the "user friendly" name, whatever matches the uploaded csv, ex: "First Name" (CIP) or "is enrolled" (CSV)
 *  variable: the "mustache friendly" and unique version of the variable name, ex: "FirstName" (CIP) or "is_enrolled" (CSV)
 *  cip: boolean
 * }
 */
export const generateVariableList = (
  dataSourceHeadings = [],
  includeCIP = true
) => {
  let variables = [];
  if (dataSourceHeadings.length) {
    variables.push({ name: "CSV Data: " });
    variables = variables.concat(dataSourceHeadings);
  }
  if (includeCIP) {
    variables.push({ name: "Associate Data: " });
    Object.keys(CIP_FIELDS).forEach((fieldName) => {
      const field = CIP_FIELDS[fieldName];
      variables.push({
        value: field.label,
        variable: field.variable,
        cip: true,
      });
    });
  }
  return variables;
};

export const validateCampaignChannels = (campaign, selectedTeam) => {
  const channels = getCampaignChannels(campaign);
  let valid = true;
  let i = 0;
  while (valid && i < channels?.length) {
    valid = isNotificationTypeAllowed(channels[i].value, selectedTeam);
    i++;
  }
  return valid;
};
