import { URLS, getAPIEndPoint } from "../consts/APIEndpoints";
import { getResponse } from "../utils/common";

export async function getSas(id, uploadType, includeThemes = false) {
  let response = await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${id}/sas/${uploadType}?includeThemes=${includeThemes}`,
    "get"
  );
  return uploadType === "image" && includeThemes ? response : response?.sas;
}

export async function getSasForAttachments(campaignId) {
  let response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/sas/attachment`,
    "get"
  );
  return response?.sas;
}
