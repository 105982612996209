import Handlebars from "handlebars";
import { getTemplate } from "../APIs/CampaignAPI";
import { getTeamTemplate } from "../APIs/TemplatesAPI";
import {
  deleteUnusedTemplateVariables,
  getTemplateMustacheVariables,
} from "../components/template/EmailEditor/grapesjs/util";
import { JWT } from "../consts/AuthFields";
import { ERROR_MSG } from "../consts/Common";
import { TEAM, TEMPLATE } from "../consts/DBFields";
import { isUserAdmin } from "./user";
import {
  GALLERY_SCHEMA,
  GALLERY_TABS,
  IGNORE_VARIABLES,
} from "../consts/Template";

// select a template from the gallery and do something with the content (save it to a campaign)
export const copyTemplateFromGallery = async (
  activeGalleryType,
  galleryTemplate,
  channel,
  loadContentInEditor,
  selectedTeam
) => {
  const template = await getTemplateContentFromGallery(
    activeGalleryType,
    galleryTemplate,
    channel,
    selectedTeam
  );
  if (template) {
    loadContentInEditor(template[TEMPLATE.CONTENT]);
  } else {
    throw new Error(ERROR_MSG.GET_TEMPLATE);
  }
};

// get the template_content value for a template from the template gallery
export const getTemplateContentFromGallery = async (
  activeGalleryType,
  galleryTemplate,
  templateType,
  selectedTeam
) => {
  switch (activeGalleryType) {
    case GALLERY_TABS.THEME:
      return galleryTemplate;
    case GALLERY_TABS.SAVED:
      return await getTeamTemplate(
        selectedTeam?.[TEAM.ID],
        galleryTemplate[GALLERY_SCHEMA.ID],
        true
      );
    case GALLERY_TABS.USED:
      const template = await getTemplate(
        galleryTemplate[GALLERY_SCHEMA.ID],
        templateType
      );
      const updatedContent = removeInvalidDynamicContent(
        template?.[TEMPLATE.CONTENT],
        [],
        true
      );
      if (updatedContent) {
        template[TEMPLATE.CONTENT] = updatedContent;
      }
      return template;
    default:
      return null;
  }
};

// fetches all variables in {{example}} format in the zoom template
export const getZoomTemplateVariables = (templateContent) => {
  const variables = {};
  let varNames = templateContent?.match(/{{[^{\:}]*}}/gm);
  varNames?.forEach((name) => {
    name = name.replace(/^{{/, "").replace(/}}$/, "");
    variables[name] = {
      srcname: name,
    };
  });
  return variables;
};

// fetches all variables in ${example} format in the teams template
export const getTeamsTemplateVariables = (templateContent) => {
  const variables = {};
  let varNames = templateContent?.match(/\$?{[^{\:}]*}/gm);
  varNames?.forEach((name) => {
    name = name.replace(/^\$?{/, "").replace(/}$/, "");
    variables[name] = {
      srcname: name,
    };
  });
  return variables;
};

// recursive function for getting nested variables
const parseBody = (bodyArray, variables) => {
  bodyArray?.forEach((element) => {
    let name = getVariableName(element);
    if (name && !IGNORE_VARIABLES.includes(name) && !variables.includes(name)) {
      variables.push(name);
    }
    if (element?.program?.body) {
      variables = parseBody(element.program.body, variables);
    }
  });
  return variables;
};

// parse the name from a handlebars element
const getVariableName = (templateElement) => {
  if (templateElement?.type === "MustacheStatement") {
    return templateElement?.path?.original;
  } else if (templateElement?.type === "BlockStatement") {
    if (templateElement.params?.length) {
      return templateElement.params[0]?.original;
    } else {
      return templateElement.path?.original;
    }
  }
};

export const makeTemplateFieldName = (variable) => {
  return `${variable?.replace(/[^a-zA-Z0-9]/g, "").replace(/\s/g, "_")}-csv`;
};

export const userHasOwnerPrivileges = (user, template) => {
  return isUserAdmin(user) || isUserTemplateOwner(user, template);
};

export const isUserTemplateOwner = (user, template) => {
  if (template?.[TEMPLATE.OWNER] && user?.[JWT.UPN]) {
    return (
      template[TEMPLATE.OWNER]?.toLowerCase() === user[JWT.UPN]?.toLowerCase()
    );
  }
};

// given a list of available datasourceheadings, remove dynamic/variable content from the template that are no longer valid
// returns updatedTemplateContent is content was removed, returns nothing otherwise
export const removeInvalidDynamicContent = (
  templateContent,
  dataSourceHeadings,
  includeCip
) => {
  let variables = getTemplateMustacheVariables(templateContent, includeCip) || {};
  Object.keys(variables)?.forEach((templateName) => {
    let match = dataSourceHeadings.find(
      (heading) => heading.variable === templateName
    );
    if (match) {
      // only unmatched variables will be left in the object
      delete variables[templateName];
    }
  });
  if (Object.keys(variables)?.length) {
    let updatedTemplate = deleteUnusedTemplateVariables(
      templateContent,
      Object.keys(variables)
    );
    return updatedTemplate;
  }
  return;
};

export const addPreviewCss = (content) => {
  // mjml templates have css classes for column widths that are missing in the preview html
  // parse the template and add it to the preview
  const htmlContent = document.createElement("div");
  htmlContent.innerHTML = content;
  const cols = htmlContent.querySelectorAll("div.mj-outlook-group-fix"); // find all of the columns in the template
  cols.forEach((col) => {
    const classes = col.getAttribute("class")?.split(" ");
    let colWidth = classes
      .find((name) => name.includes("mj-column-per-"))
      ?.split("mj-column-per-"); // find the classname that includes the width
    if (colWidth?.length > 1) {
      colWidth = colWidth[1]; // remove the mj-column-per- prefix
      colWidth = colWidth?.split("-")?.[0]; // split the number from the hyphen and type cast it
      colWidth = Number(colWidth);
      if (typeof colWidth === "number" && colWidth > 0) {
        col.setAttribute(
          "style",
          `${col.getAttribute("style") || ""} width: ${colWidth}%;`
        );
      }
    }
  });
  return htmlContent.innerHTML;
};
