import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { EditorState } from "../../../../contexts/Editor";
import { setButtonTextFormat } from "../grapesjs/settingsUtil";
import ColorInput from "./ColorInput";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-ButtonFont`;

function ButtonFont({ element, setting, componentSettings }) {
  const { FONT, SIZE, COLOR, BOLD, ITALIC } = setting?.INPUTS;
  const [font, setFont] = useState(FONT?.defaultValue);
  const [size, setSize] = useState(SIZE?.defaultValue);
  const [color, setColor] = useState(COLOR?.defaultValue);
  const [bold, setBold] = useState(BOLD.defaultValue);
  const [italic, setItalic] = useState(ITALIC.defaultValue);

  useEffect(() => {
    const formats = componentSettings?.[setting.ID] || {};
    setFont(formats.font || FONT.defaultValue);
    setSize(formats.size || SIZE.defaultValue);
    setColor(formats.color || COLOR.defaultValue);
    setBold(formats.bold || BOLD.defaultValue);
    setItalic(formats.italic || ITALIC.defaultValue);
  }, [componentSettings]);

  return (
    <>
      <Box mb={1}>
        <Typography variant="body2" color="textSecondary">
          <strong>{FONT?.label}</strong>
        </Typography>
        <Box mt={1}>
          <Select
            value={font}
            onChange={(e) => {
              setButtonTextFormat(element, {
                color,
                size,
                font: e.target.value,
                bold,
                italic,
              });
            }}
            fullWidth
            variant="outlined"
            margin="dense"
            id={`${QA_ID_PREFIX}-Select`}
          >
            {FONT?.values.map((font) => {
              return (
                <MenuItem
                  value={font.value}
                  key={font.value}
                  id={`${QA_ID_PREFIX}-MenuItem-${font.label}`}
                >
                  {font.label}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </Box>
      <Box my={1}>
        <Typography variant="body2" color="textSecondary">
          <strong>{SIZE?.label}</strong>
        </Typography>
        <Box display="flex" width="100%" alignItems="center">
          <Box width="40%">
            <TextField
              type="number"
              value={size}
              onChange={(e) => {
                const fontSize = e.target.value;
                if (fontSize >= SIZE.minValue && fontSize <= SIZE.maxValue) {
                  setButtonTextFormat(element, {
                    color,
                    size: e.target.value,
                    font,
                    bold,
                    italic,
                  });
                }
              }}
              fullWidth
              variant="outlined"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{SIZE.unit}</InputAdornment>
                ),
              }}
              id={`${QA_ID_PREFIX}-TextField`}
            />
          </Box>
          <Box ml={3} mb={0.5} mt={1}>
            <ToggleButtonGroup
              value={[bold && "bold", italic && "italic"]}
              onChange={(e, newValues) => {
                setButtonTextFormat(element, {
                  color,
                  size,
                  font,
                  bold: newValues?.includes("bold"),
                  italic: newValues?.includes("italic"),
                });
              }}
              size="small"
              id={`${QA_ID_PREFIX}-ToggleButtonGroup`}
            >
              <ToggleButton value="bold" id={`${QA_ID_PREFIX}-Bold`}>
                <Tooltip title="Bold">{BOLD.label}</Tooltip>
              </ToggleButton>
              <ToggleButton value="italic" id={`${QA_ID_PREFIX}-Italic`}>
                <Tooltip title="Italic">{ITALIC.label}</Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        <EditorState>
          {({ colorPresets, addUsedColor }) => {
            return (
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  <strong>{COLOR?.label}</strong>
                </Typography>
                <ColorInput
                  color={color}
                  setColor={(newColor) => {
                    setButtonTextFormat(element, {
                      color: newColor,
                      size,
                      font,
                      bold,
                      italic,
                    });
                  }}
                  presets={colorPresets}
                  addPreset={addUsedColor}
                  id={QA_ID_PREFIX}
                />
              </Box>
            );
          }}
        </EditorState>
      </Box>
    </>
  );
}

export default ButtonFont;
