import React, { useState } from "react";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";
import { COPY } from "../../consts/Copy";
import QA from "../../consts/QA";
import { CampaignState } from "../../contexts/Campaign";
import CampaignWarningDialog from "./CampaignWarningDialog";
import CampaignEditReviewSection from "./CampaignEditReviewSection";

//MUI
import { Box, Button, Grid, makeStyles, Typography, Tooltip } from "@material-ui/core";
import { AppState } from "../../contexts/Core";
import { isNotificationTypeAllowed } from "../../utils/notification";
import { FEATURE } from "../../consts/Common";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditChannel`;

const useStyles = makeStyles(() => ({
  cardButton: {
    height: "100%",
    alignItems: "stretch",
    minHeight: 175,
    boxSizing: "border-box",
  },
  selectedCard: {
    border: "solid 2px",
    "&.Mui-disabled": {
      border: "solid 2px",
    },
  },
}));

function CampaignEditChannel({ isReview = false, isResend = false }) {
  const classes = useStyles();
  const [warningDialog, toggleWarningDialog] = useState(false);
  const [valueToSelect, setValueToSelect] = useState();

  const openWarningDialog = () => toggleWarningDialog(true);

  return (
    <>
      <CampaignState>
        {({ handleChannelSelect, channel }) => {
          return (
            <>
              {!isReview ? (
                <Box
                  boxSizing="border-box"
                  width="100%"
                  overflow="hidden"
                  id={QA_ID_PREFIX}
                >
                  <Grid container alignItems="stretch" justify="center">
                    {Object.keys(NOTIFICATION_CHANNELS).map((type) => {
                        const buttonValue = NOTIFICATION_CHANNELS[type].value;
                        return (
                          <AppState>
                            {({ selectedTeam, isToggleEnabled}) => {
                              const isChannelEnabled = isToggleEnabled(FEATURE[type.toUpperCase()]);
                              return (
                                <Tooltip
                                  disableHoverListener={isChannelEnabled}
                                  arrow
                                  title={
                                    <Typography variant="caption">
                                      { !isChannelEnabled
                                        ? ( COPY.CAMPAIGN_EDIT.CHANNEL
                                            .TOOLTIP[type.toUpperCase()] ? COPY.CAMPAIGN_EDIT.CHANNEL
                                            .TOOLTIP[type.toUpperCase()].CHANNEL_DISABLED : COPY.CAMPAIGN_EDIT.CHANNEL
                                            .TOOLTIP.COMMON.CHANNEL_DISABLED )
                                        : null}
                                    </Typography>
                                  }
                                  >
                                    <Grid
                                      item
                                      xs={10}
                                      sm={4}
                                      md={3}
                                      key={type}
                                      zeroMinWidth
                                    >
                                    <Box height="100%" boxSizing="border-box">
                                            <Button
                                              variant="outlined"
                                              className={
                                                channel === buttonValue
                                                  ? `${classes.selectedCard} ${classes.cardButton}`
                                                  : classes.cardButton
                                              }
                                              onClick={() => {
                                                const newValue =
                                                  channel === buttonValue
                                                    ? undefined
                                                    : buttonValue;
                                                setValueToSelect(newValue);
                                                handleChannelSelect(
                                                  newValue,
                                                  openWarningDialog
                                                );
                                              }}
                                              disabled={
                                                isResend ||
                                                !isNotificationTypeAllowed(
                                                  type,
                                                  selectedTeam
                                                ) ||
                                                !isChannelEnabled
                                              }
                                              id={`${QA_ID_PREFIX}-CardButton-${type}`}
                                            >
                                              <Box
                                                px={2}
                                                py={4}
                                                boxSizing="border-box"
                                                display="flex"
                                                flexWrap="wrap"
                                                alignItems="stretch"
                                                justifyContent="space-evenly"
                                              >
                                                <Box width="100%">
                                                  <Typography
                                                    align="center"
                                                    variant="h3"
                                                    id={`${QA_ID_PREFIX}-CardButton-${type}-Label`}
                                                  >
                                                    <strong>
                                                      {NOTIFICATION_CHANNELS[type].label}
                                                    </strong>
                                                  </Typography>
                                                </Box>
                                                <Box
                                                  width="100%"
                                                  display="flex"
                                                  alignItems="center"
                                                  justifyContent="center"
                                                  my={1}
                                                >
                                                  <Typography
                                                    align="center"
                                                    variant="h5"
                                                    id={`${QA_ID_PREFIX}-CardButton-${type}-Caption`}
                                                  >
                                                    {NOTIFICATION_CHANNELS[type].caption}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                            </Button>
                                    </Box>
                                  </Grid>
                                </Tooltip>
                              )
                            }}
                          </AppState> 
                        )
                      })
                    }
                  </Grid>
                  <CampaignWarningDialog
                    isOpen={warningDialog}
                    closeDialog={() => toggleWarningDialog(false)}
                    title={COPY.CAMPAIGN_EDIT.CHANNEL_CHANGE_WARNING.TITLE}
                    subtitle={
                      COPY.CAMPAIGN_EDIT.CHANNEL_CHANGE_WARNING.SUBTITLE
                    }
                    primaryButton={
                      COPY.CAMPAIGN_EDIT.CHANNEL_CHANGE_WARNING.ACCEPT_BUTTON
                    }
                    secondaryButton={
                      COPY.CAMPAIGN_EDIT.CHANNEL_CHANGE_WARNING.CANCEL_BUTTON
                    }
                    primaryAction={() => {
                      handleChannelSelect(valueToSelect);
                      toggleWarningDialog(false);
                      setValueToSelect(undefined);
                    }}
                    secondaryAction={() => toggleWarningDialog(false)}
                    id={`${QA_ID_PREFIX}-EditChannelWarningDialog`}
                  />
                </Box>
              ) : (
                <CampaignEditReviewSection
                  inline
                  reviewItems={[
                    {
                      label: "Channel",
                      value: NOTIFICATION_CHANNELS[channel]?.label,
                    },
                  ]}
                  id={`${QA_ID_PREFIX}-Channel-ReviewSection`}
                />
              )}
            </>
          );
        }}
      </CampaignState>
    </>
  );
}

export default CampaignEditChannel;
