import { CAMPAIGN_TYPES, UI_DATA } from "../consts/Campaign";
import {
  CONFIGURATIONS,
  EXTERNAL_USER_OPTIONS_CONFIGS,
  MAPPING,
  NOTIFICATION,
  PRIORITIES,
  RECIPIENTS,
  TEAM,
  TEMPLATE,
} from "../consts/DBFields";
import { validateField } from "./campaign";
import { getConfigIndex } from "./campaignEdit";

// mail, zoom or teams
export const getChannel = (notification) => {
  return notification?.[NOTIFICATION.TYPE];
};

// get the "to" recipient value - returns the 'field' attribute of the mapping
export const getToField = (notification) => {
  const recipients = notification?.[NOTIFICATION.RECIPIENTS] || [];
  const to = recipients.find(
    (recipient) => recipient[RECIPIENTS.TYPE] === RECIPIENTS.TO
  );
  return to?.[RECIPIENTS.FIELD];
};

// get the "from" config value - returns the 'value' attribute of the config
export const getFromField = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  const from = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === CONFIGURATIONS.FROM
  );
  return from?.[CONFIGURATIONS.VALUE];
};

// get the "fromDisplayName" config value - returns the 'value' attribute of the config
export const getFromDisplayNameField = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  const from = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === CONFIGURATIONS.FROM_DISPLAY_NAME
  );
  return from?.[CONFIGURATIONS.VALUE];
};

// get the "subject" config value - returns the 'value' attribute of the config
export const getSubject = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  const subject = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === CONFIGURATIONS.SUBJECT
  );
  return subject?.[CONFIGURATIONS.VALUE];
};

// get the "sn_email_snippet" mapping value
export const getSnippet = (notification) => {
  const mappings = notification?.[NOTIFICATION.MAPPING] || [];
  const snippet = mappings.find(
    (mapping) => mapping[MAPPING.TEMPLATE_NAME] === UI_DATA.SNIPPET_TEXT
  );
  return snippet?.[MAPPING.RULE]?.[MAPPING.CONSTANT_VALUE];
};

// get the "priority" config value - returns the 'value' attribute of the config
export const getPriority = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  let priority = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === CONFIGURATIONS.PRIORITY
  );
  priority = priority?.[CONFIGURATIONS.VALUE];
  if (PRIORITIES[priority]) {
    return priority;
  }
  return null;
};

//get hasExternalUsers config value - returns the 'value' attribute of the config
export const getHasExternalUsers = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  
  const hasExternalUsers = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === EXTERNAL_USER_OPTIONS_CONFIGS.INCLUDE_UNSUBSCRIBE_LINK
  );

  return hasExternalUsers?.[CONFIGURATIONS.VALUE] || ""
}

export const getAttachments = (notification) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  let files = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === CONFIGURATIONS.ATTACHMENTS
  );
  return files?.[CONFIGURATIONS.VALUE];
};

// get the value for trackClick or trackView
export const getTrackingConfig = (notification, configName) => {
  const configs = notification?.[NOTIFICATION.CONFIGURATIONS] || [];
  const trackingConfig = configs.find(
    (config) => config[CONFIGURATIONS.NAME] === configName
  );
  return trackingConfig?.[CONFIGURATIONS.VALUE];
};

// add a new configuration to a notification, or just update it if it exists
export const updateOrCreateConfigurations = (notification, configToUpdate) => {
  if (notification) {
    const configs = notification[NOTIFICATION.CONFIGURATIONS] || [];
    const configIndex = getConfigIndex(
      configs,
      CONFIGURATIONS.NAME,
      configToUpdate[CONFIGURATIONS.NAME]
    );
    if (configIndex > -1) {
      configs[configIndex] = configToUpdate;
    } else {
      configs.push(configToUpdate);
    }
    notification[NOTIFICATION.CONFIGURATIONS] = configs;
  }
  return notification;
};

// checks that all mandatory fields exists
export const isValidMailNotification = (notification, dataSourceHeadings, hasExternalUsersToggle, campaignType) => {
  const value = notification &&
  validateField(getToField(notification), dataSourceHeadings) &&
  getSubject(notification) &&
  getFromField(notification)

  if(value && campaignType !== CAMPAIGN_TYPES.personalized.value){
    return true
  }
  else if(campaignType === CAMPAIGN_TYPES.personalized.value){
    if(hasExternalUsersToggle){
      return value && getHasExternalUsers(notification)!== "" ? true : false
    }
    else return value
  }
  else {
    return false
  }
};

// checks that all mandatory fields exists
export const isValidZoomTeamNotification = (
  notification,
  dataSourceHeadings
) => {
  if (
    notification &&
    validateField(getToField(notification), dataSourceHeadings)
  ) {
    return true;
  }
  return false;
};

export const getTemplateContent = (notification) => {
  return notification?.[NOTIFICATION.TEMPLATE]?.[TEMPLATE.CONTENT];
};

// does the selected team have permission to create campaigns of the given notification type?
export const isNotificationTypeAllowed = (
  notificationType,
  selectedTeam = {}
) => {
  if (!notificationType || !selectedTeam[TEAM.CHANNELS]) {
    return true;
  } else {
    return selectedTeam[TEAM.CHANNELS]?.includes(notificationType);
  }
};
