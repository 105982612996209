import axios from "axios";
import API from "../utils/auth/AuthenticatedAPI";
import { URLS, getAPIEndPoint } from "../consts/APIEndpoints";
import { TEAM_USER } from "../consts/DBFields";
import { getResponse } from "../utils/common";
import { AD_USER } from "../consts/Common";

export async function getUserTeams(upn) {
  return getResponse(
    `${getAPIEndPoint(URLS.USERS_API)}/${upn}/teams`,
    "get",
    undefined,
    { limit: 500 }
  );
}

export async function getTeamUsers(teamId) {
  return getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/users`,
    "get",
    undefined,
    { limit: 500 }
  );
}

export async function getAllTeams() {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}`,
    "get",
    undefined,
    { limit: 500, sort_by: "name", sort_order: "asc" }
  );
}

export async function updateTeamRole(teamId, user, roleId) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/users/${user[TEAM_USER.UPN]}`,
    "put",
    { roleId: roleId }
  );
}
export async function addTeamUser(teamId, userObject) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/users`,
    "post",
    {
      upn: userObject[AD_USER.UPN],
      preferred_name: userObject[AD_USER.DISPLAY_NAME],
      email: userObject[AD_USER.MAIL] != null ? userObject[AD_USER.MAIL] : userObject[AD_USER.UPN],
      roleId: userObject[AD_USER.ROLE_ID] || 300,
    }
  );
}
export async function removeTeamUser(teamId, user) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/users/${user[TEAM_USER.UPN]}`,
    "delete",
    undefined
  );
}

export async function getTeamSenders(teamId) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/senders`,
    "get"
  );
}

export const logout = () => {
  return axios.post(API.getFullAPIURL(API.Routes.LOGOUT));
};
