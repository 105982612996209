import React from "react";

// MUI
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backgroundColor:
      theme.palette.type === "dark"
        ? "rgba(0,0,0,.5)"
        : "rgba(255,255,255,0.75)",
    zIndex: 10000,
  },
}));

function LoadingOverlay({ id }) {
  const classes = useStyles();
  return (
    <>
      <Backdrop open={true} className={classes.backdrop}>
        <CircularProgress id={`${id}-LoadingOverlay-Icon`} />
      </Backdrop>
    </>
  );
}

export default LoadingOverlay;
