import { CAMPAIGN_STATUSES } from "./Campaign";
import { CAMPAIGN, REQUEST_PARAMS, TEAM } from "./DBFields";
import {
  FileCopyOutlined,
  DeleteOutlined,
  ClearOutlined,
  Edit,
  CloudDownloadOutlined,
  SettingsOutlined
} from "@material-ui/icons";
import { hasQueuedExecution, userHasFolderOwnerPrivileges, userHasOwnerPrivileges } from "../utils/campaign";
import { COPY } from "./Copy";
import { TrashCan, UserPlus, SignOut } from "@livingdesign/icons";
import { canDuplicateCampaign, canEditCampagin, canMoveCampaign } from "../utils/folderUtils";

export const tableHeaders = {
  name: {
    id: "campaign-table-name",
    label: "Message Name",
    statuses: [
      CAMPAIGN_STATUSES.SENT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
      CAMPAIGN_STATUSES.DRAFT.value,
    ],
  },
  type: {
    id: "campaign-table-type",
    label: "Type",
    statuses: [CAMPAIGN_STATUSES.SENT.value, CAMPAIGN_STATUSES.SCHEDULED.value],
  },
  channel: {
    id: "campaign-table-channel",
    label: "Channel",
    statuses: [CAMPAIGN_STATUSES.SENT.value, CAMPAIGN_STATUSES.SCHEDULED.value],
  },
  recipients: {
    id: "campaign-table-recipients",
    label: "Recipients",
    statuses: [CAMPAIGN_STATUSES.SENT.value],
  },
  status: {
    id: "campaign-table-status",
    label: "Status",
    statuses: [CAMPAIGN_STATUSES.SENT.value],
  },
  opens: {
    id: "campaign-table-opens",
    label: "Open Rate",
    statuses: [CAMPAIGN_STATUSES.SENT.value],
  },
  view: {
    id: "campaign-table-view",
    label: "",
    ariaLabel: "View Campaign",
    statuses: [CAMPAIGN_STATUSES.SENT.value],
  },
  edit: {
    id: "campaign-table-edit",
    label: "",
    ariaLabel: "Edit Campaign",
    statuses: [
      CAMPAIGN_STATUSES.DRAFT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
    ],
  },
  more: {
    id: "campaign-table-more",
    label: "",
    ariaLabel: "More Options",
    statuses: [
      CAMPAIGN_STATUSES.SENT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
      CAMPAIGN_STATUSES.DRAFT.value,
    ],
  },
};

export const folderTableHeaders = {
  name: {
    id: "folder-table-name",
    label: "FOLDER NAME",
  },
  created_by: {
    id: "folder-table-created-by",
    label: "CREATED BY",
  },
  date_created: {
    id: "folder-table-date-created",
    label: "DATE CREATED",
  },
  more: {
    id: "folder-table-more",
    label: "",
    ariaLabel: "More Options",
  },
};

export const rowsPerPageOptions = [25, 50, 75, 100];
export const folderRowsPerPageOptions = [10, 20, 40, 60, 100];

export const SORTING_OPTIONS = {
  NEWEST: {
    label: "Newest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: CAMPAIGN.DATE_CREATED,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
  OLDEST: {
    label: "Oldest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: CAMPAIGN.DATE_CREATED,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  AZ: {
    label: "A to Z",
    params: {
      [REQUEST_PARAMS.SORT_BY]: CAMPAIGN.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  ZA: {
    label: "Z to A",
    params: {
      [REQUEST_PARAMS.SORT_BY]: CAMPAIGN.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
};

export const MORE_OPTIONS = [
  {
    label: "Send Again",
    icon: <UserPlus size="medium" />,
    statuses: [CAMPAIGN_STATUSES.SENT.value],
    userIsAllowed: canEditCampagin,
    validateChannel: true,
    invalidChannelTooltip: COPY.CAMPAIGN_LIST.DISABLED_CHANNEL_SEND_AGAIN,
  },
  {
    label: "Duplicate Message",
    icon: <FileCopyOutlined fontSize="small" />,
    statuses: [
      CAMPAIGN_STATUSES.SENT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
      CAMPAIGN_STATUSES.DRAFT.value,
    ],
    userIsAllowed: canDuplicateCampaign,
    validateChannel: true,
    invalidChannelTooltip: COPY.CAMPAIGN_LIST.DISABLED_CHANNEL_DUPLICATE,
  },
  {
    label: "Delete Message",
    icon: <TrashCan size="medium" />,
    statuses: [
      CAMPAIGN_STATUSES.SENT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
      CAMPAIGN_STATUSES.DRAFT.value,
    ],
    userIsAllowed: (user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType) => {
      const hasNoQueuedExecution = !hasQueuedExecution(campaign);
      return hasNoQueuedExecution && canEditCampagin(user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType)
    },
  },
  {
    label: "Move Message",
    icon: <SignOut size="medium" />,
    statuses: [
      CAMPAIGN_STATUSES.SENT.value,
      CAMPAIGN_STATUSES.SCHEDULED.value,
      CAMPAIGN_STATUSES.DRAFT.value,
    ],
    userIsAllowed: canMoveCampaign
  },
  {
    label: "Cancel Schedule",
    icon: <ClearOutlined fontSize="small" />,
    statuses: [CAMPAIGN_STATUSES.SCHEDULED.value],
    userIsAllowed: () => false,
  },
];

export const MORE_OPTIONS_FOLDERS = [
  {
    label: "Settings",
    icon: <SettingsOutlined fontSize="small" />,
    userIsAllowed: (user, signedInUserIsOwner, folder) => {
      return signedInUserIsOwner || userHasFolderOwnerPrivileges(user, folder);
    },
    validateChannel: true,
    invalidChannelTooltip: COPY.CAMPAIGN_LIST.DISABLED_CHANNEL_SEND_AGAIN,
    checkUserFolderAccess: false
  },
  {
    label: "Delete",
    icon: <DeleteOutlined fontSize="small" />,
    userIsAllowed: (user, signedInUserIsOwner, folder) => {
      return signedInUserIsOwner || userHasFolderOwnerPrivileges(user, folder);
    },
    checkUserFolderAccess: true
  }
];

export const FILE_OPTIONS = [
  {
    label: "Download",
    icon: <CloudDownloadOutlined fontSize="small" color="action" />,
  },
  {
    label: "Delete",
    icon: <DeleteOutlined fontSize="small" color="action" />,
  }
];
