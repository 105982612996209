import React, { useState } from "react";
import { CAMPAIGN_TYPES_FEATURES } from "../../consts/Campaign";
import { CAMPAIGN_TYPES } from "../../consts/Campaign";
import { COPY } from "../../consts/Copy";
import QA from "../../consts/QA";
import { CampaignState } from "../../contexts/Campaign";
import CampaignWarningDialog from "./CampaignWarningDialog";
import CampaignEditReviewSection from "./CampaignEditReviewSection";

// MUI
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { EVENTS, EVENT_TYPES } from "../../consts/Common";
import { trackEvent } from "../../utils/appInsight";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditType`;

const useStyles = makeStyles(() => ({
  cardButton: {
    height: "100%",
    alignItems: "stretch",
    minHeight: 175,
    boxSizing: "border-box",
    maxWidth: 420,
    overflow: "hidden",
  },
  selectedCard: {
    border: "solid 2px",
    boxSizing: "border-box",
    overflow: "hidden",
    boxShadow: "0px 4px 11px 5px rgba(1,113,206,.5)",
  },
  featureListItem: {}
}));

function CampaignEditType({ isReview = false }) {
  const [warningDialog, toggleWarningDialog] = useState(false);
  const [valueToSelect, setValueToSelect] = useState();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const classes = useStyles();
  return (
    <>
      <CampaignState>
        {({ campaignType, handleCampaignTypeSelect, channel, selectedCampaign }) => {
          return (
            <>
              {!isReview ? (
                <Box
                  boxSizing="border-box"
                  width="100%"
                  overflow="hidden"
                  id={QA_ID_PREFIX}
                >
                  <Grid container alignItems="stretch" justify="center">
                    {Object.keys(CAMPAIGN_TYPES).map((type) => {
                      const buttonValue = CAMPAIGN_TYPES[type].value;
                      return (
                        <Grid
                          item
                          xs={10}
                          sm={6}
                          md={5}
                          key={buttonValue}
                          zeroMinWidth
                        >
                          <Box
                            height="100%"
                            boxSizing="border-box"
                            display="flex"
                            justifyContent="center"
                            paddingTop="10px"
                            paddingBottom="16px"
                          >
                            <Tooltip
                              disableHoverListener={!CAMPAIGN_TYPES[type].hoverTooltipText}
                              title={CAMPAIGN_TYPES[type].hoverTooltipText}
                              placement='top'
                            >
                              <Button
                                id={`${QA_ID_PREFIX}-CardButton-${CAMPAIGN_TYPES[type].label}`}
                                variant="outlined"
                                className={
                                  campaignType === buttonValue
                                    ? `${classes.selectedCard} ${classes.cardButton}`
                                    : classes.cardButton
                                }
                                onClick={() => {
                                  const newValue =
                                    campaignType === buttonValue
                                      ? undefined
                                      : buttonValue;
                                  setValueToSelect(newValue);
                                  handleCampaignTypeSelect(newValue, () =>
                                    toggleWarningDialog(true)
                                  );
                                  const eventType = CAMPAIGN_TYPES[type].value ==="general" ? EVENT_TYPES.USER_INTERACTION.STANDARD_CAMPAIGN : EVENT_TYPES.USER_INTERACTION.ADVANCED_CAMPAIGN
                                  trackEvent(EVENTS.USER_INTERACTION, {campaignId:selectedCampaign?.uid}, eventType)
                                }}
                                disabled={CAMPAIGN_TYPES[type].disabled}
                                fullWidth
                              >
                                <Box
                                  pt={2}
                                  px={smScreen ? 1 : 2}
                                  boxSizing="border-box"
                                  display="flex"
                                  flexWrap="wrap"
                                  alignItems="flex-end"
                                  paddingTop={"35px"}
                                  pb={campaignType === buttonValue ? 2 : 2.125}
                                  // the border around the selected button makes the feature list items slightly out of line, compensate for it here
                                >
                                  {CAMPAIGN_TYPES[type].showRecommendedBanner && (
                                    <Box
                                      style={{
                                        paddingTop: "40px",
                                        paddingRight: "30px",
                                        paddingBottom: "10px",
                                        paddingLeft: "47px",
                                        background: "#0171ce",
                                        fontWeight: 700,
                                        position: "absolute",
                                        right: "-40px",
                                        top: "-19px",
                                        transform: "rotate(37deg)",
                                        fontSize: "14px",
                                        color: "#fff",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        Recommended
                                      </Typography>
                                    </Box>
                                  )}
                                  <Box width="100%">
                                    <Typography align="center" variant="h4">
                                      <strong>
                                        {CAMPAIGN_TYPES[type].label}
                                      </strong>
                                    </Typography>
                                    <Box mt={1}>
                                      <Typography align="center">
                                        {CAMPAIGN_TYPES[type].caption}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box
                                    width="100%"
                                    mt={3.5}
                                    id={`${QA_ID_PREFIX}-CardButton-${CAMPAIGN_TYPES[type].label}-FeatureList`}
                                  >
                                    {CAMPAIGN_TYPES_FEATURES[channel]?.map(
                                      (feature, index) => {
                                        return (
                                          <>
                                            {index > 0 ? (
                                              <Box width="100%" my={1.5}>
                                                <Divider />
                                              </Box>
                                            ) : null}
                                            <Box
                                              width="100%"
                                              display="flex"
                                              justifyContent="space-between"
                                              alignItems="center"
                                              px={smScreen ? 1 : 3.5}
                                              boxSizing="border-box"
                                            >
                                              <Box
                                                width={20}
                                                height={20}
                                                mr={2}
                                                id={`${QA_ID_PREFIX}-CardButton-${CAMPAIGN_TYPES[type].label}-FeatureListItem-${index}`}
                                              >
                                                {feature.types.includes(
                                                  buttonValue
                                                ) ? (
                                                  <Check
                                                    fontSize="small"
                                                    color={
                                                      CAMPAIGN_TYPES[type]
                                                        .disabled
                                                        ? "disabled"
                                                        : "primary"
                                                    }
                                                  />
                                                ) : (
                                                  <Close
                                                    fontSize="small"
                                                    color="disabled"
                                                  />
                                                )}
                                              </Box>
                                              <Box width="calc(100% - 28px)">
                                                <Typography
                                                  variant="h5"
                                                  align="left"
                                                  noWrap={false}
                                                  color={
                                                    CAMPAIGN_TYPES[type]
                                                      .disabled ||
                                                    !feature.types.includes(
                                                      buttonValue
                                                    )
                                                      ? "textSecondary"
                                                      : "textPrimary"
                                                  }
                                                >
                                                  {feature.label}
                                                </Typography>
                                              </Box>
                                            </Box>
                                          </>
                                        );
                                      }
                                    )}
                                  </Box>
                                </Box>
                              </Button>
                            </Tooltip>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <CampaignWarningDialog
                    isOpen={warningDialog}
                    closeDialog={() => toggleWarningDialog(false)}
                    title={COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.TITLE}
                    subtitle={COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.SUBTITLE}
                    primaryButton={
                      COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.ACCEPT_BUTTON
                    }
                    secondaryButton={
                      COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.CANCEL_BUTTON
                    }
                    primaryAction={() => {
                      handleCampaignTypeSelect(valueToSelect);
                      toggleWarningDialog(false);
                      setValueToSelect(undefined);
                    }}
                    secondaryAction={() => toggleWarningDialog(false)}
                    id={`${QA_ID_PREFIX}-EditType-WarningDialog`}
                  />
                </Box>
              ) : (
                <CampaignEditReviewSection
                  inline
                  reviewItems={[
                    {
                      label: "Connection",
                      value: CAMPAIGN_TYPES[campaignType]?.label,
                    },
                  ]}
                  id={`${QA_ID_PREFIX}-ReviewSection`}
                />
              )}
            </>
          );
        }}
      </CampaignState>
    </>
  );
}

export default CampaignEditType;
