import React, { useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { HEX_COLORS } from "../../../../consts/Colors";

// MUI
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  makeStyles,
  Popper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  colorBox: {
    borderRadius: 4,
    minWidth: "40%",
    padding: "2px 8px",
    height: 40,
    boxSizing: "border-box",
    margin: "4px 0",
    width: "40%",
  },
  diagonalLineOne: {
    width: 28,
    height: 20,
    borderBottom: "1px solid red",
    "-webkit-transform": `translateY(-18px)
            translateX(-11px)
            rotate(-45deg)`,
    position: "absolute",
    top: "20px",
  },
}));

function ColorInput({ color, setColor, presets, addPreset, clearColor, id }) {
  const classes = useStyles();
  const [showPicker, toggleShowPicker] = useState(false);
  const anchorRef = useRef(null);

  return (
    <>
      <Button
        variant="outlined"
        className={classes.colorBox}
        onClick={(e) => {
          toggleShowPicker(true);
        }}
        ref={anchorRef}
        id={`${id}-ColorInput-Button`}
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box
            width={20}
            height={20}
            border={`solid 1px ${HEX_COLORS.gray}`}
            bgcolor={color}
            borderRadius={2}
            my={0.25}
            mr={0.75}
          >
            {!color ? (
              <div
                className={classes.diagonalLineOne}
                id={`${id}-ColorInput-NoColor`}
              ></div>
            ) : null}
          </Box>
          <Typography color="textSecondary" id={`${id}-ColorInput-Label`}>
            {color}
          </Typography>
        </Box>
      </Button>
      {color && clearColor ? (
        <Tooltip title="Clear">
          <IconButton onClick={clearColor} id={`${id}-ColorInput-Clear`}>
            <Clear fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
      {showPicker ? (
        <ClickAwayListener
          onClickAway={() => {
            toggleShowPicker(false);
            if (color) {
              addPreset(color);
            }
          }}
        >
          <Popper
            open={showPicker}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            transition
            disablePortal
            style={{ zIndex: 1000 }}
            id={`${id}-ColorInput-PopperMenu`}
          >
            <Box mt={1} width={200}>
              <SketchPicker
                color={color ? color : "#ffffff"}
                presetColors={presets}
                onChangeComplete={(color) => {
                  setColor(color ? color.hex : null);
                }}
                id={`${id}-ColorInput-Picker`}
              />
            </Box>
          </Popper>
        </ClickAwayListener>
      ) : null}
    </>
  );
}

export default ColorInput;
