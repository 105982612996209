import React, { useState } from "react";
import QA from "../../consts/QA";
import CampaignEditCsvUpload from "./CampaignEditCsvUpload";

// MUI
import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { CampaignState } from "../../contexts/Campaign";
import CampaignWarningDialog from "./CampaignWarningDialog";
import { COPY } from "../../consts/Copy";
import { MANUAL_DATA_SOURCE } from "../../consts/Campaign";
import { CAMPAIGN } from "../../consts/DBFields";
import { trackEvent } from "../../utils/appInsight";
import { EVENT_TYPES, EVENTS } from "../../consts/Common";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditGeneralDataConnection`;

const useStyles = makeStyles(() => ({
  recipientManualOption: {
    borderRadius: "0px",
    height: "250px",
    border: `dashed 1px rgb(175, 175, 175)`,
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
  },
  solidBorder: {
    border: `solid 2px black`,
  },
}));

function CampaignEditGeneralDataConnection() {
  const classes = useStyles();
  const [showCsvDeleteAlert, setShowCsvDeleteAlert] = useState(false);

  return (
    <CampaignState>
      {({
        selectedCampaign,
        removeExistingCsvIfPresent,
        saveCampaign,
        csvFile,
      }) => {
        return (
          <>
            <Box
              boxSizing="border-box"
              width="100%"
              overflow="hidden"
              height="100%"
              display="flex"
              alignItems="center"
              id={QA_ID_PREFIX}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Grid container alignItems="stretch" justify="center">
                <Grid
                  item
                  xs={10}
                  sm={6}
                  md={5}
                  zeroMinWidth
                  id={`${QA_ID_PREFIX}-manual-recipients`}
                >
                  <Box
                    height="100%"
                    boxSizing="border-box"
                    display="flex"
                    justifyContent="center"
                  >
                    <Button
                      variant="outlined"
                      id={`${QA_ID_PREFIX}-button`}
                      className={`${
                        selectedCampaign.general_data_source_type ===
                          "manual" && classes.solidBorder
                      }`}
                      style={{ fontSize: 18, width: "60%", height: 300 }}
                      onClick={async () => {
                        if (csvFile) {
                          setShowCsvDeleteAlert(true);
                        } else if (
                          selectedCampaign?.general_data_source_type !==
                          "manual"
                        ) {
                          await saveCampaign(
                            {
                              ...selectedCampaign,
                              general_data_source_type: "manual",
                              metadata: {...selectedCampaign[CAMPAIGN.METADATA], [CAMPAIGN.METADATA_HEADINGS]: []},
                            },
                            false
                          );
                        }
                        trackEvent(EVENTS.USER_INTERACTION, null, EVENT_TYPES.USER_INTERACTION.ENTER_RECIPIENTS_MANUALLY)
                      }}
                    >
                      <Box mb={1}>
                        <Typography
                          variant="h4"
                          align="center"
                          style={{ fontSize: 18 }}
                          id={`${QA_ID_PREFIX}-ManualTitle`}
                        >
                          {MANUAL_DATA_SOURCE.TITLE}
                        </Typography>
                        <Typography
                          variant="h4"
                          align="center"
                          id={`${QA_ID_PREFIX}-ManualSubTitle`}
                          style={{
                            fontSize: 14,
                            marginTop: 8,
                            color: "grey",
                          }}
                        >
                          {MANUAL_DATA_SOURCE.SUB_TITLE}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  id={`${QA_ID_PREFIX}-csv-upload`}
                  xs={10}
                  sm={6}
                  md={5}
                  zeroMinWidth
                  style={{
                    display: "flex",
                    flexFlow: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <CampaignEditCsvUpload />
                </Grid>
              </Grid>
              <CampaignWarningDialog
                isOpen={showCsvDeleteAlert}
                closeDialog={() => setShowCsvDeleteAlert(false)}
                title={"Are you sure to change the datasource type?"}
                subtitle={"This is will delete the uploaded CSV"}
                primaryButton={
                  COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.ACCEPT_BUTTON
                }
                secondaryButton={
                  COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.CANCEL_BUTTON
                }
                primaryAction={async () => {
                  const campaign = { ...selectedCampaign };
                  await removeExistingCsvIfPresent(campaign);
                  await saveCampaign(
                    {
                      ...campaign,
                      general_data_source_type: "manual",
                      metadata: {...campaign[CAMPAIGN.METADATA], [CAMPAIGN.METADATA_HEADINGS]: []},
                    },
                    false
                  );
                  setShowCsvDeleteAlert(false);
                }}
                secondaryAction={() => setShowCsvDeleteAlert(false)}
                id={`${QA_ID_PREFIX}-EditType-WarningDialog`}
              />
            </Box>
          </>
        );
      }}
    </CampaignState>
  );
}

export default CampaignEditGeneralDataConnection;
