export const COMMANDS = {
  VIEW_DESKTOP: "set-device-desktop",
  VIEW_MOBILE: "set-device-mobile",
  EXPORT_TEMPLATE: "export-template",
  IMPORT_TEMPLATE: "import_template",
  TB_DELETE_CONFIRM: "tlb-delete-confirm",
  CHANGE_TEMPLATE: "change-template",
  OPEN_IMAGE_GALLERY: "open-image-gallery",
  TB_DYNAMIC: "tlb-dynamic",
  SAVE_AS_TEMPLATE: "save_as_template",
};
