import {
  UI_DATA,
  CAMPAIGN_TYPES,
  NOTIFICATION_CHANNELS,
} from "../consts/Campaign";
import { CIP_FIELDS } from "../consts/CIP";
import { DEFAULT_FLOW, STEPPER } from "../consts/CampaignEdit";
import {
  CAMPAIGN,
  CONFIGURATIONS,
  DATA_SOURCE,
  DATA_SOURCE_TYPES,
  MAPPING,
  NOTIFICATION,
  RECIPIENTS,
  TRIGGER,
} from "../consts/DBFields";
import {
  getCampaignType,
  getNotification,
  getRecipientsFieldValues,
} from "./campaign";

// updates a notification at an index, or creates a notification if it doesn't exist
export const updateOrCreateNotification = (
  campaign,
  notificationIndex,
  newValue
) => {
  let notifications = campaign[CAMPAIGN.NOTIFICATIONS] || [];
  if (!notifications[notificationIndex]) {
    notifications.push(newValue ? newValue : {});
  } else if (newValue) {
    notifications[notificationIndex] = newValue;
  }
  campaign[CAMPAIGN.NOTIFICATIONS] = notifications;
  return campaign;
};

// removes a notification from the notification array of a campaign
// also remove the notification attribute entirely if campaign.notifications === []
export const removeNotificationAtIndex = (campaign, notificationIndex) => {
  if (campaign && campaign[CAMPAIGN.NOTIFICATIONS]) {
    campaign[CAMPAIGN.NOTIFICATIONS].splice(notificationIndex, 1);
  }
  if (!campaign?.[CAMPAIGN.NOTIFICATIONS]?.length) {
    delete campaign[CAMPAIGN.NOTIFICATIONS];
  }
  return campaign;
};

// update the to, cc, or bcc field with an array of values (general) or field name (personalized)
export const updateRecipientField = (
  campaign,
  notificationIndex,
  recipientType,
  newValue,
  hasCsvFile
) => {
  const campaignType = getCampaignType(campaign, notificationIndex);
  let recipientFieldName;
  if (
    campaignType === CAMPAIGN_TYPES.general.value &&
    campaign.general_data_source_type === "manual"
  ) {
    // if a general campaign
    recipientFieldName = UI_DATA.MANUAL_RECIPIENTS[recipientType];
    if (recipientType === RECIPIENTS.TO) {
      campaign = updateManualToRecipients({ ...campaign }, newValue);
    } else {
      // update a constant mapping for cc/bcc
      campaign = updateMapping(
        campaign,
        notificationIndex,
        recipientFieldName,
        typeof newValue === "object" ? newValue : [newValue],
        MAPPING.CONSTANT
      );
    }
  } else {
    // if a personalized campaign
    newValue = Array.isArray(newValue) ? "" : newValue;
    recipientFieldName = newValue?.trim();
  }
  // update recipient configuration, regardless of campaign type
  campaign = updateRecipientConfig(
    campaign,
    notificationIndex,
    recipientType,
    recipientFieldName,
    newValue
  );
  return campaign;
};

// update the value of campaign.notifications[x].configurations[y]
export const updateNotificationConfig = (
  campaign,
  notificationIndex,
  configName,
  newValue
) => {
  const notification = getNotification(campaign, notificationIndex) || {};
  const configs = notification[NOTIFICATION.CONFIGURATIONS] || [];
  const configIndex = getConfigIndex(configs, CONFIGURATIONS.NAME, configName);
  if (configIndex >= 0) {
    if (newValue && newValue.length) {
      configs[configIndex] = {
        ...configs[configIndex],
        [CONFIGURATIONS.VALUE]: newValue,
      };
    } else {
      configs.splice(configIndex, 1);
    }
  } else if (newValue && newValue.length) {
    configs.push({
      [CONFIGURATIONS.NAME]: configName,
      [CONFIGURATIONS.TYPE]: "inline",
      [CONFIGURATIONS.VALUE]: newValue,
    });
  }
  if (configs.length) {
    notification[NOTIFICATION.CONFIGURATIONS] = configs;
  } else {
    delete notification[NOTIFICATION.CONFIGURATIONS];
  }
  campaign = updateOrCreateNotification(
    campaign,
    notificationIndex,
    notification
  );

  return campaign;
};

// update the array of manual data in the data source object
const updateManualToRecipients = (campaign, recipients) => {
  let dataSource = campaign[CAMPAIGN.DATA_SOURCE] || {};
  let recipientsArray = [];
  if (recipients && recipients.length) {
    if (typeof recipients === "object") {
      recipients.map((recipient) =>
        recipientsArray.push({
          [UI_DATA.MANUAL_RECIPIENTS.to]: recipient,
        })
      );
    } else {
      recipientsArray = [{ [UI_DATA.MANUAL_RECIPIENTS.to]: recipients }];
    }
    dataSource = {
      [DATA_SOURCE.TYPE]: DATA_SOURCE_TYPES.MANUAL,
      [DATA_SOURCE.CONFIGURATIONS]: [
        {
          type: "inline",
          name: "data",
          value: recipientsArray,
        },
      ],
    };
  } else {
    dataSource = {
      [DATA_SOURCE.TYPE]: DATA_SOURCE_TYPES.MANUAL,
      [DATA_SOURCE.CONFIGURATIONS]: [],
    };
  }
  campaign[CAMPAIGN.DATA_SOURCE] = dataSource;
  return campaign;
};

// modify, create or remove one of the recipient configurations for a notification
const updateRecipientConfig = (
  campaign,
  notificationIndex,
  recipientType,
  recipientFieldName,
  newValue
) => {
  const notification = getNotification(campaign, notificationIndex) || {};
  const recipientConfigs = notification[NOTIFICATION.RECIPIENTS] || [];
  const recipientIndex = getConfigIndex(
    recipientConfigs,
    CONFIGURATIONS.TYPE,
    recipientType
  );
  if (recipientIndex >= 0) {
    if (newValue && newValue.length) {
      recipientConfigs[recipientIndex] = {
        ...recipientConfigs[recipientIndex],
        [RECIPIENTS.FIELD]: recipientFieldName,
      };
    } else {
      recipientConfigs.splice(recipientIndex, 1);
    }
  } else if (newValue && newValue.length) {
    recipientConfigs.push({
      [RECIPIENTS.TYPE]: recipientType,
      [RECIPIENTS.FIELD]: recipientFieldName,
    });
  }
  notification[NOTIFICATION.RECIPIENTS] = recipientConfigs;
  campaign = updateOrCreateNotification(
    campaign,
    notificationIndex,
    notification
  );
  return campaign;
};

export const updateMapping = (
  campaign,
  notificationIndex,
  mappingTemplateName,
  newValue,
  mappingType,
  srcFieldName
) => {
  const notification = getNotification(campaign, notificationIndex) || {};
  const mappings = notification[NOTIFICATION.MAPPING] || [];
  const mappingIndex = getConfigIndex(
    mappings,
    MAPPING.TEMPLATE_NAME,
    mappingTemplateName
  );
  const mappingFieldProperty =
    mappingType === MAPPING.CIP ? MAPPING.CIP_FIELD : MAPPING.CONSTANT_VALUE;
  if (mappingIndex >= 0) {
    if (newValue && newValue.length) {
      let updatedMapping = {
        ...mappings[mappingIndex],
      };
      if (mappingType) {
        updatedMapping = {
          ...updatedMapping,
          [MAPPING.RULE]: {
            [MAPPING.TYPE]: mappingType,
            [mappingFieldProperty]: newValue,
          },
        };
      }
      mappings[mappingIndex] = updatedMapping;
      if (srcFieldName) {
        mappings[mappingIndex] = {
          ...mappings[mappingIndex],
          [MAPPING.SRC_NAME]: srcFieldName,
        };
      }
    } else {
      mappings.splice(mappingIndex, 1);
    }
  } else if (newValue && newValue.length) {
    let newMapping = {
      [MAPPING.TEMPLATE_NAME]: mappingTemplateName,
    };
    if (mappingType) {
      newMapping = {
        ...newMapping,
        [MAPPING.RULE]: {
          [MAPPING.TYPE]: mappingType,
          [mappingFieldProperty]: newValue,
        },
      };
    }
    if (srcFieldName) {
      newMapping[MAPPING.SRC_NAME] = srcFieldName;
    }
    mappings.push(newMapping);
  }
  if (mappings.length) {
    notification[NOTIFICATION.MAPPING] = mappings;
  } else {
    delete notification[NOTIFICATION.MAPPING];
  }

  campaign = updateOrCreateNotification(
    campaign,
    notificationIndex,
    notification
  );
  return campaign;
};

export const getEmbeddedTemplates = (campaign) => {
  return campaign?.[CAMPAIGN.NOTIFICATIONS]?.map((notification) => {
    return notification[NOTIFICATION.TEMPLATE];
  });
};

export const removeEmbeddedTemplates = async (campaign) => {
  await campaign?.[CAMPAIGN.NOTIFICATIONS]?.forEach((notification, index) => {
    delete notification[NOTIFICATION.TEMPLATE];
    campaign[CAMPAIGN.NOTIFICATIONS][index] = notification;
  });
  return campaign;
};

export const addEmbeddedTemplates = async (campaign, templates = []) => {
  await campaign?.[CAMPAIGN.NOTIFICATIONS]?.forEach((notification, index) => {
    notification[NOTIFICATION.TEMPLATE] = templates[index];
    campaign[CAMPAIGN.NOTIFICATIONS][index] = notification;
  });
  return campaign;
};

export const updateCronTrigger = (campaign, cronExp, isOneTime = true) => {
  if (campaign) {
    if (cronExp) {
      campaign[CAMPAIGN.TRIGGER] = {
        [TRIGGER.TYPE]: TRIGGER.CRON,
        [TRIGGER.STATE]: TRIGGER.ACTIVE,
        [TRIGGER.CONFIG]: {
          [TRIGGER.CRON_EXP]: cronExp,
        },
        [TRIGGER.IS_ONE_TIME]: isOneTime,
      };
    } else if (campaign[CAMPAIGN.TRIGGER]) {
      campaign[CAMPAIGN.TRIGGER] = {
        ...campaign[CAMPAIGN.TRIGGER],
        [TRIGGER.STATE]: TRIGGER.INACTIVE,
      };
    }
  }
  return campaign;
};

// helper function that checks if all previous required steps are complete
// accepts an array of steps, the current active step index and selected campaign
// returns true of false
export const previousRequiredStepsComplete = (
  steps,
  activeStepIndex,
  campaign
) => {
  let complete = true;
  let i = 0;
  while (i < activeStepIndex && complete) {
    if (steps[i].required) {
      complete = steps[i].isComplete(campaign);
    }
    i = i + 1;
  }
  return complete;
};

export const getStepperConfig = (channel, campaignType) => {
  let stepper = DEFAULT_FLOW;
  if (channel) {
    stepper = STEPPER[channel];
    const stepperTypes = Object.keys(stepper);
    if (stepperTypes?.includes(campaignType)) {
      stepper = stepper[campaignType];
    } else {
      stepper = stepper[stepperTypes?.[0]];
    }
  }
  return stepper;
};

// determine what page to land the user on when a campaign is opened
export const getCurrentStepIndex = (campaign, steps) => {
  let current = 0;
  if (steps?.length) {
    while (current < steps.length - 1 && steps[current].isComplete(campaign)) {
      ++current;
    }
  }
  return current;
};

// helper function that returns the index that a particular config is at in a configurations array
export const getConfigIndex = (configs, attributeToMatch, configValue) => {
  const isConfig = (config) => config[attributeToMatch] === configValue;
  return configs?.findIndex(isConfig);
};

export const autoMapRecipientField = (
  campaign,
  notificationIndex,
  recipientField
) => {
  const notification = getNotification(campaign, notificationIndex);
  if (notification && recipientField) {
    let mappings = notification[NOTIFICATION.MAPPING] || [];
    let defaultCIPFieldMapping = CIP_FIELDS.EMAIL.variable;
    let recipientMapping = {
      [MAPPING.SRC_NAME]: recipientField,
      [MAPPING.TEMPLATE_NAME]: recipientField,
      [MAPPING.RULE]: {
        [MAPPING.TYPE]: MAPPING.CIP,
        [MAPPING.CIP_FIELD]: CIP_FIELDS.EMAIL.variable,
      },
    };
    if (notification.type === NOTIFICATION_CHANNELS.teams.value) {
      recipientMapping = {
        [MAPPING.SRC_NAME]: recipientField,
        [MAPPING.TEMPLATE_NAME]: recipientField,
        [MAPPING.RULE]: {
          [MAPPING.TYPE]: MAPPING.CIP,
          [MAPPING.CIP_FIELD]: CIP_FIELDS.UPN.variable,
        },
      };
      defaultCIPFieldMapping = CIP_FIELDS.UPN.variable;
    }
    const mappingIndex = mappings.findIndex(
      (mapping) =>
        mapping[MAPPING.SRC_NAME] === recipientMapping[MAPPING.SRC_NAME] &&
        mapping[MAPPING.TEMPLATE_NAME] ===
          recipientMapping[MAPPING.TEMPLATE_NAME]
    );
    if (mappingIndex < 0) {
      mappings.push(recipientMapping);
    } else if (
      mappings[mappingIndex]?.[MAPPING.RULE][MAPPING.CIP_FIELD] !==
      defaultCIPFieldMapping
    ) {
      mappings[mappingIndex] = recipientMapping;
    } else {
      // no updates needed
      return campaign;
    }
    notification[NOTIFICATION.MAPPING] = mappings;
    campaign[CAMPAIGN.NOTIFICATIONS][notificationIndex] = notification;
  }
  return campaign;
};

export const removeMapping = (
  campaign,
  notificationIndex,
  srcName,
  templateName
) => {
  const notification = getNotification(campaign, notificationIndex);
  if (notification) {
    let mappings = notification[NOTIFICATION.MAPPING];
    const mappingIndex = mappings?.findIndex(
      (mapping) =>
        mapping[MAPPING.SRC_NAME] === srcName &&
        mapping[MAPPING.TEMPLATE_NAME] === templateName
    );
    if (mappingIndex > -1) {
      mappings.splice(mappingIndex, 1);
      if (mappings?.length) {
        notification[NOTIFICATION.MAPPING] = mappings;
      } else {
        delete notification[NOTIFICATION.MAPPING];
      }
      campaign[CAMPAIGN.NOTIFICATIONS][notificationIndex] = notification;
    }
  }
  return campaign;
};

// removes a notifications recipient and recipient-related mappings
export const resetRecipients = (campaign, notificationIndex) => {
  if (campaign) {
    const recipients = getRecipientsFieldValues(campaign, notificationIndex);
    [RECIPIENTS.TO, RECIPIENTS.CC, RECIPIENTS.BCC].forEach((type) => {
      const originalRecipient = recipients?.[type];
      if (originalRecipient) {
        campaign = updateRecipientField(
          campaign,
          notificationIndex,
          type,
          null
        );
        campaign = removeMapping(
          campaign,
          notificationIndex,
          originalRecipient,
          originalRecipient
        );
      }
    });
  }
  return campaign;
};

// Remove any csv mappings that no longer apply to current dataSourceHeadings
export const removeUnusedMappings = (campaign, dataSourceHeadings) => {
  campaign?.[CAMPAIGN.NOTIFICATIONS]?.map((notification, index) => {
    let unused = [];
    notification?.[NOTIFICATION.MAPPING]?.map((mapping) => {
      if (
        !mapping[MAPPING.RULE] ||
        mapping[MAPPING.RULE][MAPPING.TYPE] !== MAPPING.CONSTANT
      ) {
        const match = dataSourceHeadings?.find(
          (heading) => heading.value === mapping[MAPPING.SRC_NAME]
        );
        if (!match) {
          unused.push(mapping);
        }
      }
    });
    unused?.forEach((unusedMapping) => {
      campaign = removeMapping(
        campaign,
        index,
        unusedMapping[MAPPING.SRC_NAME],
        unusedMapping[MAPPING.TEMPLATE_NAME]
      );
    });
  });
  return campaign;
};

// add a field to the fieldErrors array if true, remove it otherwise
export const updateFieldErrors = (
  fieldName,
  error,
  fieldErrors,
  setFieldErrors
) => {
  let errors = [...fieldErrors];
  if (error) {
    if (!errors.includes(fieldName)) {
      errors.push(fieldName);
      setFieldErrors(errors);
    }
  } else if (errors.includes(fieldName)) {
    let errorIndex = errors.findIndex((field) => field === fieldName);
    if (errorIndex > -1) {
      errors.splice(errorIndex, 1);
      setFieldErrors(errors);
    }
  }
};

// determine if a given step should have an error state
// a step has errors if fields in that step are included in the fieldErrors array
export const checkForStepError = (step, fieldErrors) => {
  let error = false;
  let i = 0;
  while (!error && i < step.fields?.length) {
    error = fieldErrors?.includes(step.fields[i]);
    i++;
  }
  return error;
};
