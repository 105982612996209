import axios from "axios";
import { ErrorTypes } from "../../consts/ErrorTypes";
import { handleError } from "../common";
import Configs from '../../Configurations';
import PulseService from '../../services/PulseService';
let getToken;
let setError;

/**
 * Sets token provider & error handler used in request & response interceptors
 * Sets default values for Axios
 * Sets baseURL to window.env.REACT_APP_API_BASE_URL
 * Use getFullAPIURL if need the full URL
 */

const AuthenticatedAPI = {
  Routes: {
    LOGOUT: "logout",
  },
  getFullAPIURL: function (serviceName) {
    return `${Configs.REACT_APP_API_ENDPOINT}${serviceName}`;
  },

  setup: function (tokenProvider, errorHandler) {
    //Set callbacks needed for request & response interceptors
    getToken = tokenProvider;
    setError = errorHandler;
    //DEFAULTS - Since all API calls should go thru the BE, we can set the baseURL here and pass only the service name for url.
    axios.defaults.baseURL = Configs.REACT_APP_API_ENDPOINT;
    axios.defaults.headers.post["Content-Type"] = "application/json";

    axios.interceptors.request.use(
      function (config) {
        const token = getToken();
        config.headers["jwt"] = token;
        let customDimensions = PulseService.getCustomDimensions();
        for (let key in customDimensions) {
          config.headers[key] = customDimensions[key];
        }
        return config;
      },
      function (error) {
        setError(ErrorTypes.APP_ERROR);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (
          response?.status === 204 ||
          (response?.status === 200 && response?.data.type === "success")
        ) {
          return response;
        }
        return Promise.reject(response);
      },
      (error) => {
        const { response } = error;
        //Handle error thrown while preparing authorization header in request interceptors
        if (
          error.errorCode === ErrorTypes.TOKEN_EXPIRED ||
          response?.status === 401
        ) {
          setError(ErrorTypes.TOKEN_EXPIRED);
          return response;
        } else if (response?.status === 403) {
          setError(ErrorTypes.USER_ACTION_NOT_ALLOWED);
          return response;
        }
        //if the error isn't access related, console log it
        handleError(error);
        return Promise.reject(error);
      }
    );
  },
};

export default AuthenticatedAPI;
