import React, { useState, useRef } from "react";
import { CSV_SIZE_LIMIT_MB, EVENTS, EVENT_TYPES } from "../../consts/Common";
import { checkValidCsv } from "../../utils/campaign";

import FileUpload from "../common/FileUpload";
import { CampaignState } from "../../contexts/Campaign";
import { uploadCampaignCsv } from "../../utils/campaign";
import QA from "../../consts/QA";
import { trackEvent } from "../../utils/appInsight";
import { useAppContext } from "../../contexts/Core";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CsvUpload`;

function CampaignEditCsvUpload() {
  const [csvError, toggleCsvError] = useState(false); // SAS/upload error
  const [csvDataSourceHeaderError, toggleCsvDataSourceHeaderError] =
    useState(false); //invalid csv data source header error
  const [csvDataSourceHeaderErrorMessage, setCsvDataSourceHeaderErrorMessage] =
    useState(""); //invalid csv data source header
  const fileUploadStateRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false);

  const {pageTitle} = useAppContext()

  return (
    <CampaignState>
      {({
        selectedCampaign,
        csvFile,
        setCsvFile,
        updateDataSourceHeadings,
        downloadError,
        toggleSavingCampaign,
        saveCampaign,
        setSelectedCampaign,
      }) => {
        return (
          <FileUpload
            id={QA_ID_PREFIX}
            accept=".csv"
            sizeLimit={CSV_SIZE_LIMIT_MB * 1000000}
            selectedCampaign={selectedCampaign}
            files={csvFile ? [csvFile] : undefined}
            fileUploadStateRef={fileUploadStateRef}
            showLoader={showLoader}
            handleUpload={async (files) => {
              setShowLoader(true);
              toggleSavingCampaign(true);
              const validateCsvHeadersResult = await checkValidCsv(files?.[0]);
              const headersErrorMsg = `Please remove ',' or '.' from "${validateCsvHeadersResult.invalidHeaders}" column in the csv file and re-upload it!!`
              if (!validateCsvHeadersResult.isValid) {
                toggleCsvDataSourceHeaderError(true);
                setCsvDataSourceHeaderErrorMessage(headersErrorMsg);
                trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: headersErrorMsg, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.CSV_INVALID_HEADERS)
                toggleSavingCampaign(false);
                return false;
              }
              await uploadCampaignCsv(
                selectedCampaign,
                files?.[0],
                async () => {
                  // For personalised campaign, setting general_data_source_type null
                  const updatedCampaign = selectedCampaign;

                  updatedCampaign["general_data_source_type"] =
                    updatedCampaign?.data_source?.type === "csv" ? null : "csv";

                  await saveCampaign(updatedCampaign, false);

                  await updateDataSourceHeadings({ ...updatedCampaign }, false);
                  setCsvFile(files?.[0]);
                  toggleSavingCampaign(false);
                  toggleCsvDataSourceHeaderError(false);
                  setCsvDataSourceHeaderErrorMessage("");
                  setShowLoader(false);
                  return files;
                }, //success handler
                (error) => {
                  toggleCsvError(true);
                  toggleSavingCampaign(false);
                  setShowLoader(false);
                } //error handler
              );
              return true;
            }}
            error={csvError}
            csvDataSourceHeaderError={csvDataSourceHeaderError}
            csvDataSourceHeaderErrorMessage={csvDataSourceHeaderErrorMessage}
            clearError={() => {
              toggleCsvError(false);
              toggleSavingCampaign(false);
            }}
            dropInstructions={
              <>
                Drag or <u>browse</u> to{" "}
                {csvFile ? (
                  <>
                    <strong>replace</strong> your
                  </>
                ) : (
                  "upload a"
                )}{" "}
                CSV file.
              </>
            }
            fileInstructions={`You can upload a CSV file up to ${CSV_SIZE_LIMIT_MB} MB.`}
            downloadError={downloadError}
            enableDownload={true}
          />
        );
      }}
    </CampaignState>
  );
}

export default CampaignEditCsvUpload;
