import { DIALOGS } from "../consts/Dialogs";
import { COMMANDS } from "../consts/Commands";

const commands = (editor, opts) => {
  if (editor) {
    editor.Commands.add(COMMANDS.TB_DELETE_CONFIRM, {
      run() {
        opts.setDialogContent(DIALOGS.DELETE_COMPONENT);
      },
    });
    editor.Commands.add(COMMANDS.TB_DYNAMIC, {
      run() {
        opts.openDynamicContentDialog();
      },
    });
    editor.Commands.add(COMMANDS.EXPORT_TEMPLATE, {
      run() {
        opts.exportTemplate();
      },
    });
    editor.Commands.add(COMMANDS.IMPORT_TEMPLATE, {
      run() {
        opts.importTemplate();
      },
    });
    editor.Commands.add(COMMANDS.CHANGE_TEMPLATE, {
      run() {
        opts.changeTemplate();
      },
    });
    editor.Commands.add(COMMANDS.OPEN_IMAGE_GALLERY, {
      run() {
        opts.openImageGallery();
      },
    });
    editor.Commands.add(COMMANDS.SAVE_AS_TEMPLATE, {
      run() {
        opts.saveAsTemplate();
      },
    });
  }
};
export default commands;
