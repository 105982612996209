import React from "react";
import { CAMPAIGN_FIELDS, CAMPAIGN_TYPES, NOTIFICATION_CHANNELS } from "../../consts/Campaign";
import QA from "../../consts/QA";
import { CampaignState } from "../../contexts/Campaign";
import EmailInputField from "../common/EmailInputField";
import SelectInputField from "../common/SelectInputField";
import { COPY } from "../../consts/Copy";
import CampaignEditReviewSection from "./CampaignEditReviewSection";
import FileList from "../common/FileList";

// MUI
import { Box, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import {
  ATTACHMENT,
  CONFIGURATIONS,
  NOTIFICATION,
  PRIORITIES,
  TRACKING_CONFIGS,
} from "../../consts/DBFields";
import TextInputField from "../common/TextInputField";
import CheckboxField from "../common/CheckboxField";
import CampaignEditFromField from "./CampaignEditFromField";
import { AppState } from "../../contexts/Core";
import { EVENT_TYPES, EVENTS, FEATURE } from "../../consts/Common";
import CampaignEditAttachments from "./CampaignEditAttachments";
import RadioButtons from "../common/RadioButtons";
import { trackEvent } from "../../utils/appInsight";

export const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditDetails`;

const useStyles = makeStyles((theme) => ({
  mappingChip: {
    borderRadius: 6,
  },
}));

function CampaignEditDetails({ isReview = false, isResend = false }) {
  const classes = useStyles();

  const getUpdatedRecipientFields = (recipientFields, campaign) => {
    const updatedRecipientFields = [];
    recipientFields.forEach((recipientField) => {
      if (campaign?.general_data_source_type === "csv") {
        if (!["cc", "bcc"].includes(recipientField?.name)) {
          updatedRecipientFields.push(recipientField);
        }
      } else {
        updatedRecipientFields.push(recipientField);
      }
    });

    return updatedRecipientFields;
  };

  const externalUserOptions = CAMPAIGN_FIELDS.EXTERNAL_USER_OPTIONS[0]

  return (
    <>
      <CampaignState>
        {({
          notificationRecipientsFields,
          notificationConfigurationFields,
          notificationTrackingFields,
          externalUsersOptionsFields,
          campaignType,
          dataSourceHeadings,
          channel,
          fieldErrors,
          selectedCampaign,
        }) => {
          const updatedRecipientFields = getUpdatedRecipientFields(
            CAMPAIGN_FIELDS[NOTIFICATION.RECIPIENTS],
            selectedCampaign
          );

          return (
            <>
              <AppState>
                {({ user, sendersList, isToggleEnabled }) => {
                  return !isReview ? (
                    <Box
                      boxSizing="border-box"
                      width="100%"
                      pt={1}
                      pb={4}
                      overflow="hidden"
                      maxWidth={1100}
                      id={QA_ID_PREFIX}
                    >
                      <Grid container justify="center" spacing={2}>
                        {updatedRecipientFields.map((field) => {
                          if (field.channels.includes(channel)) {
                            return (
                              <Grid
                                item
                                {...getCampaignFieldWidth(field)}
                                key={`campaign-details-form-${field.name}`}
                              >
                                {campaignType ===
                                  CAMPAIGN_TYPES.personalized.value ||
                                selectedCampaign.general_data_source_type ===
                                  "csv" ? (
                                  <SelectInputField
                                    value={
                                      notificationRecipientsFields?.[field.name]
                                        ?.value
                                    }
                                    field={{
                                      ...field,
                                      options: dataSourceHeadings,
                                    }}
                                    handleChange={
                                      notificationRecipientsFields?.[field.name]
                                        ?.handleChange
                                    }
                                    placeholder={{
                                      value: " ",
                                      label:
                                        COPY.CAMPAIGN_EDIT
                                          .DATA_SOURCE_HEADING_PLACEHOLDER,
                                    }}
                                    id={`${QA_ID_PREFIX}-Personalized-RecipientInput-${field.name}`}
                                  />
                                ) : (
                                  <EmailInputField
                                    componentKey={`campaign-details-form-${field.name}`}
                                    multiple
                                    value={
                                      notificationRecipientsFields?.[field.name]
                                        ?.value
                                    }
                                    field={field}
                                    handleChange={
                                      notificationRecipientsFields?.[field.name]
                                        ?.handleChange
                                    }
                                    id={`${QA_ID_PREFIX}-General-RecipientInput-${field.name}`}
                                    isLookUp={true}
                                    includeGroups={field.includeGroups}
                                    enableAnalytics={true}
                                  />
                                )}
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        })}
                        {CAMPAIGN_FIELDS[NOTIFICATION.CONFIGURATIONS].map(
                          (field) => {
                            if (
                              field.channels.includes(channel) &&
                              !notificationConfigurationFields?.[field.name]
                                ?.toggleDisabled
                            ) {
                              return (
                                <Grid
                                  item
                                  {...getCampaignFieldWidth(field)}
                                  key={`campaign-details-form-${field.name}`}
                                >
                                  {field.name === CONFIGURATIONS.FROM ? (
                                    isToggleEnabled(FEATURE.VALIDATE_FROM) ? (
                                      <CampaignEditFromField
                                        field={field}
                                        value={
                                          notificationConfigurationFields?.[
                                            field.name
                                          ]?.value
                                        }
                                        handleChange={
                                          notificationConfigurationFields?.[
                                            field.name
                                          ]?.handleChange
                                        }
                                        user={user}
                                        sendersList={sendersList}
                                      />
                                    ) : (
                                      <EmailInputField
                                        multiple={false}
                                        value={
                                          notificationConfigurationFields?.[
                                            field.name
                                          ]?.value
                                        }
                                        field={field}
                                        handleChange={
                                          notificationConfigurationFields?.[
                                            field.name
                                          ]?.handleChange
                                        }
                                        componentKey={`campaign-details-form-${field.name}`}
                                        id={`${QA_ID_PREFIX}-ConfigurationEmailInput-${field.name}`}
                                      />
                                    )
                                  ) : field.type === "text" ? (
                                    <TextInputField
                                      handleChange={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.handleChange
                                      }
                                      value={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.value
                                      }
                                      field={field}
                                      componentKey={`campaign-details-form-${field.name}`}
                                      id={`${QA_ID_PREFIX}-ConfigurationTextInput-${field.name}`}
                                    />
                                  ) : field.type === "select" ? (
                                    <SelectInputField
                                      handleChange={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.handleChange
                                      }
                                      value={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.value
                                      }
                                      field={field}
                                      componentKey={`campaign-details-form-${field.name}`}
                                      id={`${QA_ID_PREFIX}-ConfigurationSelectInput-${field.name}`}
                                    />
                                  ) : field.type === "file" &&
                                    isToggleEnabled(
                                      FEATURE.EMAIL_ATTACHMENTS
                                    ) ? (
                                    <CampaignEditAttachments
                                      {...field}
                                      handleChange={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.handleChange
                                      }
                                      value={
                                        notificationConfigurationFields?.[
                                          field.name
                                        ]?.value
                                      }
                                    />
                                  ) : null}
                                </Grid>
                              );
                            } else {
                              return null;
                            }
                          }
                        )}
                        {isToggleEnabled(FEATURE.EXTERNAL_USERS_CHECK) && channel === NOTIFICATION_CHANNELS.mail.value && campaignType === CAMPAIGN_TYPES.personalized.value ? 
                        <>
                        <Grid item xs={12} sm={10}>
                          <Box mt={2}>
                            <Typography
                              variant="h5"
                              color="textSecondary"
                              id={`${QA_ID_PREFIX}-ExternalUsersOptionsTitle`}
                            >
                              <strong>
                                {CAMPAIGN_FIELDS.EXTERNAL_USER_OPTIONS_TITLE}
                              </strong>
                            </Typography>
                          </Box>
                        </Grid>
                        
                          <Grid item xs={12} sm={10}>
                            <RadioButtons 
                              field={{
                              required: true,
                              name: externalUserOptions?.name,
                              helperText: externalUserOptions?.helperText,
                              rowLayout: true
                            }}
                            id={`${QA_ID_PREFIX}-ExternalUsersOptions`}
                              value={externalUsersOptionsFields[externalUserOptions?.name]?.value} 
                              options={externalUsersOptionsFields[externalUserOptions?.name]?.options}
                              handleChange={externalUsersOptionsFields[externalUserOptions?.name]?.handleChange}/>
                          </Grid> 
                        </> : <></>}

                        <Grid item xs={12} sm={10}>
                          <Box mt={2}>
                            <Typography
                              variant="h5"
                              color="textSecondary"
                              id={`${QA_ID_PREFIX}-TrackingConfigurationTitle`}
                            >
                              <strong>
                                {CAMPAIGN_FIELDS.TRACKING_TITLE[channel]}
                              </strong>
                            </Typography>
                          </Box>
                        </Grid>
                        {CAMPAIGN_FIELDS.TRACKING.map((field) => {
                          if (
                            field.channels.includes(channel) &&
                            (!field.featureToggle ||
                              isToggleEnabled(field.featureToggle))
                          ) {
                            return (
                              <Grid
                                item
                                {...getCampaignFieldWidth(field)}
                                key={`campaign-details-form-${field.name}`}
                              >
                                <CheckboxField
                                  field={{
                                    ...field,
                                    disabled:
                                      field.disabled ||
                                      isResend ||
                                      (field.enableIfTracked &&
                                        field.enableRef &&
                                        !notificationTrackingFields[
                                          field.enableRef
                                        ].value),
                                  }}
                                  value={
                                    notificationTrackingFields[field.name]
                                      ?.value
                                  }
                                  handleChange={(e) => {
                                    notificationTrackingFields[field.name]
                                    ?.handleChange(e)
                                    trackEvent(EVENTS.USER_INTERACTION, 
                                      {campaignId: selectedCampaign?.uid,
                                        configName: field.name}, 
                                      EVENT_TYPES.USER_INTERACTION.TRACKING_CONFIG)
                                  }}
                                  id={`${QA_ID_PREFIX}-TrackingConfiguration-${field.name}`}
                                />
                              </Grid>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Grid>
                    </Box>
                  ) : (
                    <CampaignEditReviewSection
                      inline
                      reviewItems={
                        channel
                          ? [
                              ...CAMPAIGN_FIELDS[NOTIFICATION.RECIPIENTS].map(
                                (field) => {
                                  if (field.channels.includes(channel)) {
                                    const value =
                                      notificationRecipientsFields[field.name]
                                        .value;
                                    return {
                                      label: field.label,
                                      field: field.name,
                                      value: value?.length ? (
                                        selectedCampaign.general_data_source_type ===
                                        CAMPAIGN_TYPES.general.dataSourceTypes
                                          .MANUAL.value ? (
                                          `${value.length} ${
                                            value.length > 1
                                              ? "Recipients"
                                              : "Recipient"
                                          }`
                                        ) : (
                                          <Chip
                                            label={
                                              <Typography>{value}</Typography>
                                            }
                                            variant="outlined"
                                            className={classes.mappingChip}
                                            id={`${QA_ID_PREFIX}-ReviewSection-TrackingConfig-Chip-${field.label}`}
                                          />
                                        )
                                      ) : null,
                                    };
                                  } else {
                                    return null;
                                  }
                                }
                              ),
                              ...CAMPAIGN_FIELDS[
                                NOTIFICATION.CONFIGURATIONS
                              ].map((field) => {
                                if (
                                  field.channels.includes(channel) &&
                                  !notificationConfigurationFields?.[field.name]
                                    ?.toggleDisabled
                                ) {
                                  const value =
                                    notificationConfigurationFields[field.name]
                                      ?.value;
                                  return {
                                    label: field.label,
                                    field: field.name,
                                    value:
                                      field.name === CONFIGURATIONS.PRIORITY ? (
                                        PRIORITIES[value]?.label
                                      ) : field.name ===
                                          CONFIGURATIONS.ATTACHMENTS &&
                                        value?.length ? (
                                        <FileList
                                          fileNames={value?.map(
                                            (file) => file[ATTACHMENT.NAME]
                                          )}
                                        />
                                      ) : (
                                        value
                                      ),
                                  };
                                } else {
                                  return null;
                                }
                              }),
                              ...CAMPAIGN_FIELDS.TRACKING.map((field) => {
                                if (
                                  field.channels.includes(channel) &&
                                  (!field.featureToggle ||
                                    isToggleEnabled(field.featureToggle))
                                ) {
                                  const value =
                                    notificationTrackingFields[field.name]
                                      ?.value;
                                  return {
                                    label: field.label,
                                    field: field.name,
                                    value: (
                                      <Chip
                                        color={
                                          value && value !== "false"
                                            ? "primary"
                                            : "default"
                                        }
                                        label={
                                          value && value !== "false"
                                            ? "On"
                                            : "Off"
                                        }
                                        id={`${QA_ID_PREFIX}-ReviewSection-TrackingConfiguration-Chip-${field.label}`}
                                      />
                                    ),
                                  };
                                } else {
                                  return null;
                                }
                              }),
                            ]
                          : [{ label: "Details", value: null }]
                      }
                      id={`${QA_ID_PREFIX}-ReviewSection`}
                      fieldErrors={fieldErrors}
                    />
                  );
                }}
              </AppState>
            </>
          );
        }}
      </CampaignState>
    </>
  );
}

export default CampaignEditDetails;

const getCampaignFieldWidth = (field) => {
  return {
    xs: !field.width ? 12 : field.width?.xs ? field.width.xs : 6,
    sm: !field.width ? 10 : field.width?.sm ? field.width.sm : 5,
    md: !field.width ? 10 : field.width?.md ? field.width.md : 5,
  };
};
