import React, { useState } from "react";
import { PAGES } from "../../consts/Common";
import UserMenu from "./UserMenu";

// MUI
import {
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer as Drawer,
  IconButton,
  Hidden,
  Grid,
} from "@material-ui/core";

import { Menu as MenuIcon } from "@material-ui/icons";

const QA_ID_PREFIX = `MobileNav`;

function MobileNav({ activePage, user, setActivePage, openCreateTeamOverlay }) {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        aria-label="menu"
        onClick={(e) => {
          setAnchorEl(e.target);
        }}
        id={`${QA_ID_PREFIX}-MenuIconButton`}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchorel={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onOpen={() => {}}
        id={`${QA_ID_PREFIX}-Drawer`}
      >
        <Grid container direction="column" alignItems="flex-end" spacing={0}>
          <Grid item>
            <Hidden smUp>
              <UserMenu
                user={user}
                id={`${QA_ID_PREFIX}-UserMenu`}
                openCreateTeamOverlay={openCreateTeamOverlay}
              />
            </Hidden>
          </Grid>
          <Grid item>
            <List id={`${QA_ID_PREFIX}-List`}>
              {Object.keys(PAGES).map((opt, index) => {
                if (!PAGES[opt].disabled) {
                  return (
                    <ListItem
                      button
                      key={PAGES[opt].label}
                      onClick={() => {
                        setAnchorEl(null);
                        setActivePage(index);
                      }}
                      selected={index === activePage}
                      id={`${QA_ID_PREFIX}-ListItem-${PAGES[opt].label}`}
                    >
                      <ListItemText
                        primary={PAGES[opt].label}
                        id={`${QA_ID_PREFIX}-ListItem-${PAGES[opt].label}-Label`}
                      />
                    </ListItem>
                  );
                }
                else{
                  return <></>
                }
              })}
            </List>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

export default MobileNav;
