import { SETTINGS, SETTINGS_PANELS } from "./consts/Settings";
import { toHex } from "colornames";
import { getElementType } from "./util";
import { TYPES } from "./consts/DomComponents";
import validator from "validator";
import { TEMPLATE_VARIABLES } from "../../../../consts/Template";

export const getComponentSettings = (model) => {
  let values = {};
  const settings = SETTINGS_PANELS[getElementType(model)] || [];
  settings.forEach((setting) => {
    if (setting.VALUE) {
      values[setting.ID] = setting.VALUE(model);
    } else {
      values[setting.ID] = null;
    }
  });
  return values;
};

export const getComponentBorder = (model) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  if (getElementType(model) !== TYPES.DIVIDER) {
    const border = attrs[SETTINGS.BORDER.ATTRIBUTE]?.split(" ") || [];
    return {
      style: border.length ? border[0] : null,
      width: border.length > 1 ? stringToNum(border[1]) : null,
      color:
        border.length > 2
          ? border[2].includes("#")
            ? border[2]
            : toHex(border[2])
          : null,
    };
  } else {
    return {
      style: attrs?.["border-style"],
      width: stringToNum(attrs?.["border-width"]),
      color: attrs?.["border-color"],
    };
  }
};

export const setComponentBorder = (model, { style, width, color }) => {
  if (!model) {
    return;
  }
  const BORDER = SETTINGS.BORDER;
  const defaultStyle = BORDER.INPUTS.STYLE.defaultValue;
  const defaultWidth = `${BORDER.INPUTS.WIDTH.defaultValue}px`;
  const defaultColor = BORDER.INPUTS.COLOR.defaultValue;
  const attrs = model.getAttributes() || {};

  if (getElementType(model) !== TYPES.DIVIDER) {
    delete attrs["border-top"];
    delete attrs["border-right"];
    delete attrs["border-bottom"];
    delete attrs["border-left"];
    const currentBorder = attrs[BORDER.ATTRIBUTE]?.split(" ") || [];
    const updatedBorder = [defaultStyle, defaultWidth, defaultColor];
    if (style) {
      updatedBorder[0] = style;
    } else if (currentBorder.length) {
      updatedBorder[0] = currentBorder[0];
    }
    if (width) {
      updatedBorder[1] = `${width}px`;
    } else if (currentBorder.length > 1) {
      updatedBorder[1] = currentBorder[1];
    }
    if (color) {
      updatedBorder[2] = color;
    } else if (currentBorder.length > 2) {
      updatedBorder[2] = currentBorder[2];
    }
    model.setAttributes({
      ...attrs,
      [BORDER.ATTRIBUTE]: updatedBorder.join(" "),
    });
  } else {
    model.setAttributes({
      ...attrs,
      ["border-style"]: style ? style : attrs["border-style"] || defaultStyle,
      ["border-width"]: width
        ? `${width}px`
        : attrs["border-width"] || defaultWidth,
      ["border-color"]: color ? color : attrs["border-color"] || defaultColor,
    });
  }
};

// get the width or height value of an element as a number
export const getComponentDimension = (model, unit, attributeName) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  const dimension = attrs[attributeName];
  return stringToNum(dimension, unit);
};

export const getComponentAttribute = (model, attributeName) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  return attrs[attributeName];
};

// set the width or height of an element as a px or % value
export const setComponentDimension = (model, newValue, unit, attributeName) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  if (newValue || newValue === 0) {
    attrs[attributeName] = `${newValue}${unit}`;
  } else {
    delete attrs[attributeName];
  }
  model.setAttributes(attrs);
};

// get the padding setting(s) for an element and return as an array of length 0, 1 or 4.
// if length === 1, use the value for padding attribute
// if length ===4, use the values for padding-top, padding-right, padding-bottom and padding-left respectively
export const getComponentPadding = (model) => {
  if (!model) {
    return {};
  }
  let paddingValues = [];
  const attrs = model.getAttributes() || {};
  if (attrs.padding) {
    paddingValues = cssPaddingToArray(model, attrs.padding, "px");
  } else {
    const top = stringToNum(attrs["padding-top"]) || 0;
    const right = stringToNum(attrs["padding-right"]) || 0;
    const bottom = stringToNum(attrs["padding-bottom"]) || 0;
    const left = stringToNum(attrs["padding-left"]) || 0;
    paddingValues = [top, right, bottom, left];
  }
  return paddingValues;
};

// set element padding setting based on the values in an array
export const setComponentPadding = (model, values) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  if (values.length > 1) {
    // if custom values
    attrs["padding-top"] = `${values[0] || 0}px`;
    attrs["padding-right"] = `${values[1] || 0}px`;
    attrs["padding-bottom"] = `${values[2] || 0}px`;
    attrs["padding-left"] = `${values[3] || 0}px`;
    delete attrs.padding;
  } else {
    if (values.length) {
      // if same value for all sides
      attrs.padding = `${values[0] || 0}px`;
    } else {
      // if no padding
      delete attrs.padding;
    }
    delete attrs["padding-top"];
    delete attrs["padding-right"];
    delete attrs["padding-bottom"];
    delete attrs["padding-left"];
  }
  model.setAttributes(attrs);
};

export const getComponentBgColor = (model, attribute) => {
  if (!model) {
    return;
  }
  const attrs = model.getAttributes() || {};
  let bgColor = attrs[attribute];
  if (!bgColor?.includes("#")) {
    try {
      bgColor = toHex(bgColor);
    } catch (err) {
      bgColor = null;
    }
  }
  return bgColor;
};

export const setComponentBgColor = (model, attribute, value) => {
  if (!model) {
    return;
  }
  const attrs = model.getAttributes() || {};
  if (value) {
    attrs[attribute] = value;
  } else {
    delete attrs[attribute];
  }
  model.setAttributes(attrs);
};

// helper function for converting a string pixel or percent value to a number
export const stringToNum = (px, unit = "px") => {
  if (px) {
    const num = Number(px.replace(unit, ""));
    return isNaN(num) ? null : num;
  }
};

export const cssPaddingToArray = (model, string, unit, splitValue = " ") => {
  string = string?.split(splitValue) || [];
  let newValues = [];
  switch (string.length) {
    case 1:
      newValues = [
        stringToNum(string[0], unit),
        stringToNum(string[0], unit),
        stringToNum(string[0], unit),
        stringToNum(string[0], unit),
      ];
      if (model) {
        setComponentPadding(model, newValues);
      }
      return newValues;
    case 2:
      newValues = [
        stringToNum(string[0], unit),
        stringToNum(string[1], unit),
        stringToNum(string[0], unit),
        stringToNum(string[1], unit),
      ];
      if (model) {
        setComponentPadding(model, newValues);
      }
      return newValues;
    case 3:
      newValues = [
        stringToNum(string[0], unit),
        stringToNum(string[1], unit),
        stringToNum(string[2], unit),
        stringToNum(string[1], unit),
      ];
      if (model) {
        setComponentPadding(model, newValues);
      }
      return newValues;
    case 4:
      return [
        stringToNum(string[0], unit),
        stringToNum(string[1], unit),
        stringToNum(string[2], unit),
        stringToNum(string[3], unit),
      ];
    default:
      return [];
  }
};

// gets the font, font-size, font-weight, font-style, and color attributes
export const getButtonTextFormat = (model) => {
  if (!model || getElementType(model) !== TYPES.BUTTON) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  let fontSize = attrs["font-size"];
  if (fontSize) {
    let fontUnit = fontSize?.includes("pt") ? "pt" : "px";
    fontSize = stringToNum(fontSize, fontUnit);
    if (fontUnit === "px") {
      fontSize = Math.round(fontSize * 0.75);
    }
  } else {
    fontSize = null;
  }
  return {
    font: SETTINGS.BUTTON_FONT.INPUTS.FONT.values.find(
      (font) =>
        font.value.split(",")[0]?.toLowerCase() ===
        attrs["font-family"]?.split(",")[0]?.toLowerCase()
    )?.value,
    size: fontSize,
    bold: attrs["font-weight"] === "bold",
    italic: attrs["font-style"] === "italic",
    color: attrs.color
      ? attrs.color.includes("#")
        ? attrs.color
        : toHex(attrs.color)
      : null,
  };
};

// sets the font, font-size, font-weight, font-style and color for a button
export const setButtonTextFormat = (model, formats) => {
  if (!model || getElementType(model) !== TYPES.BUTTON) {
    return;
  }
  const { font, size, bold, italic, color } = formats;
  const attrs = model.getAttributes() || {};
  attrs["font-family"] = font
    ? font
    : SETTINGS.BUTTON_FONT.INPUTS.FONT.defaultValue;
  attrs["font-size"] = `${(
    (size ? size : SETTINGS.BUTTON_FONT.INPUTS.SIZE.defaultValue) / 0.75
  ).toFixed(1)}px`;
  attrs["font-weight"] = bold ? "bold" : "normal";
  attrs["font-style"] = italic ? "italic" : "normal";
  if (color) {
    attrs.color = color;
  } else {
    delete attrs.color;
  }
  model.setAttributes(attrs);
};

export const getComponentHref = (model) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  return {
    value: attrs[TEMPLATE_VARIABLES.HREFSRC]
      ? attrs[TEMPLATE_VARIABLES.HREFSRC]
      : attrs.href,
    variable: attrs[TEMPLATE_VARIABLES.HREFSRC]
      ? attrs?.href?.replace("{{", "").replace("}}", "")
      : undefined,
    cip: attrs?.[TEMPLATE_VARIABLES.HREFCIP] === "true",
  };
};

export const setComponentHref = (model, href, variable, cip) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  if (href) {
    if (
      !variable &&
      !href.startsWith("{{") &&
      !href.startsWith("http://") &&
      !href.startsWith("https://")
    ) {
      href = `https://${href}`;
    }
    attrs.href = variable ? `{{${variable}}}` : href;
  } else {
    delete attrs.href;
  }
  if (variable) {
    attrs[TEMPLATE_VARIABLES.HREFSRC] = href;
    if (cip) {
      attrs[TEMPLATE_VARIABLES.HREFCIP] = "true";
    } else {
      delete attrs[TEMPLATE_VARIABLES.HREFCIP];
    }
  } else {
    delete attrs[TEMPLATE_VARIABLES.HREFSRC];
    delete attrs[TEMPLATE_VARIABLES.HREFCIP];
  }

  model.setAttributes(attrs);
};

export const getButtonText = (model) => {
  if (!model || getElementType(model) !== TYPES.BUTTON) {
    return {};
  }
  const text = model.components()?.models?.[0]?.get("content")?.trim() || "";
  const attrs = model.getAttributes();
  return {
    value: attrs?.[TEMPLATE_VARIABLES.VARIABLE]
      ? attrs[TEMPLATE_VARIABLES.SRC]
      : text,
    variable: attrs?.[TEMPLATE_VARIABLES.VARIABLE],
    cip: attrs?.cip === "true",
  };
};

export const setButtonText = (model, text, variable, cip) => {
  if (!model || getElementType(model) !== TYPES.BUTTON) {
    return {};
  }
  model.components(variable ? `{{${text}}}` : text);
  const attrs = model.getAttributes();
  if (variable) {
    attrs[TEMPLATE_VARIABLES.VARIABLE] = variable;
    attrs[TEMPLATE_VARIABLES.SRC] = text;
    if (cip) {
      attrs[TEMPLATE_VARIABLES.CIP] = "true";
    } else {
      delete attrs[TEMPLATE_VARIABLES.CIP];
    }
  } else {
    delete attrs[TEMPLATE_VARIABLES.VARIABLE];
    delete attrs[TEMPLATE_VARIABLES.SRC];
    delete attrs[TEMPLATE_VARIABLES.CIP];
  }
  model.setAttributes(attrs);
};

export const getComponentBorderRadius = (model) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  let brArray = attrs["border-radius"]?.split(" ");
  if (brArray?.length) {
    return stringToNum(
      brArray[0],
      SETTINGS.ROUNDED_CORNERS.INPUTS.BORDER_RADIUS.unit
    );
  } else return 0;
};

export const setComponentBorderRadius = (model, radius = 0) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  attrs[
    "border-radius"
  ] = `${radius}${SETTINGS.ROUNDED_CORNERS.INPUTS.BORDER_RADIUS.unit}`;

  model.setAttributes(attrs);
};

export const getComponentAlignment = (model, attribute) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  return attrs[attribute] || "";
};

export const setComponentAlignment = (model, attribute, value) => {
  if (!model) {
    return {};
  }
  const attrs = model.getAttributes() || {};
  attrs[attribute] = value;
  model.setAttributes(attrs);
};

// get the number of columns in a section component
export const getColumnCount = (section) => {
  if (!section || getElementType(section) !== TYPES.SECTION) {
    return;
  }
  const columns = section.findType(TYPES.COLUMN) || [];
  return columns.length;
};

// returns true if a section's columns are wrapped in an mj-group, false otherwise
export const getComponentStacking = (section) => {
  if (!section || getElementType(section) !== TYPES.SECTION) {
    return;
  }
  const group = section.findType(TYPES.GROUP) || [];
  return group.length ? false : true;
};

// disable section stacking (add an mj-group) or enable it (remove an mj-group)
export const setComponentStacking = (model, stackable) => {
  if (!model || getElementType(model) !== TYPES.SECTION) {
    return;
  }
  const group = model.findType(TYPES.GROUP) || [];
  let children = model.components()?.models;
  if (!stackable) {
    if (group.length) {
      return;
    }
    model.components(`<${TYPES.GROUP}></${TYPES.GROUP}>`);
    model.components().models?.[0].components(children);
    model.view.render();
  } else {
    if (!group.length) {
      return;
    }
    const cols = [];
    children[0]?.components()?.models?.forEach((col) => {
      cols.push(col);
    });
    model.components(cols);
    model.view.render();
  }
};

export const getComponentImageSrc = (model) => {
  if (!model || getElementType(model) !== TYPES.IMAGE) {
    return;
  }
  return model.get("src");
};

export const getImageAltText = (model) => {
  if (!model || getElementType(model) !== TYPES.IMAGE) {
    return;
  }
  return model.getAttributes()?.alt;
};

export const setImageAltText = (model, alt = "") => {
  if (!model || getElementType(model) !== TYPES.IMAGE) {
    return;
  }
  model.setAttributes({ ...model.getAttributes(), alt: alt });
};

export const getIconList = (model) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  let icons = [];
  model.findType(TYPES.SOCIAL_ICON)?.map((component, index) => {
    const attrs = component.getAttributes() || {};
    const icon = SETTINGS.ICON_LIST.INPUTS.ICONS.values.find(
      (value) => value.attributes.name === attrs?.name
    );
    if (icon) {
      icons.push({
        ...icon,
        attributes: {
          ...icon.attributes,
          href: attrs.href || "",
        },
      });
    } else {
      removeIcon(model, index);
    }
    return;
  });
  model.view.render();
  return icons;
};

export const removeIcon = (model, index) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  let list = model.findType(TYPES.SOCIAL_ICON);
  if (list?.length > index) {
    list.splice(index, 1);
    model.components(list);
    model.view.render();
  }
};

export const updateIcon = (model, index, updatedAttributes = {}) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  let list = model.findType(TYPES.SOCIAL_ICON);
  list[index].setAttributes({
    ...list[index]?.getAttributes(),
    ...updatedAttributes,
  });
  model.components(list);
  model.view.render();
};

export const addIcon = (model, color) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  model.append(
    `<mj-social-element name="${
      SETTINGS.ICON_LIST.INPUTS.ICONS.values[0].attributes.name
    }" src="${
      SETTINGS.ICON_LIST.INPUTS.ICONS.values[0].attributes.src
    }" background-color="${
      color
        ? color
        : SETTINGS.ICON_LIST.INPUTS.ICONS.values[0].attributes[
            "background-color"
          ]
    }"></mj-social-element>`
  );
  model.view.render();
};

export const getIconColors = (model) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  const icon = model.findType(TYPES.SOCIAL_ICON)?.[0];
  if (icon) {
    let attrs = icon.getAttributes() || {};
    const color = attrs["background-color"];
    const iconType = SETTINGS.ICON_LIST.INPUTS.ICONS.values.find(
      (value) => value.attributes.name === attrs.name
    );
    if (iconType && color === iconType.attributes["background-color"]) {
      return;
    } else {
      return color.includes("#") ? color : toHex(color);
    }
  }
};

export const updateIconColors = (model, color) => {
  if (!model || getElementType(model) !== TYPES.SOCIAL_MENU) {
    return;
  }
  const icons = model.findType(TYPES.SOCIAL_ICON) || [];
  icons.map((icon) => {
    const attrs = icon.getAttributes() || {};
    icon.setAttributes({
      ...attrs,
      "background-color": color
        ? color
        : SETTINGS.ICON_LIST.INPUTS.ICONS.values.find(
            (value) => value.attributes.name === attrs.name
          )?.attributes["background-color"],
    });
  });
  model.components(icons);
  model.view.render();
};
