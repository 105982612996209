import { CAMPAIGN, FOLDER, TEAM_USER, USER } from "../consts/DBFields";
import { FOLDER_LEVEL_PERMISSIONS, FOLDER_LEVEL_ROLES } from "../consts/Folders";
import { isUserAdmin, isUserCampaignOwner } from "./user";

export const canEditCampagin = (user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType) => {
    const hasOwnerLevelAccess = isUserAdmin(user) || signedInUserIsOwner || isFolderOwner;
    if(!hasOwnerLevelAccess) {
      const creator = isUserCampaignOwner(user, campaign);
      if(folderType === FOLDER_LEVEL_PERMISSIONS.PUBLIC) {
        return creator;
      } else {
        return creator && folderUserRole === FOLDER_LEVEL_ROLES.MEMBER_EDIT;
      }
    }
    return hasOwnerLevelAccess;
}

export const canDuplicateCampaign = (user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType) => {
    const hasOwnerLevelAccess = signedInUserIsOwner || isFolderOwner || isUserAdmin(user);
    if(!hasOwnerLevelAccess) {
      if(folderType === FOLDER_LEVEL_PERMISSIONS.PUBLIC) {
        return true;
      }
      return folderUserRole !== FOLDER_LEVEL_ROLES.MEMBER_READ_ONLY;
    }
    return hasOwnerLevelAccess;
}

export const canMoveCampaign = (user, signedInUserIsOwner, campaign, isFolderOwner, folderUserRole, folderType) => {
  const hasOwnerLevelAccess = signedInUserIsOwner || isFolderOwner;
  if(!hasOwnerLevelAccess) {
    if(folderType === FOLDER_LEVEL_PERMISSIONS.PUBLIC) {
      return user[TEAM_USER.UPN]?.toLowerCase() === campaign[CAMPAIGN.OWNER]?.toLowerCase();
    }
  }
  return hasOwnerLevelAccess;
}