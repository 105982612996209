import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  addIcon,
  removeIcon,
  updateIcon,
  updateIconColors,
} from "../grapesjs/settingsUtil";
import QA from "../../../../consts/QA";
import { DeleteOutline } from "@material-ui/icons";
import { SETTINGS } from "../grapesjs/consts/Settings";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-SocialIconsList`;

function SocialIconsList({ element, setting, componentSettings }) {
  const { ICONS } = setting?.INPUTS;
  const [list, setList] = useState([]);
  const inputEl = React.useRef([]);
  const [iconColor, setIconColor] = useState();

  useEffect(() => {
    const iconList = componentSettings?.[setting.ID] || [];
    setList(iconList);
    setIconColor(componentSettings?.[SETTINGS.ICON_COLOR.ID]);
  }, [componentSettings]);

  return (
    <Box id={QA_ID_PREFIX} px={1}>
      {list
        ? list.map((icon, index) => {
            return (
              <Box key={index}>
                {index ? <Divider /> : null}
                <Box
                  mb={1}
                  mt={index ? 1 : 0}
                  id={`${QA_ID_PREFIX}-icon-${index}`}
                >
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                      <Box
                        width={36}
                        height={36}
                        borderRadius="50%"
                        padding={0.75}
                        boxSizing="border-box"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        bgcolor={icon?.attributes["background-color"]}
                      >
                        <img
                          src={icon?.attributes.src}
                          id={`${QA_ID_PREFIX}-icon-${index}-image`}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        value={icon?.attributes.name}
                        onChange={(e) => {
                          const newAttributes = {
                            ...ICONS.values.find(
                              (value) =>
                                value.attributes.name === e.target.value
                            )?.attributes,
                          };
                          updateIcon(element, index, {
                            ...newAttributes,
                            href: icon?.attributes.href,
                          });
                          if (iconColor) {
                            updateIconColors(element, iconColor);
                          }
                        }}
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        id={`${QA_ID_PREFIX}-Select-${index}`}
                      >
                        {ICONS.values.map((iconOption) => {
                          return (
                            <MenuItem
                              value={iconOption.attributes.name}
                              key={iconOption.attributes.name}
                              id={`${QA_ID_PREFIX}-MenuItem-${iconOption.label}`}
                            >
                              {iconOption.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            removeIcon(element, index);
                          }}
                          disabled={list.length === 1}
                          id={`${QA_ID_PREFIX}-icon-${index}-removeButton`}
                        >
                          <DeleteOutline />
                        </IconButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        id={`${QA_ID_PREFIX}-Href-Label`}
                      >
                        <strong>{`${icon?.label} URL`}</strong>
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        ref={(ref) => {
                          if (!inputEl.current[index]) {
                            inputEl.current.push(ref);
                          }
                        }}
                        onChange={(e) => {
                          inputEl.current[index].value = e.target.value || "";
                        }}
                        onBlur={() => {
                          updateIcon(element, index, {
                            href: inputEl.current[index].value || "",
                          });
                        }}
                        defaultValue={icon.attributes.href}
                        placeholder={icon.exampleUrl}
                        key={new Date().toISOString()} // maintain a unique key regardless of index since index in list can change
                        id={`${QA_ID_PREFIX}-TextField-Href-${index}`}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            );
          })
        : null}
      <Button
        onClick={() => {
          addIcon(element, iconColor);
        }}
        id={`${QA_ID_PREFIX}-AddIconButton`}
      >
        <u>Add another social icon</u>
      </Button>
    </Box>
  );
}

export default SocialIconsList;
