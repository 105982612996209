import {
  Box,
  Collapse,
  Divider,
  Fade,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { setComponentPadding } from "../grapesjs/settingsUtil";
import { getElementLabel } from "../grapesjs/util";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Padding`;

function Padding({ element, setting, componentSettings }) {
  const [equalPadding, setEqualPadding] = useState(true);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingRight, setPaddingRight] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);

  useEffect(() => {
    const padding = componentSettings?.[setting.ID] || [0, 0, 0, 0];
    const paddingTop = padding?.[0];
    setPaddingTop(paddingTop);
    setPaddingRight(padding?.[1]);
    setPaddingBottom(padding?.[2]);
    setPaddingLeft(padding?.[3]);
    setEqualPadding(
      padding[0] === padding[1] &&
        padding[0] === padding[2] &&
        padding[0] === padding[3]
    );
  }, [componentSettings]);

  const getUpdatedPaddingValues = (index, newValue) => {
    let updatedValues = [];
    if (equalPadding) {
      updatedValues = [newValue, newValue, newValue, newValue];
    } else {
      updatedValues = [paddingTop, paddingRight, paddingBottom, paddingLeft];
      updatedValues[index] = newValue;
    }
    return updatedValues;
  };

  const handleChange = (index, newValue) => {
    if (!newValue || newValue >= setting.INPUTS.PADDING.minValue) {
      const updatedValues = getUpdatedPaddingValues(index, newValue);
      setComponentPadding(element, updatedValues);
    }
  };

  const makePaddingEqual = (equal) => {
    if (equal) {
      setComponentPadding(element, [
        paddingTop,
        paddingTop,
        paddingTop,
        paddingTop,
      ]);
    } else {
      setPaddingRight(paddingTop);
      setPaddingBottom(paddingTop);
      setPaddingLeft(paddingTop);
    }
    setEqualPadding(equal);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" flex={1}>
          <Box mr={1}>
            <TextField
              type="number"
              value={paddingTop}
              onChange={(e) => {
                handleChange(0, e.target.value);
              }}
              onBlur={() => {
                if (!paddingTop) {
                  setPaddingTop(0);
                }
              }}
              variant="outlined"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">px</InputAdornment>
                ),
              }}
              id={`${QA_ID_PREFIX}-TextField`}
            />
          </Box>
          <Fade in={!equalPadding}>
            <Typography variant="body2" color="textSecondary">
              <strong>Top</strong>
            </Typography>
          </Fade>
        </Box>
        <Box ml={1} flex={1} display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              equalPadding
                ? `Allow custom padding for each side of the ${getElementLabel(
                    element
                  )?.toLowerCase()}.`
                : `Make the padding the same for each side of the ${getElementLabel(
                    element
                  )?.toLowerCase()}.`
            }
            id={`${QA_ID_PREFIX}-Tooltip`}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={!equalPadding}
                  onChange={() => makePaddingEqual(!equalPadding)}
                  color="primary"
                  id={`${QA_ID_PREFIX}-Switch`}
                />
              }
              label={
                <Typography variant="h6" color="textSecondary">
                  More Options
                </Typography>
              }
            />
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={!equalPadding}>
        {[
          {
            value: paddingBottom,
            setter: setPaddingBottom,
            label: "Bottom",
            index: 2,
          },
          {
            value: paddingLeft,
            setter: setPaddingLeft,
            label: "Left",
            index: 3,
          },
          {
            value: paddingRight,
            setter: setPaddingRight,
            label: "Right",
            index: 1,
          },
        ].map((paddingInput, key) => {
          return (
            <div key={`padding-setting-${key}`}>
              <Box display="flex" alignItems="center" mb={key ? 0 : 1.5}>
                <Box mr={1} width="40%">
                  <TextField
                    type="number"
                    value={paddingInput.value}
                    onChange={(e) => {
                      handleChange(paddingInput.index, e.target.value);
                    }}
                    onBlur={() => {
                      if (!paddingInput.value) {
                        paddingInput.setter(0);
                      }
                    }}
                    variant="outlined"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">px</InputAdornment>
                      ),
                    }}
                    id={`${QA_ID_PREFIX}-TextField-${paddingInput.label}`}
                  />
                </Box>
                <Typography variant="body2" color="textSecondary">
                  <strong>{paddingInput.label}</strong>
                </Typography>
              </Box>
              {!key ? (
                <Box width="40%" mb={1}>
                  <Divider />
                </Box>
              ) : null}
            </div>
          );
        })}
      </Collapse>
    </>
  );
}

export default Padding;
