import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { setComponentDimension } from "../grapesjs/settingsUtil";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Dimension`;

function Dimension({ element, setting, componentSettings }) {
  const [dimension, setDimension] = useState(0);
  const { unit, defaultValue, defaultValueLabel, minValue } =
    setting?.INPUTS?.[Object.keys(setting.INPUTS)[0]];

  useEffect(() => {
    const elementDimension = componentSettings?.[setting?.ID];
    setDimension(elementDimension);
  }, [componentSettings]);

  return (
    <>
      <Box width="100%" display="flex" alignItems="center">
        <Box width="40%">
          <TextField
            type="number"
            value={!dimension && dimension !== 0 ? defaultValue : dimension}
            placeholder={!dimension && dimension !== 0 ? defaultValueLabel : ""}
            onChange={(e) => {
              const dimensionInt = e.target.value;
              if (dimensionInt >= minValue) {
                setComponentDimension(
                  element,
                  dimensionInt,
                  unit,
                  setting?.ATTRIBUTE
                );
              }
            }}
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{unit}</InputAdornment>
              ),
            }}
            id={`${QA_ID_PREFIX}-TextField-${setting.ID}`}
          />
        </Box>
        {dimension || dimension === 0 ? (
          <Tooltip title="Clear">
            <IconButton
              onClick={() => {
                setComponentDimension(
                  element,
                  defaultValue,
                  unit,
                  setting?.ATTRIBUTE
                );
              }}
              id={`${QA_ID_PREFIX}-Clear-${setting.ID}`}
            >
              <Clear fontSize="small" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
    </>
  );
}

export default Dimension;
