import { findIndex, round } from "lodash";
import {
  getCampaignStats,
  getCampaignEvents,
  getCampaignCustomReports,
} from "../APIs/CampaignAPI";
import {
  CAMPAIGN_STATS_API_FIELDS,
  REPORT_INTERVALS,
  STATS_LIST,
} from "../consts/Campaign";
import moment from "moment-timezone";
import { CAMPAIGN, NOTIFICATION, METADATA } from "../consts/DBFields.js";
import { TIME_INTERVALS, TIME_SCALE } from "../consts/DataViz";

export async function fetchCampaignStats(
  campaignId,
  trackAdvancedMetrics,
  isCampaignTable,
  campaignExecutionsDetails
) {
  //call the stats API
  let stats = await getCampaignStats(
    campaignId,
    trackAdvancedMetrics,
    campaignExecutionsDetails
  );

  let sentCount = stats?.sent_count_data?.buckets[0]?.sent_count?.value || 0;
  let queuedCount = stats?.queued_count_data?.buckets[0]?.queued_count?.value || 0;
  let errorCount = stats?.error_count_data?.buckets[0]?.error_count?.value || 0;
  let totalDLCount = stats?.sent_count_data?.buckets[0]?.total_dl_count?.value || 0;
  let totalDLMembersCount = stats?.sent_count_data?.buckets[0]?.total_dl_members_count?.value || 0;
  let unsubscribedCount = stats?.unsubscribed_count_data?.buckets[0]?.unsubscribed_count?.value || 0;
  let notSentCount = stats?.not_sent_count_data?.buckets[0]?.not_sent_count?.value || 0;

  let uniqueViews =
    stats &&
    stats.hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.VIEWS) &&
    stats[CAMPAIGN_STATS_API_FIELDS.VIEWS].hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.UNIQUE_VIEWS) &&
    stats[CAMPAIGN_STATS_API_FIELDS.VIEWS][CAMPAIGN_STATS_API_FIELDS.UNIQUE_VIEWS].hasOwnProperty("value")
      ? stats[CAMPAIGN_STATS_API_FIELDS.VIEWS][CAMPAIGN_STATS_API_FIELDS.UNIQUE_VIEWS].value
      : null;

  //open rate is unique view count divided by sent count times 100
  let openRate = sentCount ? (uniqueViews / sentCount) * 100 : 0;

  //handles javascript NaN response to 0/0 in "falsey" scenarios
  if (isNaN(openRate)) {
    openRate = 0;
  }

  //round to nearest 10nth
  openRate = Math.round(10 * openRate) / 10;

  if (!isCampaignTable) {
    let viewCount =
      stats &&
      stats.hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.VIEWS) &&
      stats[CAMPAIGN_STATS_API_FIELDS.VIEWS].hasOwnProperty("doc_count")
        ? stats[CAMPAIGN_STATS_API_FIELDS.VIEWS].doc_count
        : null;

    let clickCount =
      stats &&
      stats.hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.CLICKS) &&
      stats[CAMPAIGN_STATS_API_FIELDS.CLICKS].hasOwnProperty("doc_count")
        ? stats[CAMPAIGN_STATS_API_FIELDS.CLICKS].doc_count
        : null;

    let uniqueClicks =
      stats &&
      stats.hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.CLICKS) &&
      stats[CAMPAIGN_STATS_API_FIELDS.CLICKS].hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKS) &&
      stats[CAMPAIGN_STATS_API_FIELDS.CLICKS][CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKS].hasOwnProperty("value")
        ? stats[CAMPAIGN_STATS_API_FIELDS.CLICKS][CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKS].value
        : null;

    let uniqueClickedUser =
      stats &&
      stats.hasOwnProperty(CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER) &&
      stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER].hasOwnProperty(
        "count"
      ) &&
      stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER][
        "count"
      ].hasOwnProperty("value")
        ? stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER].count.value
        : stats &&
          stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER].hasOwnProperty(
            "COUNT"
          ) &&
          stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER][
            "COUNT"
          ].hasOwnProperty("value")
        ? stats[CAMPAIGN_STATS_API_FIELDS.UNIQUE_CLICKED_USER].COUNT.value
        : null;

    //click rate is unique clicked user count divided by sent count times 100
    let clickRate = sentCount ? (uniqueClickedUser / sentCount) * 100 : 0;

    //handles javascript NaN response to 0/0 in "falsey" scenarios
    if (isNaN(clickRate)) {
      clickRate = 0;
    }

    //round to nearest 10nth
    clickRate = Math.round(10 * clickRate) / 10;

    let advancedMetrics;
    if (viewCount) {
      const rawAdvancedMetrics =
        stats[CAMPAIGN_STATS_API_FIELDS.VIEW_ADVANCED_METRICS];
      advancedMetrics = formatAdvancedMetrics(rawAdvancedMetrics, viewCount);
    }
    return {
      openRate: openRate,
      recipients: sentCount,
      uniqueViews: uniqueViews,
      viewCount: viewCount,
      clickCount: clickCount,
      uniqueClicks: uniqueClicks,
      errorCount: errorCount,
      queuedCount: queuedCount,
      uniqueClickedUser: uniqueClickedUser,
      clickRate: clickRate,
      advancedMetrics,
      totalDLCount: totalDLCount,
      totalDLMembersCount: totalDLMembersCount,
      unsubscribedCount: unsubscribedCount,
      notSentCount: notSentCount,
    };
  }

  return {
    openRate: openRate,
    recipients: sentCount,
    rawCampaignStats: stats,
  };
}

export function formatAdvancedMetrics(advancedMetrics, viewCount) {
  const { BROWSER_METRICS, DEVICE_METRICS, OS_METRICS, DOC_COUNT } =
    CAMPAIGN_STATS_API_FIELDS;

  const browserMetrics = advancedMetrics?.[BROWSER_METRICS]?.buckets;
  const deviceMetrics = advancedMetrics?.[DEVICE_METRICS]?.buckets;
  const osMetrics = advancedMetrics?.[OS_METRICS]?.buckets;

  const mapUsageStats = (metrics = [], statsList) => {
    const mappedUsageStats = [];

    metrics.forEach((metric) => {
      const metricDocValue = round((metric?.[DOC_COUNT] / viewCount) * 100, 1);
      if (statsList.includes(metric?.key)) {
        mappedUsageStats.push({
          id: metric?.key,
          label: metric?.key,
          value: metricDocValue,
          count: metric?.[DOC_COUNT],
        });
      } else {
        const othersIndex = findIndex(mappedUsageStats, { id: "Others" });
        const updatedUsageStat = {
          id: "Others",
          label: "Others",
          value: metricDocValue + (mappedUsageStats[othersIndex]?.value || 0),
          count:
            metric?.[DOC_COUNT] + (mappedUsageStats[othersIndex]?.count || 0),
        };

        if (othersIndex === -1) mappedUsageStats.push(updatedUsageStat);
        else mappedUsageStats[othersIndex] = updatedUsageStat;
      }
    });
    return mappedUsageStats;
  };

  return {
    browserMetrics: mapUsageStats(browserMetrics, STATS_LIST.BROWSER),
    deviceMetrics: mapUsageStats(deviceMetrics, STATS_LIST.DEVICE),
    osMetrics: mapUsageStats(osMetrics, STATS_LIST.OS),
  };
}

function formatChartData(res) {
  if (res) {
    //format date from ES
    let viewData = [];
    let clickData = [];
    res.view.data.forEach(function (view) {
      viewData.push({ x: new Date(Date.parse(view.date)), y: view.count });
    });

    res.click.data.forEach(function (click) {
      clickData.push({ x: new Date(Date.parse(click.date)), y: click.count });
    });

    return [
      { id: "Total Opens", data: viewData },
      { id: "Total Clicks", data: clickData },
    ];
  }
  return false;
}

export async function getCampaignEventsForDates(campaignId, startDate, end) {
  //logic to format ISO86 to the ElasticSearch date format
  let endDate;
  //if no endDate is passed, use first 24 hours
  if (!end) {
    endDate = moment(startDate).add(1, "days").toISOString();
  } else {
    endDate = end;
  }
  let events = await getCampaignEvents(campaignId, startDate, endDate);
  let returnObj = {
    events: formatChartData(events),
    bucketSize: events.aggregateBy,
  };
  return returnObj;
}

//function to determine largest reasonable bucket size
export function determineBucketSize(startDT, endDT) {
  try {
    if (startDT && endDT) {
      //parse start/end time with moment
      var startTime = moment(startDT);
      var end = moment(endDT);

      //initialize the duration object
      var duration = moment.duration(end.diff(startTime));
      //verify the duration isn't negative
      if (Math.sign(duration.asHours()) === 1) {
        //use duration to determine "bucket size"
        if (duration.asDays() <= 2) {
          // return hours bucket
          return REPORT_INTERVALS.HOURS;
        } else if (duration.asWeeks() <= 3) {
          // return days bucket
          return REPORT_INTERVALS.DAYS;
        } else if (duration.asWeeks() <= 26) {
          // return weeks bucket
          return REPORT_INTERVALS.WEEKS;
        } else if (duration.asMonths() <= 24) {
          // return months bucket
          return REPORT_INTERVALS.MONTHS;
        } else if (Math.round(duration.asYears()) <= 4) {
          //some rounding is needed to handle uneven but "close enough" year ranges
          // return quarters bucket
          return REPORT_INTERVALS.QUARTERS;
        } else if (
          duration.asYears() >= 1 ||
          Math.round(duration.asYears()) === 4
        ) {
          //some rounding is needed to handle uneven but "close enough" year ranges
          // return years bucket
          return REPORT_INTERVALS.YEARS;
        } else {
          throw new Error("There was an error parsing the date range");
        }
      } else {
        throw new Error(
          "determineBucketExam: The date range is negative or the dates are the same. Please Double check the values provided"
        );
      }
    } else {
      //handle dates not being passed
      throw new Error(
        "determineBucketExam: function was called without the proper parameters"
      );
    }
  } catch (err) {
    //handle general errors
    throw new Error(err);
  }
}

/**
 *
 * @param {string} bucketSize // hours, days, etc
 * @param {array} data // array of event data objects
 * @returns
 */
export function getChartTimeInterval(bucketSize, numberOfRecords = 0) {
  var scaleValue;
  var formatDateTime;
  const { TIME_FORMAT, DAY_FORMAT } = TIME_SCALE;
  switch (bucketSize) {
    case REPORT_INTERVALS.HOURS: //hour level formatting
      if (numberOfRecords >= 48) {
        //48+ hours formatting
        scaleValue = TIME_INTERVALS.SIX_HOURS;
        formatDateTime = TIME_FORMAT;
      } else {
        scaleValue = TIME_INTERVALS.THREE_HOURS; //24 hours formatting
        formatDateTime = TIME_FORMAT;
      }
      break;
    case REPORT_INTERVALS.DAYS: //day level formatting
      scaleValue = TIME_INTERVALS.ONE_DAY;
      formatDateTime = DAY_FORMAT;
      break;
    case REPORT_INTERVALS.WEEKS: //week level formatting
      scaleValue = TIME_INTERVALS.ONE_WEEK;
      formatDateTime = DAY_FORMAT;
      break;
    case REPORT_INTERVALS.MONTHS: //month level formatting
      scaleValue = TIME_INTERVALS.ONE_MONTH;
      formatDateTime = DAY_FORMAT;
      break;
    case REPORT_INTERVALS.QUARTERS: //quarters level formatting
      scaleValue = TIME_INTERVALS.ONE_QUARTER;
      formatDateTime = DAY_FORMAT;
      break;
    case REPORT_INTERVALS.YEARS: //years level formatting
      scaleValue = TIME_INTERVALS.ONE_YEAR;
      formatDateTime = DAY_FORMAT;
      break;
    default: //default to 24 hours as this is what the chart starts with
      scaleValue = TIME_INTERVALS.THREE_HOURS;
      formatDateTime = TIME_FORMAT;
  }
  return { scale: scaleValue, format: formatDateTime };
}

export async function getClickTrackingStatsForCampaign(campaign) {
  try {
    let hrefs_in_metadata = [];
    if (
      campaign[CAMPAIGN.UID] &&
      campaign?.[CAMPAIGN.NOTIFICATIONS][0]?.[NOTIFICATION.TYPE] &&
      campaign?.[CAMPAIGN.METADATA]?.[METADATA.HYPERLINK_INFO]?.[
        campaign[CAMPAIGN.NOTIFICATIONS][0][NOTIFICATION.TYPE]
      ]
    ) {
      //getting click tracking for 0 index alone
      hrefs_in_metadata =
        campaign?.[CAMPAIGN.METADATA]?.[METADATA.HYPERLINK_INFO]?.[
          campaign[CAMPAIGN.NOTIFICATIONS][0][NOTIFICATION.TYPE]
        ];
      if (hrefs_in_metadata) {
        //get click tracking stats from the date campaign was created to today
        let startDate = campaign[CAMPAIGN.DATE_CREATED];
        var endDate = new Date().toISOString();
        let aggregate_by = "tracking_id",
          filters = `[event_name=click,campaign_id=${campaign[CAMPAIGN.UID]}]`;
        let clickTrackingStats = await getCampaignCustomReports(
          startDate,
          endDate,
          aggregate_by,
          filters
        );
        let total_clicks = 0;
        for (let href of hrefs_in_metadata) {
          href.count = 0;
          for (let trackingidObj of clickTrackingStats?.tracking_id) {
            if (trackingidObj[href?.trackingid]) {
              href.count = trackingidObj[href?.trackingid];
              total_clicks += href.count;
              break;
            }
          }
        }
        //calculate click rate
        for (let href of hrefs_in_metadata) {
          //round to 2 digits
          href.click_rate =
            Math.round(((href.count * 100) / total_clicks) * 100) / 100;
        }
      }
    }
    //sort by max click count
    hrefs_in_metadata.sort((obj1, obj2) => obj2.count - obj1.count);
    return hrefs_in_metadata;
  } catch (err) {
    //handle general errors
    throw new Error(err);
  }
}
