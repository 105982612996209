import React, { useState } from "react";
import { FILE_OPTIONS } from "../../consts/CampaignTable";

// MUI
import {
  Grid,
  Typography,
  Popover,
  IconButton,
  MenuItem,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { MoreHoriz } from "@material-ui/icons";
import { CampaignState } from "../../contexts/Campaign";
import QA from "../../consts/QA";
import CampaignWarningDialog from "./CampaignWarningDialog";
import { COPY } from "../../consts/Copy";
import { CAMPAIGN } from "../../consts/DBFields";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignDownload`;

function CampaignDownload() {
  const [open, toggleOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    toggleOpen(!open);
    setAnchorEl(e.target);
  };

  const handleClose = () => {
    toggleOpen(false);
    setAnchorEl(null);
  };

  const [showCsvDeleteAlert, setShowCsvDeleteAlert] = useState(false);
  const [isPrimaryBtnDisabledForCsvDelete, setIsPrimaryBtnDisabledForCsvDelete] =  useState(false);

  return (
    <CampaignState>
      {({
        selectedCampaign,
        downloadCampaignCSV,
        isDownloading,
        removeExistingCsvIfPresent,
        saveCampaign,
      }) => {
        return (
          <>
            <IconButton
              onClick={handleClick}
              id={`myid-${QA_ID_PREFIX}-Button`}
            >
              <MoreHoriz />
            </IconButton>
            <>
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                id={`${QA_ID_PREFIX}-PopOverMenu`}
              >
                {FILE_OPTIONS.map((option, index) => {
                  return (
                    <span>
                      <MenuItem
                        key={option.label}
                        onClick={() => {
                          if (index === 0 && option.label === "Download") {
                            downloadCampaignCSV(selectedCampaign);
                          } else if (index === 1 && option.label === "Delete") {
                            setShowCsvDeleteAlert(true);
                          }
                        }}
                        id={`myid-${QA_ID_PREFIX}-MenuItem-${option.label}`}
                        disabled={isDownloading}
                      >
                        <Box width="100%" py={0.5}>
                          <Grid container alignItems="center">
                            <Grid item>
                              <Box display="flex" alignItems="center">
                                {option.icon}
                              </Box>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" color="textSecondary" className={`${option.label}-label`}>
                                {option.label}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Box display="flex" alignItems="center">
                                {isDownloading && (
                                  <CircularProgress size={18} className={`${option.label}-circular-progress`} />
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </MenuItem>
                    </span>
                  );
                })}
              </Popover>
            </>
            <CampaignWarningDialog
              isOpen={showCsvDeleteAlert}
              closeDialog={() => {{
                setShowCsvDeleteAlert(false)
                setIsPrimaryBtnDisabledForCsvDelete(false)
              }}}
              isPrimaryBtnDisabled={isPrimaryBtnDisabledForCsvDelete}
              title={"Are you sure to delete the uploaded CSV?"}
              subtitle={""}
              primaryButton={
                COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.ACCEPT_BUTTON
              }
              secondaryButton={
                COPY.CAMPAIGN_EDIT.TYPE_CHANGE_WARNING.CANCEL_BUTTON
              }
              primaryAction={async () => {
                setIsPrimaryBtnDisabledForCsvDelete(true);
                const campaign = { ...selectedCampaign };
                await removeExistingCsvIfPresent(campaign);
                await saveCampaign(
                  {
                    ...campaign,
                    metadata: {...campaign[CAMPAIGN.METADATA], [CAMPAIGN.METADATA_HEADINGS]: []},
                  },
                  false
                );
                setShowCsvDeleteAlert(false);
                setIsPrimaryBtnDisabledForCsvDelete(false);
              }}
              secondaryAction={() => {
                setShowCsvDeleteAlert(false)
                setIsPrimaryBtnDisabledForCsvDelete(false)
              }}
              id={`${QA_ID_PREFIX}-EditType-WarningDialog`}
            />
          </>
        );
      }}
    </CampaignState>
  );
}

export default CampaignDownload;
