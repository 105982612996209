import { Box, FormHelperText, Typography } from "@material-ui/core";
import React from "react";
import { TEAM_FIELDS } from "../../consts/Teams";
import { TeamState } from "../../contexts/Team";
import CheckboxSelectInput from "../common/CheckboxSelectInput";
import EmailInputField from "../common/EmailInputField";
import TextInputField from "../common/TextInputField";

function TeamSettingsForm({ id, isNewTeam, readOnly }) {
  return (
    <TeamState>
      {({
        updateTeamCheckBoxField,
        updateEmailField,
        updateTeamTextField,
        teamValue = {},
        errors = {},
        openTeam = {},
      }) => (
        <>
          {Object.keys(TEAM_FIELDS).map((fieldName) => {
            const currentField = TEAM_FIELDS[fieldName] || {};
            if (!currentField.createOnly || isNewTeam) {
              const value = readOnly ? openTeam : teamValue;
              return (
                <Box width="100%" mb={3}>
                  {currentField.type === "text" ? (
                    <TextInputField
                      handleChange={(newValue) => {
                        updateTeamTextField(fieldName, currentField, newValue);
                      }}
                      value={value[fieldName]}
                      field={currentField}
                      componentKey={`${id}-${fieldName}`}
                      id={`${id}-${fieldName}`}
                      readOnly={readOnly}
                      dense
                    />
                  ) : currentField.type === "email" ? (
                    <EmailInputField
                      componentKey={`${id}-${fieldName}`}
                      multiple
                      value={value[fieldName]}
                      field={currentField}
                      disableFreeText
                      handleChange={(newValue) => {
                        updateEmailField(fieldName, currentField, newValue);
                      }}
                      id={`${id}-${fieldName}`}
                      isLookUp
                      associateOnly={Boolean(currentField.associateOnly)}
                      readOnly={readOnly}
                      {...currentField}
                    />
                  ) : currentField.type === "checkbox" ? (
                    <CheckboxSelectInput
                      handleChange={(newValue) => {
                        updateTeamCheckBoxField(
                          fieldName,
                          currentField,
                          newValue
                        );
                      }}
                      value={value[fieldName]}
                      field={currentField}
                      id={`${id}-${fieldName}`}
                      error={errors[fieldName]?.length}
                      readOnly={readOnly}
                    />
                  ) : null}
                  {isNewTeam ? (
                    <FormHelperText
                      style={{ margin: 0, marginTop: "-2px" }}
                      id={`${id}-${fieldName}-HelperText`}
                    >
                      {currentField.helperText}
                    </FormHelperText>
                  ) : null}
                  {errors[fieldName] ? (
                    <Typography
                      color="error"
                      id={`${id}-${fieldName}-ErrorText`}
                      variant="caption"
                    >
                      {errors[fieldName]}
                    </Typography>
                  ) : null}
                </Box>
              );
            } else return null;
          })}
        </>
      )}
    </TeamState>
  );
}

export default TeamSettingsForm;
