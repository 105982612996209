const QA = {
  PAGES: {
    CAMPAIGN_LIST: "CampaignListPage",
    FOLDER_LIST: "FolderListPage",
    CAMPAIGN: "CampaignPage",
    TEAM: "TeamPage",
    ERROR: "ErrorPage",
    EDITOR: "EditorPage",
    TEMPLATE: "TemplatePage",
    TEMPLATE_LIST: "TemplateListPage",
    HELP: "HelpPage",
    REPORT: "ReportPage",
    FOLDER: "Folder",
    MEDIA_LIBRARY: "MediaLibrary"
  },
};
export default QA;
