import { URLS,getAPIEndPoint } from "../consts/APIEndpoints";
import { getResponse } from "../utils/common";

/** fetchADSearchResults - This API call is used to get the ADI search results for the
 * typed name/email in input field
 */
export const fetchADSearchResults = async (searchCriteria, associateOnly = false, includeGroups = false) => {
  const matchingUsers = await getResponse(
    `${getAPIEndPoint(URLS.SEARCH_AD_API)}?searchCriteria=${searchCriteria}&associateOnly=${associateOnly}&includeGroups=${includeGroups}`,
    "get"
  );
  return matchingUsers;
};
