import { HEX_COLORS } from "../../../../../consts/Colors";
import {
  BLOCKS,
  sectionStyle,
  textStyle,
  TYPES,
  customBlockMedia,
} from "../consts/DomComponents";
import { buildToolbar, getElementType, isComponentType } from "../util";
import imageIcon from "../../../../../assets/icons/emailEditorImageIcon.svg";
import { COMMANDS } from "../consts/Commands";
import { SETTINGS } from "../consts/Settings";
import { CAMPAIGN_TYPES } from "../../../../../consts/Campaign";

const components = (editor, opts) => {
  createDynamicSectionComponent(editor);
  disableGroupComponent(editor);
  createComponentMenu(editor);
  disableButtonTextEdit(editor);
  selfClosingComponents(editor);
  handleImageDrop(editor);
  handleImageClick(editor);
  defaultSocialIconComponent(editor);
  updateToolbar(editor, opts);
};
export default components;

export const updateToolbar = (editor, opts) => {
  const allComponents = editor.DomComponents.getTypes();
  allComponents?.forEach((comp) => {
    const componentModel = editor.DomComponents.getType(comp.id)?.model;
    editor.DomComponents.addType(comp.id, {
      model: {
        initToolbar() {
          componentModel.prototype.initToolbar.apply(this, arguments);
          let tb = [];
          if (comp.id !== TYPES.BODY) {
            tb = buildToolbar(
              comp,
              opts?.campaignType === CAMPAIGN_TYPES.personalized.value
            );
          }
          this.set("toolbar", tb);
        },
      },
    });
    return;
  });
  return;
};

export const createComponentMenu = (editor) => {
  if (!editor) return;
  removeUnusedBlocks(editor.BlockManager);

  const textElement = `<mj-text ${textStyle}>Type your text here.</mj-text>`;
  const icons = SETTINGS.ICON_LIST.INPUTS.ICONS.values;

  const blocksList = [
    {
      blockId: BLOCKS.COL_1,
      customBlock: {
        label: "1 Column",
        media: customBlockMedia.COL_1,
      },
      content: `<mj-section ${sectionStyle}><mj-column>${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_33_66,
      customBlock: {
        label: "2 Columns",
        media: customBlockMedia.COL_33_66,
      },
      content: `<mj-section ${sectionStyle}><mj-column width="33.33333333%">${textElement}</mj-column><mj-column width="66.66666667%">${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_66_33,
      customBlock: {
        label: "2 Columns",
        media: customBlockMedia.COL_66_33,
      },
      content: `<mj-section ${sectionStyle}><mj-column width="66.66666667%">${textElement}</mj-column><mj-column width="33.33333333%">${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_2,
      customBlock: {
        label: "2 Columns",
        media: customBlockMedia.COL_2,
      },
      content: `<mj-section ${sectionStyle}><mj-column>${textElement}</mj-column><mj-column>${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_25_75,
      customBlock: {
        label: "2 Columns",
        media: customBlockMedia.COL_25_75,
      },
      content: `<mj-section ${sectionStyle}><mj-column width="25%">${textElement}</mj-column><mj-column width="75%">${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_75_25,
      customBlock: {
        label: "2 Columns",
        media: customBlockMedia.COL_75_25,
      },
      content: `<mj-section ${sectionStyle}><mj-column width="75%">${textElement}</mj-column><mj-column width="25%">${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_3,
      customBlock: {
        label: "3 Columns",
        media: customBlockMedia.COL_3,
      },
      content: `<mj-section ${sectionStyle}><mj-column>${textElement}</mj-column><mj-column>${textElement}</mj-column><mj-column>${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.COL_4,
      customBlock: {
        label: "4 Columns",
        media: customBlockMedia.COL_4,
      },
      content: `<mj-section ${sectionStyle}><mj-column>${textElement}</mj-column><mj-column>${textElement}</mj-column>
                <mj-column>${textElement}</mj-column><mj-column>${textElement}</mj-column></mj-section>`,
    },
    {
      blockId: BLOCKS.SPACER,
      customBlock: null,
      content: `<mj-spacer height="30px" container-background-color="transparent" />`,
    },
    {
      blockId: BLOCKS.DIVIDER,
      customBlock: {
        label: "Divider",
        media: customBlockMedia.DIVIDER,
      },
      content: `<mj-divider border-style="solid" border-width="1px" padding-top="10px" padding-bottom="10px" padding-left="10px" padding-right="10px" border-color="${HEX_COLORS.medGray}" />`,
    },
    {
      blockId: BLOCKS.TEXT,
      customBlock: {
        label: "Text",
        media: customBlockMedia.TEXT,
      },
      content: textElement,
    },
    {
      blockId: BLOCKS.BUTTON,
      customBlock: {
        label: "Button",
        media: customBlockMedia.BUTTON,
      },
      content: `<mj-button padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px"font-family="Bogle, Helvetica, sans-serif" font-size="16px" background-color="${HEX_COLORS.ink}" border-radius="24px 24px 24px 24px" width="120px" height="40px" inner-padding="5px 5px">Button</mj-button>`,
    },
    {
      blockId: BLOCKS.IMAGE,
      customBlock: null,
      content: `<mj-image src="${imageIcon}" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px"></mj-image>`,
    },
    {
      blockId: BLOCKS.SOCIAL_GROUP,
      customBlock: null,
      content: `<mj-social font-size="12px" border-radius="12px" mode="horizontal" padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px">
  <mj-social-element name="${icons[0].attributes.name}" src="${icons[0].attributes.src}" background-color="${icons[0].attributes["background-color"]}"></mj-social-element>
  <mj-social-element name="${icons[1].attributes.name}" src="${icons[1].attributes.src}" background-color="${icons[1].attributes["background-color"]}"></mj-social-element>
  <mj-social-element name="${icons[2].attributes.name}" src="${icons[2].attributes.src}" background-color="${icons[2].attributes["background-color"]}"></mj-social-element>
</mj-social>`,
    },
  ];

  blocksList.forEach((blockData) => {
    addBlock(
      editor,
      blockData.blockId,
      blockData.customBlock,
      blockData.content
    );
  });
  // // TODO: add support for videos
  // // addBlock(
  // //   editor,
  // //   BLOCKS.VIDEO,
  // //   {
  // //     label: "Video",
  // //     attributes: { class: "fa fa-play-circle gjs-block-disabled" },
  // //   },
  // // );
};

export const removeUnusedBlocks = (manager) => {
  manager.remove("mj-hero");
  manager.remove("mj-navbar");
  manager.remove("mj-navbar-link");
  manager.remove("mj-social-element");
  manager.remove("mj-wrapper");
};

export const addBlock = (editor, blockId, customBlock, content) => {
  let block;
  if (!customBlock) {
    block = editor.BlockManager.remove(blockId);
  }
  const blockConfig = {
    category: editor.I18n.t("grapesjs-mjml.category"),
  };
  const newBlock = editor.BlockManager.add(blockId, {
    label: customBlock?.label || block?.get("label"),
    category: "",
    content: content || block?.get("content"),
    attributes: customBlock?.attributes || block?.get("attributes"),
    ...blockConfig,
  });

  customBlock &&
    newBlock.set({
      label: customBlock?.label || block?.get("label"),
      category: "",
      content: content || block?.get("content"),
      attributes: customBlock?.attributes || block?.get("attributes"),
      ...blockConfig,
      media: customBlock?.media,
    });
};

export const createDynamicSectionComponent = (editor) => {
  editor.DomComponents.addType(TYPES.MUSTACHE_SECTION, {
    isComponent: (el) => {
      if (
        el.attributes?.getNamedItem("variable") &&
        el.tagName.toLowerCase() === TYPES.SECTION.toLowerCase()
      ) {
        return true;
      }
    },
    extend: TYPES.SECTION,
    model: {
      ...editor.DomComponents.getType(TYPES.SECTION)?.model,
    },
  });
};

export const disableGroupComponent = (editor) => {
  editor.DomComponents.addType(TYPES.GROUP, {
    isComponent: isComponentType(TYPES.GROUP),
    extend: TYPES.GROUP,
    model: {
      defaults: {
        selectable: false,
        hoverable: false,
      },
    },
  });
};

export const disableButtonTextEdit = (editor) => {
  editor.DomComponents.addType(TYPES.BUTTON, {
    isComponent: isComponentType(TYPES.BUTTON),
    extend: TYPES.BUTTON,
    view: {
      events: {},
    },
  });
};

const selfClosingComponents = (editor) => {
  // make sure  self-closing elements use an end tag to avoid issues
  editor.DomComponents.addType(TYPES.IMAGE, {
    model: {
      defaults: {
        "style-default": {},
        void: false,
        resizable: {
          tl: 1, // Top left
          tc: 1, // Top center
          tr: 1, // Top right
          cl: 1, // Center left
          cr: 1, // Center right
          bl: 1, // Bottom left
          bc: 1, // Bottom center
          br: 1, // Bottom right
        },
      },
    },
  });
  editor.DomComponents.addType(TYPES.DIVIDER, {
    model: {
      defaults: {
        void: false,
      },
    },
  });
  editor.DomComponents.addType(TYPES.SPACER, {
    model: {
      defaults: {
        void: false,
      },
    },
  });
};

export const handleImageDrop = (editor) => {
  if (!editor) {
    return;
  }
  editor.on("block:drag:stop", (model) => {
    if (getElementType(model) === TYPES.IMAGE) {
      editor.runCommand(COMMANDS.OPEN_IMAGE_GALLERY);
    }
  });
};

export const handleImageClick = (editor) => {
  if (!editor) {
    return;
  }
  editor.DomComponents.addType(TYPES.IMAGE, {
    view: {
      events: {
        dblclick: "openImageGallery",
      },
      openImageGallery() {
        editor.runCommand(COMMANDS.OPEN_IMAGE_GALLERY);
      },
    },
  });
};

export const defaultSocialIconComponent = (editor) => {
  if (!editor) {
    return;
  }
  editor.DomComponents.addType(TYPES.SOCIAL_ICON, {
    model: {
      defaults: {
        selectable: false,
        hoverable: false,
        attributes: {
          "border-radius": "50%",
          "icon-size": "32px",
        },
      },
    },
  });
};
