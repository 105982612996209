export const ink = "041f41";
export const wmBlue = "0071ce";
export const blue = "064f8e";
export const sky = "78b9e7";
export const yellow = "fcc220";
export const pink = "eb148d";
export const orange = "f47521";
export const green = "76c043";
export const red = "ee3b2a";
export const navy = "0e1e42";
export const techGreen = "02f17a";
export const white = "ffffff";
export const black = "000000";
export const darkGray = "3d3d3d";
export const medGray = "9a9a9a";
export const lightGray = "f0f0f0";
export const fadedInk = "274359";

export const colors = [
  ink,
  wmBlue,
  fadedInk,
  blue,
  sky,
  yellow,
  pink,
  orange,
  green,
  red,
  navy,
  techGreen,
  white,
  black,
  darkGray,
  medGray,
  lightGray,
];

export const EDITOR_COLORS = {
  brightGrey: "#eeeeee",
  antiFlashWhite: "#f3f3f3",
  americanSilver: "#d0d0d0",
  white: "#fff",
  davysGrey: "#5e5e5e",
  blackOlive: "#3d3d3d",
  outerSpace: "#494949",
}
