import { Grid, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DOMPurify from "dompurify";

const useStyles = makeStyles(() => ({
  alert: {
    marginBottom: 8,
    "&:last-child": {
      marginBottom: 0,
    },
  },
}));

const AlertBanner = ({ ccmConfig = {} }) => {
  const classes = useStyles();
  const {
    errorAlertMessage = "",
    infoAlertMessage = "",
    warningAlertMessage = "",
  } = ccmConfig;

  const renderAlerts = (alertSeverity, alerts) => {
    alerts = Array.isArray(alerts) ? alerts : [alerts];

    return alerts.map(
      (alert) =>
        alert && (
          <Alert className={classes.alert} severity={alertSeverity}>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(alert),
              }}
            />
          </Alert>
        )
    );
  };

  return (
    <Grid item>
      {errorAlertMessage && renderAlerts("error", errorAlertMessage)}
      {infoAlertMessage && renderAlerts("info", infoAlertMessage)}
      {warningAlertMessage && renderAlerts("warning", warningAlertMessage)}
    </Grid>
  );
};

export default AlertBanner;
