import { GALLERY_SCHEMA } from "../../consts/Template";
import Configs from "../../Configurations";

const blob_url = Configs?.REACT_APP_IMAGE_STORAGE_URL || "https://blob.sparknotifications.walmart.com";

const ThreeColumn = {
  [GALLERY_SCHEMA.ID]: "three_column",
  [GALLERY_SCHEMA.NAME]: "Three Column Layout",
  [GALLERY_SCHEMA.CATEGORY]: null,
  template_content: `<mjml><mj-body id="ivpy" background-color="#f0f0f0"><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px"><mj-column><mj-spacer height="8px" container-background-color="transparent" id="is0xh"></mj-spacer></mj-column></mj-section><mj-section padding-top="8px" padding-bottom="15px" padding-left="15px" padding-right="15px" id="ie2cqz" background-color="#ffffff"><mj-column><mj-image src="${blob_url}/templateeditortestteam-image/Walmart_Logos_TheSpark_ylw_rgb_dff3cfc1-a067-4c41-8c65-e37faeb7e018.png" padding-top="0px" padding-bottom="0px" padding-left="0px" padding-right="0px" id="ixgi8" width="75px"></mj-image><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p><span style="font-size:18pt"><strong>Type your text here.</strong></span></p>
</mj-text><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;</span></p>
</mj-text><mj-spacer container-background-color="transparent" height="15px" id="ifwoa"></mj-spacer></mj-column></mj-section><mj-section padding-top="6px" padding-bottom="6px" padding-left="3px" padding-right="3px" id="i4elg" background-color="#ffffff"><mj-column width="30%" id="ix7vl"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="6px" padding-bottom="6px" padding-left="6px" padding-right="6px" id="ix7i8" border="solid 1px #cccccc"></mj-image></mj-column><mj-column width="45%" id="ifuwy"><mj-text padding-top="5px" padding-bottom="5px" padding-left="8px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="i6l13"><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p>
</mj-text></mj-column><mj-column width="25%" id="iu6z6"><mj-button padding-top="8px" padding-bottom="8px" padding-left="5px" padding-right="5px" font-family="Bogle, Arial, sans-serif" font-size="14.7px" background-color="#041f41" border-radius="21.599999999999998px" width="120px" height="36px" inner-padding="5px 5px" font-weight="normal" id="ixp6l" font-style="normal">Register</mj-button></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="i7eqd" background-color="#ffffff"><mj-column><mj-divider border-width="1px" padding-top="3px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#cccccc" id="ilvrp"></mj-divider></mj-column></mj-section><mj-section padding-top="6px" padding-bottom="6px" padding-left="3px" padding-right="3px" background-color="#ffffff" id="ijrni"><mj-column width="30%" id="ij0wd"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="6px" padding-bottom="6px" padding-left="6px" padding-right="6px" border="solid 1px #cccccc" id="ifqsj"></mj-image></mj-column><mj-column width="45%" id="ibm8c"><mj-text padding-top="5px" padding-bottom="5px" padding-left="8px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="iv0th"><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p>
</mj-text></mj-column><mj-column width="25%" id="ixjkj"><mj-button padding-top="8px" padding-bottom="8px" padding-left="5px" padding-right="5px" font-family="Bogle, Arial, sans-serif" font-size="14.7px" background-color="#041f41" border-radius="21.599999999999998px" width="120px" height="36px" inner-padding="5px 5px" font-weight="normal" font-style="normal" id="is8cl">Register</mj-button></mj-column></mj-section><mj-section padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px" background-color="#ffffff" id="ibs1i"><mj-column><mj-divider border-width="1px" padding-top="3px" padding-bottom="0px" padding-left="0px" padding-right="0px" border-color="#cccccc" id="iugoj"></mj-divider></mj-column></mj-section><mj-section padding-top="6px" padding-bottom="6px" padding-left="3px" padding-right="3px" background-color="#ffffff" id="isqca"><mj-column width="30%" id="icpr1"><mj-image src="/static/media/emailEditorImageIcon.e3f1893f.svg" padding-top="6px" padding-bottom="6px" padding-left="6px" padding-right="6px" border="solid 1px #cccccc" id="iuurk"></mj-image></mj-column><mj-column width="45%" id="iol1f"><mj-text padding-top="5px" padding-bottom="5px" padding-left="8px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5" id="i4dql"><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p>
</mj-text></mj-column><mj-column width="25%" id="id5jn"><mj-button padding-top="8px" padding-bottom="8px" padding-left="5px" padding-right="5px" font-family="Bogle, Arial, sans-serif" font-size="14.7px" background-color="#041f41" border-radius="21.599999999999998px" width="120px" height="36px" inner-padding="5px 5px" font-weight="normal" font-style="normal" id="iu8hv">Register</mj-button></mj-column></mj-section><mj-section padding-top="15px" padding-bottom="3px" padding-left="3px" padding-right="3px" id="ibw6q"><mj-column><mj-social font-size="12px" border-radius="12px" mode="horizontal" padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px"><mj-social-element border-radius="50%" icon-size="32px" name="workplace-noshare" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" background-color="#1479fb"></mj-social-element><mj-social-element border-radius="50%" icon-size="32px" name="linkedin-noshare" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" background-color="#2867B2"></mj-social-element><mj-social-element border-radius="50%" icon-size="32px" name="twitter-noshare" src="${blob_url}/xmwrgbcr-image/kwwcupmb-twitter-icon.png" background-color="#1DA1F2"></mj-social-element></mj-social><mj-divider border-width="1px" padding-left="10px" padding-right="10px" border-color="#cccccc" id="iogpm"></mj-divider><mj-text padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"><p style="text-align: center;"><span style="color:#605e63">Sent with Spark Notifications</span></p>
</mj-text></mj-column></mj-section></mj-body><mj-head><mj-style>
    p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }
  </mj-style><mj-style>
  ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }
  </mj-style></mj-head></mjml>`,
  [GALLERY_SCHEMA.PREVIEW]: `<!doctype html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <title>
          
        </title>
        <!--[if !mso]><!-->
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <!--<![endif]-->
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style type="text/css">
          #outlook a { padding:0; }
          body { margin:0;padding:0;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%; }
          table, td { border-collapse:collapse;mso-table-lspace:0pt;mso-table-rspace:0pt; }
          img { border:0;height:auto;line-height:100%; outline:none;text-decoration:none;-ms-interpolation-mode:bicubic; }
          p { display:block;margin:13px 0; }
        </style>
        <!--[if mso]>
        <noscript>
        <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
        </xml>
        </noscript>
        <![endif]-->
        <!--[if lte mso 11]>
        <style type="text/css">
          .mj-outlook-group-fix { width:100% !important; }
        </style>
        <![endif]-->
        
        
    <style type="text/css">
      @media only screen and (min-width:480px) {
        .mj-column-per-100 { width:100% !important; max-width: 100%; }
.mj-column-per-30 { width:30% !important; max-width: 30%; }
.mj-column-per-45 { width:45% !important; max-width: 45%; }
.mj-column-per-25 { width:25% !important; max-width: 25%; }
      }
    </style>
    <style media="screen and (min-width:480px)">
      .moz-text-html .mj-column-per-100 { width:100% !important; max-width: 100%; }
.moz-text-html .mj-column-per-30 { width:30% !important; max-width: 30%; }
.moz-text-html .mj-column-per-45 { width:45% !important; max-width: 45%; }
.moz-text-html .mj-column-per-25 { width:25% !important; max-width: 25%; }
    </style>
    
  
        <style type="text/css">
        
        

    @media only screen and (max-width:480px) {
      table.mj-full-width-mobile { width: 100% !important; }
      td.mj-full-width-mobile { width: auto !important; }
    }
  
        </style>
        <style type="text/css">p, li {
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 0;
    }ul, ol {
    margin-block-start:0; margin-block-end:0; 
    padding-block-start:0; padding-block-end:0; Margin:0;
  }</style>
        
      </head>
      <body style="word-spacing:normal;background-color:#f0f0f0;">
        
        
      <div
         style="background-color:#f0f0f0;"
      >
        
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   style="background:transparent;font-size:0px;word-break:break-word;"
                >
                  
      <div
         style="height:8px;line-height:8px;"
      >&#8202;</div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:15px;padding-left:15px;padding-right:15px;padding-top:8px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:570px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:0px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:75px;">
              
      <img
         height="auto" src="${blob_url}/templateeditortestteam-image/Walmart_Logos_TheSpark_ylw_rgb_dff3cfc1-a067-4c41-8c65-e37faeb7e018.png" style="border:0;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="75"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:18pt"><strong>Type your text here.</strong></span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="color:#605e63">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.&nbsp;</span></p></div>
    
                </td>
              </tr>
            
              <tr>
                <td
                   style="background:transparent;font-size:0px;word-break:break-word;"
                >
                  
      <div
         style="height:15px;line-height:15px;"
      >&#8202;</div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:6px;padding-left:3px;padding-right:3px;padding-top:6px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:178.2px;" ><![endif]-->
            
      <div
         class="mj-column-per-30 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:6px;padding-right:6px;padding-bottom:6px;padding-left:6px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:164px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="164"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:267.3px;" ><![endif]-->
            
      <div
         class="mj-column-per-45 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:8px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:148.5px;" ><![endif]-->
            
      <div
         class="mj-column-per-25 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:8px;padding-right:5px;padding-bottom:8px;padding-left:5px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:120px;line-height:100%;"
      >
        <tbody>
          <tr>
            <td
               align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:21.599999999999998px;cursor:auto;font-style:normal;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
            >
              <p
                 style="display:inline-block;width:110px;background:#041f41;color:#ffffff;font-family:Bogle, Arial, sans-serif;font-size:14.7px;font-style:normal;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:21.599999999999998px;"
              >
                Register
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:3px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:594px;" role="presentation" width="594px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:6px;padding-left:3px;padding-right:3px;padding-top:6px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:178.2px;" ><![endif]-->
            
      <div
         class="mj-column-per-30 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:6px;padding-right:6px;padding-bottom:6px;padding-left:6px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:164px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="164"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:267.3px;" ><![endif]-->
            
      <div
         class="mj-column-per-45 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:8px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:148.5px;" ><![endif]-->
            
      <div
         class="mj-column-per-25 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:8px;padding-right:5px;padding-bottom:8px;padding-left:5px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:120px;line-height:100%;"
      >
        <tbody>
          <tr>
            <td
               align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:21.599999999999998px;cursor:auto;font-style:normal;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
            >
              <p
                 style="display:inline-block;width:110px;background:#041f41;color:#ffffff;font-family:Bogle, Arial, sans-serif;font-size:14.7px;font-style:normal;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:21.599999999999998px;"
              >
                Register
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:3px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:3px;padding-right:0px;padding-bottom:0px;padding-left:0px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:594px;" role="presentation" width="594px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" bgcolor="#ffffff" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="background:#ffffff;background-color:#ffffff;margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#ffffff;background-color:#ffffff;width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:6px;padding-left:3px;padding-right:3px;padding-top:6px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:178.2px;" ><![endif]-->
            
      <div
         class="mj-column-per-30 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:6px;padding-right:6px;padding-bottom:6px;padding-left:6px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:collapse;border-spacing:0px;"
      >
        <tbody>
          <tr>
            <td  style="width:164px;">
              
      <img
         height="auto" src="/static/media/emailEditorImageIcon.e3f1893f.svg" style="border:solid 1px #cccccc;display:block;outline:none;text-decoration:none;height:auto;width:100%;font-size:13px;" width="164"
      />
    
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:267.3px;" ><![endif]-->
            
      <div
         class="mj-column-per-45 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:8px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p><span style="font-size:10.5pt"><span style="color:#000000"><strong>Event Date</strong></span></span></p>

<p><span style="font-size:10.5pt"><span style="color:#000000">Event Time &amp; Location</span></span></p>

<p><span style="color:#605e63">Event details &amp; description go here.</span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td><td class="" style="vertical-align:top;width:148.5px;" ><![endif]-->
            
      <div
         class="mj-column-per-25 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" vertical-align="middle" style="font-size:0px;padding:10px 25px;padding-top:8px;padding-right:5px;padding-bottom:8px;padding-left:5px;word-break:break-word;"
                >
                  
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="border-collapse:separate;width:120px;line-height:100%;"
      >
        <tbody>
          <tr>
            <td
               align="center" bgcolor="#041f41" role="presentation" style="border:none;border-radius:21.599999999999998px;cursor:auto;font-style:normal;height:36px;mso-padding-alt:5px 5px;background:#041f41;" valign="middle"
            >
              <p
                 style="display:inline-block;width:110px;background:#041f41;color:#ffffff;font-family:Bogle, Arial, sans-serif;font-size:14.7px;font-style:normal;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;padding:5px 5px;mso-padding-alt:0px;border-radius:21.599999999999998px;"
              >
                Register
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><table align="center" border="0" cellpadding="0" cellspacing="0" class="" role="presentation" style="width:600px;" width="600" ><tr><td style="line-height:0px;font-size:0px;mso-line-height-rule:exactly;"><![endif]-->
    
      
      <div  style="margin:0px auto;max-width:600px;">
        
        <table
           align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="width:100%;"
        >
          <tbody>
            <tr>
              <td
                 style="direction:ltr;font-size:0px;padding:20px 0;padding-bottom:3px;padding-left:3px;padding-right:3px;padding-top:15px;text-align:center;"
              >
                <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:594px;" ><![endif]-->
            
      <div
         class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;"
      >
        
      <table
         border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%"
      >
        <tbody>
          
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      
     <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" ><tr><td><![endif]-->
              <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
              >
                <tbody>
                  
      <tr
        
      >
        <td  style="padding:4px;vertical-align:middle;">
          <table
             border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#1479fb;border-radius:50%;width:32px;"
          >
            <tbody>
              <tr>
                <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                  
                    <img
                       height="32" src="${blob_url}/xmwrgbcr-image/kwwbf659-workplace-icon.png" style="border-radius:50%;display:block;" width="32"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        
      </tr>
    
                </tbody>
              </table>
            <!--[if mso | IE]></td><td><![endif]-->
              <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
              >
                <tbody>
                  
      <tr
        
      >
        <td  style="padding:4px;vertical-align:middle;">
          <table
             border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#2867B2;border-radius:50%;width:32px;"
          >
            <tbody>
              <tr>
                <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                  
                    <img
                       height="32" src="${blob_url}/xmwrgbcr-image/kwwcuq26-linkedin-icon.png" style="border-radius:50%;display:block;" width="32"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        
      </tr>
    
                </tbody>
              </table>
            <!--[if mso | IE]></td><td><![endif]-->
              <table
                 align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="float:none;display:inline-table;"
              >
                <tbody>
                  
      <tr
        
      >
        <td  style="padding:4px;vertical-align:middle;">
          <table
             border="0" cellpadding="0" cellspacing="0" role="presentation" style="background:#1DA1F2;border-radius:50%;width:32px;"
          >
            <tbody>
              <tr>
                <td  style="font-size:0;height:32px;vertical-align:middle;width:32px;">
                  
                    <img
                       height="32" src="${blob_url}/xmwrgbcr-image/kwwcupmb-twitter-icon.png" style="border-radius:50%;display:block;" width="32"
                    />
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
        
      </tr>
    
                </tbody>
              </table>
            <!--[if mso | IE]></td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="center" style="font-size:0px;padding:10px 25px;padding-right:10px;padding-left:10px;word-break:break-word;"
                >
                  
      <p
         style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:100%;"
      >
      </p>
      
      <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px #cccccc;font-size:1px;margin:0px auto;width:574px;" role="presentation" width="574px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    
    
                </td>
              </tr>
            
              <tr>
                <td
                   align="left" style="font-size:0px;padding:10px 25px;padding-top:5px;padding-right:5px;padding-bottom:5px;padding-left:5px;word-break:break-word;"
                >
                  
      <div
         style="font-family:Bogle, sans-serif;font-size:13px;line-height:1.5;text-align:left;color:#000000;"
      ><p style="text-align: center;"><span style="color:#605e63">Sent with Spark Notifications</span></p></div>
    
                </td>
              </tr>
            
        </tbody>
      </table>
    
      </div>
    
          <!--[if mso | IE]></td></tr></table><![endif]-->
              </td>
            </tr>
          </tbody>
        </table>
        
      </div>
    
      
      <!--[if mso | IE]></td></tr></table><![endif]-->
    
    
      </div>
    
      </body>
    </html>`,
};
export default ThreeColumn;
