import { URLS, getAPIEndPoint } from "../consts/APIEndpoints";
import { REPORT_INTERVALS } from "../consts/Campaign";
import { getResponse } from "../utils/common";
import { determineBucketSize } from "../utils/reports";

export async function updateTeam(teamId, team) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}`,
    "put",
    team
  );
}

export async function postTeam(teamConfig) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}`,
    "post",
    teamConfig
  );
}

export async function getTeamStats(teamId, params) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/stats`,
    "get",
    undefined,
    params
  );
}

export async function getTeamEvents(teamId, startDate, endDate) {
  let aggregateBy = REPORT_INTERVALS.DAYS;
  if (startDate && endDate)
    aggregateBy = await determineBucketSize(startDate, endDate);

  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/events`,
    "get",
    undefined,
    {
      type: "view,click,sent",
      start_date: startDate,
      end_date: endDate,
      aggregate_by: aggregateBy,
    }
  );
}
