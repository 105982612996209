import { getAPIEndPoint, URLS } from "../consts/APIEndpoints";
import { TEMPLATE } from "../consts/DBFields";
import { getResponse } from "../utils/common";

export async function getTeamTemplates(teamId, params) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/saved-templates`,
    "get",
    undefined,
    {
      limit: 1000,
      sort_by: "date_created",
      sort_order: "desc",
      embed: "html",
      ...params,
    }
  );
}

export async function getTeamTemplate(teamId, templateId, embedHtml) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/saved-templates/${templateId}`,
    "get",
    undefined,
    embedHtml ? { embed: "html" } : undefined
  );
}

export async function deleteTeamTemplate(teamId, templateId) {
  return await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/saved-templates/${templateId}`,
    "delete"
  );
}

export async function postTeamTemplate(teamId, template) {
  let response = await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/saved-templates`,
    "post",
    template
  );
  let templateId = response?.split("template id : "); //get the template id from the response message
  templateId = templateId?.length ? templateId[templateId.length - 1] : "";
  return templateId;
}

export async function updateTeamTemplate(teamId, templateBody) {
  let response = await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/saved-templates/${
      templateBody?.[TEMPLATE.ID]
    }`,
    "put",
    templateBody
  );
  return response;
}
