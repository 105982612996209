import { COMMANDS } from "../consts/Commands";
import { noDelete } from "../consts/DomComponents";
import { getElementType } from "../util";

const keymaps = (editor, opts) => {
  // trigger the delete confirmation dialog on press of backspace/delete key
  editor.Keymaps.add("delete-confirm", "backspace, delete", (editor) => {
    const selected = editor.getSelected();
    if (selected && !noDelete.includes(getElementType(selected))) {
      editor.runCommand(COMMANDS.TB_DELETE_CONFIRM);
    }
  });
}
export default keymaps;
