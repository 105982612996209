import React from "react";
import { CampaignState } from "../../contexts/Campaign";
import { HEX_COLORS } from "../../consts/Colors";
import QA from "../../consts/QA";

// MUI
import { Box, Divider, List, ListItem, makeStyles } from "@material-ui/core";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditReview`;

const useStyles = makeStyles((theme) => ({
  reviewSection: {
    borderBottom: `solid 1px ${HEX_COLORS.medGray}`,
    padding: "32px 0",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  reviewList: {
    width: "100%",
    "& .MuiListItem-root": {
      width: "100%",
      paddingTop: 16,
      paddingBottom: 16,
    },
    "& .MuiDivider-root": {
      "&:last-child": {
        display: "none",
      },
    },
  },
}));

function CampaignEditReview() {
  const classes = useStyles();
  return (
    <Box
      width="100%"
      overflow="hidden"
      display="flex"
      justifyContent="center"
      id={QA_ID_PREFIX}
    >
      <Box width="100%" maxWidth={800} boxSizing="border-box">
        <CampaignState>
          {({ steps, setCurrentStep, userCanEdit }) => {
            return (
              <>
                <List
                  className={classes.reviewList}
                  aria-label="review campaign steps"
                  id={`${QA_ID_PREFIX}-List`}
                >
                  {steps?.map((step, stepIndex) => {
                    if (step.review) {
                      return (
                        <React.Fragment key={`campaignEditReview-${stepIndex}`}>
                          <ListItem
                            button={userCanEdit}
                            onClick={
                              userCanEdit
                                ? () => setCurrentStep(stepIndex)
                                : null
                            }
                            id={`${QA_ID_PREFIX}-ListItem-${stepIndex}`}
                          >
                            {step.component({ isReview: true })}
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              </>
            );
          }}
        </CampaignState>
      </Box>
    </Box>
  );
}

export default CampaignEditReview;
