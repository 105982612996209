import React, { createContext, useContext, useEffect, useState } from "react";
import { capitalize, chain, chunk, forEach, sortBy as sort, slice } from "lodash";
import {
  getCampaigns,
  deleteCampaign as deleteCampaignAPI,
  getStatsForCampaignIds as getStatsForCampaigns,
} from "../APIs/CampaignAPI";
import { CAMPAIGN_STATUSES, UI_DATA } from "../consts/Campaign";
import { SORTING_OPTIONS, rowsPerPageOptions } from "../consts/CampaignTable";
import { ERROR_MSG, EVENTS, EVENT_TYPES, PAGES, SNACK_TYPES, SUCCESS_MSG } from "../consts/Common";
import { CAMPAIGN, REQUEST_PARAMS, TEAM } from "../consts/DBFields";
import {
  doesCampaignMatch,
  getCampaignChannels,
  getCampaignStatuses,
  sortCampaignsByExecutionDate,
} from "../utils/campaign";
import { getErrorType } from "../utils/common";
import { useAppContext } from "./Core";
import { useParams } from "react-router-dom";
import { trackEvent } from "../utils/appInsight";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const CampaignsListState = Context.Consumer;
export const useCampaignsContext = () => useContext(Context);

// Create and export the provider, which defines and controls the state
function CampaignsList({ children }) {
  
  const {
    activePage,
    selectedTeam,
    setAppError,
    setSnackType,
    setSnackMessage,
    folder_id,
    setFolderId,
    pageTitle
  } = useAppContext();

  const [teamCampaigns, setTeamCampaigns] = useState([]);
  const [loadingCampaigns, toggleLoadingCampaigns] = useState(true);
  const [sortingCampaigns, toggleSortingCampaigns] = useState(false);
  const [campaignsByStatus, setCampaignsByStatus] = useState({});
  const [rawFilteredCampaigns, setRawFilteredCampaigns] = useState({});
  const [filteredCampaigns, setFilteredCampaigns] = useState({});
  const [visibleCampaigns, setVisibleCampaigns] = useState([]);
  const [channelFilter, setChannelFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState(
    CAMPAIGN_STATUSES.SENT.value
  );
  const [sortBy, setSortBy] = useState(CAMPAIGN.DATE_CREATED);
  const [sortOrder, setSortOrder] = useState(REQUEST_PARAMS.DESCENDING);
  const [statusTabOverride, setStatusTabOverride] = useState(undefined);
  const [searchPhrase, setSearchPhrase] = useState(undefined);
  const [statsForCampaignIds, setStatsForCampaignIds] = useState([]);
  const [statsAleardyFetchedLookup, setStatsAleardyFetchedLookup] = useState(new Set());

  const [pageIndex, setPageIndex] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [loadingStats, toggleLoadingStats] = useState(true);

  useEffect(() => {
    const handleChange = async () => {
      toggleLoadingCampaigns(true);
      await fetchCampaigns();
    };
    if(selectedTeam[TEAM.ID] && folder_id){
      handleChange();
    }
  }, [selectedTeam[TEAM.ID], folder_id, activePage]);

  useEffect(() => {
    filterCampaigns();
  }, [categoryFilter, channelFilter]);

  useEffect(() => {
    setVisibleCampaigns(filteredCampaigns?.[statusFilter] || []);
  }, [statusFilter, filteredCampaigns?.[statusFilter]]);

  useEffect(() => {
      fetchCampaignStats();
  }, [visibleCampaigns, pageIndex, statusFilter]);

  const fetchCampaignStats = (isRefreshBtnClicked=false) => {
    getStats(isRefreshBtnClicked);
  }

  const getStats = async (isRefreshBtnClicked) => {
    toggleLoadingStats(true);
    if(statusFilter !== CAMPAIGN_STATUSES.SENT.value) {
      return
    }
    const sentCampaigns = visibleCampaigns;
    if (sentCampaigns?.length >= 1) {
      const campaignIds = chain(sentCampaigns)
        .map((campaign) => campaign.uid)
        .value();

      const response = await getStatsForCampaignIds(campaignIds, selectedTeam[TEAM.ID], pageIndex, rowsPerPage, isRefreshBtnClicked);
      if(!isRefreshBtnClicked) {
        setStatsForCampaignIds([...statsForCampaignIds, ...response]);
      }
      else {
        const originalStats = [...statsForCampaignIds, ...response];
        const uniqueStatsForCampaignIds = [...new Map(originalStats?.map(stats=>{
          return [stats['campaignId'], stats]
        })).values()];
        setStatsForCampaignIds([...uniqueStatsForCampaignIds]);
      }
      toggleLoadingStats(false);
    }
  }

  const fetchCampaigns = async () => {
    if (activePage === PAGES.CAMPAIGN.value) {
      await getTeamCampaigns();
    }
  };

  const MIN_CHAR_COUNT = 3;

  // default tab is 'Sent', but if it is empty and there are 'scheduled' or 'draft'
  // campaigns, default to a different tab instead
  const handleStatusTabsOnLoad = () => {
    if (statusTabOverride === undefined) {
      let status = 0;
      if (rawFilteredCampaigns && Object.keys(rawFilteredCampaigns).length) {
        if (!rawFilteredCampaigns[0]?.length) {
          if (!rawFilteredCampaigns[1]?.length) {
            if (!rawFilteredCampaigns[2]?.length) {
              status = 0;
            } else {
              status = 2;
            }
          } else {
            status = 1;
          }
        }
        setStatusFilter(status);
      }
      setStatusTabOverride(status);
    } else {
      setStatusFilter(statusTabOverride);
    }
  };

  const getTeamCampaigns = async (params) => {
    setSearchPhrase("");
    if (selectedTeam && selectedTeam[TEAM.ID] && folder_id) {
      try {
        let allCampaigns = await getCampaigns(selectedTeam[TEAM.ID], folder_id, params);
        setTeamCampaigns(allCampaigns || []);
        filterCampaignsByStatus(allCampaigns || [], params);
        return allCampaigns;
      } catch (err) {
        const error = getErrorType(err)
        setAppError(error);
        trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: error?.message, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.GET_CAMPAIGN_ERROR)
      }
    }
  };

  const filterCampaignsByStatus = (campaigns, params = {}) => {
    let sent = [];
    let scheduled = [];
    let draft = [];
    let filteredSent = [];
    let filteredScheduled = [];
    let filteredDraft = [];

    campaigns.forEach((campaign) => {
      campaign[UI_DATA.CHANNELS] = getCampaignChannels(campaign);
      const statuses = getCampaignStatuses(campaign);
      const match = doesCampaignMatch(campaign, channelFilter, categoryFilter);

      if (statuses?.includes(CAMPAIGN_STATUSES.SENT.value)) {
        sent.push(campaign);
        if (match) {
          filteredSent.push(campaign);
        }
      }
      if (statuses?.includes(CAMPAIGN_STATUSES.SCHEDULED.value)) {
        scheduled.push(campaign);
        if (match) {
          filteredScheduled.push(campaign);
        }
      }
      if (statuses?.includes(CAMPAIGN_STATUSES.DRAFT.value)) {
        draft.push(campaign);
        if (match) {
          filteredDraft.push(campaign);
        }
      }
    });
    setCampaignsByStatus({
      [CAMPAIGN_STATUSES.SENT.value]:
        params[REQUEST_PARAMS.SORT_BY] === CAMPAIGN.NAME
          ? sent
          : sortCampaignsByExecutionDate(sent),
      [CAMPAIGN_STATUSES.SCHEDULED.value]: scheduled,
      [CAMPAIGN_STATUSES.DRAFT.value]: draft,
    });
    setRawFilteredCampaigns({
      [CAMPAIGN_STATUSES.SENT.value]:
        params[REQUEST_PARAMS.SORT_BY] === CAMPAIGN.NAME
          ? filteredSent
          : sortCampaignsByExecutionDate(filteredSent),
      [CAMPAIGN_STATUSES.SCHEDULED.value]: filteredScheduled,
      [CAMPAIGN_STATUSES.DRAFT.value]: filteredDraft,
    });

    setFilteredCampaigns({
      [CAMPAIGN_STATUSES.SENT.value]:
        params[REQUEST_PARAMS.SORT_BY] === CAMPAIGN.NAME
          ? filteredSent
          : sortCampaignsByExecutionDate(filteredSent),
      [CAMPAIGN_STATUSES.SCHEDULED.value]: filteredScheduled,
      [CAMPAIGN_STATUSES.DRAFT.value]: filteredDraft,
    });
    toggleLoadingCampaigns(false);
  };

  const clearCampaignsList = () => {
    setTeamCampaigns([]);
    filterCampaignsByStatus([]);
  }

  const filterCampaignsBySearchPhrase = (campaigns) => {
    const updatedCampaigns = {};

    if (searchPhrase?.length >= MIN_CHAR_COUNT) {
      forEach(campaigns, (campaign, key) => {
        const campaignsFilteredBySearchPhrase = campaign?.filter((campaign) =>
          campaign?.[CAMPAIGN.NAME]?.toLowerCase()?.includes(searchPhrase)
        );
        updatedCampaigns[key] = campaignsFilteredBySearchPhrase;
      });
    }
    return updatedCampaigns;
  };

  const filterCampaigns = () => {
    const campaignsFilteredBySearchPhrase =
      filterCampaignsBySearchPhrase(rawFilteredCampaigns);

    const filteredCampaigns =
      Object.keys(campaignsFilteredBySearchPhrase).length > 0
        ? campaignsFilteredBySearchPhrase
        : rawFilteredCampaigns;

    if (categoryFilter.length || channelFilter.length) {
      let filtered = {};
      Object.keys(filteredCampaigns).forEach((status) => {
        let matches = filteredCampaigns[status]?.filter((campaign) =>
          doesCampaignMatch(campaign, channelFilter, categoryFilter)
        );
        filtered[status] = matches;
      });
      setFilteredCampaigns(filtered);
      setVisibleCampaigns(filtered[statusFilter]);
    } else {
      setFilteredCampaigns(filteredCampaigns);
      setVisibleCampaigns(filteredCampaigns[statusFilter]);
    }
    setSortBy(CAMPAIGN.DATE_CREATED);
    setSortOrder(REQUEST_PARAMS.DESCENDING);
  };

  // remove a deleted campaign from the filtered array of campaigns
  const deleteCampaign = async (campaignId) => {
    try {
      await deleteCampaignAPI(campaignId);
      setFilteredCampaigns({
        ...filteredCampaigns,
        [statusFilter]: filteredCampaigns[statusFilter].filter(
          (campaign) => campaign[CAMPAIGN.UID] !== campaignId
        ),
      });
      setRawFilteredCampaigns({
        ...rawFilteredCampaigns,
        [statusFilter]: rawFilteredCampaigns[statusFilter].filter(
          (campaign) => campaign[CAMPAIGN.UID] !== campaignId
        ),
      });
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.DELETE_CAMPAIGN);
    } catch (err) {
      setSnackType(SNACK_TYPES.ERROR);
      setSnackMessage(ERROR_MSG.DELETE_CAMPAIGN);
      trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.DELETE_CAMPAIGN, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS.DELETE_CAMPAIGN_ERROR)
    }
  };

  // handles checking/unchecking of filter boxes
  const handleFilterCheckboxChange = (event, currentFilter, filterHandler) => {
    let updatedFilter = [];
    if (
      event.target.name !== "channel-all" &&
      event.target.name !== "category-all"
    ) {
      updatedFilter = event.target.checked
        ? [...currentFilter, event.target.name]
        : currentFilter.filter((filter) => filter !== event.target.name);
    }
    filterHandler(updatedFilter);
  };

  const updateCampaignSort = async (sortOption) => {
    toggleSortingCampaigns(true);
    const params = SORTING_OPTIONS[sortOption]?.params;
    let updatedSortBy = params[REQUEST_PARAMS.SORT_BY];
    let updatedSortOrder = params[REQUEST_PARAMS.SORT_ORDER];
    setSortBy(updatedSortBy);
    setSortOrder(updatedSortOrder);
    if (searchPhrase?.length < MIN_CHAR_COUNT) {
      await getTeamCampaigns(params);
    } else {
      const updatedFilteredCampaigns = {};
      forEach(filteredCampaigns, (filteredCampaign, key) => {
        updatedFilteredCampaigns[key] = sort(filteredCampaign, (campaign) =>
          capitalize(campaign?.[updatedSortBy])
        );
        if (updatedSortOrder === REQUEST_PARAMS.DESCENDING) {
          updatedFilteredCampaigns[key] =
            updatedFilteredCampaigns[key]?.reverse();
        }
      });
      setFilteredCampaigns(updatedFilteredCampaigns);
    }
    toggleSortingCampaigns(false);
  };

  const getStatsForCampaignIds = async (campaignIds, teamId, tablePage, rowsPerPage, isRefreshBtnClicked) => {
    const pageFirstIndex = tablePage * rowsPerPage;
    const pageLastIndex = Math.min(((pageFirstIndex) + rowsPerPage), campaignIds.length);
    let campaignIdsInCurrentPage = slice(campaignIds, pageFirstIndex, pageLastIndex);
    //only getting stats that are not cached
    if(!isRefreshBtnClicked) {
      campaignIdsInCurrentPage = campaignIdsInCurrentPage.filter(campaignId => !statsAleardyFetchedLookup.has(campaignId));
    }
    let formattedStatsForCampaignIds = [];

    if(campaignIdsInCurrentPage.length === 0){
      return formattedStatsForCampaignIds;
    }

      const { sent_data } = await getStatsForCampaigns(campaignIdsInCurrentPage, teamId);
      const formattedSentData = sent_data?.buckets?.map(
        ({ sent_count_data, view_count, key, queued_count_data, error_count_data }) => {
          const { buckets = [] } = sent_count_data;
          const { sent_count } = buckets[0];

          return {
            sentCount: sent_count?.value,
            viewCount: view_count?.unique_view_count?.value,
            campaignId: key,
            queuedCount: queued_count_data?.buckets?.[0]?.queued_count?.value,
            errorCount: error_count_data?.buckets?.[0]?.error_count?.value,
          };
        }
      );
      formattedStatsForCampaignIds =
        formattedStatsForCampaignIds.concat(formattedSentData);

    setStatsAleardyFetchedLookup(new Set([...statsAleardyFetchedLookup, ...campaignIdsInCurrentPage]));
    return formattedStatsForCampaignIds;
  };

  const handleSearchPhraseChange = (searchPhrase = "") => {
    setSearchPhrase(searchPhrase);

    if (searchPhrase?.length >= MIN_CHAR_COUNT) {
      const updatedFilteredCampaigns =
        filterCampaignsBySearchPhrase(filteredCampaigns);
      setFilteredCampaigns(updatedFilteredCampaigns);
      setVisibleCampaigns(updatedFilteredCampaigns?.[statusFilter] || []);
    } else {
      setFilteredCampaigns(rawFilteredCampaigns);
      setVisibleCampaigns(rawFilteredCampaigns?.[statusFilter] || []);
    }

    setSortBy(CAMPAIGN.DATE_CREATED);
    setSortOrder(REQUEST_PARAMS.DESCENDING);
    setCategoryFilter([]);
    setChannelFilter([]);
  };

  // create one state object to pass to the Provider
  const initialState = {
    teamCampaigns,
    getTeamCampaigns,
    fetchCampaigns,
    campaignsByStatus,
    statsForCampaignIds,
    setCampaignsByStatus,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    updateCampaignSort,
    loadingCampaigns,
    sortingCampaigns,
    filteredCampaigns,
    filterCampaigns,
    setFilteredCampaigns,
    setVisibleCampaigns,
    visibleCampaigns,
    statusFilter,
    setStatusFilter: (newStatus) => {
      setStatusFilter(newStatus);
      setStatusTabOverride(newStatus);
    },
    handleStatusTabsOnLoad,
    categoryFilter,
    channelFilter,
    setCategoryFilter,
    setChannelFilter,
    handleCategoryFilter: (e) =>
      handleFilterCheckboxChange(e, categoryFilter, setCategoryFilter),
    handleChannelFilter: (e) =>
      handleFilterCheckboxChange(e, channelFilter, setChannelFilter),
    clearAllFilters: () => {
      setCategoryFilter([]);
      setChannelFilter([]);
    },
    currentSort: Object.keys(SORTING_OPTIONS).find(
      (option) =>
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_BY] === sortBy &&
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_ORDER] === sortOrder
    ),
    setStatusTabOverride,
    deleteCampaign,
    searchPhrase,
    setSearchPhrase,
    handleSearchPhraseChange,
    setRawFilteredCampaigns,
    setPageIndex,
    handleFilterCheckboxChange,
    setFolderId,
    clearCampaignsList,
    toggleLoadingCampaigns,
    loadingStats,
    fetchCampaignStats
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default CampaignsList;
