import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EditorState } from "../../../../contexts/Editor";
import { INPUT_TYPE, SETTINGS } from "../grapesjs/consts/Settings";
import { setComponentBorder } from "../grapesjs/settingsUtil";
import ColorInput from "./ColorInput";
import QA from "../../../../consts/QA";
import ButtonGroup from "./ButtonGroup";
import DimensionSlider from "./DimensionSlider";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Border`;

function DividerBorder({ element, setting, componentSettings }) {
  const { COLOR } = SETTINGS.DIVIDER_BORDER.INPUTS;

  const [borderColor, setBorderColor] = useState(COLOR.defaultValue);

  useEffect(() => {
    const border = componentSettings?.[setting?.ID] || {};
    setBorderColor(border.color);
  }, [componentSettings]);

  return (
    <EditorState>
      {({ addUsedColor, colorPresets }) => {
        return (
          <>
            <ButtonGroup
              inputType={INPUT_TYPE.BORDER}
              element={element}
              setting={setting}
              componentSettings={componentSettings}
            />
            <Box my={2}>
              <DimensionSlider
                element={element}
                setting={setting}
                componentSettings={componentSettings}
                //   label={"Border Width"}
                onChange={(e, newValue) => {
                  setComponentBorder(element, { width: newValue });
                }}
                inputType={INPUT_TYPE.BORDER}
                maxValue={50}
              />
            </Box>
            <Box my={1}>
              <ColorInput
                color={borderColor}
                setColor={(newColor) => {
                  setComponentBorder(element, { color: newColor });
                }}
                presets={colorPresets}
                addPreset={addUsedColor}
                id={QA_ID_PREFIX}
              />
            </Box>
          </>
        );
      }}
    </EditorState>
  );
}

export default DividerBorder;
