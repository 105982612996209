import { Box, Grid, Hidden } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import QA from "../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Skeleton`;

function EmailTemplateEditorSkeleton() {
  return (
    <Box
      width="100%"
      height="100%"
      p={4}
      boxSizing="border-box"
      maxWidth={1300}
      id={QA_ID_PREFIX}
    >
      <Grid container alignItems="stretch">
        <Grid item md={4} sm={6} xs={6}>
          <Skeleton width="100%" height={70} />
        </Grid>
        <Grid item md={2} sm={6} xs={6}></Grid>
        <Grid item md={1} sm={2} xs={2}>
          <Skeleton width="100%" height={70} />
        </Grid>
        <Grid item md={1} sm={2} xs={2}>
          <Skeleton width="100%" height={70} />
        </Grid>
        <Grid item md={2} sm={4} xs={4}>
          <Skeleton width="100%" height={70} />
        </Grid>
        <Grid item md={2} sm={4} xs={4}>
          <Skeleton width="100%" height={70} />
        </Grid>
        <Grid item md={8} sm={12} xs={12}>
          <Skeleton width="100%" height="70vh" variant="rect" />
        </Grid>
        <Hidden smDown>
          <Grid item md={4}>
            <Skeleton width="100%" height="70vh" variant="rect" />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
}

export default EmailTemplateEditorSkeleton;
