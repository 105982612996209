import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { HEX_COLORS } from "../../consts/Colors";
import { COPY } from "../../consts/Copy";
import { APP_BAR_HEIGHT } from "../../consts/MuiTheme";
import { TeamState } from "../../contexts/Team";
import CampaignWarningDialog from "../campaign/CampaignWarningDialog";
import Overlay from "../common/Overlay";
import LoadingOverlay from "../common/LoadingOverlay";
import TeamSettingsForm from "./TeamSettingsForm";
import { isValidTeamSettings } from "../../utils/team";

const QA_ID_PREFIX = "TeamCreateOverlay";

function TeamCreateOverlay({ closeOverlay }) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [leavePageWarning, toggleLeavePageWarning] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  return (
    <>
      <TeamState>
        {({ teamValue, errors, createTeam, saveInProgress }) => {
          const isTeamValuesValid = isValidTeamSettings({
            errors,
            teamObj: teamValue,
            isNew: true,
          });
          const handleClose = () => {
            if (!isEmpty(teamValue)) {
              // if there are input values in any of the fields
              toggleLeavePageWarning(true);
            } else {
              closeOverlay();
            }
          };

          return (
            <Overlay
              isOpen
              closeOverlay={handleClose}
              closeText="Back to Homepage"
              id={QA_ID_PREFIX}
            >
              {saveInProgress ? <LoadingOverlay /> : null}
              <Box
                width="100%"
                display="flex"
                alignItems="stretch"
                height={`calc(100vh - ${
                  APP_BAR_HEIGHT * 2 + theme.spacing(4)
                }px)`}
                mt={`${APP_BAR_HEIGHT}px`}
                justifyContent="center"
                overflow="scroll"
              >
                <Box
                  width="100%"
                  maxWidth={1100}
                  px={xsScreen ? 2 : 5}
                  pt={xsScreen ? 2 : 4}
                  boxSizing="border-box"
                >
                  <Typography variant="h3">Create New Team</Typography>
                  <Box my={5}>
                    <TeamSettingsForm
                      id={`${QA_ID_PREFIX}-SettingsForm`}
                      isNewTeam
                      errors={fieldErrors}
                      setErrors={setFieldErrors}
                    />
                  </Box>
                  <Box height={theme.spacing(1)} />
                </Box>
                <AppBar
                  position="fixed"
                  color={
                    prefersDarkMode ? HEX_COLORS.muiGray : HEX_COLORS.white
                  }
                  style={{ top: "auto", bottom: 0 }}
                >
                  <Toolbar>
                    <Box
                      width="100%"
                      py={2}
                      px={xsScreen ? 2 : 5}
                      boxSizing="border-box"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      flexDirection="row-reverse"
                    >
                      <Box width={120}>
                        <Button
                          id={`${QA_ID_PREFIX}-CreateButton`}
                          color="primary"
                          variant="contained"
                          fullWidth
                          size="large"
                          disabled={!isTeamValuesValid}
                          onClick={async () => {
                            const success = await createTeam(teamValue);
                            if (success) closeOverlay();
                          }}
                        >
                          Create
                        </Button>
                      </Box>
                      <Box width={120} mr={2}>
                        <Button
                          id={`${QA_ID_PREFIX}-CreateButton`}
                          color="secondary"
                          variant="outlined"
                          fullWidth
                          size="large"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  </Toolbar>
                </AppBar>
              </Box>
            </Overlay>
          );
        }}
      </TeamState>
      <CampaignWarningDialog
        isOpen={leavePageWarning}
        closeDialog={() => toggleLeavePageWarning(false)}
        canClose
        title={COPY.TEAM_EDIT.LEAVE_PAGE_WARNING.TITLE}
        subtitle={COPY.TEAM_EDIT.LEAVE_PAGE_WARNING.SUBTITLE}
        primaryButton={COPY.TEAM_EDIT.LEAVE_PAGE_WARNING.ACCEPT_BUTTON}
        secondaryButton={COPY.TEAM_EDIT.LEAVE_PAGE_WARNING.CANCEL_BUTTON}
        secondaryAction={() => toggleLeavePageWarning(false)}
        primaryAction={closeOverlay}
        image={<ErrorOutline fontSize="large" color="secondary" />}
        id={`${QA_ID_PREFIX}-LeavePageWarning`}
      />
    </>
  );
}

export default TeamCreateOverlay;
