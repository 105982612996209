import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

function RadioButtons({
  options,
  value,
  handleChange,
  field = {},
  id
}) {
  return (
    <>
      <FormControl component="fieldset">
        {field?.label && (
          <FormLabel component="legend" id={`${id}-RadioButtons-Label`}>
            {field.label}
          </FormLabel>
        )}
        <RadioGroup
          row={field.rowLayout}
          name={field.name}
          value={value}
          onChange={handleChange}
          id={`${id}-RadioButtons-Group`}
        >
          {options?.map((option) => {
            return (
              <FormControlLabel
                value={option?.value}
                control={<Radio />}
                label={option?.label}
                key={`RadioButton-${option.label}`}
                id={`RadioButton-${option.label}`}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      {field.helperText ? (
        <FormHelperText id={`${id}-RadioButtons-HelperText`}>
          {field.helperText}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default RadioButtons;
