import React, { Suspense, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppState } from "../../contexts/Core";
import TeamProvider from "../../contexts/Team";
import { PAGES } from "../../consts/Common";
import Nav from "./Nav";
import MobileNav from "./MobileNav";
import NotificationIcon from "./NotificationIcon";
import UserMenu from "./UserMenu";
import { ReactComponent as LogoLight } from "../../assets/images/spark-notifications-logo.svg";
import { ReactComponent as LogoDark } from "../../assets/images/spark-notifications-logo-dark.svg";

// MUI
import {
  AppBar,
  Hidden,
  Grid,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import TeamCreateOverlay from "../team/TeamCreateOverlay";

const QA_ID_PREFIX = `PageHeader`;

const useStyles = makeStyles(() => ({
  logo: {
    padding: 8,
    maxHeight: 50,
    boxSizing: "border-box",
    "& svg": {
      maxHeight: 34,
      width: "100%",
    },
  },
  logoCenter: {
    "& svg": {
      display: "block",
      margin: "auto",
    },
  },
  headerMargin: {
    height: 50,
    boxSizing: "border-box",
  },
}));

function PageHeader({
  user,
  fixed,
  isDarkMode = false,
  showNotifications = false,
}) {
  const history = useHistory();
  const classes = useStyles();

  const [createTeam, toggleCreateTeam] = useState(false);

  const handlePageChange = (newValue) => {
    let route = PAGES[Object.keys(PAGES)[newValue]]?.route;
    if (route) {
      history.push(`/${route}`);
    }
  };

  return (
    <>
      <AppBar
        color="inherit"
        style={{ position: fixed ? "fixed" : "relative" }}
      >
        <AppState>
          {({ activePage }) => (
            <Grid container alignItems="flex-end">
              <Hidden mdUp>
                <Grid item sm={3} xs={2}>
                  <MobileNav
                    activePage={activePage}
                    user={user}
                    setActivePage={handlePageChange}
                    openCreateTeamOverlay={() => toggleCreateTeam(true)}
                  />
                </Grid>
              </Hidden>
              <Grid item md={3} sm={6} xs={8}>
                <div
                  className={`${classes.logo} ${classes.logoCenter}`}
                  id={`${QA_ID_PREFIX}-SNLogo-Container`}
                >
                  <Link to="/">
                    {isDarkMode ? (
                      <LogoLight id={`${QA_ID_PREFIX}-SNLogo-LightMode`} />
                    ) : (
                      <LogoDark id={`${QA_ID_PREFIX}-SNLogo-DarkMode`} />
                    )}
                  </Link>
                </div>
              </Grid>
              <Hidden smDown>
                <Grid item md={7}>
                  <Nav
                    activePage={activePage}
                    setActivePage={handlePageChange}
                  />
                </Grid>
              </Hidden>
              <Grid item md={2} sm={3} xs={2}>
                <Grid container justifyContent="flex-end">
                  {showNotifications ? (
                    <Grid item>
                      <NotificationIcon />
                    </Grid>
                  ) : null}
                  <Hidden xsDown>
                    <Grid item>
                      <UserMenu
                        user={user}
                        id={`${QA_ID_PREFIX}-UserMenu`}
                        openCreateTeamOverlay={() => toggleCreateTeam(true)}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          )}
        </AppState>
      </AppBar>
      <div hidden={fixed ? false : true} className={classes.headerMargin}></div>
      {createTeam ? (
        <Suspense fallback={<Typography variant="h4">Loading...</Typography>}>
          <TeamProvider>
            <TeamCreateOverlay closeOverlay={() => toggleCreateTeam(false)} />
          </TeamProvider>
        </Suspense>
      ) : null}
    </>
  );
}

export default PageHeader;
