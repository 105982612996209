export const ErrorTypes = {
  //Generic
  NO_ERROR: "NO_ERROR",
  TOKEN_EXPIRED: "TOKEN_EXPIRED", //401
  USER_ACTION_NOT_ALLOWED: "USER_ACTION_NOT_ALLOWED", //403

  //Login
  APP_USER_NOT_AUTHORIZED: "APP_USER_NOT_AUTHORIZED", //403
  SSO_LOGIN_FAILED: "SSO_LOGIN_FAILED", //401

  //FE Error
  APP_ERROR: "APP_ERROR",

  //BE Error
  API_ERROR: "API_ERROR",
};
