import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HEX_COLORS } from "../../../../consts/Colors";
import { EditorState } from "../../../../contexts/Editor";
import { SETTINGS } from "../grapesjs/consts/Settings";
import { setComponentBorder } from "../grapesjs/settingsUtil";
import ColorInput from "./ColorInput";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-Border`;

function Border({ element, setting, componentSettings }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const { STYLE, WIDTH, COLOR } = SETTINGS.BORDER.INPUTS;

  const [style, setStyle] = useState(STYLE.defaultValue);
  const [width, setWidth] = useState(WIDTH.defaultValue);
  const [color, setColor] = useState(COLOR.defaultValue);

  useEffect(() => {
    const border = componentSettings?.[setting?.ID] || {};
    setStyle(border.style);
    setWidth(border.width);
    setColor(border.color);
  }, [componentSettings]);

  return (
    <EditorState>
      {({ addUsedColor, colorPresets }) => {
        return (
          <>
            <Box mt={1} id={QA_ID_PREFIX}>
              <Select
                value={style ? style : STYLE.values[0]}
                onChange={(e) => {
                  setComponentBorder(element, { style: e.target.value });
                }}
                fullWidth
                variant="outlined"
                margin="dense"
                id={`${QA_ID_PREFIX}-Select`}
              >
                {STYLE.values.map((style) => {
                  return (
                    <MenuItem
                      value={style}
                      key={style}
                      id={`${QA_ID_PREFIX}-MenuItem-${style}`}
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Box width={75}>
                          <Typography>{`${style[0].toUpperCase()}${style
                            .split("")
                            .slice(1)
                            .join("")}`}</Typography>
                        </Box>
                        <Box
                          width="100%"
                          ml={3}
                          mr={2}
                          border={`${style} 1px ${
                            prefersDarkMode
                              ? HEX_COLORS.lightestGray
                              : HEX_COLORS.medGray
                          }`}
                        ></Box>
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
            <Box my={1} width="40%">
              <TextField
                type="number"
                value={width ? width : 0}
                onChange={(e) => {
                  const widthInt = e.target.value;
                  if (
                    widthInt >= WIDTH.minValue &&
                    widthInt <= WIDTH.maxValue
                  ) {
                    setComponentBorder(element, { width: widthInt });
                  }
                }}
                variant="outlined"
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">px</InputAdornment>
                  ),
                }}
                id={`${QA_ID_PREFIX}-TextField`}
              />
            </Box>
            <Box my={1}>
              <ColorInput
                color={color}
                setColor={(newColor) => {
                  setComponentBorder(element, { color: newColor });
                }}
                presets={colorPresets}
                addPreset={addUsedColor}
                id={QA_ID_PREFIX}
              />
            </Box>
          </>
        );
      }}
    </EditorState>
  );
}

export default Border;
