import PulseService from '../services/PulseService';
import SessionService from '../services/SessionService';
import Configs from '../Configurations';

export const initializeSparkInsights = (callback) => {
//   PulseService.initialize(Configs.REACT_APP_NAME, () => {
//     SessionService.createSession();
//     return {
//       sessionId: SessionService.getSessionId(),
//     };
//   });
    const customDimensions = callback();
    if (Configs.REACT_APP_IS_ADOBE_ANALYTICS_ENABLED) {
        if (window._satellite && window._satellite.track) {
          window._satellite.track('event-trigger', {
            eventName: 'USER_DETAILS',
            data: customDimensions,
          });
        }
    }
};

export const getCustomDimensions = () => {
  PulseService.getCustomDimensions();
};
export const trackPage = ( { name } ) => {
  // PulseService.trackPageView(pageName);
  if (Configs.REACT_APP_IS_ADOBE_ANALYTICS_ENABLED) {
    if (window._satellite && window._satellite.track) {
      window._satellite.track('page-view', 
      { 
        pageTitle: name, 
        uri: window?.location?.href 
      });
    }
  }
};
export const trackEvent = (eventName, data, eventType) => {
  console.log("trackEvent", eventName, data, eventType)
  // PulseService.trackEvent(eventName, data);
  if (Configs.REACT_APP_IS_ADOBE_ANALYTICS_ENABLED) {
    if (window._satellite && window._satellite.track) {
      window._satellite.track(eventType || 'event-trigger', {
        eventName: eventName,
        data: data,
      });
    }
  }
};

// export const trackError = (message, properties) => {
//   PulseService.trackError(message, properties);
//   if (Configs.REACT_APP_IS_ADOBE_ANALYTICS_ENABLED) {
//     if (window._satellite && window._satellite.track) {
//       window._satellite.track('event-error', { message, properties });
//     }
//   }
// };

// export const setUserContext = userId => {
//   PulseService.addCustomDimensions({
//     [Consts.JWT_FIELD_NAMES.UPN]: userId,
//   });
// };
