import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import { isSessionActive, redirectToSSO } from "./AuthenticatedUser";
import Page from "../../pages/Page";
import { Box, Typography } from "@material-ui/core";
import { AppState } from "../../contexts/Core";

export default function AuthenticatedRoute({
  component: Component,
  componentProps,
  pageProps,
  ...routeProps
}) {
  const authenticated = isSessionActive();
  return (
    <AppState>
      {({ authError, setAuthError, appError, setPageTitle }) => {
        return (
          <Route
            {...routeProps}
            render={(props) =>
              authenticated === true ? (
                <Page
                  {...pageProps}
                  error={authError ? authError : appError ? appError : null}
                  clearError={() => setAuthError()}
                  setPageTitle={setPageTitle}
                >
                  <Suspense
                    fallback={
                      <Box width="100%" mt={2}>
                        <Typography variant="h4" align="center">
                          Loading...
                        </Typography>
                      </Box>
                    }
                  >
                    <Component {...props} {...componentProps} />
                  </Suspense>
                </Page>
              ) : (
                redirectToSSO(true, window.location.pathname)
              )
            }
          />
        );
      }}
    </AppState>
  );
}
