import { NOTIFICATION_CHANNELS } from "./Campaign";

// MUI
import { DoneOutline, ErrorOutline, Send, Warning } from "@material-ui/icons";
import { Link } from "@material-ui/core";
export const MAX_TEST_RECIPIENTS = 10;

export const COPY = {
  CORE: {
    USER_TEAMS_DROPDOWN: "My teams:",
  },
  TEAM_EDIT: {
    REMOVE_FROM_TEAM_WARNING: {
      TITLE: "Are you sure you want to remove the user from the team?",
      ACCEPT_BUTTON: "Yes",
      CANCEL_BUTTON: "Never mind",
    },
    LEAVE_PAGE_WARNING: {
      TITLE: "Are you sure you want to leave this page?",
      SUBTITLE: "All unsaved changes will be lost.",
      ACCEPT_BUTTON: "Yes, leave page",
      CANCEL_BUTTON: "No, stay on page",
    },
  },
  CAMPAIGN_LIST: {
    LOADING: "Fetching your messages...",
    NOT_FOUND: "No messages found",
    NOT_FOUND_SUBTITLE: 'Click "Create Message" to start a new message.',
    NOT_FOUND_SELECTION_SUBTITLE:
      "Try adjusting your selection to find what you are looking for.",
    NOT_FOUND_FILTER_SUBTITLE:
      "Try adjusting your filters to find what you are looking for.",
    SEARCH_LABEL: "Search for messages by name",
    SEARCH_BUTTON: "Search",
    CREATE_BUTTON: "Create Message",
    FILTER_CATEGORY: "Filter by Category",
    FILTER_CHANNEL: "Filter by Channel",
    FILTER_BUTTON: "Edit Filters",
    DISABLED_CHANNEL_SEND_AGAIN:
      "This message cannot be sent again because the notification channel is not allowed.",
    DISABLED_CHANNEL_DUPLICATE:
      "This message cannot be duplicated because the notification channel is not allowed.",
  },
  FOLDER_LIST: {
    CREATE_BUTTON: "Create Folder",
    SEARCH_LABEL: "Search for folders by name",
    SEARCH_BUTTON: "Search",
    LOADING: "Fetching Folders...",
  },
  CAMPAIGN_CREATE: {
    NAME_DIALOG: {
      TITLE: "Name your new message",
      SUBTITLE: "You can always change this later",
      NAME_LABEL: "Message Name",
      CATEGORY_LABEL: "Message Category",
      BUTTON: "Begin",
    },
  },
  CAMPAIGN_DETAILS: {
    NO_DATA: "No links were added for this message",
    TABLE: {
      UNIQUE_CLICKS: "Unique Clicks",
      TOTAL_CLICKS: "Total Clicks",
      RATE: "Rate",
    },
    TITLE: "Link Click Stats",
  },
  ADVANCED_TRACKING: {
    TITLE: "Open Stats by OS / Device / Browser",
    DISABLED: "Advanced tracking is not enabled for this message",
    NOT_AVAILABLE: "No advanced metric data found for this message",
    PIE_CHART_TITLES: {
      BROWSER: "Browser usage stats",
      DEVICE: "Device usage stats",
      OS: "Operating system usage stats",
    },
    METRICS: {
      OS: "osMetrics",
      DEVICE: "deviceMetrics",
      BROWSER: "browserMetrics",
    },
  },
  CAMPAIGN_DUPLICATE: {
    NAME_DIALOG: {
      TITLE: "Want to give your new message a different name?",
      SUBTITLE: "You can always change this later",
      NAME_LABEL: "Message Name",
      CATEGORY_LABEL: "Message Category",
      BUTTON: "Duplicate",
    },
  },
  CAMPAIGN_DELETE: {
    WARNING_DIALOG: {
      SUBTITLE: "This cannot be undone.",
      ACCEPT_BUTTON: "Yes, delete it.",
      CANCEL_BUTTON: "Never mind",
    },
  },
  CAMPAIGN_EDIT: {
    CHANNEL: {
      TOOLTIP: {
        TEAMS: {
          CHANNEL_DISABLED:
            "Due to technical issues, this option is currently unavailable.",
        },
        ZOOM: {
          CHANNEL_DISABLED:
            "We will no longer support sending communications via Zoom",
        },
        COMMON: {
          CHANNEL_DISABLED:
            "Due to technical issues, this option is currently unavailable.",
        },
      },
    },
    STEPPER: {
      BACK: "Save & Go Back",
      NEXT: "Save & Continue",
    },
    NAME_DIALOG: {
      TITLE: "Edit your message's name and category",
      SUBTITLE: "You can always change this later",
      NAME_LABEL: "Message Name",
      CATEGORY_LABEL: "Message Category",
      BUTTON: "Confirm changes",
    },
    CHANNEL_CHANGE_WARNING: {
      TITLE: "Are you sure you want to change this notification's channel?",
      SUBTITLE: "This will erase all other notification settings.",
      ACCEPT_BUTTON: "Yes",
      CANCEL_BUTTON: "Never mind",
    },
    TYPE_CHANGE_WARNING: {
      TITLE: "Are you sure you want to change the type of this message?",
      SUBTITLE: "This will erase all other notification settings.",
      ACCEPT_BUTTON: "Yes",
      CANCEL_BUTTON: "Never mind",
    },
    BACK_WARNING: {
      TITLE:
        "Are you sure you want to close this message without saving it first?",
      SUBTITLE: "All changes will be lost.",
      CLOSE_UNSAVED_BUTTON: "Yes, close without saving",
      GO_BACK_BUTTON: "No, back to message",
    },
    EDIT_SCHEDULED_WARNING: {
      TITLE: "Please cancel the current schedule to edit this message.",
      SUBTITLE: "You will be able to re-schedule it later.",
      ACCEPT_BUTTON: "Cancel schedule & edit",
      CANCEL_BUTTON: "Never mind",
    },
    DATA_SOURCE_HEADING_PLACEHOLDER: "Select a data source",
    TEMPLATE: {
      BACK_BUTTONS: {
        TO_CAMPAIGN: "Back to Edit Message",
        TO_TEMPLATE: "Back to Template",
        TO_GALLERY: "Back to Gallery",
        TO_TEMPLATES: "Back to Saved Templates",
      },
      SELECT_BUTTON: {
        [NOTIFICATION_CHANNELS.mail.value]: "Select a template",
        [NOTIFICATION_CHANNELS.teams.value]: "Build your template",
      },
      EDIT_BUTTON: {
        [NOTIFICATION_CHANNELS.mail.value]: "Edit template",
        [NOTIFICATION_CHANNELS.teams.value]: "Edit template",
      },
      GALLERY: {
        TITLE: "Select a template",
        NOT_FOUND: "No templates found",
        NOT_FOUND_SUBTITLES: [
          "Sorry, we're having trouble loading these templates.",
          "Looks like you haven't created any templates yet.",
          "Looks like you haven't used any templates yet.",
        ],
      },
      EDITOR: {
        TITLE: "Customize your template",
        DYNAMIC_CONTENT_HELPER:
          "The recipients who match the following criteria will see the content.",
        DYNAMIC_CONTENT_OPTIONS: ["Data exists", "Data does not exist"],
        DYNAMIC_CONTENT_SAVE: "Dynamic settings saved.",
      },
      BACK_WARNING: {
        TITLE:
          "Are you sure you want to close this template without saving it first?",
        SUBTITLE: "All changes will be lost.",
        CLOSE_UNSAVED_BUTTON: "Yes, close without saving",
        GO_BACK_BUTTON: "No, back to template",
      },
      CHANGE_TEMPLATE_WARNING: {
        TITLE: "Are you sure you want to go back to the template gallery?",
        SUBTITLE:
          "All unsaved changes will be lost when you select a new template.",
        CLOSE_UNSAVED_BUTTON: "Yes, I am sure",
        GO_BACK_BUTTON: "Cancel",
      },
      SAVE_AS_TEMPLATE: {
        TITLE: "Name your template",
        BUTTON: "Save",
      },
      NOTIFICATION_BUILDER: {
        LABEL: "Bot Code",
        LINKS: {
          zoom: "https://nodebots.zoom.us/botbuilderkit/page/createMessage",
          teams: "https://adaptivecards.io/designer/",
        },
        NEW_HELPER:
          "Once you have built your template in the notification builder, copy it and paste it here.",
        EXISTING_HELPER:
          "Paste your updated template code from the bot builder here.",

        NOTIFICATION_CODE_PLACEHOLDER: {
          zoom: `{
	"content": {
		"head": {
			
		},
		"body": []
	},
	"to_jid": "{{to_jid}}",
	"robot_jid": "{{account_id}}",
	"account_id": "{{account_id}}"
}`,
          teams: `{
    "type": "AdaptiveCard",
    "body": [
        {
            "type": "TextBlock",
            "size": "Medium",
            "weight": "Bolder",
            "text": ""
        },
    ],
    "actions": [
        {
            "type": "Action.OpenUrl",
            "title": "View",
            "url": ""
        }
    ],
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.3"
}`,
        },
      },
    },
    SEND: {
      TOOLTIP: {
        FIELD_MISSING:
          "This message has missing fields that are required to execute.",
        FIELD_ERRORS:
          "This message has errors that must be fixed before it can be executed.",
      },
      NOW: {
        SUBTITLE: "This cannot be undone.",
        ACCEPT_BUTTON: "Send it!",
        CANCEL_BUTTON: "Never mind",
        IMAGE: <Send fontSize="large" />,
      },
      SCHEDULE: {
        TITLE: "Schedule your message",
        SUBTITLE:
          "We will begin sending your message based on the scheduled date and time.",
        ACCEPT_BUTTON: "Schedule",
        CANCEL_BUTTON: "Cancel",
        DATE_HELPER_TEXT: "Date should be in MM/DD/YYYY format.",
        TIME_HELPER_TEXT: "Scheduled times are in ",
        TIME_ERROR:
          "Messages cannot be scheduled less than one hour from now, please choose a later time.",
        CONFIRMATION_TEXT: "This message will be sent on",
        CONFIRMATION_BUTTON: "Ok, schedule it",
      },
      TEST: {
        TITLE: "Test out your message!",
        SUBTITLE: "Send a test notification to yourself or your colleagues.",
        TITLE_VARIABLES: "Have some sample data to test with?",
        SUBTITLE_VARIABLES:
          "Fill in the fields below to send your test template with sample data or leave them blank.",
        ACCEPT_BUTTON: "Send test",
        CANCEL_BUTTON: "Cancel",
        PLACEHOLDER_TEXT: "Who do you want to send this test to?",
        HELPER_TEXT:
          "Test notifications will only be sent to the people you specify below and won't affect your message metrics.",
        MAX_RECIPIENTS_ERROR: `Sorry, you can only send a test to ${MAX_TEST_RECIPIENTS} recipients at a time.`,
      },
    },
    SENT: {
      SUCCESS: {
        TITLE: "Executed successfully!",
        SUBTITLE:
          "Your recipients should be receiving their notifications soon.",
        ACCEPT_BUTTON: "Track its progress",
        IMAGE: <DoneOutline fontSize="large" />,
      },
      ERROR: {
        TITLE: "Sorry, there was a problem executing your message.",
        SUBTITLE: "Please save your message and try again later.",
        ACCEPT_BUTTON: "Back to message",
        IMAGE: <ErrorOutline fontSize="large" />,
      },
    },
    REVIEW: {
      OWNERS_EDIT_MSG: "Only message owners can edit the message.",
      DISABLED_CHANNEL_MSG:
        "Messages cannot be edited because this team is not allowed to send notifications of this type.",
      DESIGN_DISCLAIMER: (
        <>
          Actual notifications may differ slightly from the preview and from
          browser to browser, we strongly recommend{" "}
          <strong>sending a test</strong> notification to yourself for the most
          accurate preview of what your audience will see.
        </>
      ),
    },
  },
  CAMPAIGN_REPORT: {
    NO_CONTENT: "No content to display.",
    TRACKING_DISABLED: "Tracking is disabled for this message",
  },
  ERROR_PAGE: {
    INFO: "For more information please contact support via",
    LINK: {
      HREF: "",
      TEXT: "Our Channel",
    },
    RELOAD_BUTTON: "Reload Page",
    HOME_BUTTON: "Return to home",
  },
  EMAIL_EDITOR: {
    LINKS: {
      MJML: "https://mjml.io/",
    },
    IMAGE_GALLERY: {
      TITLE: "Choose an image",
      NO_IMAGES_TITLE: "Looks like you don't have any images uploaded yet.",
      NO_GALLERY_IMAGES_TITLE: "Sorry, no images were found for this theme.",
      NO_SEARCH_RESULTS: "No results found.",
      NO_IMAGES_SUBTITLE: (
        <>
          Drag and drop images here or{" "}
          <Link>
            <u>click to browse</u>
          </Link>
          .
        </>
      ),
      ERROR_TITLE:
        "Sorry, we are having trouble fetching the images for your team.",
      ERROR_SUBTITLE: "Please try again later.",
      DROPZONE_TEXT: (
        <>
          Select an image from one of the galleries below or{" "}
          <Link>
            <u>upload an image</u>
          </Link>
          .
        </>
      ),
      ALLOWED_FILES_TEXT: "Allowed file types are .jpeg, .jpg, .png and .gif",
    },
  },
  TEMPLATE_LIST: {
    CREATE_BUTTON: "Create Template",
    LOADING: "Fetching your templates...",
    NOT_FOUND: "Looks like you haven't created any templates yet.",
    NOT_FOUND_SUBTITLE: `Click "Create Template" to begin.`,
    NO_RESULTS: "No matching templates found.",
    SEARCH_LABEL: "Search for a template by name",
    DELETE_DIALOG: {
      TITLE: "This template will be permanently deleted.",
      SUBTITLE: "Are you sure you want to delete it?",
      ACCEPT_BUTTON: "Yes, delete it",
      IMAGE: <Warning />,
    },
    IMAGE_STUDIO: {
      BUTTON: "Image Studio",
      SEARCH_LABEL: "Search for an image",
      DELETE_DIALOG_TITLE:
        "The image will be permanently deleted. Are you sure you want to delete it?",
      DELETE_DIALOG_BUTTON: "Yes, delete it",
    },
    BLANK: {
      NAME: "Blank"
    }
  },
  TEMPLATE: {
    CREATE_DIALOG: {
      TITLE: "Name your new template",
      BUTTON: "Next",
    },
    RENAME_DIALOG: {
      TITLE: "Rename your template",
      BUTTON: "Rename Template",
    },
    DUPLICATE_DIALOG: {
      TITLE: "Duplicate template",
      SUB_TITLE: "Please specify a name for this template.",
      BUTTON: "Duplicate",
    },
  },
  REPORTS: {
    ENGAGEMENT_TITLE: "Engagement Overview",
    CALENDAR_COPY: {
      CUSTOM: "Custom range",
      CUSTOM_APPLY: "Apply",
      CUSTOM_CANCEL: "Cancel",
    },
    OPEN_RATE_HELPER:
      "Open rate is calculated by dividing the number of unique opens by the number of successfully sent notifications.",
    CLICK_RATE_HELPER:
      "Click rate is calculated by dividing the number of unique users who clicked on the notification by the number of successfully sent notifications.",
    NOT_FOUND: "No reports found",
    NOT_FOUND_SUBTITLE:
      "Try adjusting your time range to find what you are looking for.",
  },
  MEDIA_LIBRARY: {
    FOLDER_NAME: "Folder Name",
    DISABLED_DELETE: "You don't have permissions to delete the file",
    DISABLED_RENAME: "You don't have permissions to rename the file",
    NO_FILES_FOUND: "No files found",
    LOADING: "Fetching Media...",
    MEDIA_NAME_LENGTH_EXCEEDED:
      "Maximum limit reached: File name should be less than 255 characters",
    SEARCH_LABEL: "Search for media by name",
    NEW_FILE: "New file",
    ITEMS_SELECTED: "items selected",
    CLEAR_SELECTED: "Clear selected",
    CREATE_FOLDER: "Create a new folder",
    MEDIA_DRAG_AND_DROP: "Drag and drop items to upload",
    MEDIA_UPLOAD_CONSTRAINTS: "You can upload up to 10 .png, .jpg, .csv, xlsx, .pptx, docx, .zip, or .pdf files up to 10MB.",
  },
};
