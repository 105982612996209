import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import { HEX_COLORS } from "../../consts/Colors";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";
import QA from "../../consts/QA";
import { addPreviewCss } from "../../utils/template";

// MUI
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Fade,
  Grid,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  Badge
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { DEFAULT_LINE_HEIGHT } from "./EmailEditor/grapesjs/plugins/plugin";
import { COPY } from "../../consts/Copy";

const QA_ID_PREFIX = `${QA.PAGES.TEMPLATE}-TemplateThumbnail`;

function TemplateThumbnail({
  onClick,
  buttonText,
  previewHTML,
  fetchPreviewHTML,
  toScale,
  channel,
  caption,
  templateKey,
  isGallery,
  isTable,
  showDisclaimer,
  id,
}) {
  const previewWidth = isTable ? 100 : isGallery ? 220 : 300;
  const previewHeight = isTable ? 110 : isGallery ? 240 : 350;
  const padding = isTable ? 8 : isGallery ? 16 : 32;
  const templateWidth = 600;
  const ratio = previewWidth / previewHeight;
  const thumbnailWidth = previewWidth + padding * ratio;
  const thumbnailHeight = previewHeight + padding;
  const previewScale = previewWidth / templateWidth;

  const useStyles = makeStyles((theme) => ({
    button: {
      "&.MuiButton-root": {
        backgroundColor: "white",
      },
    },
    buttonContainer: {
      position: "absolute",
    },
    hoverButton: {
      display: "none",
    },
    card: {
      border: "none",
      backgroundColor:
        theme.palette.type === "dark" ? "rgba(255,255,255,0.1)" : "initial",
      "&:hover": {
        "& $hoverButton": {
          display: "block",
        },
      },
    },
    thumbnailContainer: {
      position: "relative",
      height: thumbnailHeight,
      width: thumbnailWidth,
    },
    thumbnailContainerToScale: {
      height: "auto",
      width: thumbnailWidth * 2 - padding / 2,
      [theme.breakpoints.down("600")]: {
        width: "100%",
      },
    },
    placeholder: {
      width: previewWidth,
      height: previewHeight,
      overflow: "hidden",
      boxSizing: "border-box",
      backgroundColor:
        theme.palette.type === "dark"
          ? HEX_COLORS.medGray
          : HEX_COLORS.lightGray,

      "& .MuiSkeleton-root": {
        backgroundColor:
          theme.palette.type === "dark" ? HEX_COLORS.darkGray : "white",
        opacity: theme.palette.type === "dark" ? 0.5 : 1,
      },
      "& .MuiSkeleton-text": {
        marginTop: 2,
      },
    },
    placeholderToScale: {
      width: previewWidth * 2,
      height: previewHeight * 2,
    },
    preview: {
      position: "absolute",
      height: previewHeight,
      width: previewWidth,
      boxSizing: "border-box",
      "& > div": {
        opacity:
          theme.palette.type === "dark"
            ? 0.85
            : isTable
            ? 1
            : isGallery
            ? 0.9
            : 0.5,
        backgroundColor: "white",
        pointerEvents: "none",
        overflow: "hidden",
        width: `${previewWidth / previewScale}px`,
        height: `${previewHeight / previewScale}px`,
        "-webkit-transform": `scale(${previewScale})`,
        "-moz-transform": `scale(${previewScale})`,
        "-ms-transform": `scale(${previewScale})`,
        transform: `scale(${previewScale})`,
        "-webkit-transform-origin": "0 0 0",
        "-moz-transform-origin": "0 0 0",
        "-ms-transform-origin": "0 0 0",
        "transform-origin": "0 0 0",
      },
    },
    previewToScale: {
      boxSizing: "border-box",
      width: templateWidth,
      [theme.breakpoints.down("600")]: {
        width: "100%",
      },
      margin: `${padding * 0.75}px 0`,
      height: "100%",
    },
    templatePreview: {
      "& p, li": {
        "line-height": DEFAULT_LINE_HEIGHT,
        "margin-top": 0,
        "margin-bottom": 0,
      },
      "& ul, ol": {
        "margin-block-start": 0,
        "margin-block-end": 0,
        "padding-block-start": 0,
        "padding-block-end": 0,
        Margin: 0,
      },
    },
    badge: {
      "& .MuiBadge-badge" : {
        top: 10,
        width: "60px",
        height: "25px",
        backgroundColor: '#0071ce',
        color: '#fff',
        fontSize: "14px",
        borderRadius: '4px',
        fontWeight: "700",
        transform: "scale(1) translate(0%, -50%)"
      }
    }
   
  }));
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [safeHTML, setSafeHTML] = useState();
  const [loadingPreview, toggleLoadingPreview] = useState(true);

  useEffect(() => {
    const handlePreview = async () => {
      toggleLoadingPreview(true);
      let preview = previewHTML;
      if (!preview && fetchPreviewHTML) {
        preview = await fetchPreviewHTML();
      }
      if (preview) {
        preview = addPreviewCss(preview);
      }
      setSafeHTML(DOMPurify.sanitize(preview));
      toggleLoadingPreview(false);
    };
    handlePreview();
  }, [previewHTML, templateKey]);

  // make sure links open in a new tab when clicked
  useEffect(() => {
    let preview = document.getElementById(`${id}-${QA_ID_PREFIX}-PreviewHTML`);
    let links = preview?.getElementsByTagName("a");
    for (var i = 0; i < links?.length; i++) {
      let link = links[i];
      link.setAttribute("target", "_blank");
    }
  }, [safeHTML]);

  const template = (
    <Box
      className={`${classes.thumbnailContainer} ${
        toScale ? classes.thumbnailContainerToScale : null
      }`}
      boxSizing="border-box"
      display="flex"
      alignItems="center"
      justifyContent="center"
      aria-hidden="true"
    >
      {buttonText && !toScale && !loadingPreview ? (
        <Fade in>
          <Box
            zIndex={10000}
            className={`${classes.buttonContainer} ${
              isGallery ? classes.hoverButton : null
            }`}
          >
            <Button
              component="div"
              variant={prefersDarkMode ? "contained" : "outlined"}
              color="primary"
              size="large"
              className={prefersDarkMode ? null : classes.button}
              id={`${id}-${QA_ID_PREFIX}-Button`}
              aria-label="Select Template"
            >
              {buttonText}
            </Button>
          </Box>
        </Fade>
      ) : null}
      {safeHTML && !loadingPreview ? (
        <Box
          className={`${classes.templatePreview} ${
            toScale ? classes.previewToScale : classes.preview
          }`}
        >
          <Paper elevation={12}>
            <div
              dangerouslySetInnerHTML={{ __html: safeHTML }}
              id={`${id}-${QA_ID_PREFIX}-PreviewHTML`}
            ></div>
          </Paper>
        </Box>
      ) : channel === NOTIFICATION_CHANNELS.mail.value ? (
        <Grid
          className={`${classes.placeholder} ${
            toScale ? classes.placeholderToScale : null
          }`}
          container
          alignItems="flex-start"
          justify="center"
          spacing={isTable ? 2 : 4}
          id={`${id}-${QA_ID_PREFIX}-Placeholder`}
        >
          <Grid item xs={12}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              width="100%"
              height={30}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={isTable ? 50 : 75}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={isTable ? 50 : 75}
            />
          </Grid>
          <Grid item xs={12}>
            <Skeleton
              width="100%"
              animation={loadingPreview ? "pulse" : false}
            />
            <Skeleton
              width="100%"
              animation={loadingPreview ? "pulse" : false}
            />
            <Skeleton
              width="100%"
              animation={loadingPreview ? "pulse" : false}
            />
            <Skeleton
              width="100%"
              animation={loadingPreview ? "pulse" : false}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          className={`${classes.placeholder} ${
            toScale ? classes.placeholderToScale : null
          }`}
          container
          alignItems="flex-start"
          justify="center"
          spacing={4}
        >
          <Grid item xs={2}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={50}
            />
          </Grid>
          <Grid item xs={10}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={50}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={30}
            />
          </Grid>
          <Grid item xs={10}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={30}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={90}
            />
          </Grid>
          <Grid item xs={10}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={90}
            />
          </Grid>
          <Grid item xs={2}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={30}
            />
          </Grid>
          <Grid item xs={10}>
            <Skeleton
              variant="rect"
              animation={loadingPreview ? "pulse" : false}
              height={30}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );

  const templateCard = (
    <Card className={classes.card} id={`${id}-${QA_ID_PREFIX}`}>
      {onClick ? (
        <CardActionArea
          onClick={(e) => onClick(e)}
          disabled={loadingPreview}
          aria-label="Template Card"
        >
          {template}

        </CardActionArea>
      ) : (
        template
      )}
    </Card>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        {caption === COPY.TEMPLATE_LIST.BLANK.NAME ?
          (<Badge
            badgeContent="NEW"
            overlap="rectangle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className={classes.badge}
            id={`${id}-${QA_ID_PREFIX}-new-badge`}
          >
            { templateCard }
          </Badge>)
          :
          templateCard
        }
        {showDisclaimer ? (
          <Box
            width="100%"
            maxWidth={templateWidth}
            py={2}
            boxSizing="border-box"
            id={`${id}-${QA_ID_PREFIX}-Disclaimer`}
          >
            <Box my={1}>
              <Typography align="center" color="primary" variant="h4">
                <strong>Heads up!</strong>
              </Typography>
            </Box>
            <Typography variant="h5" align="center" color="textSecondary">
              {COPY.CAMPAIGN_EDIT.REVIEW.DESIGN_DISCLAIMER}
            </Typography>
          </Box>
        ) : null}
        {caption ? (
          <Box mt={1}>
            <Typography
              align="center"
              color="textSecondary"
              id={`${id}-${QA_ID_PREFIX}-Caption`}
            >
              {caption}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default TemplateThumbnail;
