import axios from "axios";
import { get, intersectionWith, isEqual } from "lodash";
import {
  AD_USER,
  ERROR_TYPES,
  TIME_ZONE,
  TIME_ZONE_ABBR,
} from "../consts/Common";
import { TEAM, TEAM_USER } from "../consts/DBFields";
import Configs, { get as getConfiguration } from "../Configurations";
import * as XLSX from "xlsx";

const jwt = require("jsonwebtoken");

export const getIntValue = (input) => {
  const intValue = parseInt(input);
  if (!isNaN(intValue)) {
    return intValue;
  }
  return null;
};

let expiryInHrs = getIntValue(Configs.REACT_APP_ACCESS_KEY_EXPIRY_HRS);
expiryInHrs = expiryInHrs ? expiryInHrs : 8; //defaulting to 8 hours if environment variable is not configured

export function getJSONWebToken() {
  const exp = new Date().setTime(
    new Date().getTime() + expiryInHrs * 60 * 60 * 1000
  );
  const accessKeyId = getConfiguration("REACT_APP_ACCESS_KEY_ID");
  const secretAccessKey = getConfiguration("REACT_APP_SECRET_ACCESS_KEY");
  let token = "";
  if (accessKeyId && secretAccessKey) {
    token = jwt.sign(
      {
        kid: accessKeyId,
        exp: exp,
      },
      secretAccessKey
    );
  }
  return token;
}
export function getRequestConfig(url, httpMethod, data, params) {
  return {
    url: url,
    method: httpMethod,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      jwt: getJSONWebToken(),
    },
    data: data,
    params: params,
  };
}

export function validateAndGetMessage(response) {
  if (response?.data?.type === "success") {
    return response.data.message;
  } else if (response?.status === 204) {
    // teams without campaigns return 204 no data, but app shouldn't go into error state
    return;
  } else {
    throw new Error("not a success response");
  }
}

export async function getResponse(url, httpMethod, data, params) {
  const config = getRequestConfig(url, httpMethod, data, params);
  let response = await axios(config);
  if (params && params.requestType === "getMessageDetails") {
    return response;
  }
  return validateAndGetMessage(response);
}

// WCNP Migration Change - This function won't be used anymore
export function getEnvVariable(key) {
  return (
    (window.customInjection && window.customInjection[key]) ||
    window.env[key] ||
    undefined
  );
}

//generic error handling function to be shared, for all scenarios that don't warrant a full screen error page
export function handleError(error, alertMsg) {
  console.error(`alert message: ${alertMsg}`, error);
}

export function getErrorType(error) {
  if (error?.status === 401) {
    return ERROR_TYPES.TOKEN_INVALID;
  } else if (error.message === ERROR_TYPES.NETWORK.message) {
    return ERROR_TYPES.NETWORK;
  } else {
    return ERROR_TYPES.DEFAULT;
  }
}

// return a timestamp in MM/DD/YYYY 00:00 format
export const formatDate = (timestamp, includeTime = true, includeTz) => {
  if (timestamp) {
    const date = new Date(timestamp);
    let formattedDate = `${date.toLocaleString("en-US", {
      timeZone: TIME_ZONE,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })}`;
    if (includeTime) {
      return `${formattedDate}, ${date.toLocaleTimeString("en-US", {
        timeZone: TIME_ZONE,
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      })}${includeTz ? ` ${TIME_ZONE_ABBR}` : ""}`;
    } else {
      return formattedDate;
    }
  } else {
    return null;
  }
};

export const formatRecipient = (recipient) => {
  if (!recipient || typeof recipient === "string") return recipient;
  else {
    const to = (recipient.to || []).join(", ");
    const cc = (recipient.cc || []).join(", ");
    return to + `${cc.length ? " Cc: " + cc : ""}`;
  }
};

//func to format numbers with commas
export const numberWithCommas = (x) => {
  if (x !== null && x !== undefined) {
    let returnVar = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return returnVar;
  } else {
    return "-";
  }
};

export const getNameFromUpn = (upn, selectedTeam, teamUsers, abbreviateUpn) => {
  if (upn) {
    const users = teamUsers?.[selectedTeam?.[TEAM.ID]] || [];
    let match = users.find(
      (user) => user[TEAM_USER.UPN].toLowerCase() === upn.toLowerCase()
    );
    if (match) {
      match = match[TEAM_USER.NAME] || match[TEAM_USER.EMAIL];
    }
    if (!match) {
      return abbreviateUpn ? upn.split("@")[0] : upn;
    } else {
      return match;
    }
  }
  return;
};

const upnInLowerCase = (val) => get(val, AD_USER.UPN, "").toLowerCase();

export const intersectUsers = (array1 = [], array2 = []) => {
  return intersectionWith(array1, array2, (value1, value2) =>
    isEqual(upnInLowerCase(value1), upnInLowerCase(value2))
  );
};

const ascendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return 1;
  }
  if (b[orderBy] > a[orderBy]) {
    return -1;
  }
  return 0;
};

const getComparator = (orderBy, order) => {
  return order === "asc"
    ? (a, b) => ascendingComparator(a, b, orderBy)
    : (a, b) => -ascendingComparator(a, b, orderBy);
};

export const stableSort = (array, orderBy, order = "asc") => {
  const comparator = getComparator(orderBy, order);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const exportToExcel = (records, fileName = "export.xlsx", options) => {
  const workSheet = XLSX.utils.json_to_sheet(records, options);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, workSheet, "Sheet1");
  XLSX.writeFile(wb, fileName);
};
