import { Box, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { updateIconColors } from "../grapesjs/settingsUtil";
import QA from "../../../../consts/QA";
import ColorInput from "./ColorInput";
import { HEX_COLORS } from "../../../../consts/Colors";
import { EditorState } from "../../../../contexts/Editor";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-SocialIconStyle`;

function SocialIconStyle({ element, setting, componentSettings }) {
  const { COLOR } = setting?.INPUTS;
  const [iconColor, setIconColor] = useState();
  const [useDefaultColors, toggleUseDefaultColors] = useState(true);

  useEffect(() => {
    const iconColor = componentSettings?.[setting.ID];
    setIconColor(iconColor);
    toggleUseDefaultColors(!iconColor);
  }, [componentSettings]);

  return (
    <Box id={QA_ID_PREFIX} px={1}>
      <Typography
        variant="body2"
        color="textSecondary"
        id={`${QA_ID_PREFIX}-Color-Label`}
      >
        <strong>{COLOR.label}</strong>
      </Typography>
      <Box my={1}>
        <Select
          value={useDefaultColors ? "default" : "custom"}
          onChange={(e) => {
            updateIconColors(
              element,
              e.target.value === "default" ? null : HEX_COLORS.ink
            );
            toggleUseDefaultColors(e.target.value === "default");
          }}
          fullWidth
          variant="outlined"
          margin="dense"
          id={`${QA_ID_PREFIX}-Select`}
        >
          {COLOR.values.map((option) => {
            return (
              <MenuItem
                value={option.value}
                key={option.value}
                id={`${QA_ID_PREFIX}-MenuItem-${option.value}`}
              >
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      {!useDefaultColors ? (
        <EditorState>
          {({ colorPresets, addUsedColor }) => {
            return (
              <ColorInput
                color={iconColor}
                setColor={(color) => {
                  updateIconColors(element, color);
                }}
                presets={colorPresets}
                addPreset={addUsedColor}
                id={`${QA_ID_PREFIX}-${setting.ID}`}
              />
            );
          }}
        </EditorState>
      ) : null}
    </Box>
  );
}

export default SocialIconStyle;
