import { BlobServiceClient } from "@azure/storage-blob";
import { getSas, getSasForAttachments } from "../../APIs/StorageAPI";

let tokens = {};

export const getToken = (teamId, connectionType) => {
  return tokens?.[teamId]?.[connectionType] || null;
};

export const setToken = (teamId, connectionType, token) => {
  if (tokens) {
    tokens[teamId] = {
      ...tokens[teamId],
      [connectionType]: token,
    };
  }
};
// get the SAS token, url and container name if already existing for teamId and connectionType
// otherwise generate a new sas token and store it in the tokens object
export const setUpSAS = async (id, connectionType = "data", includeThemes = false) => {
  if (!getToken(id, connectionType)) {
    try {
      let sasResponse;
      if(connectionType === "attachment") {
        sasResponse = await getSasForAttachments(id);
      }
      else if(connectionType === "image" && includeThemes) {
        const sasArr = await getSas(id, connectionType, includeThemes);
        const sasTokens = [];
        if (sasArr) {
          for(let sasResponse of sasArr) {
            let token = await createToken(sasResponse)
            sasTokens.push(token)
          }
          // TODO: storing token for themes and team in local var
          return sasTokens;
        } else {
          throw new Error("No SAS or SAS URL returned");
        }
      }
      else {
        sasResponse = await getSas(id, connectionType);
      }
      const token = await createToken(sasResponse);
      tokens = {
        ...tokens,
        [id]: {
          ...tokens[id],
          [connectionType]: token,
        },
      };
      return token;
    } catch (err) {
      // TODO: handle error
    }
  } else {
    return tokens[id][connectionType];
  }
};


async function createToken(sasResponse) {
  let connectionString = `BlobEndpoint=${sasResponse.url};SharedAccessSignature=${sasResponse.sas}`;
  let serviceClient = await BlobServiceClient.fromConnectionString(
    connectionString
  );
  let containerClient = await serviceClient.getContainerClient(
    sasResponse.container_name
  );
  return {
    SAS: sasResponse.sas,
    SAS_URL: sasResponse.url,
    SAS_CONTAINER: sasResponse.container_name,
    SERVICE_CLIENT: serviceClient,
    CONTAINER_CLIENT: containerClient,
    THEME_NAME: sasResponse.theme_name
  };
}