import { Box, Button, makeStyles, useMediaQuery } from '@material-ui/core';
import { MoreHoriz } from '@material-ui/icons';
import React, { useEffect, useState } from 'react'
import QA from '../../../../consts/QA';
import { EDITOR_COLORS } from '../../grapesjs/consts/colors';
import { INPUT_TYPE } from '../grapesjs/consts/Settings';
import { getComponentDimension, setComponentAlignment, setComponentBorder, setComponentBorderRadius, setComponentDimension } from '../grapesjs/settingsUtil';
import DimensionSlider from './DimensionSlider';
import RoundedCorners from './RoundedCorners';

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-ButtonGroup`;

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        background: EDITOR_COLORS.brightGrey,
        borderRadius: "6px",
        padding: "4px",
    },
    buttonsContainer_D: {
        background: EDITOR_COLORS.davysGrey,
        borderRadius: "6px",
        padding: "4px",
    },
    flex: {
        display: "flex",
        margin: 0,
    },
    button: {
        flex: 1,
        border: "1px solid transparent",
        minWidth:"58px"
    },
    buttonHover: {
        "&:hover": {
            backgroundColor: EDITOR_COLORS.antiFlashWhite,
        },
    },
    buttonHover_D: {
        "&:hover": {
            backgroundColor: EDITOR_COLORS.blackOlive,
        },
    },
    selected: {
        border: `1px solid ${EDITOR_COLORS.americanSilver}`,
        background: EDITOR_COLORS.white,
    },
    selected_D: {
        background: EDITOR_COLORS.outerSpace,
    }
}))

function ButtonGroup({ element, setting, componentSettings, inputType }) {

    const classes = useStyles();
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [values, setValues] = useState([]);
    const [defaultValue, setDefaultValue] = useState();
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [attribute, setAttribute] = useState();
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [showSlider, setShowSlider] = useState(false);
    const [componentSettingsState, setcomponentSettingsState] = useState(componentSettings || {});

    useEffect(() => {
        switch(inputType) {
            case INPUT_TYPE.BUTTON_SHAPE:
                setValues(setting.INPUTS.RADIUS?.["values"]);
                setDefaultValue(setting.INPUTS.RADIUS?.["defaultValue"]);
                setShowMoreButton(true);
            break;
            case INPUT_TYPE.HORIZONTAL_ALIGN:
                setValues(setting.INPUTS.ALIGN?.["values"]);
                setDefaultValue(setting.INPUTS.ALIGN?.["defaultValue"]);
                setAttribute(setting.INPUTS.ALIGN?.["attribute"]);
                setShowMoreButton(false);
            break;
            case INPUT_TYPE.BUTTON_SIZE:
                setValues(setting.INPUTS?.SIZE?.["values"]);
                setDefaultValue(setting.INPUTS?.SIZE?.["defaultValue"]?.width);
                setShowMoreButton(true);
            break;
            case INPUT_TYPE.BORDER:
                setValues(setting.INPUTS?.STYLE?.["values"]);
                setDefaultValue(setting.INPUTS?.STYLE?.["defaultValue"]);
            break;
            default:
                return;
        }
    }, [inputType]);

    useEffect(() => {
        if(INPUT_TYPE.BUTTON_SIZE === inputType) {
            const width = getComponentDimension(element, "px", "width");
            const height = getComponentDimension(element, "px", "height");
            componentSettingsState["width"] = width;
            componentSettingsState["height"] = height;
            setcomponentSettingsState({...componentSettingsState})
        }
        setCurrentValue(componentSettings?.[setting?.ID] || defaultValue);
    }, [componentSettings]);

    const getIsOptionSelected = (v) => {
        if(inputType === INPUT_TYPE.BUTTON_SIZE) {
            const width = componentSettings?.[setting?.ID];
            return (width === `${v.width}${v.unit}`);
        } 
        else if (inputType === INPUT_TYPE.BORDER){
            return v.value === currentValue?.style
        }
        else {
            return v.value === currentValue;
        }
    }
    return (
        <Box
            className={prefersDarkMode ? classes.buttonsContainer_D : classes.buttonsContainer}
        >
            <Box
                mt={1}
                id={QA_ID_PREFIX}
                className={classes.flex}
            >
                {values.map((v) => {
                    return (
                        <Button
                            key={v.value}
                            id={`${QA_ID_PREFIX}-Button-${v.label}`}
                            onClick={() => {
                                if(inputType === INPUT_TYPE.BUTTON_SHAPE) {
                                    setComponentBorderRadius(element, v.value);
                                    setShowSlider(false);
                                } else if (inputType === INPUT_TYPE.HORIZONTAL_ALIGN) {
                                    setComponentAlignment(element, attribute, v.value);
                                } else if (inputType === INPUT_TYPE.BUTTON_SIZE) {
                                    const newWidth = v.width;
                                    setComponentDimension(
                                        element,
                                        newWidth,
                                        v.unit,
                                        setting?.INPUTS?.SIZE?.WIDTH?.attribute
                                    );
                                    setShowSlider(false);
                                } else if (inputType === INPUT_TYPE.BORDER){
                                    setComponentBorder(element, {style: v.value})
                                }
                            }}
                            className={[
                                classes.button,
                                prefersDarkMode ?   classes.buttonHover_D : classes.buttonHover,
                                (getIsOptionSelected(v) && !prefersDarkMode) ? classes.selected : {},
                                (getIsOptionSelected(v) && prefersDarkMode) ? classes.selected_D : {},
                            ]}
                        >
                            {v.label}
                        </Button>
                    )
                })}
                {showMoreButton &&
                    <Button
                        id={`${QA_ID_PREFIX}-Button-More`}
                        onClick={() => setShowSlider(!showSlider)}
                    >
                        <MoreHoriz />
                    </Button>
                }
            </Box>
            {(showSlider && inputType === INPUT_TYPE.BUTTON_SHAPE) &&
                <RoundedCorners
                    element={element}
                    setting={setting}
                    componentSettings={componentSettings}
                />
            }
            {(showSlider && inputType === INPUT_TYPE.BUTTON_SIZE) &&
                <>
                    <DimensionSlider
                        element={element}
                        setting={setting}
                        componentSettings={componentSettingsState}
                        label={"Height"}
                        onChange={(e, newValue) => {
                            setComponentDimension(
                                element,
                                newValue,
                                setting.INPUTS?.SIZE?.unit,
                                setting?.INPUTS?.SIZE?.HEIGHT?.attribute
                            );
                        }}
                        inputType={INPUT_TYPE.HEIGHT}
                        maxValue={200}
                    />
                    <DimensionSlider
                        element={element}
                        setting={setting}
                        componentSettings={componentSettingsState}
                        label={"Width"}
                        onChange={(e, newValue) => {
                            setComponentDimension(
                                element,
                                newValue,
                                setting.INPUTS?.SIZE?.unit,
                                setting?.INPUTS?.SIZE?.WIDTH?.attribute
                            );
                        }}
                        inputType={INPUT_TYPE.WIDTH}
                        maxValue={200}
                    />
                </>
            }
        </Box>
    )
}

export default ButtonGroup;
