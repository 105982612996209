import React, { useState, Suspense, useEffect } from "react";
import CampaignWarningDialog from "../campaign/CampaignWarningDialog";
import Overlay from "../common/Overlay";
import { COPY } from "../../consts/Copy";
import { APP_BAR_HEIGHT } from "../../consts/MuiTheme";
import { HEX_COLORS } from "../../consts/Colors";
import { EditorState } from "../../contexts/Editor";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { TEMPLATE } from "../../consts/DBFields";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";
import QA from "../../consts/QA";
import EmailTemplateEditorSkeleton from "./EmailEditor/EmailTemplateEditorSkeleton";
import { exportMjml } from "./EmailEditor/grapesjs/util";

export const TemplateEditor = React.lazy(() => import("./TemplateEditor"));
export const TemplateGallery = React.lazy(() => import("./TemplateGallery"));

const QA_ID_PREFIX = `${QA.PAGES.TEMPLATE}-TemplateOverlay`;

function TemplateOverlay({
  closeEditor,
  template,
  saveTemplate,
  saveButtonText,
  channel,
  selectedCampaign,
  setError,
  loading,
  renameTemplate,
}) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [warningDialog, toggleWarningDialog] = useState(false);
  const [unsavedChanges, toggleUnsavedChanges] = useState(false);
  const [editorContent, setEditorContent] = useState(null);
  const [previousContent, setPreviousContent] = useState(null);

  useEffect(() => {
    setEditorContent(template?.[TEMPLATE.CONTENT]?.trim());
  }, [template]);

  const getCurrentContent = (editor) => {
    const mjml = exportMjml(editor);
    if (mjml.error) {
      return editorContent;
    } else {
      return mjml.content;
    }
  };

  return (
    <>
      <EditorState>
        {({ createGalleries, editor }) => {
          return (
            <>
              <Overlay
                isOpen={true}
                closeOverlay={() => {
                  if (previousContent) {
                    setEditorContent(previousContent);
                    setPreviousContent(null);
                  } else if (unsavedChanges) {
                    toggleWarningDialog("save");
                  } else {
                    if (editorContent && !template) {
                      toggleUnsavedChanges(false);
                      setEditorContent();
                    } else {
                      closeEditor();
                    }
                  }
                }}
                closeText={
                  !editorContent
                    ? previousContent
                      ? COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_BUTTONS.TO_TEMPLATE
                      : COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_BUTTONS[
                          selectedCampaign ? "TO_CAMPAIGN" : "TO_TEMPLATES"
                        ]
                    : template
                    ? COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_BUTTONS[
                        selectedCampaign ? "TO_CAMPAIGN" : "TO_TEMPLATES"
                      ]
                    : COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_BUTTONS.TO_GALLERY
                }
                id={QA_ID_PREFIX}
                dialogProps={{ disableEnforceFocus: true }}
              >
                <Box
                  mt={`${APP_BAR_HEIGHT}px`}
                  bgcolor={
                    prefersDarkMode
                      ? HEX_COLORS.darkGray
                      : HEX_COLORS.lightestGray
                  }
                  minHeight={`calc(100vh - ${APP_BAR_HEIGHT}px)`}
                >
                  {loading ? (
                    <EmailTemplateEditorSkeleton />
                  ) : !editorContent &&
                    channel === NOTIFICATION_CHANNELS.mail.value ? (
                    <Suspense
                      fallback={
                        <Typography variant="h4">Loading...</Typography>
                      }
                    >
                      <TemplateGallery
                        createGalleries={createGalleries}
                        openInEditor={(content) => {
                          toggleUnsavedChanges(true);
                          setEditorContent(content);
                          setPreviousContent(null);
                        }}
                        channel={channel}
                        selectedCampaign={selectedCampaign}
                      />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<EmailTemplateEditorSkeleton />}>
                      <TemplateEditor
                        templateContent={editorContent}
                        channel={channel}
                        saveTemplate={saveTemplate}
                        saveButtonText={saveButtonText}
                        toggleUnsavedChanges={toggleUnsavedChanges}
                        openGallery={() => {
                          toggleWarningDialog("change");
                        }}
                        selectedCampaign={selectedCampaign}
                        selectedTemplate={template}
                        setError={setError}
                        renameTemplate={renameTemplate}
                        unsavedChanges={unsavedChanges}
                      />
                    </Suspense>
                  )}
                </Box>
              </Overlay>
              <CampaignWarningDialog
                isOpen={warningDialog == "save"}
                closeDialog={() => toggleWarningDialog(false)}
                title={COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_WARNING.TITLE}
                subtitle={COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_WARNING.SUBTITLE}
                primaryButton={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_WARNING.CLOSE_UNSAVED_BUTTON
                }
                secondaryButton={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.BACK_WARNING.GO_BACK_BUTTON
                }
                secondaryAction={() => toggleWarningDialog(false)}
                primaryAction={() => {
                  toggleWarningDialog(false);
                  if (template) {
                    closeEditor();
                  } else {
                    toggleUnsavedChanges(false);
                    setEditorContent();
                  }
                }}
                buttonWidth="large"
                id={`${QA_ID_PREFIX}-UnsavedChangesWarning`}
              />
              <CampaignWarningDialog
                isOpen={warningDialog == "change"}
                closeDialog={() => toggleWarningDialog(false)}
                title={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.CHANGE_TEMPLATE_WARNING.TITLE
                }
                subtitle={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.CHANGE_TEMPLATE_WARNING.SUBTITLE
                }
                primaryButton={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.CHANGE_TEMPLATE_WARNING
                    .CLOSE_UNSAVED_BUTTON
                }
                secondaryButton={
                  COPY.CAMPAIGN_EDIT.TEMPLATE.CHANGE_TEMPLATE_WARNING
                    .GO_BACK_BUTTON
                }
                secondaryAction={() => toggleWarningDialog(false)}
                primaryAction={() => {
                  setPreviousContent(getCurrentContent(editor));
                  setEditorContent(null);
                  toggleWarningDialog(false);
                }}
                id={`${QA_ID_PREFIX}-ChangeTemplateWarning`}
              />
            </>
          );
        }}
      </EditorState>
    </>
  );
}

export default TemplateOverlay;
