import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  DROPZONE_FILE_LIMIT,
  DROPZONE_SIZE_ERROR,
  DROPZONE_TYPE_ERROR,
  ERROR_MSG,
  EVENTS,
  EVENT_TYPES,
} from "../../consts/Common";
import FileList from "./FileList";

import {
  Box,
  Card,
  Grid,
  Typography,
  InputLabel,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ATTACHMENT } from "../../consts/DBFields";
import CampaignDownload from "../campaign/CampaignDownload";
import { trackEvent } from "../../utils/appInsight";

function FileUpload({
  id,
  label,
  maxFiles,
  accept,
  dropInstructions,
  fileInstructions,
  fileUploadStateRef,
  csvDataSourceHeaderError,
  csvDataSourceHeaderErrorMessage,
  sizeLimit,
  handleUpload,
  error = false, // a SAS upload error
  clearError,
  multiple,
  removable,
  removeFileAtIndex,
  dense,
  files,
  getTotalFileSize = () => {},
  downloadError,
  enableDownload = false,
  showLoader = false,
}) {
  const [isUploading, toggleIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [fileError, setFileError] = useState("");
  const [fileNames, setFileNames] = useState([]);

  useEffect(() => {
    updateFileNames(files);
  }, [files]);

  useEffect(() => {
    if(fileUploadStateRef) {
      fileUploadStateRef.current = setUploadComplete;
    }
  }, [])

  const updateFileNames = (files) => {
    if (files?.length) {
      setFileNames(
        files.map((file) => (file.name ? file.name : file[ATTACHMENT.NAME]))
      );
      if (uploadComplete || fileError || error) {
        toggleIsUploading(false);
      }
    } else {
      setFileNames([]);
    }
  };

  useEffect(() => {
    if (uploadComplete || fileError || error) {
      toggleIsUploading(false);
    }
  }, [uploadComplete, fileError, error]);

  const onDrop = useCallback(async (files, acceptedFiles) => {
    setUploadComplete(false);
    clearError();
    setFileError("");
    if (acceptedFiles && acceptedFiles.length) {
      toggleIsUploading(true);
      if (maxFiles && acceptedFiles.length + files?.length > maxFiles) {
        setFileError(ERROR_MSG.IMAGE_FILE_LIMIT);
        return;
      }
      if (multiple && sizeLimit) {
        try {
          let size = await getTotalFileSize();
          acceptedFiles?.map((file) => {
            size = size + file.size;
          });
          if (size > sizeLimit) {
            setFileError(ERROR_MSG.FILE_SIZE(sizeLimit / 1000000));
            return;
          }
        } catch (err) {
          setFileError(ERROR_MSG.FILES_UPLOAD);
        }
      }
      const isUploaded = await handleUpload(acceptedFiles, multiple ? files : undefined);
      if(!isUploaded) {
        toggleIsUploading(false);
        return;
      }
      updateFileNames([
        ...(acceptedFiles?.length ? acceptedFiles : []),
        ...(multiple && files?.length ? files : []),
      ]);
      if (!multiple) {
        setTimeout(() => {
          setUploadComplete(true);
        }, [350]);
      } else {
        setUploadComplete(true);
      }
      setFileError("");
    }
    trackEvent(EVENTS.USER_INTERACTION, null, EVENT_TYPES.USER_INTERACTION.CSV_UPLOAD)
  }, [handleUpload]);

  const onDropRejected = (events) => {
    let errors = [];
    events?.map((event) => {
      if (event.errors.length) {
        errors.push(...event.errors);
      }
    });
    const fileCountError = errors.find(
      (err) => err.code === DROPZONE_FILE_LIMIT
    );
    if (fileCountError) {
      setFileError(ERROR_MSG.IMAGE_FILE_LIMIT);
    } else {
      const fileSizeError = errors.find(
        (err) => err.code === DROPZONE_SIZE_ERROR
      );
      if (fileSizeError) {
        setFileError(ERROR_MSG.FILE_SIZE(sizeLimit / 1000000));
      } else {
        const fileTypeError = errors.find(
          (err) => err.code === DROPZONE_TYPE_ERROR
        );
        if (fileTypeError) {
          setFileError(fileTypeError.message);
        } else {
          setFileError("There was an error uploading your file.");
        }
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(files, acceptedFiles),
    onDropRejected,
    accept: accept,
    maxSize: sizeLimit,
    maxFiles: multiple ? maxFiles : 1,
    multiple: multiple,
    disabled: isUploading,
  });

  return (
    <>
      {label ? (
        <Box mb={1}>
          <InputLabel shrink id={`${id}-Label`}>
            {label}
          </InputLabel>
        </Box>
      ) : null}
      <Box
        style={{
          border: `solid ${files ? "2px black" : "1px #afafaf"}`,
          width: "60%",
          borderRadius: "24px",
          cursor: !showLoader && "pointer",
          opacity: showLoader ? 0.5 : 1,
          pointerEvents: showLoader && "none",
        }}
        id={`${id}-FileUpload-Box`}
      >
        <Box
          {...getRootProps()}
          p={2}
          boxSizing="border-box"
          minHeight={dense ? 125 : 250}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            opacity: isUploading ? 0.5 : 1,
            border: 0,
            height: 300,
          }}
        >
          <input {...getInputProps()} name={`${id}-FileUpload-Input`} role="File Upload"/>
          <section className="drop-zone" id={`${id}-FileUpload-DropZone`}>
            {isDragActive ? (
              <Typography>{`Drop the file${
                multiple ? "s" : ""
              } here...`}</Typography>
            ) : (
              <>
                <Box mb={1}>
                  <Typography
                    variant="h4"
                    align="center"
                    id={`${id}-FileUpload-Instructions`}
                  >
                    {dropInstructions}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  id={`${id}-FileUpload-FileSizeInfo`}
                >
                  {fileInstructions}
                </Typography>
              </>
            )}
          </section>
          {showLoader && (
            <Box position="absolute">
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        width="100%"
        mt={2}
        style={{
          opacity: showLoader ? 0.5 : 1,
          pointerEvents: showLoader ? "none" : "auto",
        }}
      >
        <Grid container alignItems="center">
          {
            <Grid item xs={12}>
              <Grid
                container
                alignItems="center"
                id={`${id}-FileUpload-FileName`}
              >
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                  >
                    {!error && files?.length && (!isUploading || multiple) ? (
                      <>
                        <FileList
                          label={`File${multiple ? "s" : ""}: `}
                          fileNames={fileNames}
                          removable={removable}
                          removeFileAtIndex={(index) => {
                            if (!isUploading) {
                              setUploadComplete(false);
                              removeFileAtIndex(index);
                            }
                          }}
                        />
                        {enableDownload ? <CampaignDownload /> : null}
                      </>
                    ) : null}
                  </Box>
                  <Typography
                    variant="h5"
                    align="center"
                    id={`${id}-FileUpload-ProgressMessage`}
                    color={error || fileError ? "error" : "textSecondary"}
                  >
                    {error
                      ? ERROR_MSG.FILE_UPLOAD
                      : !showLoader &&
                        (uploadComplete && files
                          ? "Upload Complete!"
                          : fileError)}
                  </Typography>
                  {downloadError ? (
                    <>
                      <Typography
                        variant="h5"
                        align="center"
                        id={`${id}-Download-ProgressMessage`}
                        color="error"
                      >
                        Download Failure
                      </Typography>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          }
        </Grid>
      </Box>
      {csvDataSourceHeaderError && (
        <Alert severity="error">{csvDataSourceHeaderErrorMessage}</Alert>
      )}
    </>
  );
}

export default FileUpload;
