import React from "react";

// MUI
import { Snackbar } from "@material-ui/core";
import { AppState } from "../../contexts/Core";
import { Alert } from "@material-ui/lab";
import { SNACK_TYPES } from "../../consts/Common";

const QA_ID_PREFIX = "SnackBar";

function SnackBar() {
  return (
    <AppState>
      {({
        snackMessage,
        clearSnackMessage,
        snackType,
        snackPosition,
        snackDuration,
        overriddenSnackCss,
      }) => {
        const { vertical = "bottom", horizontal = "center" } =
          snackPosition || {};
        return (
          <Snackbar
            anchorOrigin={{
              vertical,
              horizontal,
            }}
            open={snackMessage ? true : false}
            autoHideDuration={snackDuration}
            onClose={clearSnackMessage}
            id={`${QA_ID_PREFIX}-SnackBar`}
          >
            <Alert
              severity={snackType || SNACK_TYPES.INFO}
              id={`${QA_ID_PREFIX}-Alert`}
              style={overriddenSnackCss}
            >
              {snackMessage}
            </Alert>
          </Snackbar>
        );
      }}
    </AppState>
  );
}

export default SnackBar;
