import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const RenderReportStatsSkeleton = () => {
  return (
    <Grid container style={{ marginTop: 12, marginBottom: 20 }}>
      <Grid item xs={12} sm={12} md={2}>
        <Skeleton variant="rect" width={"100%"} height={118} />
      </Grid>
      <Grid item xs={12} sm={12} md={10}>
        <Skeleton variant="rect" width={"100%"} height={118} />
      </Grid>
    </Grid>
  );
};

const RenderOpenStatsSkeleton = () => {
  return (
    <Grid container style={{ marginTop: 12, marginBottom: 20 }} spacing={3}>
      <Grid item xs={12} sm={12} md={6}>
        <Skeleton variant="rect" width={"100%"} height={118} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Skeleton variant="rect" width={"100%"} height={118} />
      </Grid>
    </Grid>
  );
};

const ReportAnalyticsSkeleton = () => {
  return (
    <>
      <RenderReportStatsSkeleton />
      <RenderReportStatsSkeleton />
      <RenderOpenStatsSkeleton />
    </>
  );
};

export default ReportAnalyticsSkeleton;
