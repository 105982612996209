import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  Modal,
  IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { COPY } from "../consts/Copy";
import { HEX_COLORS } from "../consts/Colors";
import QA from "../consts/QA";

const useStyles = makeStyles(() => ({
  container: {
    height: "100vh",
    padding: "32px 8px",
    position: "absolute",
    top: 0,
    left: 0,
    paddingTop: 50,
    boxSizing: "border-box",
  },
  "& a": {
    color: HEX_COLORS.navy,
    textDecoration: "underline",
  },
}));

function ErrorPage({ error, clearError }) {
  const classes = useStyles();
  let history = useHistory();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  return (
    <>
      <Grid
        container
        className={classes.container}
        alignItems="center"
        id={QA.PAGES.ERROR}
      >
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid
              item
              md={error?.button ? 3 : 4}
              sm={error?.button ? 4 : 5}
              xs={error?.button ? 6 : 8}
            >
              <Box p={2} boxSizing="border-box">
                <img
                  src={
                    prefersDarkMode && error?.imageDark
                      ? error?.imageDark
                      : error?.image
                  }
                  alt={"error"}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Box mb={4}>
              <Typography variant="h1" align="center">
                {error?.title}
              </Typography>
            </Box>
            <Typography variant="body1" align="center">
              {error?.subtitle}
            </Typography>
          </Box>
        </Grid>
        <OnboardingStepsDialog />
        <Grid item xs={12}>
          <Box width="100%" display="flex" justifyContent="center">
            {error?.button ? (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => {
                  clearError && clearError();
                  if (error.button === COPY.ERROR_PAGE.RELOAD_BUTTON) {
                    window.location.reload();
                    return false;
                  } else {
                    history.push("/");
                  }
                }}
                id={`${QA.PAGES.ERROR}-Button`}
              >
                {error.button}
              </Button>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default ErrorPage;

const OnboardingStepsDialog = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    console.log('Location changed', location);
    if(location.hash === '#onboarding_steps') {
      setDialogOpen(true);
      // @TODO: remove #onboarding_steps from url
    }
  }, [location]);

  const handleClose = () => {
    setDialogOpen(false);
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: 800,
    bgcolor: 'background.paper',
    borderRadius: 10,
    boxShadow: 24,
  };
  const modalContentStyle = {
    p: 4,
  };
  const closeModalStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
  };

  return (
    <Modal
      open={dialogOpen}
      onClose={handleClose}
    >
      <Box sx={modalStyle}>
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          boxSizing="border-box"
          sx={closeModalStyle}
        >
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Box sx={modalContentStyle}>
          <Typography mt={0} mb={2} variant="h1" component="h2" style={{ marginBottom: 30 }}>
            Follow below onboarding steps:
          </Typography>
          <Typography component="ul" style={{ marginBottom: 20 }}>
            1. Fill up  <a target="_blank" href="https://jira.walmart.com/servicedesk/customer/portal/7384/user/login?destination=portal%2F7384%2Fcreate%2F15449"><u>Intake form</u></a>.
          </Typography>
          <Typography component="ul" style={{ marginBottom: 20 }}>
            2. Once intake request approved, raise a request for "<strong>spark-notifications-members</strong>" AD group access thorugh <a target="_blank" underline href="https://walmartglobal.service-now.com/wm_sp?id=sc_cat_item_guide&sys_id=b3234c3b4fab8700e4cd49cf0310c7d7"><u>Service-Now</u></a>. <br/><i>Please mention approved intake ticket number in description while creating ticket.</i> <br/><i>Select all the users as members that you want to have access for your team.</i>
          </Typography>
          <Typography component="ul" style={{ marginBottom: 20 }}>
            3. Once both steps are done, Spark Notifications team can help to create a team for you and you can add mentioned users in your team.
          </Typography>
        </Box>
      </Box>
    </Modal>
  )
}
