import { FOLDER } from "./DBFields";

export const ADD_MEMBER_COPY_PRIMARY = "Add member to";
export const ADD_MEMBER_COPY_SECONDARY = "You can always update this later.";

export const FOLDER_FIELDS = {
    [FOLDER.NAME]: {
      label: "Folder Name",
      helperText: "What is the name of this folder?",
      required: true,
      type: "text",
      max: 40,
      min: 3,
      createOnly: true,
    },
}

export const FOLDER_LEVEL_PERMISSIONS = {
    PUBLIC: 401,
    PRIVATE: 402,
}

export const FOLDER_LEVEL_ROLES = {
    OWNER: 1002,
    MEMBER_READ_ONLY: 1000,
    MEMBER_EDIT: 1001,
}

export const FOLDER_MESSAGES = {
    CREATE_FOLDER: {
        SUCCESS: "Folder Created Successfully",
        ERROR: "Something went wrong"
    }
}

