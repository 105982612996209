import React, { useState } from "react";
import { CampaignState } from "../../contexts/Campaign";
import TemplateThumbnail from "../template/TemplateThumbnail";
import TemplateOverlay from "../template/TemplateOverlay";
import { TEMPLATE } from "../../consts/DBFields";
import QA from "../../consts/QA";

// MUI
import { Box, Grid, TextField } from "@material-ui/core";
import CampaignEditReviewSection from "./CampaignEditReviewSection";
import { COPY } from "../../consts/Copy";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";
import { BUTTONS } from "../../consts/Template";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditTemplate`;

function CampaignEditTemplate({ isReview = false }) {
  const [templateOverlay, toggleTemplateOverlay] = useState(false);
  return (
    <>
      <CampaignState>
        {({
          selectedCampaign,
          selectedTemplate,
          channel,
          templatePreview,
          saveTemplate,
          setCampaignError,
        }) => {
          return (
            <>
              {!isReview ? (
                <>
                  <Box
                    boxSizing="border-box"
                    width="100%"
                    overflow="hidden"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    id={QA_ID_PREFIX}
                  >
                    <Grid container alignItems="stretch" justify="center">
                      <Grid item xs={11} sm={9} md={7}>
                        <Grid container justify="center">
                          <Grid item>
                            <TemplateThumbnail
                              id={`${QA_ID_PREFIX}-TemplateThumbnail`}
                              previewHTML={templatePreview}
                              onClick={() => toggleTemplateOverlay(true)}
                              buttonText={
                                !selectedTemplate
                                  ? COPY.CAMPAIGN_EDIT.TEMPLATE.SELECT_BUTTON[
                                      channel
                                    ]
                                  : COPY.CAMPAIGN_EDIT.TEMPLATE.EDIT_BUTTON[
                                      channel
                                    ]
                              }
                              channel={channel}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  {templateOverlay ? (
                    <TemplateOverlay
                      closeEditor={() => toggleTemplateOverlay(false)}
                      template={selectedTemplate}
                      channel={channel}
                      saveTemplate={saveTemplate}
                      saveButtonText={BUTTONS.SAVE}
                      selectedCampaign={selectedCampaign}
                      setError={setCampaignError}
                    />
                  ) : null}
                </>
              ) : (
                <CampaignEditReviewSection
                  reviewItems={
                    channel === NOTIFICATION_CHANNELS.mail.value
                      ? [
                          {
                            label: "Design",
                            value: templatePreview ? (
                              <TemplateThumbnail
                                previewHTML={templatePreview}
                                toScale
                                showDisclaimer
                                channel={channel}
                                id={`${QA_ID_PREFIX}-TemplateThumbnail-Review`}
                              />
                            ) : null,
                          },
                        ]
                      : [
                          {
                            label: "Content",
                            value: selectedTemplate?.[TEMPLATE.CONTENT] ? (
                              <Box width="100%" maxWidth={500}>
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  value={selectedTemplate[TEMPLATE.CONTENT]}
                                  disabled
                                  fullWidth
                                  multiline
                                />
                              </Box>
                            ) : null,
                          },
                        ]
                  }
                  inline={
                    !selectedTemplate?.[TEMPLATE.CONTENT] ||
                    (channel === NOTIFICATION_CHANNELS.mail.value &&
                      !templatePreview)
                  }
                  id={`${QA_ID_PREFIX}-ReviewSection`}
                />
              )}
            </>
          );
        }}
      </CampaignState>
    </>
  );
}

export default CampaignEditTemplate;
