import React from "react";

// MUI
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Grow,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

function CampaignWarningDialog({
  isOpen,
  closeDialog,
  title,
  subtitle,
  secondaryButton,
  primaryButton,
  primaryAction,
  secondaryAction,
  buttonWidth,
  image,
  canClose = true,
  loading = false,
  id,
  dialogProps = {},
  isPrimaryBtnDisabled = false
}) {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <Dialog
        disableBackdropClick
        open={isOpen}
        fullWidth
        maxWidth="sm"
        id={id}
        {...dialogProps}
      >
        <DialogContent>
          {canClose ? (
            <Box
              width="100%"
              display="flex"
              justifyContent="flex-end"
              boxSizing="border-box"
              id={`${id}-CloseIconButton`}
            >
              <IconButton onClick={closeDialog}>
                <Close />
              </IconButton>
            </Box>
          ) : null}
          <Box
            m="auto"
            minHeight={200}
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            mb={image || xsScreen ? 2 : 0}
            mt={canClose ? 0 : 2}
          >
            {!loading ? (
              <>
                {image ? (
                  <Grow in>
                    <Box
                      width="100%"
                      display="flex"
                      justifyContent="center"
                      id={`${id}-image`}
                    >
                      {image}
                    </Box>
                  </Grow>
                ) : null}
                <Box>
                  <Box maxWidth={400}>
                    <Grow in>
                      <Typography
                        variant="h3"
                        align="center"
                        id={`${id}-title`}
                      >
                        {title}
                      </Typography>
                    </Grow>
                  </Box>
                  <Box my={1}>
                    <Grow in>
                      <Typography
                        variant="body1"
                        align="center"
                        color="secondary"
                        id={`${id}-subtitle`}
                      >
                        <u>{subtitle}</u>
                      </Typography>
                    </Grow>
                  </Box>
                </Box>
                <Grid container justify="center" direction="row-reverse">
                  {primaryButton ? (
                    <Grid item sm={buttonWidth === "large" ? 5 : 4} xs={12}>
                      <Button
                        variant={secondaryButton ? "contained" : "outlined"}
                        color={secondaryButton ? "secondary" : "primary"}
                        size="large"
                        onClick={primaryAction}
                        fullWidth
                        id={`${id}-PrimaryButton`}
                        disabled={isPrimaryBtnDisabled}
                      >
                        <strong>{primaryButton}</strong>
                      </Button>
                    </Grid>
                  ) : null}
                  {secondaryButton ? (
                    <Grid item sm={buttonWidth === "large" ? 5 : 4} xs={12}>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={secondaryAction}
                        fullWidth
                        id={`${id}-SecondaryButton`}
                      >
                        {secondaryButton}
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : (
              <Box
                width="100%"
                height="100%"
                minHeight={200}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress fontSize="large" id={`${id}-LoadingIcon`} />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CampaignWarningDialog;
