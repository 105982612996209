import React, { useEffect, useState } from "react";

// MUI
import {
  Box,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { validateStringLength } from "../../utils/campaign";

function TextInputField({
  value,
  handleChange,
  field,
  componentKey,
  id,
  ...props
}) {
  const [charCountError, toggleCharCountError] = useState(false);
  useEffect(() => {
    toggleCharCountError(!validateStringLength(value, field.max));
  }, [value]);
  return (
    <Box my={1}>
      <TextField
        name={`${id}-TextInputField`}
        key={componentKey}
        multiline={field.multiline}
        onChange={(e) =>
          handleChange(
            e.target.value,
            !validateStringLength(e.target.value, field.max)
          )
        }
        disabled={field.disabled}
        error={charCountError}
        helperText={charCountError ? field.errorText : ""}
        variant="outlined"
        fullWidth
        value={value}
        label={field.label}
        placeholder={field.placeholder}
        required={field.required}
        InputProps={{
          startAdornment: (
            <Box mr={1} display="flex" alignItems="center">
              {field.tooltip ? (
                <Tooltip
                  title={field.tooltip?.title}
                  disableHoverListener={
                    field.tooltip?.disabled || !field.tooltip?.title
                  }
                  id={`${id}-TextInputField-Tooltip`}
                >
                  {field.tooltip?.icon}
                </Tooltip>
              ) : null}
            </Box>
          ),
          endAdornment: (
            <>
              {field.max ? (
                <InputAdornment position="end">
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    id={`${id}-TextInputField-CharCounter`}
                  >
                    {value ? value.length : 0}/{field.max}
                  </Typography>
                </InputAdornment>
              ) : null}
            </>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          "aria-label": "Input Field"
        }}
        {...props}
      />
    </Box>
  );
}

export default TextInputField;
