import {
  Box,
  Tooltip,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";

function VariableToggle({
  tooltipProps,
  id,
  checked,
  handleChange,
  label = "Use a Personalized Data Value",
}) {
  return (
    <Box ml={1} flex={1} display="flex" justifyContent="flex-end">
      <Tooltip {...tooltipProps} id={id}>
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              color="primary"
              id={`${id}-Switch`}
            />
          }
          label={
            <Typography variant="h6" color="textSecondary">
              {label}
            </Typography>
          }
        />
      </Tooltip>
    </Box>
  );
}

export default VariableToggle;
