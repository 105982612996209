import {
  Box,
  Grid,
  makeStyles,
  Slider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { HEX_COLORS } from "../../../../consts/Colors";
import {
  getComponentDimension,
  setComponentBorderRadius,
} from "../grapesjs/settingsUtil";
import { getElementDOMEl } from "../grapesjs/util";
import QA from "../../../../consts/QA";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-RoundedCorners`;

const useStyle = makeStyles({
  flex1: {
    display: "flex",
    gap: "20px",
  }
});

function RoundedCorners({ element, setting, componentSettings }) {
  const classes = useStyle();

  const [radius, setRadius] = useState(0);
  const [height, setHeight] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const maxRadius = 50;

  useEffect(() => {
    const elementHeight = getHeightValue();
    setHeight(elementHeight);
    const br = componentSettings?.[setting.ID];
    setRadius(br || 0);
    if (!percentage) {
      setPercentage(elementHeight && br ? br / elementHeight : 0);
    }
  }, [componentSettings]);

  useEffect(() => {
    if (radius && percentage) {
      const updatedValue = height * percentage;
      setComponentBorderRadius(element, updatedValue);
    }
  }, [height]);

  const getHeightValue = () => {
    let val = componentSettings?.height;
    if (!val) {
      val = getComponentDimension(element, "px", "height");
    }
    if (!val) {
      val = getElementDOMEl(element)?.offsetHeight;
    }
    return val;
  };

  return (
    <>
      <Box px={1} mt={1}>
        <Box className={classes.flex1}>
          <Slider
            value={radius}
            onChange={(e, newValue) => {
              setComponentBorderRadius(element, newValue);
              setPercentage(newValue && height ? newValue / height : 0);
            }}
            min={0}
            max={maxRadius}
            id={`${QA_ID_PREFIX}-Slider`}
          />
          {`${Math.round(radius)}px`}
        </Box>
      </Box>
    </>
  );
}

export default RoundedCorners;

const CustomTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 400,
  },
}))(Tooltip);

export const RoundedCornersTooltip = () => {
  return (
    <CustomTooltip
      id={`${QA_ID_PREFIX}-Tooltip`}
      arrow
      title={
        <Box p={2}>
          <Typography variant="h6">
            Heads up! Rounded corners may display differently on some of your
            recipients devices. For example:
          </Typography>
          <Box mt={1.5} mb={0.5}>
            <Grid container justify="space-between" spacing={4}>
              {["Mac", "Windows"].map((device) => {
                return (
                  <Grid item xs={6} key={device}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Typography align="center" variant="h6">
                        <strong>{`${device} recipients see`}</strong>
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        bgcolor={HEX_COLORS.lightestGray}
                        py={1}
                        borderRadius={device === "Mac" ? 24 : 0}
                        display="flex"
                        justifyContent="center"
                        mt={1}
                        color={HEX_COLORS.darkGray}
                        fontSize="14px"
                      >
                        Button
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      }
    >
      <Box display="flex">
        Shape{" "}
        <Box ml={1}>
          <InfoOutlined fontSize="small" />
        </Box>
      </Box>
    </CustomTooltip>
  );
};
