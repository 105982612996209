import { isEqual } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { deleteTeamTemplate, getTeamTemplates } from "../APIs/TemplatesAPI";
import { NOTIFICATION_CHANNELS } from "../consts/Campaign";
import { SORTING_OPTIONS } from "../consts/CampaignTable";
import { ERROR_MSG, PAGES, SNACK_TYPES, SUCCESS_MSG } from "../consts/Common";
import { REQUEST_PARAMS, TEAM, TEMPLATE } from "../consts/DBFields";
import { getErrorType } from "../utils/common";
import { useAppContext } from "./Core";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const TemplatesListState = Context.Consumer;
export const useTemplatesContext = () => useContext(Context);

// Create and export the provider, which defines and controls the state
function TemplatesList({ children }) {
  const {
    selectedTeam,
    setAppError,
    setSnackMessage,
    setSnackType,
    activePage,
  } = useAppContext();
  const [teamTemplates, setTeamTemplates] = useState([]);
  const [filteredTeamTemplates, setFilteredTeamTemplates] = useState([]);
  const [loadingTemplates, toggleLoadingTemplates] = useState(true);
  const [sortingTemplates, toggleSortingTemplates] = useState(false);
  const [sortBy, setSortBy] = useState(TEMPLATE.DATE_CREATED);
  const [sortOrder, setSortOrder] = useState(REQUEST_PARAMS.DESCENDING);
  const [tablePage, setTablePage] = useState(0);
  const [templateSearchCriteria, setTemplateSearchCriteria] = useState();

  useEffect(() => {
    if (selectedTeam) {
      getSavedTemplates(selectedTeam[TEAM.ID], {
        [REQUEST_PARAMS.SORT_BY]: TEMPLATE.DATE_UPDATED,
        [REQUEST_PARAMS.SORT_ORDER]: "desc",
      });
    }
  }, [selectedTeam]);

  useEffect(() => {
    if (!isEqual(teamTemplates, filteredTeamTemplates)) {
      setFilteredTeamTemplates(teamTemplates);
    }
  }, [teamTemplates]);

  useEffect(() => {
    if (
      selectedTeam &&
      activePage === PAGES.TEMPLATE.value &&
      teamTemplates.length &&
      teamTemplates[0][TEMPLATE.TEAM_ID] !== selectedTeam[TEAM.ID]
    ) {
      getSavedTemplates(selectedTeam[TEAM.ID], {
        [REQUEST_PARAMS.SORT_BY]: TEMPLATE.DATE_UPDATED,
        [REQUEST_PARAMS.SORT_ORDER]: "desc",
      });
    }
  }, [activePage]);

  useEffect(() => {
    handleTemplateSearch();
  }, [templateSearchCriteria]);

  const getSavedTemplates = async (teamId, params) => {
    if (teamId) {
      toggleLoadingTemplates(true);
      try {
        let savedTemplates = await getTeamTemplates(teamId, params);
        savedTemplates =
          savedTemplates?.filter(
            (template) =>
              template[TEMPLATE.TYPE] === NOTIFICATION_CHANNELS.mail.value
          ) || [];
        setTeamTemplates(savedTemplates);
        toggleLoadingTemplates(false);
        return savedTemplates;
      } catch (err) {
        setAppError(getErrorType(err));
        toggleLoadingTemplates(false);
      }
    }
  };

  const deleteTemplate = async (templateId) => {
    try {
      await deleteTeamTemplate(selectedTeam?.[TEAM.ID], templateId);
      const updatedList = await filteredTeamTemplates.filter(
        (template) => template[TEMPLATE.ID] !== templateId
      );
      setFilteredTeamTemplates(updatedList);
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.DELETE_SAVED_TEMPLATE);
    } catch {
      setSnackType(SNACK_TYPES.ERROR);
      setSnackMessage(ERROR_MSG.DELETE_SAVED_TEMPLATE);
    }
  };

  const updateTemplateSort = async (sortOption) => {
    toggleSortingTemplates(true);
    const params = SORTING_OPTIONS[sortOption]?.params;
    const updatedSortBy = params[REQUEST_PARAMS.SORT_BY];
    const updatedSortOrder = params[REQUEST_PARAMS.SORT_ORDER];
    setSortBy(updatedSortBy);
    setSortOrder(updatedSortOrder);
    if (updatedSortBy !== sortBy) {
      await getSavedTemplates(selectedTeam[TEAM.ID], params);
    } else if (updatedSortOrder !== sortOrder) {
      let reverseAll = [...teamTemplates].reverse();
      let reverse = [...filteredTeamTemplates].reverse();
      setTeamTemplates(reverseAll);
      setFilteredTeamTemplates(reverse);
    }
    toggleSortingTemplates(false);
  };

  const handleTemplateSearch = () => {
    setTablePage(0);
    if (templateSearchCriteria?.length >= 3) {
      const searchCriteria = templateSearchCriteria?.toLowerCase();
      const filtered = teamTemplates?.filter((template) => {
        const templateString = template[TEMPLATE.NAME]?.toLowerCase() || "";
        if (templateString.includes(searchCriteria)) {
          return template;
        }
      });
      setFilteredTeamTemplates(filtered);
    } else {
      setFilteredTeamTemplates(teamTemplates);
    }
  };

  // create one state object to pass to the Provider
  const initialState = {
    loadingTemplates,
    teamTemplates,
    filteredTeamTemplates,
    currentSort: Object.keys(SORTING_OPTIONS).find(
      (option) =>
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_BY] === sortBy &&
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_ORDER] === sortOrder
    ),
    updateTemplateSort,
    sortingTemplates,
    templateSearchCriteria,
    setTemplateSearchCriteria,
    tablePage,
    setTablePage,
    deleteTemplate,
    getSavedTemplates,
    setTeamTemplates,
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default TemplatesList;
