import { URLS, getAPIEndPoint } from "../consts/APIEndpoints";
import { determineBucketSize } from "../utils/reports";
import { getResponse } from "../utils/common";

// returns an array of campaigns
export async function getCampaigns(teamId, folderId, params = {}) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}`,
    "get",
    undefined,
    {
      limit: 1000,
      team_id: teamId,
      folder_id: folderId,
      sort_by: "date_created",
      sort_order: "desc",
      ...params,
    }
  );
  return response;
}

//return folders for a team
export async function getFolders(teamId, params = {}) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.TEAMS_API)}/${teamId}/folders`,
    "get",
    undefined,
    {
      offset: 0,
      limit: 100,
      team_id: teamId,
      sort_by: "createdAt",
      sort_order: "desc",
      ...params,
    }
  );
  return response;
}

// returns a campaign object
export async function getCampaign(uid, params = {}) {
  return await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${uid}`,
    "get",
    undefined,
    { embed: "template", ...params }
  );
}

export async function getTemplate(campaignId, type, embedHtml) {
  if (!campaignId) {
    return null;
  }
  let response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/templates/${type}`,
    "get",
    undefined,
    embedHtml ? { embed: "html" } : undefined
  );
  return response;
}

// returns an array of data source headings
export async function getCampaignMetaData(uid, params = {}) {
  return await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${uid}/metadata`,
    "get",
    undefined,
    params
  );
}

// returns a campaign uid
export async function postCampaign(campaign, params = {}) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}`,
    "post",
    campaign,
    params
  );
  let campaignId = response?.split("campaignId : "); //get the campaign id from the response message
  campaignId = campaignId?.length ? campaignId[campaignId.length - 1] : "";
  return campaignId;
}

export async function updateCampaign(campaign) {
  return await getResponse(
    getAPIEndPoint(URLS.CAMPAIGNS_API) + "/" + campaign.uid,
    "put",
    campaign
  );
}

export async function updateTemplate(template, campaignId, type) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/templates/${type}`,
    "put",
    template
  );
  return response;
}

export async function previewTemplate(campaignId, variables, options) {
  const requestBody = {
    variables: variables,
    options: {
      strict: false,
      ...options,
    },
  };
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/preview`,
    "post",
    requestBody
  );
  return response?.markup;
}

export async function executeCampaign(campaignId) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/executions`,
    "post"
  );
  return response;
}

export async function executeCampaignV2(campaignId) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API_V2)}/${campaignId}/executions`,
    "post"
  );
  return response;
}



export async function testCampaign(campaignId, params) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/test`,
    "post",
    params
  );
  return response;
}


export async function deleteCampaign(campaignId) {
  return await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}`,
    "delete"
  );
}

export async function getCampaignStats(
  campaignId,
  embedAdvancedMetrics,
  campaignExecutionsDetails
) {
  const response = await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/stats`,
    "get",
    undefined,
    {
      embed: embedAdvancedMetrics ? "trackViewAdvancedMetrics" : undefined,
      campaignExecutionsDetails: campaignExecutionsDetails ? true : undefined,
    }
  );
  return response;
}

export async function getStatsForCampaignIds(campaignIds, teamId) {
  const response = await getResponse(
    `${getAPIEndPoint()}campaigns/stats`,
    "post",
    { campaignIds, teamId }
  );

  return response;
}

export async function getCampaignEvents(campaignId, startDate, endDate) {
  const aggregateBy = await determineBucketSize(startDate, endDate);
  const response = await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/events?type=view,click&start_date=${startDate}&end_date=${endDate}&aggregate_by=${aggregateBy}`,
    "get"
  );
  response.aggregateBy = aggregateBy;
  return response;
}

export async function getCampaignCustomReports(
  startDate,
  endDate,
  aggregate_by,
  filters
) {
  const response = await getResponse(
    `${getAPIEndPoint()}reports/custom?filters=${filters}&aggregate_by=${aggregate_by}&start_date=${startDate}&end_date=${endDate}`,
    "get"
  );
  return response;
}

export const getCampaignFailures = async (campaignId, from = 0) => {
  const response = await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=failures&from=${from}`,
    "get"
  );
  return response;
};

export const getCampaignSent = async (campaignId, from = 0) => {
  const response = await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=sent&from=${from}`,
    "get"
  );
  return response;
};

export const getCampaignUnsubscribe = async (campaignId, from = 0) => {
  const response = await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=unsubscribed&from=${from}`,
    "get"
  );
  return response;
};

export const getNotSent = async (campaignId, from) => {
  const response = await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=not_sent&from=${from}`,
    "get"
  );
  return response;
}

export const getUniqueViews = async (campaignId, from = null) => {
  return await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=view&from=${from}`,
    "get"
  );
}

export const getUniqueClicks = async (campaignId, from = null) => {
  return await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=click&from=${from}`,
    "get"
  );
}

export const getSentRecipients = async (campaignId, from = null) => {
  return await getResponse(
    `${getAPIEndPoint(
      URLS.CAMPAIGNS_API
    )}/${campaignId}/stats?embed=sent_recipients&from=${from}`,
    "get"
  );
}

// returns campaign_execution data
export async function getCampaignExecutionsDetail(campaignId, params = {}) {
  return await getResponse(
    `${getAPIEndPoint(URLS.CAMPAIGNS_API)}/${campaignId}/executions`,
    "get",
    undefined,
    {
      ...params,
    }
  );
}
