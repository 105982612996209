import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HEX_COLORS } from "../../../../consts/Colors";
import QA from "../../../../consts/QA";
import { EditorState } from "../../../../contexts/Editor";
import { COMMANDS } from "../grapesjs/consts/Commands";
import imageIcon from "../../../../assets/icons/emailEditorImageIcon.svg";
import { getImageNameFromSrc } from "../grapesjs/util";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-ImageReplace`;

const THUMBNAIL_SIZE = 80;

const useStyles = makeStyles(() => ({
  imageName: {
    wordBreak: "break-all",
  },
  button: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
}));

function ImageReplace({ setting, componentSettings }) {
  const classes = useStyles();
  const [src, setSrc] = useState("");

  useEffect(() => {
    let imageSrc = componentSettings?.[setting?.ID] || "";
    if (imageSrc !== imageIcon && typeof imageSrc === "string") {
      setSrc(imageSrc);
    } else {
      setSrc("");
    }
  }, [componentSettings]);

  return (
    <Box id={QA_ID_PREFIX} display="flex">
      <EditorState>
        {({ editor, imageGalleries }) => {
          return (
            <Button
              fullWidth
              className={classes.button}
              onClick={() => {
                editor.runCommand(COMMANDS.OPEN_IMAGE_GALLERY);
              }}
              id={`${QA_ID_PREFIX}-Button`}
            >
              <Box
                maxWidth={THUMBNAIL_SIZE}
                minWidth={THUMBNAIL_SIZE}
                minHeight={THUMBNAIL_SIZE}
                overflow="hidden"
                mr={1}
                boxSizing="border-box"
                border={`solid 1px ${HEX_COLORS.lightGray}`}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="#dfdfdf"
              >
                <img
                  src={src ? src : imageIcon}
                  id={`${QA_ID_PREFIX}-Thumbnail`}
                  alt=""
                />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                px={1}
              >
                {src ? (
                  <Box boxSizing="border-box" mb={1}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.imageName}
                      align="left"
                      id={`${QA_ID_PREFIX}-Name`}
                    >
                      {getImageNameFromSrc(src, imageGalleries?.[0])}
                    </Typography>
                  </Box>
                ) : null}
                <Typography
                  variant="button"
                  align="left"
                  id={`${QA_ID_PREFIX}-Text`}
                >
                  <u>
                    <u>{src ? "Replace" : "Select an image"}</u>
                  </u>
                </Typography>
              </Box>
            </Button>
          );
        }}
      </EditorState>
    </Box>
  );
}

export default ImageReplace;
