import React, { createContext, useContext, useEffect, useState } from "react";
import { FOLDER, TEAM } from "../consts/DBFields";
import { getErrorType } from "../utils/common";
import { useAppContext } from "./Core";
import { getFolders } from "../APIs/CampaignAPI";
import { deleteFolderAPI } from "../APIs/FoldersAPI";
import _ from "lodash";
import { SNACK_TYPES } from "../consts/Common";
import { useLocation } from "react-router-dom";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const FoldersListState = Context.Consumer;
export const useFoldersContext = () => useContext(Context);

// Create and export the provider, which defines and controls the state
const FoldersList = ({ children }) => {
  const {
    activePage,
    selectedTeam,
    setAppError,
    folder_id,
    setSnackType,
    setSnackMessage,
  } = useAppContext();
  const location = useLocation();
  const [teamFolders, setTeamFolders] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState(undefined);
  const [loadingFolders, toggleLoadingFolders] = useState(true);
  const [showFolderSettingsOverlay, setShowFolderSettingsOverlay] = useState(false);
  const [currentFolderSettings, setCurrentFolderSettings] = useState({});
  const [currentFolder, setCurrentFolder] = useState(null);

  useEffect(() => {
    return () => {
      closeFolderSettings();
    }
  }, []);

  useEffect(() => {
    // Clear the current folder state
    if(["/folders", "/", "/report", "/templates", "/folders/media", "/help"].includes(location?.pathname)) {
      setCurrentFolder(null);
    }
  }, [location?.pathname]);

  useEffect(() => {
    const handleChange = async () => {
      toggleLoadingFolders(true);
      await fetchFoldersForTeam();
    };
    handleChange();
  }, [selectedTeam[TEAM.ID], activePage]);

  const fetchFoldersForTeam = async (params) => {
    setSearchPhrase("");
    if (selectedTeam && selectedTeam[TEAM.ID]) {
      try {
        const folders = await getFolders(selectedTeam[TEAM.ID], params);
        setTeamFolders(folders || []);
        toggleLoadingFolders(false);
        return folders;
      } catch (err) {
        toggleLoadingFolders(false);
        setAppError(getErrorType(err));
      }
    }
  };

  const closeFolderSettings = () => {
    setCurrentFolderSettings({});
    setShowFolderSettingsOverlay(false);
  }

  const openFolderSettings = (folderObj) => {
    setCurrentFolderSettings(_.cloneDeep(folderObj));
    setShowFolderSettingsOverlay(true);
  }

  const updateFolderSettingsCallback = (updatedFolderObj = {}) => {
    const index = teamFolders.findIndex(folder => folder[FOLDER.FOLDER_ID] === updatedFolderObj[FOLDER.FOLDER_ID]);
    teamFolders[index] = _.cloneDeep(updatedFolderObj);
    setTeamFolders([...teamFolders]);
  }

  const selectCurrentFolder = () => {
    return getFolderById(folder_id);
  }

  const getFolderById = (folder_id) => {
    const folder = teamFolders.find(folder => folder[FOLDER.FOLDER_ID] === folder_id);
    return folder;
  }

  const deleteFolder = async (folderId) => {
    try{
      const response = await deleteFolderAPI(folderId);
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage("Folder deleted successfully");
      await fetchFoldersForTeam();
      return response;
    } catch (error) {
      setSnackType(SNACK_TYPES.ERROR);
      setSnackMessage("Error occured while deleting folder details");
    }
  };

  // create one state object to pass to the Provider
  const initialState = {
    teamFolders,
    loadingFolders,
    closeFolderSettings,
    openFolderSettings,
    showFolderSettingsOverlay,
    currentFolderSettings,
    updateFolderSettingsCallback,
    selectCurrentFolder,
    fetchFoldersForTeam,
    deleteFolder,
    getFolderById,
    currentFolder,
    setCurrentFolder,
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default FoldersList;
