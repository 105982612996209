export const CIP_FIELDS = {
  EMAIL: { variable: "Email", label: "Associate Email" },
  PREFERRED_NAME: {
    variable: "PreferredName",
    label: "Associate Preferred Name",
  },
  FIRST_NAME: { variable: "FirstName", label: "Associate First Name" },
  LAST_NAME: { variable: "LastName", label: "Associate Last Name" },
  MANAGER_NAME: {
    variable: "ManagerPreferredName",
    label: "Manager Preferred Name",
  },
  UPN: { variable: "UPN", label: "UPN" },
};
