import React, { useState, useEffect } from "react";
import {
  Box,
  FormControlLabel,
  Typography,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { getColumnCount, setComponentStacking } from "../grapesjs/settingsUtil";

function ColumnStacking({ element, setting, componentSettings }) {
  const { defaultValue } = setting.INPUTS.STACKABLE;
  const [stackable, toggleStack] = useState(defaultValue);
  const [multiColumn, toggleMultiColumn] = useState(false);

  useEffect(() => {
    toggleMultiColumn(getColumnCount(element) > 1 ? true : false);
  }, []);

  useEffect(() => {
    if (multiColumn) {
      toggleStack(
        componentSettings?.hasOwnProperty(setting?.ID)
          ? componentSettings[setting?.ID]
          : defaultValue
      );
    }
  }, [multiColumn, componentSettings]);

  if (multiColumn) {
    return (
      <>
        <Typography variant="body2" color="textSecondary">
          <strong>Mobile Settings</strong>
        </Typography>
        <Box mt={1}>
          <Tooltip
            arrow
            title={
              <Typography variant="h6">
                When enabled, columns will stack on top of each other for mobile
                screens. Click the mobile icon at the top to preview.
              </Typography>
            }
          >
            <FormControlLabel
              control={
                <Switch
                  checked={stackable}
                  onChange={() => {
                    setComponentStacking(element, !stackable);
                  }}
                  color="primary"
                  id="column-stack"
                />
              }
              label={
                <Typography variant="h6" color="textSecondary">
                  Allow columns to stack in mobile
                </Typography>
              }
            />
          </Tooltip>
        </Box>
      </>
    );
  } else {
    return null;
  }
}

export default ColumnStacking;
