import { MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { generateVariableList } from "../../../../utils/campaign";

function VariableList({
  value, // a string representing the variable name of the selected variable (ex: "button_text")
  variables = [],
  handleChange,
  id,
  disabled,
}) {
  const [variableList, setVariableList] = useState([]);

  useEffect(() => {
    setVariableList(generateVariableList(variables));
  }, [variables]);

  return (
    <Select
      value={value}
      onChange={(e) => {
        let match = variableList.find(
          (listItem) => listItem.variable === e.target.value
        );
        handleChange(match || {});
      }}
      fullWidth
      variant="outlined"
      margin="dense"
      id={`${id}-Select`}
      disabled={disabled}
    >
      <MenuItem value={" "} disabled id={`${id}-MenuItem`}>
        Choose a variable
      </MenuItem>
      {variableList.map((listItem) => {
        return (
          <MenuItem
            value={listItem.variable || listItem.name}
            key={listItem.value || listItem.name}
            id={`${id}-MenuItem-${listItem.value || listItem.name}`}
            disabled={listItem.value ? false : true}
          >
            {listItem.value ? listItem.value : listItem.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default VariableList;
