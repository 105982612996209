export const TYPES = {
  SECTION: "mj-section",
  COLUMN: "mj-column",
  BODY: "mj-body",
  SPACER: "mj-spacer",
  DIVIDER: "mj-divider",
  TEXT: "mj-text",
  BUTTON: "mj-button",
  IMAGE: "mj-image",
  SOCIAL_MENU: "mj-social",
  SOCIAL_ICON: "mj-social-element",
  HEAD: "mj-head",
  STYLE: "mj-style",
  GROUP: "mj-group",
  MUSTACHE_SECTION: "mj-mustache-section",
};

export const dynamicToolbar = [TYPES.SECTION, TYPES.MUSTACHE_SECTION];

export const dynamicToolbarEnabled = [TYPES.MUSTACHE_SECTION];

export const noClone = [TYPES.BODY, TYPES.COLUMN];

export const noDelete = [TYPES.BODY];

export const noMove = [TYPES.BODY];

export const BLOCKS = {
  COL_1: "mj-1-column",
  COL_2: "mj-2-columns",
  COL_3: "mj-3-columns",
  COL_4: "mj-4-columns",
  COL_33_66: "mj-2-columns-33-66",
  COL_66_33: "mj-2-columns-66-33",
  COL_25_75: "mj-2-columns-25-75",
  COL_75_25: "mj-2-columns-75-25",
  SPACER: TYPES.SPACER,
  DIVIDER: TYPES.DIVIDER,
  TEXT: TYPES.TEXT,
  BUTTON: TYPES.BUTTON,
  IMAGE: TYPES.IMAGE,
  VIDEO: "embedded-video",
  SOCIAL_GROUP: "mj-social-group",
};

export const blockTabs = {
  Content: {
    value: 0,
    label: "Content",
    blocks: [
      BLOCKS.SPACER,
      BLOCKS.DIVIDER,
      BLOCKS.TEXT,
      BLOCKS.BUTTON,
      BLOCKS.IMAGE,
      BLOCKS.SOCIAL_GROUP,
    ],
  },
  Layout: {
    value: 1,
    label: "Layout",
    blocks: [BLOCKS.COL_1,BLOCKS.COL_33_66, BLOCKS.COL_66_33, BLOCKS.COL_2, BLOCKS.COL_25_75,  BLOCKS.COL_75_25, BLOCKS.COL_3, BLOCKS.COL_4],
  },
};

export const customBlockMedia = {
  COL_1: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="4" y="4" width="48" height="22" rx="1"  stroke-width="2.5"/>
  </svg>`,
  COL_2: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="2" y="4" width="23" height="22" rx="1"  stroke-width="2.5"/>
  <rect fill="none" x="31" y="4" width="23" height="22" rx="1"  stroke-width="2.5"/>
  </svg>`,
  COL_3: `<svg fill="none" height="30" viewBox="0 0 56 30"  xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="2" y="4" width="14.6667" height="22" rx="1" stroke-width="2.5"  />
  <rect fill="none" x="20.6699" y="4" width="14.6667" height="22" rx="1" stroke-width="2.5"  />
  <rect fill="none" x="39.3398" y="4" width="14.6667" height="22" rx="1" stroke-width="2.5"/>
  </svg>`,
  COL_4: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="2" y="4" width="8.5" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="16" y="4" width="8.5" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="31" y="4" width="8.5" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="45" y="4" width="8.5" height="22" rx="1" stroke-width="2.5"/>
  </svg>`,
  COL_33_66: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="22" y="4" width="31.33" height="22" rx="1" stroke-width="2.5" />
  <rect fill="none" x="2.6667" y="4" width="14.6667" height="22" rx="1"  stroke-width="2.5"/>
  </svg>`,
  COL_66_33: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="3" y="4" width="31.33" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="39" y="4" width="14.6667" height="22" rx="1" stroke-width="2.5"/>
  </svg>`,
  COL_25_75: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="19" y="4" width="34" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="4" y="4" width="10" height="22" rx="1" stroke-width="2.5"/>
  </svg>`,
  COL_75_25: `<svg fill="none" height="30" viewBox="0 0 56 30" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <rect fill="none" x="4" y="4" width="34" height="22" rx="1" stroke-width="2.5"/>
  <rect fill="none" x="43" y="4" width="10" height="22" rx="1" stroke-width="2.5"/>
  </svg>
  `,
  DIVIDER: `<svg height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <path fill="none" d="M3 12H3.01M7.5 12H7.51M16.5 12H16.51M12 12H12.01M21 12H21.01M21 21V20.2C21 19.0799 21 18.5198 20.782 18.092C20.5903 17.7157 20.2843 17.4097 19.908 17.218C19.4802 17 18.9201 17 17.8 17H6.2C5.0799 17 4.51984 17 4.09202 17.218C3.7157 17.4097 3.40973 17.7157 3.21799 18.092C3 18.5198 3 19.0799 3 20.2V21M21 3V3.8C21 4.9201 21 5.48016 20.782 5.90798C20.5903 6.28431 20.2843 6.59027 19.908 6.78201C19.4802 7 18.9201 7 17.8 7H6.2C5.0799 7 4.51984 7 4.09202 6.78201C3.71569 6.59027 3.40973 6.28431 3.21799 5.90798C3 5.48016 3 4.92011 3 3.8V3"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  TEXT: `<svg height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="Block Icon">
  <path fill="none" d="M12 3V21M9 21H15M19 6V3H5V6"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  BUTTON: `<svg xmlns="http://www.w3.org/2000/svg" height="40px" viewBox="0 -960 960 960" width="40px" aria-label="Block Icon"><path d="M200-280q-33 0-56.5-23.5T120-360v-240q0-33 23.5-56.5T200-680h560q33 0 56.5 23.5T840-600v240q0 33-23.5 56.5T760-280H200Zm0-80h560v-240H200v240Zm0 0v-240 240Z"/></svg>`,
};

export const sectionStyle = `padding-top="3px" padding-bottom="3px" padding-left="3px" padding-right="3px"`;
export const textStyle = `padding-top="5px" padding-bottom="5px" padding-left="5px" padding-right="5px" font-family="Bogle, sans-serif" line-height="1.5"`;
