// the fields names existing in objects returned from the database
export const TEAM = {
  ID: "id",
  NAME: "name",
  OWNER: "owner",
  ACTIVE: "active",
  CREATED_AT: "createdAt",
  UPDATED_AT: "updatedAt",
  MEMBERS: "members",
  OWNERS: "owners",
  THEMES: "themes",
  CHANNELS: "channels",
  SENDERS: "senders",
};

export const TEAM_USER = {
  UPN: "upn",
  ROLE: "role",
  EMAIL: "email",
  NAME: "preferred_name",
};

export const CAMPAIGN = {
  NAME: "name",
  LOWER_CASE_NAME: "lower_case_name",
  UID: "uid",
  TEAM_ID: "team_id",
  TRIGGER: "trigger",
  DATA_SOURCE: "data_source",
  NOTIFICATIONS: "notifications",
  DATE_CREATED: "date_created",
  DATE_UPDATED: "date_updated",
  CATEGORY_ID: "category_id",
  OWNER: "owner",
  STATS: "stats",
  DATE_LAST_EXECUTED: "date_last_executed",
  LAST_EXECUTED_BY: "last_executed_by",
  LAST_SCHEDULED_BY: "last_scheduled_by",
  LAST_EDITED_BY: "last_edited_by",
  IS_DRAFT: "is_draft",
  METADATA: "metadata",
  METADATA_HEADINGS: "headings",
  GENERAL_DATA_SOURCE_TYPE: "general_data_source_type",
  FOLDER_ID: "folder_id",
};

export const FOLDER = {
  FOLDER_ID: "folder_id",
  PARENT_FOLDER_ID: "parent_folder_id",
  NAME: "name",
  TEAM_ID: "team_id",
  ACTIVE: "active",
  CREATED_BY: "created_by",
  DESCRIPTION: "description",
  PERMISSION_TYPE: "permission_type",
  PERMISSIONS: "permissions",
  CREATED_AT: "createdAt",
  RESTRICT_EDIT: "restrict_edit",
}

export const FOLDER_PERMISSION = {
  ROLE: "role",
  UPN: "upn",
}

export const DATA_SOURCE = {
  TYPE: "type",
  CONFIGURATIONS: "configurations",
};

export const DATA_SOURCE_TYPES = {
  CSV: "csv",
  MANUAL: "manual",
  MS_SQL: "ms-sql",
  REST_API: "rest-api",
};

export const NOTIFICATION = {
  TYPE: "type",
  CONFIGURATIONS: "configurations",
  MAPPING: "mapping",
  RECIPIENTS: "recipients",
  TEMPLATE: "template",
};

export const METADATA = {
  HYPERLINK_INFO: "hyperlink_info",
};

export const MAPPING = {
  SRC_NAME: "src_field_name",
  TEMPLATE_NAME: "template_field_name",
  RULE: "rule",
  TYPE: "type",
  CONSTANT: "constant",
  CONSTANT_VALUE: "constant_value",
  CIP: "cipLookUp",
  CIP_FIELD: "cip_field",
};

export const RECIPIENTS = {
  TYPE: "type",
  FIELD: "field",
  TO: "to",
  CC: "cc",
  BCC: "bcc",
};

export const CONFIGURATIONS = {
  NAME: "name",
  TYPE: "type",
  VALUE: "value",
  KEYNAME: "keyname",
  SUBJECT: "subject",
  PRIORITY: "priority",
  FROM: "from",
  FROM_DISPLAY_NAME: "fromDisplayName",
  ZOOM: ["robot_jid", "account_id", "client_id", "client_secret"],
  ATTACHMENTS: "attachments",
};

export const PRIORITIES = {
  low: {
    value: "low",
    label: "Low",
  },
  medium: {
    value: "medium",
    label: "Normal",
  },
  high: {
    value: "high",
    label: "High",
  },
};

export const TRACKING_CONFIGS = {
  VIEW: "trackView",
  VIEW_CID: "trackViewCampaignId",
  CLICK: "trackClick",
  CLICK_CID: "trackClickCampaignId",
  ADVANCED_METRICS: "trackViewAdvancedMetrics",
  TRACK_LOCATION: "trackClickLocations",
};

export const EXTERNAL_USER_OPTIONS_CONFIGS = {
  INCLUDE_UNSUBSCRIBE_LINK: "includeUnsubscribeLink"
}

export const TRIGGER = {
  TYPE: "type",
  CRON: "cron",
  STATE: "state",
  ACTIVE: "active",
  INACTIVE: "inActive",
  LAST_EXECUTION_DATE: "last_execution_date",
  NEXT_EXECUTION_DATE: "next_execution_date",
  CONFIG: "config",
  CRON_EXP: "cron_exp",
  IS_ONE_TIME: "is_one_time",
};

export const CATEGORY = {
  101: {
    value: "101",
    label: "Awareness, Engagement, or Informational",
  },
  201: {
    value: "201",
    label: "Cost Savings",
  },
  301: {
    value: "301",
    label: "Compliance, Ethics, Governance, or Security",
  },
  401: {
    value: "401",
    label: "People, Organization, or Operational Updates",
  },
  501: {
    value: "501",
    label: "Leadership Updates",
  },
  601: {
    value: "601",
    label: "Associate Trainings & Offerings",
  },
};

export const TEMPLATE = {
  CONTENT: "template_content",
  TYPE: "notification_type",
  HTML: "html",
  NAME: "name",
  DATE_CREATED: "date_created",
  DATE_UPDATED: "date_updated",
  OWNER: "owner",
  ID: "template_id",
  TEAM_ID: "team_id",
};

export const REQUEST_PARAMS = {
  SORT_BY: "sort_by",
  SORT_ORDER: "sort_order",
  DESCENDING: "desc",
  ASCENDING: "asc",
};

export const USER = {
  ROLE_ID: "roleId",
  ROLE: "role",
};

export const SENDER = {
  EMAIL: "email",
  CREATED_BY: "created_by",
  DATE_CREATED: "date_created",
};

export const ATTACHMENT = {
  NAME: "filename",
  PATH: "path",
};
