import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";

import React from "react";
import { NOTIFICATION_CHANNELS } from "../../consts/Campaign";

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function CheckboxSelectInput({
  handleChange,
  value = [],
  field,
  id,
  error,
  readOnly,
}) {
  return (
    <Box width="100%" my={1}>
      <FormControl
        variant="outlined"
        fullWidth
        required={field.required}
        error={error}
        style={{ pointerEvents: readOnly ? "none" : "unset" }}
      >
        <InputLabel shrink id={`${id}-Label`}>
          {field.label}
        </InputLabel>
        <Select
          labelId={`${id}-Label`}
          id={id}
          multiple
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          input={
            <OutlinedInput
              id={`${id}-input`}
              label={<Box pr={1}>{field.label}</Box>}
              notched
              startAdornment={
                field.tooltip ? (
                  <Box mr={1} display="flex" alignItems="center">
                    <Tooltip
                      title={
                        <Typography variant={"caption"}>
                          {field.tooltip?.title}
                        </Typography>
                      }
                      disableHoverListener={
                        field.tooltip?.disabled || !field.tooltip?.title
                      }
                      id={`${id}-TextInputField-Tooltip`}
                    >
                      {field.tooltip?.icon || <Info color={"action"} />}
                    </Tooltip>
                  </Box>
                ) : undefined
              }
            />
          }
          InputLabelProps={{
            shrink: true,
          }}
          IconComponent={readOnly ? () => <></> : undefined}
          MenuProps={MenuProps}
          renderValue={(selected) => (
            <Box style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected?.map((chipValue, index) => (
                <Box ml={index ? 1 : 0}>
                  <Chip
                    key={chipValue}
                    label={
                      <Typography variant="h5">
                        {NOTIFICATION_CHANNELS[chipValue]?.label || chipValue}
                      </Typography>
                    }
                    variant="outlined"
                    color="primary"
                  />
                </Box>
              ))}
            </Box>
          )}
        >
          {field.options?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={value?.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default CheckboxSelectInput;
