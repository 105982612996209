import React, { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../utils/auth/AuthenticatedUser";
import { getErrorType, getNameFromUpn } from "../utils/common";
import {
  isSignedInUserATeamOwner,
  isUserAdmin,
  buildSendersList,
} from "../utils/user";
import { getUserTeams, getAllTeams, getTeamUsers } from "../APIs/UserAPI";
import { getEnabledToggles } from "../APIs/ToggleAPI";
import { getCcmConfig } from "../APIs/CcmAPI";
import { TEAM } from "../consts/DBFields";
import { JWT } from "../consts/AuthFields";
import {
  DEFAULT_PAGE,
  ERROR_TYPES,
  FEATURE,
  LOCAL_STORAGE,
  SNACK_DURATION,
} from "../consts/Common";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const AppState = Context.Consumer;
export const useAppContext = () => useContext(Context);

// Create and export the provider, which defines and controls the state
function Core({ children }) {
  const [activePage, setActivePage] = useState(DEFAULT_PAGE);
  const [user, setUser] = useState({});
  const [signedInUserIsOwner, toggleSignedInUserIsOwner] = useState(false);
  const [sendersList, setSendersList] = useState([]);
  const [userTeams, setTeams] = useState([]);
  const [loadingFeatures, toggleLoadingFeatures] = useState(false);
  const [toggles, setToggles] = useState([]);
  const [loadingTeamUsers, toggleLoadingTeamUsers] = useState(true);
  const [teamUsers, setTeamUsers] = useState({});
  const [selectedTeam, setSelectedTeam] = useState({});
  const [authError, setAuthError] = useState(false);
  const [appError, setAppError] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);
  const [snackType, setSnackType] = useState();
  const [snackPosition, setSnackPosition] = useState({
    vertical: "bottom",
    horizontal: "center",
  });
  const [overriddenSnackCss, setOverriddenSnackCss] = useState(null);
  const [snackDuration, setSnackDuration] = useState(SNACK_DURATION);
  const [ccmConfig, setCcmConfig] = useState({});
  const [folder_id, setFolderId] = useState(null);
  const [pageTitle, setPageTitle] = useState("")

  useEffect(() => {
    if (!user || !user[JWT.UPN]) {
      try {
        setUser(getUser());
      } catch (err) {
        //  SSO will handle this error
      }
    }
  }, []);

  useEffect(() => {
    setUserTeams(user);
    setFeatureToggles(user);
  }, [user[JWT.UPN]]);

  useEffect(() => {
    async function handleTeamChange() {
      await getMembersOfTeam(selectedTeam?.[TEAM.ID]);
    }
    handleTeamChange();
  }, [selectedTeam[TEAM.ID], activePage]);

  useEffect(() => {
    if (
      isUserAdmin(user) ||
      isSignedInUserATeamOwner(user, teamUsers?.[selectedTeam?.[TEAM.ID]])
    ) {
      toggleSignedInUserIsOwner(true);
    } else {
      toggleSignedInUserIsOwner(false);
    }
  }, [user, selectedTeam?.[TEAM.ID], teamUsers?.[selectedTeam?.[TEAM.ID]]]);

  useEffect(() => {
    const updateSendersList = async () => {
      const senders = await buildSendersList(
        selectedTeam,
        signedInUserIsOwner,
        teamUsers,
        user
      );
      setSendersList(senders);
    };
    updateSendersList();
  }, [selectedTeam, signedInUserIsOwner, teamUsers?.[selectedTeam?.[TEAM.ID]]]);

  useEffect(() => {
    const getConfigFromCcm = async () => {
      const isAlertBannerEnabled = isToggleEnabled(FEATURE.SHOW_ALERT_BANNER);
      const ccmConfig = isAlertBannerEnabled && toggles ? await getCcmConfig() : [];
      setCcmConfig(ccmConfig);
    };
    getConfigFromCcm();
  }, [toggles]);

  const isToggleEnabled = (toggle) => {
    return toggles?.includes(toggle);
  };

  const setFeatureToggles = async (user) => {
    let toggles = [];
    if (user && user[JWT.UPN]) {
      toggleLoadingFeatures(true);
      try {
        toggles = await getEnabledToggles(user[JWT.UPN]);
        if (toggles && toggles.length) {
          setToggles(toggles);
        } else {
          setToggles([]);
        }
      } catch (err) {
        setToggles([]);
      } finally {
        toggleLoadingFeatures(false);
      }
    } else {
      setToggles([]);
    }
  };

  const setUserTeams = async (user) => {
    let teams = [];
    if (user && user[JWT.UPN]) {
      try {
        if (isUserAdmin(user)) {
          teams = await getAllTeams();
        } else {
          teams = await getUserTeams(user[JWT.UPN]);
        }
        if (teams && teams.length) {
          const recentTeam = teams.find(
            (team) =>
              team[TEAM.ID] ===
              localStorage.getItem(LOCAL_STORAGE.RECENT_TEAM_ID)
          );
          setSelectedTeam(recentTeam || teams[0]);
        } else {
          setSelectedTeam({});
          setAppError(ERROR_TYPES.USER_NOT_FOUND);
        }
      } catch (err) {
        setAppError(getErrorType(err));
      }
    }
    setTeams(teams);
  };

  const getMembersOfTeam = async (teamId) => {
    if (teamId && !teamUsers?.[teamId]) {
      toggleLoadingTeamUsers(true);
      try {
        let curentTeamUsers = await getTeamUsers(teamId);
        if (!curentTeamUsers) curentTeamUsers = [];
        //put owners and co-owners at the top of the list
        const sortedTeamUsers = curentTeamUsers.sort((a, b) => a.role - b.role);
        setTeamUsers({
          ...teamUsers,
          [teamId]: sortedTeamUsers,
        });
        toggleLoadingTeamUsers(false);
        return sortedTeamUsers;
      } catch (err) {
        toggleLoadingTeamUsers(false);
        // TODO handle error
      }
    }
    return;
  };

  // create one state object to pass to the Provider
  const initialState = {
    activePage,
    setActivePage,
    user,
    setUser: (user) => setUser(user ? user : {}),
    userTeams,
    setUserTeams,
    loadingTeamUsers,
    toggles,
    isToggleEnabled,
    teamUsers,
    setTeamUsers,
    selectedTeam,
    setSelectedTeam: (team) => {
      localStorage.setItem(LOCAL_STORAGE.RECENT_TEAM_ID, team?.[TEAM.ID]);
      setSelectedTeam(team ? team : {});
    },
    authError,
    appError,
    setAppError,
    setAuthError,
    snackMessage,
    clearSnackMessage: () => {
      setSnackMessage(null);
      setOverriddenSnackCss(null);
    },
    setSnackMessage,
    setSnackType,
    snackPosition,
    setSnackPosition,
    snackDuration,
    setSnackDuration,
    overriddenSnackCss,
    setOverriddenSnackCss,
    snackType,
    sendersList,
    signedInUserIsOwner,
    getMembersOfTeam,
    loadingFeatures,
    getNameFromUpn: (upn, abbreviateUpn = true) => {
      return getNameFromUpn(upn, selectedTeam, teamUsers, abbreviateUpn);
    },
    ccmConfig,
    folder_id,
    setFolderId,
    pageTitle,
    setPageTitle
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default Core;
