import { Box, Chip, Typography } from "@material-ui/core";
import React from "react";

function FileList({ fileNames, removable, removeFileAtIndex, label }) {
  return (
    <Box maxWidth="100%" display="flex" alignItems="center" flexWrap="wrap">
      {label ? <Typography variant="h5">{label}</Typography> : null}
      {fileNames?.length
        ? fileNames?.map((name, index) => {
            return (
              <Box mr={1} mb={1} ml={label && index === 0 ? 1 : 0}>
                <Chip
                  label={<Typography variant="h5">{name}</Typography>}
                  variant="outlined"
                  color="primary"
                  onDelete={
                    removable
                      ? () => {
                          removeFileAtIndex(index);
                        }
                      : undefined
                  }
                />
              </Box>
            );
          })
        : null}
    </Box>
  );
}

export default FileList;
