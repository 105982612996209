import React, { useState } from "react";
import { ErrorTypes } from "../../consts/ErrorTypes";
import API from "./AuthenticatedAPI";
import { getToken, redirectToSSO } from "./AuthenticatedUser";

function SSO({ setAuthError, children, error }) {
  const [called, setCalled] = useState(false);

  const useConstructor = (callBack = () => {}) => {
    //using use state to help the execution call to happen only once
    if (called) {
      return;
    }
    callBack();
    setCalled(true);
  };

  //this call will be called only once for the component before any rendering
  useConstructor(() => {
    API.setup(
      () => {
        if(error !== null) {
          setAuthError(null);
        }
        return getToken();
      },
      (errorType) => {
        if (errorType === ErrorTypes.TOKEN_EXPIRED) {
          setAuthError(null);
          redirectToSSO(true, window.location.pathname);
        } else {
          setAuthError(errorType);
        }
      }
    );
  });

  return <>{children}</>;
}

export default SSO;
