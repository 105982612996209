import cookie from "react-cookies";
import qs from "qs";
import { LANDING_URL } from "../../consts/AuthFields";
import { ErrorTypes } from "../../consts/ErrorTypes";
import { logout as ssoLogout } from "../../APIs/UserAPI";
import Configs from '../../Configurations';

const COOKIE_NAMES = {
  TOKEN: "accessToken",
};

const PARAM_NAMES = {
  CODE: "responseCode",
};

const SSO_LOGIN_URL = Configs.REACT_APP_SSO_LOGIN_URL;
const SSO_LOGOUT_URL = Configs.REACT_APP_SSO_LOGOUT_URL;

const clearCookies = () => {
  cookie.remove(COOKIE_NAMES.TOKEN);
};

export const handlePostLogin = (queryString, onSuccess, onError) => {
  try {
    const pageToLand = sessionStorage.getItem(LANDING_URL) || "/";
    if (isSessionActive()) {
      //Ignore the override call silently without any error
      sessionStorage.setItem(LANDING_URL, "");
      return onSuccess(pageToLand);
    } else {
      const queryParams = queryString
        ? qs.parse(queryString.split("?")[1])
        : null;
      const code = queryParams && queryParams[PARAM_NAMES.CODE];

      if (code) {
        //Set generic error
        let errorType = ErrorTypes.API_ERROR;
        // 403: User not found
        if (code === 403 || Number(code) === 403) {
          errorType = ErrorTypes.APP_USER_NOT_AUTHORIZED;
        } else if (code === 401 || code === 500) {
          errorType = ErrorTypes.SSO_LOGIN_FAILED;
        }
        return onError(errorType);
      } else {
        //Initiate SSO if token expired or not present
        redirectToSSO(true);
        return "Redirecting...";
      }
    }
  } catch (e) {
    return onError(ErrorTypes.APP_ERROR);
  }
};

export const redirectToSSO = (clearSession, pageToLand = "/") => {
  if (clearSession) {
    clearCookies();
  }
  if (pageToLand !== "/login") {
    // prevent it from going into a redirect loop
    // Store the current pathname to enable to resume where the app was left
    sessionStorage.setItem(LANDING_URL, pageToLand);
    window.location.href = SSO_LOGIN_URL;
  }
};

export const getUser = () => {
  let token = getToken();
  return JSON.parse(atob(token.split(".")[1]));
};

export const getToken = () => {
  const token = cookie.load(COOKIE_NAMES.TOKEN);
  //Handle cookie expiry
  if (!token) {
    // throw new Error(ErrorTypes.TOKEN_EXPIRED);
  }
  return token;
};

export const isSessionActive = () => {
  let token = null;
  try {
    token = getToken();
  } catch (e) { }
  return token ? true : false;
};

export const logout = () => {
  //Clear cookies and redirect to sso logout url irrespective of the response status for account safety
  return ssoLogout()
    .then((response) => {
      //Do nothing
    })
    .catch((err) => {
      //Have empty catch block to not to propogate the promise reject further
    })
    .finally(() => {
      //Don't care about the response, clear cookie and redirect to sso logout url
      clearCookies();
      window.location.href = SSO_LOGOUT_URL;
    });
};
