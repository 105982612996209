import { sortBy as sort, capitalize, isEmpty } from "lodash";
import { REQUEST_PARAMS } from "../consts/DBFields";
import { IMAGE_SCHEMA } from "../consts/Template";
const NAME_MAX_CHAR = 255;

export const sortImages = (imageArray, sortBy, sortOrder) => {
  let sortedImageArray;
  if (sortBy === IMAGE_SCHEMA.CREATED_ON) {
    sortedImageArray = sort(imageArray, [sortBy]);
  } else {
    sortedImageArray = sort(imageArray, (image) => {
      return capitalize(image[sortBy]);
    });
  }
  if (sortOrder === REQUEST_PARAMS.DESCENDING) {
    sortedImageArray = sortedImageArray.reverse();
  }
  return sortedImageArray;
};

export const validateImageName = (value = "") => {
  if (isEmpty(value) || isEmpty(value.trim())) {
    return `Please enter an image name`;
  } else if (value.length > NAME_MAX_CHAR) {
    return `Please enter an image name that does not exceed ${NAME_MAX_CHAR} characters.`;
  } else if (
    !value.match(/^[\w\- ]+(\.(png|PNG|jpg|JPG|gif|GIF|jpeg|JPEG))?$/)
  ) {
    return "Please enter a valid image name";
  } else {
    return null;
  }
};
