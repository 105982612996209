export const ink = "#041f41";
export const wmBlue = "#0071ce";
export const blue = "#064f8e";
export const sky = "#78b9e7";
export const yellow = "#fcc220";
export const pink = "#eb148d";
export const orange = "#f47521";
export const green = "#76c043";
export const red = "#ee3b2a";
export const navy = "#0e1e42";
export const techGreen = "#02f17a";
export const white = "#ffffff";
export const black = "#000000";
export const darkGray = "#3d3d3d";
export const muiGray = "#424242";
export const medGray = "#9a9a9a";
export const mediumGray = "#74767C";
const gray = "#cccccc";
export const lightGray = "#f0f0f0";
export const lightestGray = "#f9f9f9";
export const fadedInk = "#274359";
export const commsGray = "#605e63";
export const samsBlue = "#0067A0";
export const darkBlue = "#005BB0";
export const darkBlue2 = "#004F9A";
export const darkestBlue = "#01396D";
export const lightBlue = "#1B7FE0";
export const lightestBlue = "#99C6F1";
export const lightBlue2 = "#338DE3";
export const lightestBlue2 = "#99C6F1";
export const lightBlack = "#999";
export const advancedTrackingGray = "#787878";

export const HEX_COLORS = {
  ink,
  wmBlue,
  blue,
  sky,
  yellow,
  pink,
  orange,
  green,
  red,
  navy,
  techGreen,
  white,
  black,
  darkGray,
  medGray,
  muiGray,
  gray,
  lightGray,
  lightestGray,
  fadedInk,
  darkBlue,
  darkestBlue,
  lightBlue,
  lightestBlue,
  lightBlack,
  advancedTrackingGray,
  lightBlue2,
  lightestBlue2,
  darkBlue2,
  mediumGray,
};

export const EDITOR_COLORS = [
  pink,
  red,
  orange,
  yellow,
  green,
  techGreen,
  sky,
  wmBlue,
  blue,
  fadedInk,
  ink,
  darkGray,
  medGray,
  gray,
  lightGray,
  lightestGray,
  commsGray,
  samsBlue,
  white,
  black,
];

export const PIE_CHART_COLORS = {
  darkBlue: "#cc5641",
  orange: "#003f5c",
  lightBlue: "#4ac8db",
  purple: "#955196",
  pink: "#dd5182",
}

export const MEDIA_LIBRARY_COLORS = {
  "DOWNLOAD_PROGRESS_BG": "#E3E4E5",
  "DOWNLOAD_PROGRESS_PRIMARY": "#515357",
  "WHITE": "#fff",
  "BLACK": "#000",
}
