import React from "react";
import { Redirect } from "react-router-dom";
import { handlePostLogin, getUser } from "./AuthenticatedUser";

function Login({ response, actions: { setError, setUser } }) {
  return handlePostLogin(
    response,
    (landingUrl) => {
      //Redirect to home page or to the last viewed after successful login
      setUser(getUser());
      setError(null);
      return <Redirect to={landingUrl} />;
    },
    (errorType) => {
      setError(errorType);
      return <Redirect to="/" />;
    }
  );
}
export default Login;
