import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { updateTeam } from "../APIs/TeamAPI";
import {
  addTeamUser,
  removeTeamUser,
  updateTeamRole,
  getTeamSenders,
} from "../APIs/UserAPI";
import { postTeam } from "../APIs/TeamAPI";
import {
  AD_USER,
  ERROR_MSG,
  FEATURE,
  SNACK_TYPES,
  SUCCESS_MSG,
  ROUTES,
} from "../consts/Common";
import { SENDER, TEAM, TEAM_USER } from "../consts/DBFields";
import { CODES, TEAM_FIELD_ERROR_STATES } from "../consts/Teams";
import { intersectUsers } from "../utils/common";
import { serializeCreateTeamConfig } from "../utils/team";
import { useAppContext } from "./Core";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const TeamState = Context.Consumer;
export const useTeamContext = () => useContext(Context);
// Create and export the provider, which defines and controls the state
function Team({ children }) {
  const {
    setTeamUsers,
    setSnackMessage,
    setSnackType,
    userTeams,
    isToggleEnabled,
    getMembersOfTeam,
    setUserTeams,
    user,
    teamUsers,
    selectedTeam,
  } = useAppContext();
  const { team_id } = useParams();
  const history = useHistory();
  const [teamValue, setTeamValue] = useState({});
  const [openTeam, setOpenTeam] = useState({});
  const [errors, setErrors] = useState({});
  const [saveInProgress, setSaveInProgress] = useState(false);

  useEffect(() => {
    const getCurrentTeam = async () => {
      if (userTeams.length > 0) {
        const validTeam = await userTeams.find(
          (team) => team[TEAM.ID] === team_id
        );
        if (validTeam && isToggleEnabled(FEATURE.SHOW_TEAM_SETTINGS)) {
          // Converting senders to an array of strings
          const team = {
            ...validTeam,
            [TEAM.SENDERS]: ((await getTeamSenders(team_id)) || []).map(
              (sender) => sender[SENDER.EMAIL]
            ),
          };
          setOpenTeam(team);
          setTeamValue(team);
          await getMembersOfTeam(team_id);
        } else {
          history.replace("/");
        }
      }
    };
    if (team_id) {
      getCurrentTeam();
    } else {
      setOpenTeam({});
      setTeamValue({});
    }
  }, [userTeams, team_id]);

  const handleErrors = (error, fieldName) => {
    if (error) setErrors((prev) => ({ ...prev, [fieldName]: error }));
    else
      setErrors((prev) => {
        delete prev[fieldName];
        return { ...prev };
      });
  };

  const updateTeamCheckBoxField = (fieldName, currentField, fieldValue) => {
    let error;

    setTeamValue((prev) => ({ ...prev, [fieldName]: fieldValue }));
    if (currentField.required && !fieldValue?.length)
      error = TEAM_FIELD_ERROR_STATES.REQUIRED;

    handleErrors(error, fieldName);
  };

  const updateTeamTextField = (fieldName, currentField, fieldValue = "") => {
    let error;

    setTeamValue((prev) => ({ ...prev, [fieldName]: fieldValue }));
    if (currentField.required && !fieldValue)
      error = TEAM_FIELD_ERROR_STATES.REQUIRED;
    if (currentField.max && fieldValue?.length > currentField.max)
      error = TEAM_FIELD_ERROR_STATES.MAX_LENGTH_EXCEEDED(currentField.max);
    if (
      currentField.min &&
      fieldValue?.replace(/\s/g, "")?.length < currentField.min
    )
      error = TEAM_FIELD_ERROR_STATES.MIN_LENGTH_SUBCEEDED(currentField.min);

    handleErrors(error, fieldName);
  };

  const updateEmailField = (fieldName, currentField, fieldValue) => {
    let error;

    setTeamValue((prev) => ({ ...prev, [fieldName]: fieldValue }));
    if (currentField.required && fieldValue?.length < 1)
      error = TEAM_FIELD_ERROR_STATES.REQUIRED;
    const compareField = currentField.exclusiveOf;
    if (
      compareField &&
      intersectUsers(fieldValue, teamValue[compareField]).length
    )
      error = currentField.exclusiveOfText;

    handleErrors(error, fieldName);
  };

  const saveTeamSettings = async (settingsConfig) => {
    let updatedTeamObject = teamValue;
    let successSnackMessage = SUCCESS_MSG.PUT_TEAM;
    let errorSnackMessage = ERROR_MSG.PUT_TEAM;

    if (settingsConfig) {
      const { teamValue, snackMessage } = settingsConfig;
      updatedTeamObject = teamValue;
      successSnackMessage = snackMessage?.success;
      errorSnackMessage = snackMessage?.error;
    }

    // todo - handle owners, members and senders arrays
    try {
      await updateTeam(openTeam[TEAM.ID], updatedTeamObject);
      setOpenTeam(updatedTeamObject);
      await setUserTeams(user);
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(successSnackMessage);
      return;
    } catch (error) {
      if (error.response?.data?.code === CODES.DUPLICATE_TEAM) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.POST_TEAM_DUPLICATE);
      } else {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(errorSnackMessage);
      }
      return error;
    }
  };

  const addUsersToTheTeam = async (teamConfig, teamId) => {
    const { owners = [], members = [] } = teamConfig;
    const otherOwners = owners.slice(1);

    for (let owner of otherOwners) {
      owner.roleId = 100;
      await addTeamUser(teamId, owner);
    }

    for (let member of members) {
      await addTeamUser(teamId, member);
    }
  };

  const createTeam = async (teamConfig) => {
    let success = true;
    setSaveInProgress(true);
    try {
      const createTeamConfig = serializeCreateTeamConfig.toRequest(teamConfig);
      const rawResponse = await postTeam(createTeamConfig);
      const teamId = serializeCreateTeamConfig.fromResponse(rawResponse);

      await addUsersToTheTeam(teamConfig, teamId);
      await setUserTeams(user);
      history.push(`/${ROUTES.TEAM}/${teamId}`, { from: "/" });

      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.POST_TEAM);
    } catch (error) {
      if (error.response?.data?.code === CODES.DUPLICATE_TEAM) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.POST_TEAM_DUPLICATE);
      } else {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.POST_TEAM);
      }
      success = false;
    } finally {
      setSaveInProgress(false);
    }
    return success;
  };

  const addUser = async (userObject, callback) => {
    const teamId = openTeam[TEAM.ID];
    try {
      await addTeamUser(teamId, userObject);
      setTeamUsers((prev) => ({
        [teamId]: [
          ...prev[teamId],
          {
            upn: userObject[AD_USER.UPN],
            preferred_name: userObject[AD_USER.DISPLAY_NAME],
            email:
              userObject[AD_USER.MAIL] != null
                ? userObject[AD_USER.MAIL]
                : userObject[AD_USER.UPN],
            role: 300,
          },
        ],
      }));
      callback(true);
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.POST_TEAM_USER);
    } catch (err) {
      if (err.response?.data?.code === CODES.DUPLICATE_TEAM_USER) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.POST_TEAM_USER_DUPLICATE);
      }
      else if (err.response?.data?.code === CODES.USER_DOES_NOT_HAVE_ACCESS) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.USER_DOES_NOT_HAVE_ACCESS);
      }
      else {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.POST_TEAM_USER_GENERIC);
      }
      callback(false);
    }
  };

  const updateUser = async (selectedUser) => {
    const newRoleId = selectedUser[TEAM_USER.ROLE] < 300 ? 300 : 100;
    const teamId = openTeam[TEAM.ID];
    try {
      await updateTeamRole(teamId, selectedUser, newRoleId);
      setTeamUsers((prev) => {
        const newArr = prev[teamId].map((u) =>
          u[TEAM_USER.UPN] === selectedUser[TEAM_USER.UPN]
            ? { ...u, role: newRoleId }
            : u
        );
        return { [teamId]: newArr };
      });
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.PUT_TEAM_USER);
    } catch (err) {
      //USER_OBJ_ERR007
      if (err.response?.data?.code === CODES.LAST_TEAM_MEMBER) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.REMOVE_LAST_USER);
      } else {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.PUT_TEAM_USER);
      }
    }
  };

  const removeUser = async (selectedUser) => {
    const teamId = openTeam[TEAM.ID];
    try {
      await removeTeamUser(teamId, selectedUser);
      setTeamUsers((prev) => ({
        [teamId]: prev[teamId].filter(
          (u) => u[TEAM_USER.UPN] !== selectedUser[TEAM_USER.UPN]
        ),
      }));
      setSnackType(SNACK_TYPES.SUCCESS);
      setSnackMessage(SUCCESS_MSG.DELETE_TEAM_USER);
    } catch (err) {
      //USER_OBJ_ERR007
      if (err.response?.data?.code === CODES.LAST_TEAM_MEMBER) {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.REMOVE_LAST_USER);
      } else {
        setSnackType(SNACK_TYPES.ERROR);
        setSnackMessage(ERROR_MSG.DELETE_TEAM_USER);
      }
    }
  };

  const resetTeamValue = () => {
    setTeamValue(openTeam || {});
  };

  // create one state object to pass to the Provider
  const initialState = {
    openTeam,
    updateUser,
    removeUser,
    addUser,
    teamValue,
    errors,
    setTeamValue,
    updateTeamCheckBoxField,
    updateEmailField,
    updateTeamTextField,
    setOpenTeam,
    saveTeamSettings,
    resetTeamValue,
    createTeam,
    saveInProgress,
    user,
    teamUsers,
    addUsersToTheTeam,
    selectedTeam,
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default Team;
