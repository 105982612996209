import { DeleteOutlined } from "@material-ui/icons";
import { Eye, Pencil, CloudDownload } from "@livingdesign/icons";
import { COPY } from "./Copy";
import { REQUEST_PARAMS } from "./DBFields";

export const MEDIA_TYPES = {
    ALL: {
      value: 0,
      label: "All",
    },
    IMAGES: {
      value: 1,
      label: "Images",
      fileTypes: ["jpeg", "jpg", "png"]
    },
    GIFS: {
      value: 2,
      label: "Animated GIF's",
      fileTypes: ["gif"]
    },
    DOCUMENTS: {
      value: 3,
      label: "Documents",
      fileTypes: ["csv", "xlsx", "pptx", "docx", "zip", "pdf", "ics"]
    }
};

export const fileTypeFromExtenstion = {
    "csv": "CSV",
    "xlsx": "Excel",
    "pptx": "Power point",
    "docx": "Docx",
    "zip": "Zip",
    "pdf": "Pdf",
    "gif": "Gif",
    "jpeg": "Image",
    "jpg": "Image",
    "png": "Image",
    "ics": "Calendar"
}

export const mediaTableHeaders = {
    name: {
      id: "media-table-name",
      label: "FILE NAME",
      ariaLabel: "File Name",
    },
    type: {
      id: "media-table-type",
      label: "TYPE",
      ariaLabel: "Media Type",
    },
    uploaded_by: {
      id: "media-table-uploaded-by",
      label: "UPLOADED BY",
      ariaLabel: "Date Created"
    },
    uploaded_date: {
        id: "media-table-uploaded-date",
        label: "UPLOADED DATE",
        ariaLabel: "Date Created"
    },
    view: {
      id: "folder-table-view",
      label: "",
      ariaLabel: "View Media",
    },
    more: {
        id: "folder-table-more",
        label: "",
        ariaLabel: "More Options",
    },
};

export const rowsPerPageOptions = [25, 50, 75, 100];
export const mediaRowsPerPageOptions = [10, 20, 40, 60, 100];
export const MAX_LENGTH = 255;
export const mediaItemsPerPageOptions = [12, 24, 48, 72, 120];

export const MORE_OPTIONS_MEDIA = [
  {
    label: "Open / view",
    id: "view",
    icon: <Eye size="small" />,
    userIsAllowed: () => true,
    validateChannel: true,
    invalidChannelTooltip: COPY.MEDIA_LIBRARY.DISABLED_RENAME,
  },
  {
    label: "Rename",
    id: "rename",
    icon: <Pencil size="small" />,
    userIsAllowed: (media) => media[MEDIA_SCHEMA.CAN_EDIT],
    validateChannel: true,
    invalidChannelTooltip: COPY.MEDIA_LIBRARY.DISABLED_RENAME,
  },
  {
    label: "Download",
    id: "download",
    icon: <CloudDownload size="small" />,
    userIsAllowed: () => true,
    validateChannel: true,
  },
  {
    label: "Delete",
    id: "delete",
    icon: <DeleteOutlined fontSize="small" />,
    userIsAllowed: (media) => media[MEDIA_SCHEMA.CAN_EDIT],
    invalidChannelTooltip: COPY.MEDIA_LIBRARY.DISABLED_DELETE,
  }
]

export const MEDIA_SCHEMA = {
    SRC: "src",
    NAME: "name",
    CREATED_ON: "createdOn",
    LAST_MODIFIED: "lastModified",
    DELETED_ON: "deletedon",
    BLOB_NAME: "blobName",
    OWNER: "owner",
    CAN_EDIT: "canEdit",
    FOLDER_ID: "folderId",
};

export const MEDIA_VIEW_TYPE = {
    GridView: 0,
    ListView: 1,
}

export const MEDIA_TYPE_FOR_MEDIA_THUMBNAIL = {
  IMAGE: {
    types: ["jpeg", "jpg", "png", "gif"],
    value: 0,
  },
  DOC: {
    types: ["docx", "doc"],
    value: 1,
  },
  XLS: {
    types: ["xlsx", "xls"],
    value: 2,
  },
  PDF: {
    types: ["pdf"],
    value: 3,
  },
  CALENDAR: {
    types: ["ics"],
    value: 4,
  },
  PPT: {
    types: ["pptx", "ppt"],
    value: 5,
  },
  ZIP: {
    types: ["zip"],
    value: 6,
  },
  UNKNOWN_FILE: {
    types: [],
    value: 7,
  },
}

export const SORTING_OPTIONS = {
  NEWEST: {
    label: "Newest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: MEDIA_SCHEMA.CREATED_ON,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
  OLDEST: {
    label: "Oldest",
    params: {
      [REQUEST_PARAMS.SORT_BY]: MEDIA_SCHEMA.CREATED_ON,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  AZ: {
    label: "A to Z",
    params: {
      [REQUEST_PARAMS.SORT_BY]: MEDIA_SCHEMA.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.ASCENDING,
    },
  },
  ZA: {
    label: "Z to A",
    params: {
      [REQUEST_PARAMS.SORT_BY]: MEDIA_SCHEMA.NAME,
      [REQUEST_PARAMS.SORT_ORDER]: REQUEST_PARAMS.DESCENDING,
    },
  },
};

export const ACCEPTED_FILES = ".jpg,.jpeg,.png,.gif,.ics,.csv,.xlsx,.pptx,.docx,.zip,.pdf"
export const MAX_FILE_SIZE = 10; //MB
export const MAX_FILES = 10;

export const IMAGE_FORMATS = ["jpg","jpeg","png","gif"];