import React, { useState } from "react";
import { JWT } from "../../consts/AuthFields";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES, FEATURE } from "../../consts/Common";

// MUI
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import { AppState } from "../../contexts/Core";
import { FOLDER, TEAM } from "../../consts/DBFields";
import { COPY } from "../../consts/Copy";
import { HEX_COLORS } from "../../consts/Colors";
import { isUserAdmin } from "../../utils/user";
import { sortBy, capitalize } from "lodash";
import { useFoldersContext } from "../../contexts/FoldersList";

const useStyles = makeStyles(() => ({
  icon: {
    padding: 8,
  },
  avatar: {
    width: 34,
    height: 34,
    backgroundColor: HEX_COLORS.medGray,
  },
  folderSettingsBtn: {
    fontWeight: 500,
    border: "1px solid #000000",
    backgroundColor: "#ffffff",
    color: "#000000",
    "&:hover": {
      border: "1px solid #c4c4c4",
    }
  }
}));

function UserMenu({ user, id, openCreateTeamOverlay }) {
  const classes = useStyles();
  const [menu, toggleMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const history = useHistory();
  const location = useLocation();
  const { openFolderSettings, selectCurrentFolder } = useFoldersContext();
  
  const selectedFolder = selectCurrentFolder();

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
    toggleMenu(true);
  };

  const closeMenu = () => {
    toggleMenu(false);
    setAnchorEl(null);
  };

  const isInFolderPage = (pathname) => {
    const regex = new RegExp("\/folders\/(.+)$");
    return regex.test(pathname);
  }

  return (
    <AppState>
      {({
        getNameFromUpn,
        selectedTeam,
        userTeams,
        setSelectedTeam,
        isToggleEnabled,
        setFolderId,
      }) => {
        const userName = user ? getNameFromUpn(user[JWT.UPN]) : null;
        const sortedUserTeams = sortBy(userTeams, (userTeams) =>
          capitalize(userTeams?.[TEAM.NAME])
        );
        return (
          <>
            <IconButton
              className={classes.icon}
              onClick={openMenu}
              id={`${id}-IconButton`}
              aria-label="User Avatar"
            >
              <Avatar className={classes.avatar} id={`${id}-Avatar`}>
                {userName?.[0].toUpperCase()}
              </Avatar>
            </IconButton>
            <Popover
              className="user-menu"
              open={menu}
              anchorEl={anchorEl}
              onClose={closeMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              id={`${id}-PopOverMenu`}
            >
              <Box p={2} width={300} maxWidth="100%" boxSizing="border-box">
                <Box mb={2}>
                  <Box my={1}>
                    <Typography
                      variant="h4"
                      align="center"
                      id={`${id}-UserName`}
                    >
                      {userName}
                    </Typography>
                  </Box>
                </Box>
                <Divider />
                <Box mt={3}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    margin="none"
                    key="user-menu-teams-select"
                  >
                    <InputLabel id="user-menu-teams-select" shrink>
                      {COPY.CORE.USER_TEAMS_DROPDOWN}
                    </InputLabel>
                    <Select
                      input={
                        <OutlinedInput
                          notched
                          label={COPY.CORE.USER_TEAMS_DROPDOWN}
                        />
                      }
                      margin="dense"
                      labelId="user-menu-teams-select"
                      value={selectedTeam?.[TEAM.ID]}
                      onChange={(e) => {
                        const team = sortedUserTeams?.find(
                          (team) => team[TEAM.ID] === e.target.value
                        );
                        setSelectedTeam(team || {});
                        setFolderId(null);
                        history.push({
                          pathname: `/${ROUTES.FOLDERS}/`,
                          state: {
                            isResend: true,
                            from: "/",
                          },
                        });
                      }}
                      label={COPY.CORE.USER_TEAMS_DROPDOWN}
                      id={`${id}-UserTeamsDropDown`}
                    >
                      <MenuItem
                        value=" "
                        disabled
                        id={`${id}-UserTeamsDropDown-Item-Default`}
                      >
                        <Typography color="textSecondary">
                          Select a team
                        </Typography>
                      </MenuItem>
                      {sortedUserTeams?.map((team, index) => (
                        <MenuItem
                          key={`menu-${team[TEAM.ID]}`}
                          value={team[TEAM.ID]}
                          id={`${id}-UserTeamsDropDown-Item-${index}`}
                        >
                          {team[TEAM.NAME]}
                        </MenuItem>
                      ))}
                    </Select>
                    {isToggleEnabled(FEATURE.SHOW_TEAM_SETTINGS) && (
                      <Box mt={2}>
                        <Button
                          variant="outlined"
                          color="primary"
                          className={classes.folderSettingsBtn}
                          onClick={() => {
                            history.push(
                              `/${ROUTES.TEAM}/${selectedTeam[TEAM.ID]}`,
                              { from: "/" }
                            );
                          }}
                          fullWidth
                          id={`${id}-TeamSettingsButton`}
                          disabled={!selectedTeam?.[TEAM.ID]}
                        >
                          Team Settings
                        </Button>
                      </Box>
                    )}
                    {(selectedFolder && !selectedFolder[FOLDER.RESTRICT_EDIT] && isInFolderPage(location?.pathname)) ? 
                        <Box mt={2}>
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.folderSettingsBtn}
                            onClick={() => {
                              openFolderSettings(selectedFolder);
                            }}
                            fullWidth
                            id={`${id}-FolderSettingsButton`}
                            disabled={!selectedTeam?.[TEAM.ID]}
                          >
                            Folder Settings
                          </Button>
                        </Box>
                        :
                        null
                    }
                    {isToggleEnabled(FEATURE.CREATE_TEAM) &&
                    isUserAdmin(user) ? (
                      <Box mb={1} mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={openCreateTeamOverlay}
                          fullWidth
                          id={`${id}-NewTeamButton`}
                        >
                          Create New Team
                        </Button>
                      </Box>
                    ) : null}
                  </FormControl>
                </Box>
              </Box>
            </Popover>
          </>
        );
      }}
    </AppState>
  );
}

export default UserMenu;
