import React from "react";
import { PAGES } from "../../consts/Common";
import { AppState } from "../../contexts/Core";
// MUI
import { Tabs, Tab } from "@material-ui/core";

const QA_ID_PREFIX = `Nav`;

function Nav({ activePage, setActivePage }) {
  return (
    <AppState>
      {({ isToggleEnabled }) => {
        return (
          <Tabs
            value={activePage}
            onChange={(e, newValue) => {
              setActivePage(newValue);
            }}
            centered
            id={`${QA_ID_PREFIX}-Tabs`}
          >
            {Object.keys(PAGES).map((opt, index) => {
              if (
                !PAGES[opt].disabled &&
                (!PAGES[opt].toggle || isToggleEnabled(PAGES[opt].toggle))
              ) {
                return (
                  <Tab
                    label={PAGES[opt].label}
                    key={PAGES[opt].label}
                    id={`${QA_ID_PREFIX}-Tab-${index}`}
                  />
                );
              }
              return <Tab style={{ display: "none" }} />;
            })}
          </Tabs>
        );
      }}
    </AppState>
  );
}

export default Nav;
