import { Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { SENDER_CATEGORIES } from "../../consts/Campaign";
import QA from "../../consts/QA";
import { isUserAdmin } from "../../utils/user";

const QA_ID_PREFIX = `${QA.PAGES.CAMPAIGN}-CampaignEditFromField`;

function CampaignEditFromField({
  field,
  value,
  handleChange,
  user,
  sendersList,
}) {
  const isAdmin = isUserAdmin(user);
  let currentSelection = sendersList?.find((sender) => sender.value === value);
  const options =
    isAdmin && !currentSelection && value
      ? [...sendersList, { category: SENDER_CATEGORIES.OTHER, value: value }]
      : sendersList;

  const [selectedSender, setSelectedSender] = useState(
    options?.find((sender) => sender.value === value)
  );
  const [freeInput, setFreeInput] = useState(""); // allow super admins to add a different from address

  useEffect(() => {
    if (selectedSender?.value !== value) {
      handleChange(selectedSender?.value);
    }
  }, [selectedSender, value]);

  return (
    <>
      <Autocomplete
        openOnFocus
        value={selectedSender}
        freeSolo={isAdmin}
        options={options}
        groupBy={(option) => option.category}
        clearOnBlur={!isAdmin}
        onChange={(event, newValue) => {
          setSelectedSender(newValue);
        }}
        onInputChange={(e, newInputValue) => {
          if (isAdmin) {
            setFreeInput(newInputValue);
          }
        }}
        onBlur={() => {
          if (isAdmin) {
            setSelectedSender({ value: freeInput });
          }
        }}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => (
          <TextField
            {...params}
            label={field.label}
            variant="outlined"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
            required={field.required}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        id={`${QA_ID_PREFIX}-AutoComplete`}
        aria-label="Autocomplete Input"
        PaperComponent={CustomPaper}
      />
    </>
  );
}

export default CampaignEditFromField;

const CustomPaper = (props) => {
  return <Paper elevation={1} {...props} />;
};
