import React, { useEffect, useState, useRef } from "react";
import { fetchADSearchResults } from "../../APIs/MsGraphAPI";
import { AD_USER, SEARCH, DATATYPE, ERROR_MSG, EVENTS, EVENT_TYPES } from "../../consts/Common";
import { validateEmail, validateEmailArray } from "../../utils/campaign";
import {
  constructFullUserDetailsForDropDown,
  fetchMailIDFromUserObject,
  checkIfEmailAlreadyExist,
  checkIfEmailInUserList,
} from "../../utils/user";

// MUI
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
  Chip,
  CircularProgress,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { uniqBy } from "lodash";
import { trackEvent } from "../../utils/appInsight";
import { useAppContext } from "../../contexts/Core";

function EmailInputField({
  multiple,
  value,
  field,
  setError,
  handleChange,
  componentKey,
  chipProps,
  id,
  getTypedTextValue,
  setValueFromDropdownSelected,
  isLookUp = false, //this props is used to toggle between Dropdown and normal text field.
  disableFreeText = false, //this prop is used to restric the email to be one of the lookups.
  valueType = "mail", // determines what should be type of the values stored. 'mail' is the default, 'userObject' contains user data, 'upn' stores the users upn
  displayField = "displayName", // used to display user details when valueType is 'userObject',
  associateOnly = false,
  includeGroups = false,
  enableAnalytics = false
}) {
  const [loadingResults, toggleLoadingResults] = useState(false);
  const [options, setOptions] = useState([]);
  const [emailError, toggleEmailError] = useState(false);
  const [emailLengthError, toggleEmailLengthError] = useState(false);
  const [showError, toggleShowError] = useState(false);
  const [fieldValue, setFieldValue] = useState(multiple ? [] : "");
  /** optionSelected, setOptionSelected - this variable is created to know whether a
   * input text is populated by value selected from dropdown.
   */
  const [optionSelected, setOptionSelected] = useState(false);
  const timeoutId = useRef(); //Used for storing/clearing the timeout if one is in-progress
  /** dropDownSearchValue - Used for storing the typed text for dropdown scenarios.
   * we store the text in this state variable only when both values of "multiple" and "isLookUp" is true
   * in other cases we use fieldValue to capture the typed text
   */
  const [dropDownSearchValue, setDropDownSearchValue] = useState("");
  /** errorMessage - This state variable is used to display various error message */
  const [errorMessage, setErrorMessage] = useState("");
  const {pageTitle} = useAppContext();
  
  // when waiting on active directory results, filter the current options array with the new input
  const filterOptions = createFilterOptions({
    stringify: (option) => {
      return `${option[AD_USER.MAIL]} ${option[AD_USER.DISPLAY_NAME]} ${
        option[AD_USER.GIVEN_NAME]
      } ${option[AD_USER.SURNAME]} ${option[AD_USER.UPN]}`;
    },
  });
  // otherwise, skip filtering and just return the option
  const noFilter = (option) => option;

  /** For every change in value and component key, this use effect will be called.Eg : Every time we
   * try to add new value in the input field, this will be called
   */
  useEffect(() => {
    if (value) {
      setFieldValue(value);
    } else {
      setFieldValue(multiple ? [] : "");
    }
    /** We would call validate input in all cases except when isLookUp is true */
    if (!isLookUp) {
      validateInput(value);
    }
  }, [value, componentKey]);

  useEffect(() => {
    if (setError) {
      setError(emailError);
    }
    handleChange(value, emailError);
  }, [emailError]);

  useEffect(() => {
    if (getTypedTextValue) {
      getTypedTextValue(fieldValue);
    }
  }, [fieldValue]);

  /**This useEffect is used to disable the dropdown in case if the input value length (typed text)
   * are less than 3 and the response arrives late populating the options array. This can be validated
   * by typing more than 2 chars, trigger api call and quickly erase it all, you will be seeing an
   * inconsistent display of dropdown.
   */
  useEffect(() => {
    //if we are using field value for capturing typed text then this "if" logic will execute
    if (
      typeof fieldValue !== DATATYPE.OBJECT &&
      options.length > 0 &&
      fieldValue.length <= SEARCH.MIN_CHAR_LENGTH
    ) {
      setOptions([]);
    }
    //if we are using dropDownSearchValue for capturing typed text then this "if" logic will execute
    else if (
      dropDownSearchValue &&
      options.length > 0 &&
      dropDownSearchValue.length <= SEARCH.MIN_CHAR_LENGTH
    ) {
      setOptions([]);
    }
  }, [options]);

  /** This useEffect is used to handle "HandleSearch" functionality separately for
   * every change in field value. */
  useEffect(() => {
    /** We are processing the inputText for searching only if the isLookUp value is true
     * and when option is not selected yet
     */
    if (isLookUp && !optionSelected) {
      //We want handle search to happen only when the field type is string and not array
      if (typeof fieldValue === DATATYPE.STRING) {
        /** Set Timeout is given here to make sure that we trigger api call only after 300
         * milliseconds of input typing. This way we can avoid API call being triggered for
         * every letter typed
         */
        timeoutId.current = setTimeout(() => {
          handleSearch(fieldValue);
        }, SEARCH.DELAY_MS);
      }
    }
  }, [fieldValue]);

  /** This useEffect is used to handle "HandleSearch" functionality separately for
   * every change in dropDownSearchValue */
  useEffect(() => {
    /** We are processing the inputText for searching only if the isLookUp value is true
     * and when option is not selected yet
     */
    if (isLookUp && !optionSelected) {
      /** Set Timeout is given here to make sure that we trigger api call only after 300
       * milliseconds of input typing. This way we can avoid API call being triggered for
       * every letter typed
       */
      timeoutId.current = setTimeout(() => {
        handleSearch(dropDownSearchValue);
      }, SEARCH.DELAY_MS);
    }
  }, [dropDownSearchValue]);

  /** processInputForSearch - this is used to process the typed text before sending it for dropdown */
  const processInputForSearch = (inputText) => {
    if (isLookUp && timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    if (!multiple) {
      setFieldValue(inputText);
      if (!isLookUp) validateInput(inputText);
    } else {
      //Handle Scenarios of multiple-true and isLookUp is false (or) true
      const regex = /[,;(\r\n|\r|\n)]/g;
      /**
       * Below condition check if multiples addreses are copied in 'to' field
       * so regex check if string contains ; or , or \n, checking these characters
       * in regex confirms that string may contain multiple email addresses
       * if this is true then parse the email addresses from the string
       * verify the emails addresses if they're valid
       * then finally set the email addresses list to state
       */
      if(regex.test(inputText)) {
        // Define a regex pattern to match email addresses
        const emailPattern = /\b\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+\b/g;
        // Find all matches of email addresses in the input string in 'to' field
        const emailMatches = inputText.match(emailPattern);
        validateInput(emailMatches);
        handleChange([...value, ...emailMatches], emailError);
      }
      else {
        setDropDownSearchValue(inputText);
      }
    }
  };

  const validateInput = (input) => {
    let valid = false;
    if (multiple) {
      valid = validateEmailArray(input);
    } else {
      valid = validateEmail(input);
      if (field.max && input?.length > field.max) {
        toggleEmailLengthError(true);
      } else if (emailLengthError) {
        toggleEmailLengthError(false);
      }
    }
    toggleEmailError(!valid); //toggle email error is set to false if we receive valid email
    if (multiple || valid) {
      toggleShowError(!valid); //toggle show error is set to false if we receive multiple emails and valid emails
    }
    if (!valid) setErrorMessage(ERROR_MSG.INVALID_EMAIL_ID);
    return valid;
  };

  const handleFieldChange = (_event, newValue) => {
    let recipients;
    let error = null;
    toggleShowError(false);

    if (multiple) {
      recipients = [];
      if (valueType === "userObject") {
        const objects = (newValue || []).filter(
          (value) => typeof value === "object"
        );
        recipients = uniqBy(objects, AD_USER.UPN);
        setDropDownSearchValue("");
      } else {
        (newValue || []).forEach((recipient) => {
          // get the email address
          let mailId;
          if (typeof recipient === DATATYPE.STRING) {
            mailId = recipient;
          } else {
            mailId =
              valueType === "mail"
                ? fetchMailIDFromUserObject(recipient)
                : // TODO
                  // : valueType === "upn"
                  // ? fetchUpnFromUserObject(recipient)
                  // : valueType === "displayName"
                  // ? fetchNameFromUserObject(recipient)
                  constructFullUserDetailsForDropDown(recipient);
          }

          // validations
          if (!mailId) return;
          if (checkIfEmailAlreadyExist(recipients, mailId)) {
            error = ERROR_MSG.EMAIL_ID_ALREADY_ADDED;
          } else if (recipients.length > SEARCH.MAX_EMAIL_LIMIT_ALLOWED) {
            error = ERROR_MSG.EMAIL_LIMIT_REACHED;
          } else if (mailId === dropDownSearchValue) {
            const emailInOptions = checkIfEmailInUserList(options, mailId);
            if (!validateEmail(mailId)) {
              error = ERROR_MSG.INVALID_EMAIL_ID;
            } else if (disableFreeText && !emailInOptions) {
              error = ERROR_MSG.EMAIL_NOT_FOUND;
            }
          }

          if (error) {
            toggleShowError(true);
            setErrorMessage(error);
            enableAnalytics && trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.INVALID_EMAIL_ID, pageTitle: pageTitle}, EVENT_TYPES.CREATE_MESSAGE_ERRORS)
          } else {
            recipients.push(mailId);
          }
        });

        setDropDownSearchValue("");
        if (!isLookUp) validateInput(recipients);
      }
    } else {
      // single-value field
      const mailId = newValue || fieldValue;
      if (typeof mailId === DATATYPE.STRING) {
        recipients = mailId;
        if (!optionSelected) {
          const valid = validateInput(recipients);
          !valid && enableAnalytics && trackEvent(EVENTS.ERRORS.GENERIC_ERROR, {errorMsg: ERROR_MSG.INVALID_EMAIL_ID, pageTitle: pageTitle},  EVENT_TYPES.COMMON.EMAIL_VALIDATION)
          toggleShowError(!valid);
        }
      } else {
        recipients = constructFullUserDetailsForDropDown(mailId);
        //We are setting the selected dropdown value below
        if (setValueFromDropdownSelected) setValueFromDropdownSelected(mailId);
        //We are notifying that the current change in input is because of dropdown selection
        setOptionSelected(true);
        /**We are setting the options to empty array to avoid dropdown being displayed
         * with last fetched options
         * */
        setOptions([]);
      }
    }
    handleChange(recipients, emailError);
    toggleLoadingResults(false);
  };

  const handleFieldBlur = (event) => {
    const inputValue = multiple
      ? [...(value || []), dropDownSearchValue]
      : fieldValue;
    if (!disableFreeText && valueType !== "userObject")
      handleFieldChange(event, inputValue);
  };

  // fetch matching results from active directory
  const handleSearch = async (value) => {
    if (!value) {
      setOptions([]);
    } else {
      if (value.length > SEARCH.MIN_CHAR_LENGTH) {
        try {
          const results = await fetchADSearchResults(value, associateOnly, includeGroups);
          setOptions(results ? results : [SEARCH.EMPTY_RESULTS_DROPDOWN_MESSAGE]);
          toggleLoadingResults(false);
        } catch (error) {
          setOptions([SEARCH.ERROR_DROPDOWN_MESSAGE]);
          toggleLoadingResults(false);
        }
      }
    }
  };

  return (
    <>
      <Autocomplete
        disabledItemsFocusable
        key={componentKey}
        value={fieldValue}
        options={options}
        filterOptions={loadingResults ? filterOptions : noFilter}
        onInputChange={(e, newValue) => {
          // handle input
          if (newValue && typeof newValue === DATATYPE.OBJECT) {
            //if AD search result
            newValue = newValue[AD_USER.MAIL] || newValue[AD_USER.UPN];
          }
          processInputForSearch(newValue);
          /** Loading is required only when the input field is dropdown type */
          if (isLookUp) {
            toggleLoadingResults(
              newValue &&
                newValue.length > SEARCH.MIN_CHAR_LENGTH &&
                newValue !== fieldValue
                ? true
                : false
            );
          }
        }}
        onChange={handleFieldChange}
        freeSolo
        multiple={multiple}
        openOnFocus={false}
        disableClearable
        clearOnBlur={true}
        getOptionLabel={(option) => {
          return typeof option === DATATYPE.OBJECT
            ? constructFullUserDetailsForDropDown(option)
            : option;
        }}
        /** getOptionDisabled - this is added to make sure that the "No Matches found" text is
         * disabled in dropdown
         */
        getOptionDisabled={(option) =>
          typeof option === DATATYPE.OBJECT && option["message"] ? true : false
        }
        renderOption={(option) => {
          if (option?.[AD_USER.DISPLAY_NAME]) {
            return (
              <Typography variant="body2">
                {constructFullUserDetailsForDropDown(option)}
              </Typography>
            );
          } else {
            return <Typography variant="body2">{option["message"]}</Typography>;
          }
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            return (
              <Chip
                key={index}
                label={
                  <Typography variant="body2">
                    {valueType === "userObject" ? option[displayField] : option}
                    {/** The type of option will be string hence we can use it directly*/}
                  </Typography>
                }
                {...chipProps}
                {...getTagProps({ index })}
              />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            multiline={multiple}
            error={showError || emailLengthError}
            helperText={
              showError ? errorMessage : emailLengthError ? field.errorText : ""
            }
            onBlur={handleFieldBlur}
            variant="outlined"
            label={field.label}
            placeholder={!value?.length ? field.placeholder : ""}
            required={field.required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingResults ? (
                    <CircularProgress size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        id={`${id}-EmailInputField-AutoComplete`}
        PaperComponent={CustomPaper}
        aria-label="Autocomplete Input"
      />
    </>
  );
}
export default EmailInputField;
const CustomPaper = (props) => {
  return <Paper elevation={1} {...props} />;
};
