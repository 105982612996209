import _ from "lodash";
import { TIME_ZONE } from "../consts/Common";
import { REQUEST_PARAMS, TEAM_USER } from "../consts/DBFields";
import { fileTypeFromExtenstion, IMAGE_FORMATS, MEDIA_SCHEMA, MEDIA_TYPE_FOR_MEDIA_THUMBNAIL } from "../consts/MediaLibrary";
import docLogo from "../assets/svg/doc-thumb.svg";
import pdfLogo from "../assets/svg/pdf-thumb.svg";
import pptLogo from "../assets/svg/ppt-thumb.svg";
import xlsLogo from "../assets/svg/xls-thumb.svg";
import zipLogo from "../assets/svg/zip-thumb.svg";
import unknownMediaLogo from "../assets/svg/unknown-logo.svg";
import { JWT } from "../consts/AuthFields";

export const getMediaOwnerName = (teamUsers = {}, createdByUpnName = "", teamId = "")=>{
    const user =  teamUsers?.[teamId]?.find((user)=>{
      return user?.[TEAM_USER.UPN]?.toLowerCase() === createdByUpnName?.toLowerCase();
    })
    return user?.[TEAM_USER.NAME] || createdByUpnName;
}

export const splitFilename = (filename) => {
    if(!filename) return {
        name: "",
        extension: ""
    };
    const dotIndex = filename.lastIndexOf(".");
    if(dotIndex === -1) return {
        name: filename,
        extension: "",
    }
    const name = filename.substring(0, dotIndex);
    const extension = filename.substring(dotIndex + 1);
    return { name: name, extension: extension };
}

export const getMediaName = (name) => {
    if(!name) return "";
    return name.trim();
}

export const getFileExtension = (name) => {
    return splitFilename(name).extension;
}

export const getMediaType = (name) => {
    if(!name) return "";
    const extension = getFileExtension(name);
    return fileTypeFromExtenstion[extension] || extension;
}

export const getCreatedOnDate = (createdAt) => {
    if (createdAt) {
        const date = new Date(createdAt);
        return `${date.toLocaleString("as-IN", {
            timeZone: TIME_ZONE,
            year: "numeric",
            month: "short",
            day: "2-digit",
         })}`;
    } else return null;
}

export const sortMedia = (mediaList, sortByProperty, sortOrder) => {
    let sortedMediaList = [];
    if(sortByProperty === MEDIA_SCHEMA.CREATED_ON) {
        sortedMediaList = _.sortBy(mediaList, [sortByProperty]);
    } else {
        sortedMediaList = _.sortBy(mediaList, (media) => {
            return _.capitalize(media[sortByProperty]);
        });
    }

    if(sortOrder === REQUEST_PARAMS.DESCENDING) {
        sortedMediaList = sortedMediaList.reverse();
    }
    return sortedMediaList;
}

export const getThumbnailForMedia = (fileType) => {
    const thumbnailMapping = {
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.PDF.value]: pdfLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.DOC.value]: docLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.PPT.value]: pptLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.XLS.value]: xlsLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.ZIP.value]: zipLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.CALENDAR.value]: unknownMediaLogo,
        [MEDIA_TYPE_FOR_MEDIA_THUMBNAIL.UNKNOWN_FILE.value]: unknownMediaLogo,
    }
    return thumbnailMapping[fileType] || unknownMediaLogo;
}

export const isMediaOwner = (media, user) => {
    return media?.[MEDIA_SCHEMA.OWNER]?.toLowerCase() === user?.[JWT.UPN]?.toLowerCase()
}

export const filterOnlyImages = (mediaList = []) => {
    return mediaList.filter((media) => {
        const extension = getFileExtension(media[MEDIA_SCHEMA.BLOB_NAME]);
        return IMAGE_FORMATS.indexOf(extension.toLowerCase()) >= 0 ? true : false;
    });
}
