import { URLS, getAPIEndPoint } from "../consts/APIEndpoints";
import { getResponse } from "../utils/common";

export async function getFolderAPI(folder_id, params = {}) {
    let response = await getResponse(
      `${getAPIEndPoint(URLS.FOLDERS_API)}/${folder_id}`,
      "get",
      undefined,
      {
        ...params,
      }
    );
    return response;
}

export async function updateFolderAPI(folderObj, folder_id, params) {
    let response = await getResponse(
      `${getAPIEndPoint(URLS.FOLDERS_API)}/${folder_id}`,
      "put",
      folderObj,
      {
        ...params,
      }
    );
    return response;
}

export async function createFolderAPI(folderData) {
  return await getResponse(
    `${getAPIEndPoint(URLS.FOLDERS_API)}/`,
    "post",
    folderData
  );
}

export async function deleteFolderAPI(folderId) {
  return await getResponse(
    `${getAPIEndPoint(URLS.FOLDERS_API)}/${folderId}/`,
    "delete",
  );
}