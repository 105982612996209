import React, { createContext, useCallback, useContext, useEffect, useState, useRef } from "react";
import { useAppContext } from "./Core";
import { CAMPAIGN, REQUEST_PARAMS, TEAM, TEMPLATE } from "../consts/DBFields";
import { NOTIFICATION_CHANNELS } from "../consts/Campaign";
import {
  GALLERY_SCHEMA,
  IMAGE_SCHEMA,
  SORTING_OPTIONS,
  GALLERY_TABS,
  IMAGE_GALLERY_TABS,
} from "../consts/Template";
import { findNotificationOfType } from "../utils/campaign";
import { previewTemplate } from "../APIs/CampaignAPI";
import { EDITOR_COLORS } from "../consts/Colors";
import {
  getTeamAssets,
  renameImage,
  softDeleteImage,
  uploadFile,
} from "../utils/blobStorage";
import { FEATURE } from "../consts/Common";
import { setToken } from "../utils/auth/SAS";
import { isSignedInUserATeamOwner } from "../utils/user";
import { sortImages } from "../utils/image";
import { useTemplatesContext } from "./TemplatesList";
import THEME_TEMPLATES from "../assets/themeTemplates";
import { useCampaignsContext } from "./CampaignsList";
import Configs from "../Configurations";
import { filterOnlyImages } from "../utils/mediaUtil";

// Create and export the consumer, which allows state to be used by other components
export const Context = createContext();
export const EditorState = Context.Consumer;
export const useEditorContext = () => useContext(Context);

// Create and export the provider, which defines and controls the state
function Editor({ children }) {
  const {
    selectedTeam,
    isToggleEnabled,
    user,
    teamUsers,
    getMembersOfTeam,
    userTeams,
  } = useAppContext();

  const { getSavedTemplates, teamTemplates } = useTemplatesContext();
  const { teamCampaigns, getTeamCampaigns } = useCampaignsContext();

  const [editor, setEditor] = useState();
  const [galleryTemplates, setGalleryTemplates] = useState({});
  const [savedTemplates, setSavedTemplates] = useState([]);
  const [usedTemplates, setUsedTemplates] = useState([]);
  const [galleries, setGalleries] = useState({});
  const [loadingGallery, toggleLoadingGallery] = useState(false);
  const [fetchingTemplates, setFetchingTemplates] = useState([]);
  const [galleriesCreatedFor, toggleGalleriesCreatedFor] = useState(null);
  const [activeGalleryType, setActiveGalleryType] = useState(
    GALLERY_TABS.THEME
  );
  const [activeTemplateTab, setActiveTemplateTab] = useState(0);
  const [gallery, setGallery] = useState(null);
  const [defaultImages, setDefaultImages] = useState({});
  const [teamImages, setTeamImages] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [imageGalleries, setImageGalleries] = useState({});
  const [filteredImageGalleries, setFilteredImageGalleries] = useState({});
  const [imageSearchCriteria, setImageSearchCriteria] = useState("");
  const [loadingImages, toggleLoadingImages] = useState(false);
  const [sortingImages, toggleSortingImages] = useState(false);
  const [imageSortBy, setImageSortBy] = useState(IMAGE_SCHEMA.CREATED_ON);
  const [imageSortOrder, setImageSortOrder] = useState(
    REQUEST_PARAMS.DESCENDING
  );
  const [imagesLoadedForTeamId, setImagesLoadedForTeamId] = useState(null);
  const [activeImageGalleryType, setActiveImageGalleryType] = useState(
    IMAGE_GALLERY_TABS.MY_IMAGES
  );
  const [activeImageTab, setActiveImageTab] = useState(0);
  const [imageGalleryPage, setImageGalleryPage] = useState(0);
  const [imageGallery, setImageGallery] = useState(null);
  const [imageError, toggleImageError] = useState(false);
  const [uploadingImages, toggleUploadingImages] = useState(false);
  const [colorPresets, setColorPresets] = useState(EDITOR_COLORS);
  const [templateTabs, setTemplateTabs] = useState([]);
  const [imageTabs, setImageTabs] = useState([]);
  const [activeBaseTemplate, setActiveBaseTemplate] = useState();

  useEffect(() => {
    setGalleries({});
    toggleGalleriesCreatedFor(null); // galleries will need to be recreated on next visit to gallery tab
    setImageGalleries({});
    setFilteredImageGalleries({});
    setImageSearchCriteria("");
    setImagesLoadedForTeamId(null);
    setTemplateTabs([]);
    setImageTabs([]);
  }, [selectedTeam?.[TEAM.ID]]);

  useEffect(() => {
    if (galleriesCreatedFor) {
      toggleLoadingGallery(true);
      setGallery(galleries[activeTemplateTab] || []);
    }
  }, [galleriesCreatedFor, activeTemplateTab, galleries[activeTemplateTab]]);

  useEffect(() => {
    if (imagesLoadedForTeamId) {
      setImageGallery(filteredImageGalleries[activeImageTab] || []);
    }
  }, [
    imagesLoadedForTeamId,
    activeImageTab,
    filteredImageGalleries[activeImageTab],
  ]);

  useEffect(() => {
    if (gallery !== null) {
      toggleLoadingGallery(false);
    }
  }, [gallery]);

  useEffect(() => {
    if (imageGallery !== null) {
      toggleLoadingImages(false);
    }
  }, [imageGallery]);

  const updateImageCriteria = useCallback((newValue)=>{
    setImageSearchCriteria(newValue);
  }, []);

  const updateImageGalleries = useCallback((newValue)=>{
    setImageGalleries(newValue);
  }, [])

  useEffect(() => {
   if(imageSearchCriteria!=='') {
    handleImageSearch();
   }
  }, [imageSearchCriteria]);

  useEffect(() => {
    setActiveImageGalleryType(
      activeImageTab ? IMAGE_GALLERY_TABS.GALLERY : IMAGE_GALLERY_TABS.MY_IMAGES
    ); // if the imageTab index is 0, then it is "My Images", otherwise it is a theme gallery
  }, [activeImageTab]);

  useEffect(() => {
    if (allImages.length) {
      let themeImages = {};
      if (imageTabs.length > 1) {
        imageTabs.slice(1, imageTabs?.length).forEach(async (tab, index) => {
          const assets = allImages.find(
            (asset) => asset.themeName === tab.label
          );
          themeImages[index + 1] = sortImages(
            assets?.assets,
            imageSortBy,
            imageSortOrder
          );
        });
      }
      setDefaultImages(themeImages);
    }
  }, [allImages, imageTabs]);

  const createGalleries = async (notificationType, selectedCampaign) => {
    toggleLoadingGallery(true);
    let teamId =
      selectedCampaign?.[CAMPAIGN.TEAM_ID] || selectedTeam?.[TEAM.ID];
    setActiveGalleryType(GALLERY_TABS.THEME);
    setActiveTemplateTab(0);
    const tabs = configureTabs(
      teamId !== selectedTeam?.[TEAM.ID]
        ? userTeams?.find((team) => team[TEAM.ID] === teamId)
        : selectedTeam
    );
    setTemplateTabs(tabs);
    createStarterTemplateGallery(notificationType, tabs);
    setFetchingTemplates([tabs.length - 2, tabs.length - 1]);
    createSavedTemplateGallery(notificationType, teamId, tabs);
    createPreviouslyUsedTemplateGallery(notificationType, teamId, tabs);
    toggleGalleriesCreatedFor(teamId);
  };

  // build the template gallery tabs based on available themes
  const configureTabs = (team, isImage) => {
    const teamTabs = [];
    let tabIndex = 0;
    if (isImage) {
      teamTabs.push({
        value: tabIndex,
        label: IMAGE_GALLERY_TABS.MY_IMAGES,
      }); // all teams will have access to general gallery templates
      tabIndex++;
    }
    let themeList = team?.[TEAM.THEMES] || [];
    themeList.sort(); // sort themes alphabetically
    themeList?.forEach((theme) => {
      teamTabs.push({
        value: tabIndex,
        label: theme,
      });
      tabIndex++;
    });
    if (!isImage) {
      teamTabs.push({ value: tabIndex, label: GALLERY_TABS.SAVED });
      teamTabs.push({ value: tabIndex + 1, label: GALLERY_TABS.USED });
    }
    return teamTabs;
  };

  // use the starterTemplates directory to create gallery objects
  const createStarterTemplateGallery = (notificationType, tabs) => {
    let starterTemplates = {};
    tabs.slice(0, tabs?.length - 2).forEach((tab, index) => {
      starterTemplates[index] =
        notificationType === NOTIFICATION_CHANNELS.mail.value
          ? THEME_TEMPLATES[tab.label] || []
          : [];
    });
    setGalleryTemplates(starterTemplates);
  };

  const createSavedTemplateGallery = async (notificationType, teamId, tabs) => {
    let savedTemplatesList = [];
    if (notificationType === NOTIFICATION_CHANNELS.mail.value) {
      let allTemplates = teamTemplates;
      if (!teamTemplates.length || selectedTeam?.[TEAM.ID] !== teamId) {
        allTemplates = await getSavedTemplates(teamId, {
          [REQUEST_PARAMS.SORT_BY]: TEMPLATE.NAME,
          [REQUEST_PARAMS.SORT_ORDER]: "asc",
        });
      }
      if (allTemplates?.length) {
        await allTemplates.forEach((template) => {
          if (
            template[TEMPLATE.TYPE] === notificationType &&
            template[TEMPLATE.CONTENT].trim()
          ) {
            savedTemplatesList.push({
              [GALLERY_SCHEMA.NAME]: template[TEMPLATE.NAME],
              [GALLERY_SCHEMA.PREVIEW]: template[TEMPLATE.HTML],
              [GALLERY_SCHEMA.ID]: template[TEMPLATE.ID],
            });
          }
        });
      }
    }
    setSavedTemplates(savedTemplatesList);
    setFetchingTemplates((prev) =>
      prev.filter((tab) => tab !== tabs.length - 2)
    );
  };
  // fetch all of the sent campaigns for a team
  // if the campaign has a notification of the correct type, add it to the gallery
  const createPreviouslyUsedTemplateGallery = async (
    notificationType,
    teamId,
    tabs
  ) => {
    let usedTemplateList = [];
    let allCampaigns = teamCampaigns;
    if (
      !teamCampaigns.length ||
      teamCampaigns[0][CAMPAIGN.TEAM_ID] !== teamId
    ) {
      allCampaigns =
        (await getTeamCampaigns({
          [REQUEST_PARAMS.SORT_BY]: TEMPLATE.NAME,
          [REQUEST_PARAMS.SORT_ORDER]: "asc",
        })) || [];
    }

    if (allCampaigns?.length) {
      allCampaigns.forEach((campaign) => {
        if (campaign[CAMPAIGN.DATE_LAST_EXECUTED]) {
          const validNotification = findNotificationOfType(
            campaign,
            notificationType
          );
          if (validNotification) {
            usedTemplateList.push({
              [GALLERY_SCHEMA.NAME]: campaign[CAMPAIGN.NAME],
              [GALLERY_SCHEMA.PREVIEW]: "",
              [GALLERY_SCHEMA.ID]: campaign[CAMPAIGN.UID],
            });
          }
        }
      });
    }
    setUsedTemplates(usedTemplateList);
    setFetchingTemplates((prev) =>
      prev.filter((tab) => tab !== tabs.length - 1)
    );
  };
  const createImageGalleries = async (selectedCampaign) => {
    toggleImageError(false);
    const teamId =
      selectedCampaign?.[CAMPAIGN.TEAM_ID] || selectedTeam?.[TEAM.ID];
    if (imagesLoadedForTeamId !== teamId) {
      setActiveImageTab(0);
      try {
        toggleLoadingImages(true);
        const tabs = configureTabs(
          teamId !== selectedTeam?.[TEAM.ID]
            ? userTeams?.find((team) => team[TEAM.ID] === teamId)
            : selectedTeam,
          true
        );
        setImageTabs(tabs);
        let teamMembers = teamUsers?.hasOwnProperty(teamId)
          ? teamUsers[teamId]
          : await getMembersOfTeam(teamId);
        const isTeamOwner = isSignedInUserATeamOwner(user, teamMembers);
        await fetchTeamImages(teamId, isTeamOwner, user, true);
      } catch (err) {
        toggleImageError(true);
        setTeamImages([]);
        setImagesLoadedForTeamId(null);
        toggleLoadingImages(false);
      }
    }
  };

  const fetchTeamImages = async (teamId, isTeamOwner, user, showError) => {
    let images = teamId ? await getTeamAssets(teamId, isTeamOwner, user) : null;
    if (images?.retry) {
      setToken(teamId, "image", null);
      await fetchTeamImages(teamId, isTeamOwner, user, showError);
    } else if (showError && images?.error) {
      throw new Error(images.error);
    }
    const themeImages = images?.assets.filter((asset) => asset.themeName);
    setAllImages(themeImages);
    images = images?.assets.find((asset) => !asset.themeName).assets || [];
    images = filterOnlyImages(images);
    const sorted = sortImages(images, imageSortBy, imageSortOrder);
    setTeamImages(sorted);
    setImagesLoadedForTeamId(teamId);
  };

  const uploadTeamImages = async (images) => {
    let updatedGallery = [...imageGalleries[0]];
    let error = false;
    const uploads = [];
    const useUniqueId = isToggleEnabled(FEATURE.NEW_IMAGE_UIDS);
    await images?.forEach((image) => {
      uploads.push(
        uploadFile(
          selectedTeam[TEAM.ID],
          null,
          user,
          "image",
          image,
          useUniqueId
        )
      );
    });
    let retryImages = [];
    await Promise.all(uploads).then((res) => {
      res.forEach((response, index) => {
        if (response?.retry) {
          retryImages.push(images[index]);
        } else if (!response?.error) {
          const filePath = `${Configs?.REACT_APP_IMAGE_STORAGE_URL}/${response?.sas_container}/${response?.fileName}`;
          updatedGallery.unshift({
            [IMAGE_SCHEMA.SRC]: filePath,
            [IMAGE_SCHEMA.NAME]: images[index]?.name,
            [IMAGE_SCHEMA.CREATED_ON]: Date.now(),
            [IMAGE_SCHEMA.CAN_EDIT]: true,
            [IMAGE_SCHEMA.BLOB_NAME]: response?.blob,
          });
        } else {
          error = true;
        }
      });
    });
    if (retryImages?.length) {
      setToken(selectedTeam[TEAM.ID], "image", null); // clear the token
      return await uploadTeamImages(retryImages); // retry
    } else {
      toggleLoadingImages(true);
      const updatedGalleries = {
        ...imageGalleries,
        [0]: updatedGallery,
      };
      setImageGalleries(updatedGalleries);
      setFilteredImageGalleries(updatedGalleries);
      setImageSearchCriteria("");
      setActiveImageTab(0);
      setImageGalleryPage(0);
      toggleLoadingImages(false);
      return error;
    }
  };

  const addUsedColor = (hexColor) => {
    if (!EDITOR_COLORS.includes(hexColor)) {
      const colorIndex = colorPresets.findIndex((color) => color === hexColor);
      if (colorIndex < 0) {
        if (colorPresets.length < 32) {
          setColorPresets([...colorPresets, hexColor]);
        } else {
          let customColors = colorPresets.slice(EDITOR_COLORS.length);
          customColors = customColors.slice(1);
          customColors.push(hexColor);
          setColorPresets([...EDITOR_COLORS, ...customColors]);
        }
      } else if (colorIndex >= 0) {
        setColorPresets([
          ...colorPresets.slice(0, colorIndex),
          ...colorPresets.slice(colorIndex + 1),
          hexColor,
        ]);
      }
    }
  };

  const handleImageSearch = () => {
    setImageGalleryPage(0);
    if (imageSearchCriteria?.length >= 3) {
      const searchCriteria = imageSearchCriteria.toLowerCase();
      let updatedImageGalleries = {};
      Object.keys(imageGalleries)?.forEach((galleryType) => {
        const gallery = imageGalleries[galleryType]?.filter((image) => {
          const imageString = JSON.stringify(image)?.toLowerCase() || "";
          if (imageString.includes(searchCriteria)) {
            return image;
          }
        });
        updatedImageGalleries[galleryType] = gallery;
      });
      setFilteredImageGalleries(updatedImageGalleries);
    } else {
      setFilteredImageGalleries(imageGalleries);
    }
  };

  useEffect(() => {
    let updatedGalleries = {};
    if (templateTabs?.length >= 2) {
      updatedGalleries = {
        ...galleryTemplates,
        [templateTabs.length - 2]: savedTemplates,
        [templateTabs.length - 1]: usedTemplates,
      };
    }
    setGalleries(updatedGalleries);
  }, [galleryTemplates, savedTemplates, usedTemplates, templateTabs]);

  useEffect(() => {
    const galleries = {
      0: teamImages,
      ...defaultImages,
    };
    setImageGalleries(galleries);
    setFilteredImageGalleries(galleries);
  }, [defaultImages, teamImages]);

  // return the preview in the gallery template or fetch it if doesn't exist
  const fetchGalleryPreview = async (
    template,
    currentGallery,
    templateIndex
  ) => {
    let preview = template?.[GALLERY_SCHEMA.PREVIEW];
    if (!preview && currentGallery === GALLERY_TABS.USED) {
      // TODO, fetch the preview for saved and used templates
      preview = await previewTemplate(template[GALLERY_SCHEMA.ID]);
      if (preview) {
        let usedTemplateGallery = usedTemplates;
        if (usedTemplateGallery && usedTemplateGallery[templateIndex]) {
          usedTemplateGallery[templateIndex][GALLERY_SCHEMA.PREVIEW] = preview;
          setUsedTemplates(usedTemplateGallery);
        }
      }
    }
    return preview;
  };

  const deleteTeamImage = async (image) => {
    const error = await softDeleteImage(selectedTeam[TEAM.ID], image);
    if (!error) {
      const updatedGallery = await imageGalleries[activeTemplateTab]?.filter(
        (img) => img[IMAGE_SCHEMA.SRC] !== image?.[IMAGE_SCHEMA.SRC]
      );
      const updatedGalleries = {
        ...imageGalleries,
        [activeTemplateTab]: updatedGallery,
      };
      setImageGalleries(updatedGalleries);
      setFilteredImageGalleries(updatedGalleries);
      setImageSearchCriteria("");
      setActiveImageTab(0);
      setImageGalleryPage(0);
    }
    return error;
  };

  const renameTeamImage = async (image, newName, imagesPerPage) => {
    image = { ...image, [IMAGE_SCHEMA.NAME]: newName };
    const error = await renameImage(selectedTeam[TEAM.ID], image);
    if (!error) {
      const imageIndex = await imageGalleries[0]?.findIndex(
        (img) => img[IMAGE_SCHEMA.SRC] === image?.[IMAGE_SCHEMA.SRC]
      );
      if (imageIndex >= 0) {
        const updatedGallery = imageGalleries[0];
        updatedGallery[imageIndex] = image;
        const updatedGalleries = {
          ...imageGalleries,
          [0]: updatedGallery,
        };
        setImageGalleries(updatedGalleries);
        setFilteredImageGalleries(updatedGalleries);
        setImageSearchCriteria("");
        setActiveImageTab(0);
        setImageGalleryPage(Math.floor(imageIndex / imagesPerPage));
      }
    }
    return error;
  };

  const updateImageSort = async (sortOption) => {
    toggleSortingImages(true);
    const params = SORTING_OPTIONS[sortOption]?.params;
    let updatedSortBy = params[REQUEST_PARAMS.SORT_BY];
    let updatedSortOrder = params[REQUEST_PARAMS.SORT_ORDER];
    setImageSortBy(updatedSortBy);
    setImageSortOrder(updatedSortOrder);
    if (updatedSortBy !== imageSortBy) {
      let sortedImages = {};
      // sort the images
      await Object.keys(filteredImageGalleries).forEach((gallery) => {
        sortedImages[gallery] = sortImages(
          filteredImageGalleries[gallery],
          updatedSortBy,
          updatedSortOrder
        );
      });
      setFilteredImageGalleries(sortedImages);
    } else if (updatedSortOrder !== imageSortOrder) {
      await reverseImageSortOrder();
    }
    setImageGalleryPage(0);
    toggleSortingImages(false);
  };

  const reverseImageSortOrder = () => {
    let orderedImages = {};
    Object.keys(filteredImageGalleries).forEach((gallery) => {
      orderedImages[gallery] = filteredImageGalleries[gallery]?.reverse();
    });
    setFilteredImageGalleries(orderedImages);
  };

  // create one state object to pass to the Provider
  const initialState = {
    editor,
    setEditor: async (editor) => {
      setEditor(editor);
    },
    galleries,
    fetchGalleryPreview,
    loadingGallery,
    fetchingTemplates,
    createGalleries,
    activeGalleryType,
    gallery,
    createImageGalleries,
    imageError,
    imageGalleries,
    filteredImageGalleries,
    loadingImages,
    activeImageGalleryType,
    activeImageTab,
    setActiveImageTab,
    imageGalleryPage,
    setImageGalleryPage,
    imageGallery,
    uploadTeamImages,
    uploadingImages,
    toggleUploadingImages,
    colorPresets,
    setColorPresets,
    updateImageGalleries,
    addUsedColor,
    handleImageSearch,
    imageSearchCriteria,
    updateImageCriteria,
    deleteTeamImage,
    renameTeamImage,
    updateImageSort,
    sortingImages,
    teamImages,
    fetchTeamImages,
    activeBaseTemplate,
    setActiveBaseTemplate,
    currentSort: Object.keys(SORTING_OPTIONS).find(
      (option) =>
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_BY] ===
          imageSortBy &&
        SORTING_OPTIONS[option].params[REQUEST_PARAMS.SORT_ORDER] ===
          imageSortOrder
    ),
    templateTabs,
    imageTabs,
    activeTemplateTab,
    setActiveTemplateTab: (tabIndex) => {
      let type;
      switch (tabIndex) {
        case templateTabs?.length - 1:
          type = GALLERY_TABS.USED;
          break;
        case templateTabs?.length - 2:
          type = GALLERY_TABS.SAVED;
          break;
        default:
          type = GALLERY_TABS.THEME;
          break;
      }
      setActiveGalleryType(type);
      setActiveTemplateTab(tabIndex);
    },
    setTemplateTabs: (tabs)=>{
      setTemplateTabs(tabs);
    },
    setUsedTemplates: (templates)=>{
      setUsedTemplates(templates)
    },
    setAllImages: (images)=>{
      setAllImages(images)
    },
    setImageTabs: (tabs)=>{
      setImageTabs(tabs)
    }
  };
  return <Context.Provider value={initialState}>{children}</Context.Provider>;
}

export default Editor;
