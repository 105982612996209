import React from "react";

// MUI
import { IconButton } from "@material-ui/core";
import { NotificationsNone as NotificationBell } from "@material-ui/icons";

const QA_ID_PREFIX = `NotificationIcon`;

function NotificationIcon() {
  return (
    <IconButton id={`${QA_ID_PREFIX}-IconButton`}>
      <NotificationBell />
    </IconButton>
  );
}

export default NotificationIcon;
