import { JWT } from "../consts/AuthFields";
import { CAMPAIGN, SENDER, TEAM, TEAM_USER, USER } from "../consts/DBFields";
import { USER_ACCESS_ARRAY } from "../consts/Common";
import { getTeamSenders } from "../APIs/UserAPI";
import { SENDER_CATEGORIES } from "../consts/Campaign";
import { AD_USER, USER_TYPE } from "../consts/Common";

export const isUserSuperAdmin = (user) => {
  return user?.[JWT.ROLE] === 700;
};

export const isUserAdmin = (user) => {
  return user?.[JWT.ROLE] >= 500;
};

export const isSignedInUserATeamOwner = (user, teamUsers) => {
  let result = false;
  if (teamUsers && user?.[JWT.UPN]) {
    let currentUser = teamUsers?.find(
      (curr_user) =>
        curr_user[JWT.UPN]?.toLowerCase() === user[JWT.UPN]?.toLowerCase()
    );
    result = isTeamUserOwnerOrCoOwner(currentUser);
  }
  return result;
};

export const isTeamUserOwnerOrCoOwner = (user) => {
  return user?.[USER.ROLE_ID] <= 200 || user?.[USER.ROLE] <= 200;
};

export const isUserCampaignOwner = (user, campaign) => {
  if (campaign?.[CAMPAIGN.OWNER] && user?.[JWT.UPN]) {
    return (
      campaign[CAMPAIGN.OWNER]?.toLowerCase() === user[JWT.UPN]?.toLowerCase()
    );
  }
};

export const isUserFolderOwnerOrCoOwner = (user, folder) => {
  let result = false;
  if (folder && user?.[JWT.UPN]) {
    let currentUser = folder?.permissions?.find(
      (curr_user) =>
        curr_user[JWT.UPN]?.toLowerCase() === user[JWT.UPN]?.toLowerCase()
    );
    result = currentUser?.role >= 1002;
  }
  return result;
};

export const hasBetaAccess = (user) =>
  USER_ACCESS_ARRAY.includes(user.upn.toLowerCase());

export const buildSendersList = async (
  selectedTeam,
  isOwner,
  teamUsers,
  user
) => {
  let senders = [];
  let userEmail; // find the signed in user in the team user list
  try {
    const teamId = selectedTeam?.[TEAM.ID];
    if (teamId) {
      let sharedMailboxes = await getTeamSenders(teamId);
      sharedMailboxes?.map((sender) => {
        const email = sender[SENDER.EMAIL];
        if (email) {
          senders.push({
            category: SENDER_CATEGORIES.MAILBOXES,
            value: email,
          });
        }
      });
      teamUsers = teamUsers?.[teamId] || [];
      let teamSenders = [];
      for (var i = 0; i < teamUsers.length; i++) {
        const teamUser = teamUsers[i];
        const upn = teamUser[TEAM_USER.UPN];
        if (upn.toLowerCase() === user[JWT.UPN]?.toLowerCase()) {
          userEmail = teamUser[TEAM_USER.EMAIL];
          if (!isOwner) {
            break; // found the signed in user, nothing else is needed
          }
        } else if (isOwner && upn) {
          // if a team owner, add all users to the senders list
          teamSenders.push({
            category: SENDER_CATEGORIES.TEAM,
            value: teamUser[TEAM_USER.EMAIL] || upn,
          });
        }
      }
      senders = sortSenders(senders).concat(sortSenders(teamSenders));
    }
  } catch (err) {
    // nothing to handle
  } finally {
    if (user) {
      senders.unshift({
        category: SENDER_CATEGORIES.ME,
        value: user[JWT.EMAIL] || user[JWT.UPN],
      });
    }
  }

  return senders;
};

// sorts a list of senders alphabetically by the sender.value
const sortSenders = (senders) => {
  return senders?.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });
};

/** This method is used to extract the user id alone from the upn email */
export const getUserNameFromUPN = (upnEmail) => {
  if (upnEmail) {
    return upnEmail.substring(0, upnEmail.indexOf("@"));
  } else {
    return "";
  }
};

/** This method is used to combine the Display Name, mail Id and upn to display
 * It follows the following format :
 * <DISPLAYNAME> - <MAIL ID> - <UPN>
 */
export const constructFullUserDetailsForDropDown = (userObject) => {
  if (userObject) {
    if (userObject[AD_USER.DISPLAY_NAME] && AD_USER.UPN) {
      return `${userObject[AD_USER.DISPLAY_NAME]} - ${
        userObject[AD_USER.MAIL] !== null
          ? userObject[AD_USER.MAIL]
          : userObject[AD_USER.UPN]
      } ${userObject[AD_USER.UPN] ? "-" : ""} ${getUserNameFromUPN(userObject[AD_USER.UPN])}`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

/** This method is used to combine the Display Name, mail Id to display.
 * It follows the following format :
 * <DISPLAYNAME> - <MAIL ID>
 */
export const constructDisplayNameMailIdForDropDown = (userObject) => {
  if (userObject) {
    if (userObject[AD_USER.DISPLAY_NAME] && userObject[AD_USER.UPN]) {
      return `${userObject[AD_USER.DISPLAY_NAME]} - ${
        userObject[AD_USER.MAIL] !== null
          ? userObject[AD_USER.MAIL]
          : userObject[AD_USER.UPN]
      }`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

/** This method is used to fetch either the mail id or upn from the given user object */
export const fetchMailIDFromUserObject = (userObject) => {
  if (userObject) {
    if (userObject[AD_USER.UPN]) {
      return userObject[AD_USER.MAIL]
        ? userObject[AD_USER.MAIL]
        : userObject[AD_USER.UPN];
    } else if(userObject[AD_USER.TYPE] === USER_TYPE.GROUP && userObject[AD_USER.MAIL]) {
      return userObject[AD_USER.MAIL];
    } else {
      return "";
    }
  } else {
    return "";
  }
};

/** This method is used to check if an email id already exist in the incoming email array */
export const checkIfEmailAlreadyExist = (emailArray, emailId) => {
  if (emailArray) {
    const lowerCasedArray = emailArray.map((emailId) => emailId.toLowerCase());
    return lowerCasedArray
      ? emailId
        ? lowerCasedArray.includes(emailId.toLowerCase())
        : false
      : false;
  } else {
    return false;
  }
};

/** This method is used to check if an email id is present in the incoming user array */
export const checkIfEmailInUserList = (users = [], emailId) => {
  const mailIds = users.map((option) =>
    fetchMailIDFromUserObject(option).toLowerCase()
  );
  return !!mailIds.find((email) => email === emailId.toLowerCase());
};
