import _ from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { getFolderAPI, updateFolderAPI, } from '../APIs/FoldersAPI';
import { JWT } from '../consts/AuthFields';
import { SNACK_TYPES } from '../consts/Common';
import { FOLDER, FOLDER_PERMISSION, TEAM, CAMPAIGN, TEAM_USER } from '../consts/DBFields';
import { FOLDER_FIELDS, FOLDER_LEVEL_PERMISSIONS, FOLDER_LEVEL_ROLES } from '../consts/Folders';
import { useAppContext } from '../contexts/Core';
import { useFoldersContext } from '../contexts/FoldersList';
import { getErrorType } from '../utils/common';
import { isUserAdmin } from '../utils/user';

function useFolder(InitialFolderData = {}) {

    const {
        setSnackMessage,
        setSnackType,
        setSelectedTeam,
        userTeams,
        signedInUserIsOwner,
        user,
    } = useAppContext();

    const [folder, setFolder] = useState(InitialFolderData);
    const [folder_id, setFolderId] = useState(InitialFolderData[FOLDER.FOLDER_ID] || null);
    const [folderLoading, setFolderLoading] = useState(false);
    const [isFolderDetailsChanged, setIsFolderDetailsChanged] = useState(false);
    const [fieldsChanged, setFieldsChanged] = useState({});
    const [folderError, setFolderError] = useState(false);

    useEffect(() => {
        if (folder?.[CAMPAIGN.TEAM_ID] && userTeams.length > 0) {
            const team = filterTeamFromTeamUsers(userTeams, folder?.[FOLDER.TEAM_ID]);
            setSelectedTeam(team);
        }
    }, [userTeams, folder])

    const getFolder = async (folder_id) => {
        let selectedFolder = {};
        if (folder_id) {
            try {
                const folderResponse = await getFolderAPI(folder_id);
                selectedFolder = folderResponse;
            } catch (err) {
                if(err?.response?.status===404) {
                    setFolder({});
                    setFolderError(true);
                }
                else {
                    setFolder({});
                }
                return {};
            }
        }
        setFolderError(false);
        setFolder(selectedFolder);
        return selectedFolder;
    };
    const filterTeamFromTeamUsers = (userTeams, teamId) => {
        return userTeams?.find((team) => {
            return team?.[TEAM.ID] === teamId
        })
    }

    const updateFolderDetails = async (callback) => {
        const folderObj = {
            [FOLDER.TEAM_ID]: folder[FOLDER.TEAM_ID],
        };

        for(const field of Object.keys(fieldsChanged)) {
            folderObj[field] = folder[field];
        }

        const result = await updateFolder(folderObj);
        if(result){
            setIsFolderDetailsChanged(false);
            setFieldsChanged({});
            if(_.isFunction(callback)) {
                callback(_.cloneDeep(folder));
            }
            return _.cloneDeep(folder);
        }
        return null;        
    }

    const updateFolder = async (folderObj) => {
        try{
            setFolderLoading(true);
            const response = await updateFolderAPI(folderObj, folder_id);
            setSnackType(SNACK_TYPES.SUCCESS);
            setSnackMessage("Folder details updated successfully");
            setFolderLoading(false);
            return response;
        } catch (error) {
            setSnackType(SNACK_TYPES.ERROR);
            setSnackMessage("Error while saving folder details");
        }
        setFolderLoading(false);
    }

    const addMember = (selectedMembers, callback) => {
        const newMembers = selectedMembers.map(member => ({
            upn: member['value'],
            role: FOLDER_LEVEL_ROLES.MEMBER_EDIT
        }))
        folder[FOLDER.PERMISSIONS] = [...folder[FOLDER.PERMISSIONS], ...newMembers];
        setFolder({...folder});
        setIsFolderDetailsChanged(true);
        setFieldsChanged({
            ...fieldsChanged,
            [FOLDER.PERMISSION_TYPE]: FOLDER_LEVEL_PERMISSIONS.PRIVATE,
            [FOLDER.PERMISSIONS]: true,
        })
        callback(true);
    }

    const updateMemberRole = (userToUpdateRole) => {
        const foundIndex = folder[FOLDER.PERMISSIONS].findIndex(member => member[FOLDER_PERMISSION.UPN] === userToUpdateRole[FOLDER_PERMISSION.UPN]);
        if(foundIndex >= 0){
            folder[FOLDER.PERMISSIONS][foundIndex][FOLDER_PERMISSION.ROLE] = userToUpdateRole[FOLDER_PERMISSION.ROLE];
            setFolder({...folder});
        }
        setFieldsChanged({
            ...fieldsChanged,
            [FOLDER.PERMISSION_TYPE]: FOLDER_LEVEL_PERMISSIONS.PRIVATE,
            [FOLDER.PERMISSIONS]: true,
        })
        setIsFolderDetailsChanged(true);
    }

    const removeMembers = (membersToRemove) => {
        folder[FOLDER.PERMISSIONS] = folder[FOLDER.PERMISSIONS].filter(member => !membersToRemove.includes(member.upn));
        setFolder({...folder});
        setFieldsChanged({
            ...fieldsChanged,
            [FOLDER.PERMISSION_TYPE]: FOLDER_LEVEL_PERMISSIONS.PRIVATE,
            [FOLDER.PERMISSIONS]: true,
        })
        setIsFolderDetailsChanged(true);
    }

    const updateFolderName = async (newName, callback) => {
        if(newName.length > FOLDER_FIELDS[FOLDER.NAME].max){
            setSnackType(SNACK_TYPES.ERROR);
            setSnackMessage(`Folder name should not contain more than ${FOLDER_FIELDS[FOLDER.NAME].max} characters`);
            setFolderLoading(false);
            return false;
        }
        const result = await updateFolder({
            [FOLDER.NAME]: newName,
            [FOLDER.TEAM_ID]: folder[FOLDER.TEAM_ID],
        })
        if(result){
            folder[FOLDER.NAME] = newName;
            setFolder({...folder});
            if(_.isFunction(callback)) {
                callback(_.cloneDeep(folder));
            }
            return true;
        }
        return false;
    }

    const getIsFolderEditEnabledUtil = (admin, signedInUserIsOwner, user, folderPermissionsList, canCreateCampaign) => {
        if(admin || signedInUserIsOwner) return true;
        const teamUserUpnLower = user[TEAM_USER.UPN] ? user[TEAM_USER.UPN].toLowerCase() : "";
        const folderMember = folderPermissionsList.find(member => member[FOLDER_PERMISSION.UPN].toLowerCase() === teamUserUpnLower);
        const canEditFolder = (folderMember && folderMember[FOLDER_PERMISSION.ROLE] === FOLDER_LEVEL_ROLES.OWNER);
        if(canCreateCampaign && folderMember) {
            return canEditFolder || folderMember[FOLDER_PERMISSION.ROLE] === FOLDER_LEVEL_ROLES.MEMBER_EDIT;
        }
        return canEditFolder;
    }

    const getIsFolderEditEnabled = (folderPermissionsList = []) => {
        const admin = isUserAdmin(user);
        return getIsFolderEditEnabledUtil(admin, signedInUserIsOwner, user, folderPermissionsList);
    }

    const getCanCreateCampaign = (folderPermissionsList = []) => {
        const admin = isUserAdmin(user);
        return getIsFolderEditEnabledUtil(admin, signedInUserIsOwner, user, folderPermissionsList, true);
    }

    const getIsFolderOwner = (user, folderPermissionsList = []) => {
        const userRole = getUserRole(user, folderPermissionsList);
        return userRole === FOLDER_LEVEL_ROLES.OWNER;
    }

    const getUserRole = (user, folderPermissionsList = []) => {
        if(!folderPermissionsList || !user) return false;
        const userPermission = folderPermissionsList.find(permission => permission?.upn?.toLowerCase() === user[JWT.UPN].toLowerCase());
        if(!userPermission) return false;
        return userPermission[FOLDER_PERMISSION.ROLE]
    }

    const updateFolderDescription = (value) => {
        setFolder({...folder, [FOLDER.DESCRIPTION]: value});
        setFieldsChanged({...fieldsChanged, [FOLDER.DESCRIPTION]: value});
        setIsFolderDetailsChanged(true);
    }

    return {
        getFolder,
        updateFolderDetails,
        updateFolder,
        updateFolderName,
        removeMembers,
        updateMemberRole,
        addMember,
        folderLoading,
        isFolderDetailsChanged,
        setIsFolderDetailsChanged,
        folder,
        folder_id,
        setFolder,
        filterTeamFromTeamUsers,
        folderError,
        getIsFolderEditEnabledUtil,
        getIsFolderEditEnabled,
        updateFolderDescription,
        getCanCreateCampaign,
        getIsFolderOwner,
        getUserRole,
    }
}

export default useFolder;