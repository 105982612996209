const EVENT = 'EVENT';
const PAGE_VIEW = 'PAGE_VIEW';
const ERROR = 'ERROR';

class PulseService {
  static initialize(appName, getCustomDimensions) {
    PulseService.context = appName;
    PulseService.getCustomDimensions = getCustomDimensions;
  }
  static trackEvent = (name, data) => {
    this.sendBeacon(EVENT, name, data);
  };

  static trackPageView = (name, data) => {
    this.sendBeacon(PAGE_VIEW, name, data);
  };

  static trackError = (name, data) => {
    this.sendBeacon(ERROR, name, data);
  };

  static sendBeacon = (action, name, data) => {
    data = data || {};
    data = {
      ...data,
      ...PulseService.getCustomDimensions()
    };
    window._bcq.push(['_tagAction', PulseService.context, action, name, data]);
  };
  static getCustomDimensions = () => {
    return PulseService.customDimensions;
  };
}

export default PulseService;
