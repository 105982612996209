import moment from "moment-timezone";
import { TIME_ZONE } from "./Common";
moment.tz.setDefault(TIME_ZONE);

export const REPORTS_TABS = [{ label: "Summary" }, { label: "Content" }];

export const DATE_OPTIONS = [
  {
    title: "First 24 hours post-send",
    getDates: (start) => ({
      startDate: moment(start).toISOString(),
      endDate: moment(start).add(1, "days").toISOString(),
    }),
  },
  {
    title: "First 48 hours post-send",
    getDates: (start) => ({
      startDate: moment(start).toISOString(),
      endDate: moment(start).add(2, "days").toISOString(),
    }),
  },
  {
    title: "Yesterday",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime)
          .subtract(1, "days")
          .startOf("day")
          .toISOString(),
        endDate: moment(currentTime)
          .subtract(1, "days")
          .endOf("day")
          .toISOString(),
      };
    },
  },
  {
    title: "Last 2 days",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).subtract(2, "days").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Last 7 days",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).subtract(7, "days").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Custom Range",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).startOf("day").toISOString(),
        endDate: moment(currentTime).endOf("day").toISOString(),
      };
    },
  },
];

export const DEFAULT_TEAM_CREATION_DATE = "2021-01-01T00:00:00.000Z";
export const TEAM_REPORT_DATE_OPTIONS = [
  {
    title: "Max",
    getDates: (start) => {
      const startDate = new Date(start);
      const currentTime = new Date();
      return {
        startDate: moment(startDate).startOf("day").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Last 24 hours",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).subtract(1, "days").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Last 2 days",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).subtract(2, "days").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Last 7 days",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).subtract(7, "days").toISOString(),
        endDate: moment(currentTime).toISOString(),
      };
    },
  },
  {
    title: "Custom Range",
    getDates: () => {
      const currentTime = new Date();
      return {
        startDate: moment(currentTime).startOf("day").toISOString(),
        endDate: moment(currentTime).endOf("day").toISOString(),
      };
    },
  },
];

export const TIME_SCALE = {
  TIME_FORMAT: {
    d3: "%-I:%M%p",
    moment: "h:mA",
  },
  DAY_FORMAT: {
    d3: "%b %d",
    moment: "MMM DD",
  },
};

export const CHANNEL_STATS = {
  keys: ["mail", "teams", "zoom"],
  labels: {
    mail: "Email",
    teams: "Microsoft Teams",
    zoom: "Zoom",
  },
};

export const TIME_INTERVALS = {
  SIX_HOURS: "every 6 hours",
  THREE_HOURS: "every 3 hours",
  ONE_DAY: "every 1 day",
  ONE_WEEK: "every 1 week",
  ONE_MONTH: "every 1 month",
  ONE_QUARTER: "every 1 quarter",
  ONE_YEAR: "every 1 year",
};
