import React, { useState, useEffect } from "react";
import { HEX_COLORS } from "../consts/Colors";

// MUI
import {
  Paper,
  Grid,
  useMediaQuery,
  Fade,
  makeStyles,
} from "@material-ui/core";
import { createTheme } from "../consts/MuiTheme";
import { ThemeProvider } from "@material-ui/core/styles";

// components
import PageHeader from "../components/core/PageHeader";
import PageFooter from "../components/core/PageFooter";
import SnackBar from "../components/core/SnackBar";
import AlertBanner from "../components/core/AlertBanner";
import ErrorPage from "./ErrorPage";
import { trackPage } from "../utils/appInsight";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(() => ({
  pageBg: {
    backgroundColor: HEX_COLORS.lightestGray,
  },
  pageBgDark: {
    backgroundColor: HEX_COLORS.darkGray,
  },
  container: {
    minHeight: "100vh",
    maxHeight: "unset",
  },
  containerError: {
    minHeight: 0,
    maxHeight: 0,
  },
  content: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
  },
}));

function Page({
  user,
  title,
  error,
  clearError,
  showHeader = true,
  showFooter = false,
  fixedHeader = true,
  fixedFooter = false,
  children,
  ccmConfig,
  showAlertBanner,
  setPageTitle
}) {
  const classes = useStyles();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [theme, setTheme] = useState(createTheme(false));
  const location = useLocation();

  useEffect(() => {
    setTheme(createTheme(prefersDarkMode));
  }, [prefersDarkMode]);

  /**
   * send the page view event to adobe
   * on route change
   */
  useEffect(() => {
      if(!error && title) {
        trackPage({
          name: title
        });
        setPageTitle(title)
      }
  }, [location?.pathname])

  return (
    <>
      <ThemeProvider theme={theme}>
        <Paper
          square
          elevation={0}
          className={prefersDarkMode ? classes.pageBgDark : classes.pageBg}
        >
          {!error ? (
            <>
              <Fade in>
                <Grid
                  container
                  direction="column"
                  alignContent="center"
                  className={error ? classes.containerError : classes.container}
                >
                  <Grid item>
                    {showHeader && (
                      <PageHeader
                        user={user}
                        fixed={fixedHeader}
                        isDarkMode={prefersDarkMode}
                      />
                    )}
                  </Grid>
                  {showAlertBanner && <AlertBanner ccmConfig={ccmConfig} />}
                  <Grid item className={classes.content}>
                    {children}
                  </Grid>
                  <Grid item>
                    {showFooter && <PageFooter fixed={fixedFooter} />}
                  </Grid>
                </Grid>
              </Fade>
              <SnackBar />
            </>
          ) : (
            <Fade in>
              <ErrorPage error={error} clearError={clearError} />
            </Fade>
          )}
        </Paper>
      </ThemeProvider>
    </>
  );
}

export default Page;
