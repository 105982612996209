import { NOTIFICATION_CHANNELS } from "./Campaign";
import { TEAM } from "./DBFields";

export const ROLE_UPDATE_COPY = {
  OWNER: "Remove owner privileges",
  MEMBER: "Make an owner",
};
export const REMOVE_USER = "Remove from team";
export const ERROR = "Sorry, you don't have permission to access that team.";

export const CODES = {
  DUPLICATE_TEAM: "TEAM_ERR005",
  DUPLICATE_TEAM_USER: "POSTUSERTEAMS_ERR006",
  LAST_TEAM_MEMBER: "USER_OBJ_ERR007",
  USER_DOES_NOT_HAVE_ACCESS: "AUTH_E003"
};

export const ADD_MEMBER_COPY_PRIMARY = "Add member to";
export const ADD_MEMBER_COPY_SECONDARY = "You can always update this later.";

export const THEMES = {
  WALMART: {
    value: "Walmart",
    label: "Walmart",
    template: "COMMS",
    blobName: "walmartthemeimages",
  },
  GLOBAL_TECH: {
    value: "Global Tech",
    label: "Global Tech",
    template: "GLOBAL_TECH",
    blobName: "globaltechthemeimages",
  },
  SAMS: {
    value: "Sam's Club",
    label: "Sam's Club",
    template: "SAMS",
    blobName: "samsclubthemeimages",
  },
  INTL: {
    value: "International",
    label: "International",
    template: "INTL",
    blobName: "internationalthemeimages",
  },
};

export const TEAM_FIELDS = {
  [TEAM.NAME]: {
    label: "Team Name",
    helperText: "What is the name of this team?",
    required: true,
    type: "text",
    max: 50,
    min: 3,
    createOnly: true,
  },
  [TEAM.CHANNELS]: {
    label: "Channel",
    helperText: "Which channel(s) can the team send notifications to?",
    required: true,
    type: "checkbox",
    options: Object.values(NOTIFICATION_CHANNELS),
  },
  [TEAM.THEMES]: {
    label: "Themes",
    helperText: "Which assets would you like this team to have access to?",
    tooltip: {
      title:
        "Themes determine which templates and images will be made available to this team while creating campaigns and templates.",
    },
    required: true,
    type: "checkbox",
    options: Object.values(THEMES),
  },
  [TEAM.OWNERS]: {
    label: "Team Owners",
    helperText:
      "Who are the team owners who can manage the team? Please type name or email and select from the results.",
    required: true,
    type: "email",
    createOnly: true,
    associateOnly: true,
    exclusiveOf: TEAM.MEMBERS,
    exclusiveOfText: "This user is already added to the team as a member.",
    valueType: "userObject",
  },
  [TEAM.MEMBERS]: {
    label: "Team Members",
    helperText:
      "Who are the team members who can access the team? Please type name or email and select from the results.",
    type: "email",
    createOnly: true,
    associateOnly: true,
    exclusiveOf: TEAM.OWNERS,
    exclusiveOfText: "This user is already added to the team as an owner.",
    valueType: "userObject",
  },
  [TEAM.SENDERS]: {
    label: "Mailboxes",
    helperText: "Which mailboxes does this team have access to?",
    type: "email",
  },
};

export const TEAM_FIELD_ERROR_STATES = {
  REQUIRED: "This field is required.",
  MAX_LENGTH_EXCEEDED: (length) =>
    `This value must be less than ${length} characters.`,
  MIN_LENGTH_SUBCEEDED: (length) =>
    `This value must be at least ${length} characters.`,
};
