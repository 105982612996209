import { ErrorOutline } from "@material-ui/icons";
import { deleteSelectedComponent } from "../util";

export const DIALOGS = {
  DELETE_COMPONENT: {
    ID: "delete_component",
    TITLE: "Are you sure you want to delete this component?",
    PRIMARY_BUTTON: "Yes, delete it",
    SECONDARY_BUTTON: "Never mind",
    PRIMARY_ACTION: ({ editor, closeDialog, setSnackbar }) => {
      deleteSelectedComponent(editor);
      closeDialog();
      setSnackbar("This component has been successfully deleted.");
    },
    SECONDARY_ACTION: ({ closeDialog }) => {
      closeDialog();
    },
    IMAGE: <ErrorOutline fontSize="large" color="secondary" />,
  },
};
