import React, { useEffect, useState } from "react";

// MUI
import { Checkbox, FormControlLabel, FormHelperText } from "@material-ui/core";

function CheckboxField({ value, handleChange, field = {}, id }) {
  const [checked, toggleChecked] = useState(false);

  useEffect(() => {
    let boolChecked;
    switch (typeof value) {
      case "boolean":
        boolChecked = value;
        break;
      case "string":
        boolChecked = value === "true";
        break;
      default:
        boolChecked = false;
        break;
    }
    toggleChecked(boolChecked);
  }, [value]);

  return (
    <>
      <FormControlLabel
        disabled={field.disabled}
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => {
              handleChange(e.target.checked);
            }}
            name={`${id}-CheckboxField-Checkbox`}
          />
        }
        label={field.label}
        id={`${id}-Label`}
      />
      {field.helperText ? (
        <FormHelperText id={`${id}-CheckboxField-HelperText`}>
          {field.helperText}
        </FormHelperText>
      ) : null}
    </>
  );
}

export default CheckboxField;
