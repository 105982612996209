// customized logic for editor modules
import { dynamicToolbar, TYPES } from "../consts/DomComponents";
import { buildToolbar, getElementId, getElementType } from "../util";
import commands from "./commands";
import components from "./components";
import rte from "./rte";
import keymaps from "./keymaps";
import { CAMPAIGN_TYPES } from "../../../../../consts/Campaign";

export const DEFAULT_LINE_HEIGHT = 1.5;

// editor set up logic
const removePanels = (editor) => {
  editor?.Panels.removePanel("views");
  editor?.Panels.removePanel("options");
  editor?.Panels.removePanel("devices-c");
  editor?.Panels.removePanel("commands");
};
const handleUnsavedChanges = (editor, opts) => {
  if (editor && opts?.handleChange) {
    editor.on("update", () => {
      opts.handleChange();
    });
  }
};
const handleComponentDrop = (editor, opts) => {
  if (editor) {
    editor.on("block:drag:stop", function (model) {
      editor.select(model);
    });
  }
};
const handleSelectEvents = (editor, opts) => {
  if (!editor) {
    return;
  }
  editor.on("component:selected", function (model) {
    opts?.setSelectedElement(model);
    opts?.updateComponentSettings(model);
  });
  editor.on("component:deselected", function () {
    opts?.setSelectedElement(null);
    opts?.updateComponentSettings();
  });
};
const handleUpdateEvents = (editor, opts) => {
  // detect when a component's attributes (such as border, background-color, width, etc) update
  editor.on("component:update:attributes", function (model) {
    if (getElementId(model) === getElementId(editor.getSelected())) {
      opts?.updateComponentSettings(model);
    }
  });
  // button text and section stacking are not handled with attributes and requires both event listeners to update the setting panel
  editor.on("component:update:components", function (model) {
    const type = getElementType(model);
    if (
      getElementId(model) === getElementId(editor.getSelected()) &&
      (type === TYPES.SECTION ||
        type === TYPES.BUTTON ||
        type === TYPES.SOCIAL_MENU)
    ) {
      opts?.updateComponentSettings(model);
    }
  });
  editor.on("component:clone", function (newModel) {
    const attrs = newModel.getAttributes();
    const type = getElementType(newModel);
    if (attrs.variable && dynamicToolbar.includes(type)) {
      const tb = buildToolbar(
        editor.DomComponents.getType(TYPES.MUSTACHE_SECTION),
        opts?.campaignType === CAMPAIGN_TYPES.personalized.value
      );
      newModel.set("toolbar", tb);
    }
  });
  editor.on("undo", function () {
    const selected = editor.getSelected();
    const type = getElementType(selected);
    if (
      selected &&
      (type === TYPES.SECTION ||
        type === TYPES.BUTTON ||
        type === TYPES.SOCIAL_MENU)
    ) {
      opts?.updateComponentSettings(selected);
    }
  });
};

const styleText = (editor) => {
  // adjust default grapesjs styling of <p> tags
  let ccManager = editor.CssComposer;
  const selectorManager = editor.SelectorManager;
  const paragraph = selectorManager.add({
    name: "p",
    type: "tag",
    label: "p",
  });
  const li = selectorManager.add({
    name: "li",
    type: "tag",
    label: "li",
  });
  [paragraph, li].forEach((selector) => {
    const rule = ccManager.add(selector);
    rule.set("style", {
      "margin-top": "0 !important",
      "margin-bottom": "0 !important",
      "line-height": DEFAULT_LINE_HEIGHT,
    });
  });
};

const styleLists = (editor) => {
  let ccManager = editor.CssComposer;
  const selectorManager = editor.SelectorManager;
  const unordered = selectorManager.add({
    name: "ul",
    type: "tag",
    label: "ul",
  });
  const ordered = selectorManager.add({
    name: "ol",
    type: "tag",
    label: "ol",
  });
  [unordered, ordered].forEach((selector) => {
    let rule = ccManager.add(selector);
    rule.set("style", {
      "margin-block-start": 0,
      "margin-block-end": 0,
      "padding-block-start": 0,
      "padding-block-end": 0,
      Margin: 0,
    });
  });
};

// condense into one grapesjs "plugin"
export const plugins = {
  removePanels,
  handleUnsavedChanges,
  handleComponentDrop,
  handleSelectEvents,
  handleUpdateEvents,
  commands,
  components,
  rte,
  keymaps,
  styleText,
  styleLists,
};

const plugin = (editor, opts) => {
  Object.keys(plugins).forEach((index) => plugins[index](editor, opts));
};
export default plugin;
