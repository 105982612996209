import { AD_USER } from "../consts/Common";
import { TEAM, TEAM_USER } from "../consts/DBFields";
import { isEmpty, keys, get } from "lodash";
import { TEAM_FIELDS } from "../consts/Teams";

export const isValidTeamSettings = ({ errors = {}, teamObj = {}, isNew }) => {
  if (!isEmpty(errors)) return false;
  for (let field of keys(TEAM_FIELDS)) {
    if (TEAM_FIELDS[field].required && isEmpty(teamObj[field])) {
      if (isNew || !TEAM_FIELDS[field].createOnly) return false;
    }
  }
  return true;
};

export const serializeCreateTeamConfig = {
  toRequest: (teamConfig) => {
    const [firstOwner] = teamConfig[TEAM.OWNERS];

    return {
      [TEAM.NAME]: teamConfig[TEAM.NAME].trim(),
      [TEAM.THEMES]: teamConfig[TEAM.THEMES],
      [TEAM.CHANNELS]: teamConfig[TEAM.CHANNELS],
      [TEAM.SENDERS]: teamConfig[TEAM.SENDERS],
      owner: firstOwner[AD_USER.UPN],
      email: firstOwner[AD_USER.MAIL],
    };
  },

  fromResponse: (response) => {
    const [, teamId] = response.split(":");
    return teamId?.trim();
  },
};

export const serializeUpdateTeamConfig = {
  toRequest: (teamConfig) => ({
    [TEAM.NAME]: teamConfig?.[TEAM.NAME]?.trim(),
    [TEAM.CHANNELS]: teamConfig?.[TEAM.CHANNELS],
    [TEAM.SENDERS]: teamConfig?.[TEAM.SENDERS],
    [TEAM.THEMES]: teamConfig?.[TEAM.THEMES],
  }),
};

export const getCountForChannel = (data = {}, event = "sent") => {
  const campaignData = [];
  const notificationsData = [];
  data[event]?.data?.forEach(({ date, notificationChannel }) => {
    const campaignItem = { time: date };
    const notificationItem = { time: date };
    const buckets = get(notificationChannel, "metrics.buckets", []);
    buckets.forEach(({ key, doc_count, unique_campaign_count }) => {
      campaignItem[key] = unique_campaign_count?.value || 0;
      notificationItem[key] = doc_count;
    });
    campaignData.push(campaignItem);
    notificationsData.push(notificationItem);
  });

  return { campaignData, notificationsData };
};
