import { Box, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { setButtonText, setComponentHref } from "../grapesjs/settingsUtil";
import QA from "../../../../consts/QA";
import { CAMPAIGN_TYPES } from "../../../../consts/Campaign";
import VariableList from "./VariableList";
import VariableToggle from "./VariableToggle";
import { COPY } from "../grapesjs/consts/Settings";

const QA_ID_PREFIX = `${QA.PAGES.EDITOR}-ButtonText`;

function ButtonText({
  element,
  setting,
  componentSettings,
  campaignType,
  dataSourceHeadings,
}) {
  const { TEXT, HREF } = setting?.INPUTS;
  const [text, setText] = useState(undefined);
  const [variableText, toggleVariableText] = useState(false);
  const [href, setHref] = useState("");
  const [variableHref, toggleVariableHref] = useState(false);
  const inputEl = useRef();

  useEffect(() => {
    const button = componentSettings?.[setting.ID] || {};
    setText(button.text);
    toggleVariableText(button.text?.variable ? true : false);
    setHref(button.href);
    toggleVariableHref(button.href?.variable ? true : false);
  }, [componentSettings]);

  return (
    <>
      <Box mb={1}>
        <Typography variant="body2" color="textSecondary">
          <strong>{TEXT.label}</strong>
        </Typography>

        <Box>
          {text !== undefined ? (
            <>
              {!variableText ||
              campaignType !== CAMPAIGN_TYPES.personalized.value ? (
                <TextField
                  fullWidth
                  defaultValue={text?.value}
                  ref={(ref) => {
                    if (!inputEl.current) {
                      inputEl.current = ref;
                    }
                  }}
                  onChange={(e) => {
                    inputEl.current.value = e.target.value;
                    setButtonText(element, inputEl.current.value);
                  }}
                  variant="outlined"
                  margin="dense"
                  id={`${QA_ID_PREFIX}-TextField`}
                />
              ) : (
                <Box my={1}>
                  <VariableList
                    value={text?.variable || " "}
                    variables={dataSourceHeadings}
                    handleChange={(variable) => {
                      setButtonText(
                        element,
                        variable?.value,
                        variable?.variable,
                        variable?.cip
                      );
                    }}
                    id={`${QA_ID_PREFIX}-VariableList`}
                  />
                </Box>
              )}
              {campaignType === CAMPAIGN_TYPES.personalized.value ? (
                <VariableToggle
                  id={`${QA_ID_PREFIX}-VariableToggle`}
                  checked={variableText}
                  handleChange={() => {
                    let isVariable = !variableText;
                    toggleVariableText(isVariable);
                    if (!isVariable) {
                      setButtonText(element, text?.value);
                    }
                  }}
                  tooltipProps={{
                    title: COPY.DYNAMIC_BUTTON_TOOLTIP,
                  }}
                />
              ) : null}
            </>
          ) : null}
        </Box>
      </Box>
      <Box mb={1}>
        <Typography variant="body2" color="textSecondary">
          <strong>{HREF.label}</strong>
        </Typography>
        <Box>
          {!variableHref ||
          campaignType !== CAMPAIGN_TYPES.personalized.value ? (
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              helperText="Example: https://one.walmart.com"
              value={href?.value}
              onChange={(e) => {
                let newHref = e.target.value;
                setHref({ value: newHref });
              }}
              onBlur={() => {
                setComponentHref(element, href?.value);
              }}
              id={`${QA_ID_PREFIX}-TextField-Href`}
            />
          ) : (
            <Box my={1}>
              <VariableList
                value={href?.variable || " "}
                variables={dataSourceHeadings}
                handleChange={(variable) => {
                  setComponentHref(
                    element,
                    variable?.value,
                    variable?.variable,
                    variable?.cip
                  );
                }}
                id={`${QA_ID_PREFIX}-Href-VariableList`}
              />
            </Box>
          )}
          {campaignType === CAMPAIGN_TYPES.personalized.value ? (
            <VariableToggle
              id={`${QA_ID_PREFIX}-Href-VariableToggle`}
              checked={variableHref}
              handleChange={() => {
                let isVariable = !variableHref;
                toggleVariableHref(isVariable);
                if (!isVariable) {
                  setComponentHref(element, href?.value);
                }
              }}
              tooltipProps={{
                title: COPY.DYNAMIC_BUTTON_HREF_TOOLTIP,
              }}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}

export default ButtonText;
